/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useLocation, Link } from "react-router-dom";
import { constant } from "../constant";
import airlinesName from "../Component/AirlineName/AirlineName";
import y8ud7 from "../assest/images/icon/checki.png";
import dgwe4 from "../assest/images/khte.png";
import downh3 from "../assest/images/icon/down2.png";
import y7687 from "../assest/images/icon/traveler.png";
import yp8ed from "../assest/images/icon/payment1.png";
import dor6y from "../assest/images/icon/downd.png";
import dosrfy from "../assest/images/icon/calling.png";
import awsf5y from "../assest/images/icon/mailing.png";
import adft5r from "../assest/images/icon/booking1.png";
import lungage1 from "../assest/images/icon/luggage1.png";
import lungage2 from "../assest/images/icon/luggage2.png";
import lungage3 from "../assest/images/icon/luggage3.png";
import dfge5 from "../assest/images/icon/dst4w.png";
import apwhu7 from "../assest/images/icon/appro.png";
import rrdf5y from "../assest/images/icon/transfer1.png";
import awsey from "../assest/images/icon/support1.png";
import Header1 from "../Component/Header/Header1";
import Footer1 from "../Component/Footer/Footer1";
import MetaTitle from "../Component/MetaTitle/metaTitle";

function formatDateAndTime(isoDate) {
  const dateOptions = {
    weekday: "short",
    month: "short",
    day: "numeric",
  };

  const timeOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const formattedDate = new Date(isoDate);
  const formattedDateString = new Intl.DateTimeFormat(
    "en-US",
    dateOptions
  ).format(formattedDate);
  const formattedTimeString = new Intl.DateTimeFormat(
    "en-US",
    timeOptions
  ).format(formattedDate);

  return { formattedDateString, formattedTimeString };
}

function convertMinutesToHoursAndMinutes(minutes) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours}h ${remainingMinutes}m`;
}

const formatDate = (dateString) => {
  const dateParts = dateString.split("-"); // Split the date string into [year, month, day]
  const year = dateParts[0];
  const month = parseInt(dateParts[1]) - 1; // Convert month to zero-based index
  const day = dateParts[2];

  const date = new Date(year, month, day); // Create a Date object

  // Format the date
  const options = { day: "numeric", month: "short", year: "numeric" };
  return date.toLocaleDateString("en-US", options); // Convert to desired format
};

export default function Confirmation() {
  const location = useLocation();
  const { confirmData, values } = location.state.responseData || {};
  const [airportName, setAirportName] = useState(
    confirmData.flightResut.airports
  );
  const [utmCampaign, setUtmCampaign] = useState("");
    const [displayNumber, setDisplayNumber] = useState("");
    const jsonMapping = {
        domestics_routes: "+1-833-609-8333",
        domestics_destination: "+1-833-609-7300",
        cruise : "+1-833-609-9933",
        airline: "+1-833-609-8444"
      };
      const defaultNumber = "+1-888-415-1536"; // Default number to display if UTM data is not found
  
      useEffect(() => {
        const updateDisplayNumber = () => {
          const utmData = JSON.parse(localStorage.getItem("utm_data")) || {};
        //   console.log(utmData)
          if (utmData?.utmData?.utm_campaign) {
            // console.log("in")
            setUtmCampaign(utmData?.utm_campaign);
    
            // Check if `utm_campaign` exists in the JSON mapping
            const mappedNumber = jsonMapping[utmData?.utmData?.utm_campaign];
            setDisplayNumber(mappedNumber || defaultNumber); // Use mapped number or default
          } else {
            // console.log("out")
            // No UTM data found, use the default number
            setDisplayNumber(defaultNumber);
          }
        };
    
        // Run initially
        updateDisplayNumber();
    
        // Set up interval to check every 10 seconds
        const interval = setInterval(updateDisplayNumber, 1000000000000);
    
        // Clean up interval on component unmount
        return () => clearInterval(interval);
      }, [jsonMapping]);
  // console.log("confirm data", confirmData);
  const calculateTotalTime = (segments) => {
    // if (segments.length === 0) return null;

    // Get the departure time of the first segment
    const firstDepartureTime = new Date(segments[0].depDate);
    // Get the arrival time of the last segment
    const lastArrivalTime = new Date(segments[segments.length - 1].reachDate);

    // Calculate the total duration in milliseconds
    const durationInMs = lastArrivalTime - firstDepartureTime;

    // Convert milliseconds to hours and minutes
    const totalHours = Math.floor(durationInMs / (1000 * 60 * 60));
    const totalMinutes = Math.floor(
      (durationInMs % (1000 * 60 * 60)) / (1000 * 60)
    );

    return (
      <span>
        {totalHours}h {totalMinutes}m
      </span>
    );
  };

  const handleDownload = async () => {
    const element = document.body; // Or specify the exact element you want to capture
    if (!element) return;

    try {
      const canvas = await html2canvas(element, {
        useCORS: true, // Enable cross-origin resource sharing
        scale: 2, // Increase the quality of the screenshot
      });

      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4"); // Portrait, mm units, A4 page size

      // Get dimensions of the PDF page
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Calculate the aspect ratio and scale
      const canvasAspectRatio = canvas.width / canvas.height;
      const pdfAspectRatio = pdfWidth / pdfHeight;

      let imgWidth, imgHeight;
      if (canvasAspectRatio > pdfAspectRatio) {
        imgWidth = pdfWidth;
        imgHeight = pdfWidth / canvasAspectRatio;
      } else {
        imgHeight = pdfHeight;
        imgWidth = pdfHeight * canvasAspectRatio;
      }

      const xOffset = (pdfWidth - imgWidth) / 2; // Center horizontally
      const yOffset = (pdfHeight - imgHeight) / 2; // Center vertically

      // Add image to PDF
      pdf.addImage(imgData, "PNG", xOffset, yOffset, imgWidth, imgHeight);

      // Save the PDF
      pdf.save(`BookingPage_${confirmData.bookingID}.pdf`);
    } catch (error) {
      console.error("Failed to download the PDF:", error);
    }
  };

  const totalPassengers =
    (confirmData?.adults && confirmData?.flightResut.adults
      ? parseInt(confirmData?.flightResut.adults)
      : 0) +
    (confirmData?.child && confirmData?.flightResut.child
      ? parseInt(confirmData?.flightResut.child)
      : 0) +
    (confirmData?.infants && confirmData?.flightResut.infants
      ? parseInt(confirmData?.flightResut.infants)
      : 0) +
    (confirmData?.infantsWs && confirmData?.flightResut.infantsWs
      ? parseInt(confirmData?.flightResut.infantsWs)
      : 0);

  const totalPriceAdults = (
    confirmData?.adults * confirmData?.flightResut.fare.adultFare + confirmData?.adults * confirmData?.flightResut.fare.adultTax
  );
  const totalPriceChild = (
    confirmData?.child * confirmData?.flightResut.fare.childFare + confirmData?.child * confirmData?.flightResut.fare.childTax
  );
  const totalPriceInfant = (
    (confirmData?.infants && confirmData?.flightResut.fare.infantFare
      ? parseInt(confirmData?.infants)
      : 0) * confirmData?.flightResut.fare.infantFare + (confirmData?.infants && confirmData?.flightResut.fare.infantFare
        ? parseInt(confirmData?.infants)
        : 0) * confirmData?.flightResut.fare.infantTax
  );
  const totalPriceInfantWs = (
    (confirmData?.infantsWs && confirmData?.flightResut.fare.infantWsFare
      ? parseInt(confirmData?.infantsWs)
      : 0) * confirmData?.flightResut.fare.infantWsFare + (confirmData?.infantsWs && confirmData?.flightResut.fare.infantWsFare
        ? parseInt(confirmData?.infantsWs)
        : 0) * confirmData?.flightResut.fare.infantWsTax
  );
  // const totalPriceSeat = (totalPassengers * 7.99);
  // const totalWebCheckInPrice = (totalPassengers * 7.99);

  let totalPrice = confirmData?.flightResut.fare.grandOrgTotal;
  if (
    confirmData?.ancillaryProduct &&
    confirmData?.ancillaryProduct.length > 0
  ) {
    // Use reduce to sum up the prices of all ancillary products
    totalPrice += confirmData?.ancillaryProduct.reduce(
      (sum, product) => sum + product.price,
      0
    );
  }

  const roundedPrice = totalPrice; // Rounds to 2 decimal places

  const totalTax = confirmData?.flightResut.fare.totalTax;
  const roundedTax = totalTax?.toFixed(2);

  return (
    <>
      <MetaTitle url={`${constant.baseUrl}/confirmation`} />
      <Header1 />
      <section className="iure4">
        <div className="container">
          <div className="row">
            <div className="jgtr6">
              <img className="jisahr" src={y8ud7} alt="" />
              <p className="ued67 mb-1">Booking In Progress</p>
              <p className="hn57d mb-1">
                Great Choice!{" "}
                <span className="text-white text-capitalize">
                  {confirmData.passengerDetails[0].firstName}
                </span>
              </p>
              <p className="urt78 mb-0">
                Congratulations Your Flight Booking is in progress
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="ij978 iure4">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <p className="iu857f mb-0">Your Booking ID Number</p>
              <p className="rtj95 mb-0 confir-m">{confirmData.bookingID}</p>
              <button className="eduit4" onClick={handleDownload}>
                Download
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="iure4 pt-0">
        <div className="container px-0">
          <div className="row">
            <div className="col-lg-8">
              <div className="jda34 et643">
                <div className="jtest24">
                  <div className="row align-items-center pt-3 px-2 pb-2">
                    <div className="col-2 col-lg-6">
                      <p className="mb-0 dstge3">Depart</p>
                    </div>
                    <div className="col-10 col-lg-6 text-end">
                      <p className="mb-0 dt54s">
                        Total Trip time:{" "}
                        <span>
                        {convertMinutesToHoursAndMinutes(
                                      confirmData.flightResut.outBound[0].estimateTime
                                    )}
                        </span>
                      </p>
                    </div>
                  </div>
                  {confirmData.flightResut.outBound.map((flight, index) => (
                    <div key={index}>
                      <div className="row px-2">
                        <div className="col-8 col-lg-6">
                          <div className="d-flex align-items-center">
                            <img
                              className="hftrr"
                              src={`https://imgfolders.com/farehutz/${flight.airlineName}.png`}
                              alt={flight.airlineName}
                            />
                            <div className="iksr">
                              <p className="ikrtr">
                                {airlinesName[flight.airlineName]}
                              </p>
                              <p className="mb-0 dst342">
                                Aircraft{" "}
                                <span>
                                  {flight.equipmentType}{" "}
                                  {/* <p className="dsfed4">
                                    Embraer EMB 175 94-106 STD Seats
                                  </p> */}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-4 col-lg-6 text-end">
                          <p className="mb-0 dt54s">
                            <span>
                              {flight.cabinClass === 1
                                ? "Economy"
                                : flight.cabinClass === 2
                                ? "Premium Economy"
                                : flight.cabinClass === 3
                                ? "Business"
                                : flight.cabinClass === 4
                                ? "First"
                                : ""}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="row px-2">
                        <div className="col-lg-12">
                          <div className="jjeisr4">
                            <span>Operated by :</span>{" "}
                            <span className="ioheri">
                              {airlinesName[flight.opratingAirline]}
                            </span>
                          </div>
                          <p className="jioshr mb-0">
                            Flight Duration:{" "}
                            {convertMinutesToHoursAndMinutes(flight.eft)}{" "}
                            <span>{flight.flightNo}</span>
                          </p>

                          <div className="row align-items-center">
                            <div className="col-2 col-lg-1">
                              <div className="dtges"></div>
                              <div className="destde"></div>
                              <div className="iehte3"></div>
                            </div>
                            <div className="col-10 col-lg-11">
                              <div className="row my-3">
                                <div className="col-lg-2">
                                  <p className="edjhire">
                                    {new Date(
                                      flight.depDate
                                    ).toLocaleTimeString([], {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: true,
                                    })}
                                  </p>
                                  <p className="kijdte mb-0">
                                    {
                                      formatDateAndTime(flight.depDate)
                                        .formattedDateString
                                    }
                                  </p>
                                </div>
                                <div className="col-lg-10">
                                  <p className="edjhire">
                                    {flight.fromAirport &&
                                      airportName?.find(
                                        (a) =>
                                          a.airportCode === flight.fromAirport
                                      )?.airportName}{" "}
                                    Airport,
                                  </p>
                                  <p className="kijdte mb-0">
                                    {flight.fromAirport},{" "}
                                    {flight.fromAirport &&
                                      airportName?.find(
                                        (a) =>
                                          a.airportCode === flight.fromAirport
                                      )?.countryName}
                                  </p>
                                </div>
                              </div>
                              <div className="row my-3 gap-2 bagdis">
                                <div className="det4">
                                  <img
                                    className="ihtg45"
                                    src={lungage1}
                                    alt=""
                                  />
                                  <p className="mb-0">
                                    Personal Item (45 x 35 x 20 cm)
                                  </p>
                                </div>
                                <div className="det4 edtt">
                                  <img
                                    className="ihtg45"
                                    src={lungage2}
                                    alt=""
                                  />
                                  <p className="mb-0">No Carry on bag</p>
                                  <img className="ms-3" src={dfge5} alt="" />
                                </div>
                                <div className="det4 edtt">
                                  <img
                                    className="ihtg45"
                                    src={lungage3}
                                    alt=""
                                  />
                                  <p className="mb-0">No checked bag</p>
                                  <img className="ms-3" src={dfge5} alt="" />
                                </div>
                              </div>
                              <div className="row my-3">
                                <div className="col-lg-2">
                                  <p className="edjhire">
                                    {new Date(
                                      flight.reachDate
                                    ).toLocaleTimeString([], {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: true,
                                    })}
                                  </p>
                                  <p className="kijdte mb-0">
                                    {
                                      formatDateAndTime(flight.reachDate)
                                        .formattedDateString
                                    }
                                  </p>
                                </div>
                                <div className="col-lg-10 rela">
                                  <p className="edjhire dseaf rela">
                                    {flight.toAirport &&
                                      airportName?.find(
                                        (a) =>
                                          a.airportCode === flight.toAirport
                                      )?.airportName}{" "}
                                    Airport,
                                    <p className="mb-0 jhrt5">
                                                {(() => {
                                                  const depDate = new Date(
                                                    flight.depDate
                                                  );
                                                  const reachDate = new Date(
                                                    flight.reachDate
                                                  );

                                                  // Normalize the dates to midnight to accurately compare days
                                                  const depDateMidnight =
                                                    new Date(
                                                      depDate.getFullYear(),
                                                      depDate.getMonth(),
                                                      depDate.getDate()
                                                    );
                                                  const reachDateMidnight =
                                                    new Date(
                                                      reachDate.getFullYear(),
                                                      reachDate.getMonth(),
                                                      reachDate.getDate()
                                                    );

                                                  const timeDifference =
                                                    reachDateMidnight -
                                                    depDateMidnight; // Time difference in milliseconds
                                                  const dayDifference =
                                                    timeDifference /
                                                    (1000 * 60 * 60 * 24); // Convert to days

                                                  return dayDifference > 0
                                                    ? `+${dayDifference} Day${
                                                        dayDifference > 1
                                                          ? "s"
                                                          : ""
                                                      }`
                                                    : "";
                                                })()}
                                              </p>
                                  </p>
                                  <p className="kijdte mb-0">
                                    {flight.toAirport},{" "}
                                    {flight.toAirport &&
                                      airportName?.find(
                                        (a) =>
                                          a.airportCode === flight.toAirport
                                      )?.countryName}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {flight.layOverTime > 0 && (
                                  <div className="row align-items-center et4dsf">
                                    <div className="col-1">
                                      <div className="jgdr">
                                        <img src={apwhu7} alt="" />
                                      </div>
                                    </div>
                                    <div className="col-11">
                                      <p className="mb-0 dser34">
                                        Layover:{" "}
                                        {convertMinutesToHoursAndMinutes(
                                          flight.layOverTime
                                        )}{" "}
                                        <span>
                                          {flight.toAirport &&
                                            airportName.find(
                                              (a) =>
                                                a.airportCode ===
                                                flight.toAirport
                                            )?.airportName}{" "}
                                          ({flight.toAirport})
                                        </span>
                                        {flight?.toAirport !== confirmData.flightResut?.outBound[index + 1]?.fromAirport && (
                            <span className="hawurh">Change of Airport Required</span>
                        )}
                                      </p>
                                    </div>
                                  </div>
                                )}
                    </div>
                  ))}
                </div>
                <hr className="jhed24" />
                <div className="jtest24 pb-3">
                  {confirmData?.flightResut?.inBound?.length > 0 && (
                    <div className="row align-items-center px-2 pb-2">
                      <div className="col-2 col-lg-6">
                        <p className="mb-0 dstge3">Return</p>
                      </div>
                      <div className="col-10 col-lg-6 text-end">
                        <p className="mb-0 dt54s">
                          Total Trip time:{" "}
                          <span>
                          {convertMinutesToHoursAndMinutes(
                                        confirmData.flightResut?.inBound[0].estimateTime
                                      )}
                          </span>
                        </p>
                      </div>
                    </div>
                  )}
                  {confirmData.flightResut?.inBound?.map((flight, index) => (
                    <div key={index}>
                      <div className="row px-2">
                        <div className="col-8 col-lg-6">
                          <div className="d-flex align-items-center">
                            <img
                              className="hftrr"
                              src={`https://imgfolders.com/farehutz/${flight.airlineName}.png`}
                              alt=""
                            />
                            <div className="iksr">
                              <p className="ikrtr">
                                {airlinesName[flight.airlineName]}
                              </p>
                              <p className="mb-0 dst342">
                                Aircraft{" "}
                                <span>
                                  {flight.equipmentType}{" "}
                                  {/* <p className="dsfed4">
                                    Embraer EMB 175 94-106 STD Seats
                                  </p> */}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-4 col-lg-6 text-end">
                          <p className="mb-0 dt54s">
                            <span>
                              {flight.cabinClass === 1
                                ? "Economy"
                                : flight.cabinClass === 2
                                ? "Premium Economy"
                                : flight.cabinClass === 3
                                ? "Business"
                                : flight.cabinClass === 4
                                ? "First"
                                : ""}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="row px-2">
                        <div className="col-lg-12">
                          <div className="jjeisr4">
                            <span>Operated by :</span>{" "}
                            <span className="ioheri">
                              {airlinesName[flight.opratingAirline]}
                            </span>
                          </div>
                          <p className="jioshr mb-0">
                            Flight Duration:{" "}
                            {convertMinutesToHoursAndMinutes(flight.eft)}{" "}
                            <span>{flight.flightNo}</span>
                          </p>

                          <div className="row align-items-center">
                            <div className="col-2 col-lg-1">
                              <div className="dtges"></div>
                              <div className="destde"></div>
                              <div className="iehte3"></div>
                            </div>
                            <div className="col-10 col-lg-11">
                              <div className="row my-3">
                                <div className="col-lg-2">
                                  <p className="edjhire">
                                    {new Date(
                                      flight.depDate
                                    ).toLocaleTimeString([], {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: true,
                                    })}
                                  </p>
                                  <p className="kijdte mb-0">
                                    {
                                      formatDateAndTime(flight.depDate)
                                        .formattedDateString
                                    }
                                  </p>
                                </div>
                                <div className="col-lg-10 rela">
                                  <p className="edjhire dseaf rela">
                                    {flight.fromAirport &&
                                      airportName?.find(
                                        (a) =>
                                          a.airportCode === flight.fromAirport
                                      )?.airportName}{" "}
                                    Airport,
                                    
                                  </p>
                                  <p className="kijdte mb-0">
                                    {flight.fromAirport},{" "}
                                    {flight.fromAirport &&
                                      airportName?.find(
                                        (a) =>
                                          a.airportCode === flight.fromAirport
                                      )?.countryName}
                                  </p>
                                </div>
                              </div>
                              <div className="row my-3 gap-2 bagdis">
                                <div className="det4">
                                  <img
                                    className="ihtg45"
                                    src={lungage1}
                                    alt=""
                                  />
                                  <p className="mb-0">
                                    Personal Item (45 x 35 x 20 cm)
                                  </p>
                                </div>
                                <div className="det4 edtt">
                                  <img
                                    className="ihtg45"
                                    src={lungage2}
                                    alt=""
                                  />
                                  <p className="mb-0">No Carry on bag</p>
                                  <img className="ms-3" src={dfge5} alt="" />
                                </div>
                                <div className="det4 edtt">
                                  <img
                                    className="ihtg45"
                                    src={lungage3}
                                    alt=""
                                  />
                                  <p className="mb-0">No checked bag</p>
                                  <img className="ms-3" src={dfge5} alt="" />
                                </div>
                              </div>
                              <div className="row my-3">
                                <div className="col-lg-2">
                                  <p className="edjhire">
                                    {new Date(
                                      flight.reachDate
                                    ).toLocaleTimeString([], {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: true,
                                    })}
                                  </p>
                                  <p className="kijdte mb-0">
                                    {
                                      formatDateAndTime(flight.reachDate)
                                        .formattedDateString
                                    }
                                  </p>
                                </div>
                                <div className="col-lg-10">
                                  <p className="edjhire">
                                    {flight.toAirport &&
                                      airportName?.find(
                                        (a) =>
                                          a.airportCode === flight.toAirport
                                      )?.airportName}{" "}
                                    Airport,
                                    <p className="mb-0 jhrt5">
                                                {(() => {
                                                  const depDate = new Date(
                                                    flight.depDate
                                                  );
                                                  const reachDate = new Date(
                                                    flight.reachDate
                                                  );

                                                  // Normalize the dates to midnight to accurately compare days
                                                  const depDateMidnight =
                                                    new Date(
                                                      depDate.getFullYear(),
                                                      depDate.getMonth(),
                                                      depDate.getDate()
                                                    );
                                                  const reachDateMidnight =
                                                    new Date(
                                                      reachDate.getFullYear(),
                                                      reachDate.getMonth(),
                                                      reachDate.getDate()
                                                    );

                                                  const timeDifference =
                                                    reachDateMidnight -
                                                    depDateMidnight; // Time difference in milliseconds
                                                  const dayDifference =
                                                    timeDifference /
                                                    (1000 * 60 * 60 * 24); // Convert to days

                                                  return dayDifference > 0
                                                    ? `+${dayDifference} Day${
                                                        dayDifference > 1
                                                          ? "s"
                                                          : ""
                                                      }`
                                                    : "";
                                                })()}
                                              </p>
                                  </p>
                                  <p className="kijdte mb-0">
                                    {flight.toAirport},{" "}
                                    {flight.toAirport &&
                                      airportName?.find(
                                        (a) =>
                                          a.airportCode === flight.toAirport
                                      )?.countryName}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {flight.layOverTime > 0 && (
                                  <div className="row align-items-center et4dsf">
                                    <div className="col-1">
                                      <div className="jgdr">
                                        <img src={apwhu7} alt="" />
                                      </div>
                                    </div>
                                    <div className="col-11">
                                      <p className="mb-0 dser34">
                                        Layover:{" "}
                                        {convertMinutesToHoursAndMinutes(
                                          flight.layOverTime
                                        )}{" "}
                                        <span>
                                          {flight.toAirport &&
                                            airportName.find(
                                              (a) =>
                                                a.airportCode ===
                                                flight.toAirport
                                            )?.airportName}{" "}
                                          ({flight.toAirport})
                                        </span>
                                        {flight?.toAirport !== confirmData.flightResut?.inBound[index + 1]?.fromAirport && (
                            <span className="hawurh">Change of Airport Required</span>
                        )}
                                      </p>
                                    </div>
                                  </div>
                                )}
                    </div>
                  ))}
                </div>
              </div>
              <div className="hgdr679">
                <div className="dsctgj4 d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div className="uiety4">
                      <img src={y7687} alt="" />
                    </div>{" "}
                    <p className="mb-0 jsryt7">Traveler's Details</p>
                  </div>
                </div>

                <div className="row">
                  {/* <div className="col-lg-12">
                    <p className="etji4 mb-2">Passenger 1: Adult</p>
                  </div> */}
                  <div className="col-5">
                    <p className="jie89 mb-0 pb-0">Name</p>
                  </div>
                  <div className="col-4">
                    <p className="jie89 mb-0 pb-0">D.O.B</p>
                  </div>
                  <div className="col-2 d-none">
                    <p className="jie89 mb-0 pb-0">Gender</p>
                  </div>
                  <div className="col-3">
                    <p className="jie89 mb-0 pb-0">Type</p>
                  </div>
                </div>
                {confirmData.passengerDetails.map((passenger, index) => (
                  <div className="row pb-3 pb-lg-0">
                    <div className="col-5">
                      <p className="jie896 mb-0 pt-0 text-capitalize">
                      {passenger.title} {passenger.firstName} {passenger.middleName} {passenger.lastName}
                      </p>
                    </div>
                    <div className="col-4">
                      <p className="jie896 mb-0 pt-0">
                        {formatDate(passenger.dateOfBirth)}
                      </p>
                    </div>
                    <div className="col-2 d-none">
                      <p className="jie896 mb-0 pt-0">{passenger.gender}</p>
                    </div>
                    <div className="col-3">
                      <p className="jie896 mb-0 pt-0">
                        {passenger.passengerType === 1
                          ? "Adult"
                          : passenger.passengerType === 2
                          ? "Child"
                          : passenger.passengerType === 3
                          ? "Infant"
                          : passenger.passengerType === 4
                          ? "InfantWs"
                          : ""}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="hgdr679">
                <div className="dsctgj4 d-flex align-items-center justify-content-between">
                  <div className=" d-flex align-items-center">
                    <div className="uiety4">
                      <img src={yp8ed} alt="" />
                    </div>
                    <p className="mb-0 jsryt7">
                      Billing{" "}Information
                      {/* <span className="mob-hide">
                        (Secure SSL Encrypted Transaction)
                      </span>{" "} */}
                    </p>
                  </div>
                </div>
                <div className="row py-3 py-lg-0">
                  <div className="col-lg-6">
                    <p className="jie89 mb-0 pb-0">Biiling Number</p>
                    <p className="jie896 mb-0 pt-0">
                      {confirmData.paymentDetails.billingMobile}
                    </p>
                  </div>
                  <div className="col-lg-6">
                    <p className="jie89 mb-0 pb-0">Email Id</p>
                    <p className="jie896 mb-0 pt-0">
                      {values?.emailID}
                    </p>
                  </div>
                  {/* <div className="col-6">
                    <p className="jie89 mb-0 pb-0">Cardholder Name</p>
                    <p className="jie896 mb-0 pt-0">
                      {confirmData.paymentDetails.cardHolderName}
                    </p>
                  </div> */}
                  {/* <div className="col-6">
                    <p className="jie89 mb-0 pb-0">Card No.</p>
                    <p className="jie896 mb-0 pt-0">
                      {confirmData.paymentDetails.maskCardNumber}
                    </p>
                  </div> */}
                  {/* <div className="col-6">
                    <p className="jie89 mb-0 pb-0">Card Type</p>
                    <p className="jie896 mb-0 pt-0">
                      {confirmData.paymentDetails.cardCode}
                    </p>
                  </div> */}
                </div>
              </div>
              <div className="hgdr679">
                <div className="dsctgj4 d-flex align-items-center justify-content-between">
                  <div className=" d-flex align-items-center">
                    <div className="uiety4">
                      <img src={yp8ed} alt="" />
                    </div>
                    <p className="mb-0 jsryt7">
                      Billing{" "}Address
                    </p>
                  </div>
                </div>
                <div className="row py-3 py-lg-0">
                  <div className="col-12">
                    <p className="jie89 mb-0 pb-0">Biiling Address</p>
                    <p className="jie896 mb-0 pt-0">
                      {confirmData.paymentDetails.address1}
                    </p>
                  </div>
                   <div className="col-6">
                    <p className="jie89 mb-0 pb-0">Country Name</p>
                    <p className="jie896 mb-0 pt-0">
                    {confirmData.paymentDetails.country}
                    </p>
                  </div> 
                  <div className="col-6">
                    <p className="jie89 mb-0 pb-0">State Name</p>
                    <p className="jie896 mb-0 pt-0">
                    {confirmData.paymentDetails.state}
                    </p>
                  </div> 
                   <div className="col-6">
                    <p className="jie89 mb-0 pb-0">City Name</p>
                    <p className="jie896 mb-0 pt-0">
                    {confirmData.paymentDetails.city}
                    </p>
                  </div> 
                  <div className="col-6">
                    <p className="jie89 mb-0 pb-0">Pin Code</p>
                    <p className="jie896 mb-0 pt-0">
                    {confirmData.paymentDetails.postalCode}
                    </p>
                  </div> 
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="uit">
                <p className="mb-0 hdr68">Congrats!</p>
                <p className="uryt8">
                  Thank you for booking with us! Your flight is in progress.
                </p>
              </div>
              <div className="hgdr679 overjkg">
                <div className="row jueg5 align-items-center">
                  <div className="col-lg-12">
                    <p className="jd679 mb-0">Price Details (USD)</p>
                  </div>
                </div>
                <div className="px-3">
                  <div className="row align-items-center ht4jtr">
                    <div className="col-8 px-1 borje4">
                      <p className="mb-0 man9d">
                        {confirmData.adults} Traveler(s) : Adult
                      </p>
                    </div>
                    <div className="col-4 text-end px-1 borje4">
                      <p className="mb-0 prid9">
                        USD {totalPriceAdults.toFixed(2).split('.')[0]}<sup>.{totalPriceAdults.toFixed(2).split('.')[1]}</sup>
                        {/*<sup>89</sup>*/}
                      </p>
                    </div>
                  </div>
                  {confirmData.child !== 0 ? (
                    <div className="row align-items-center ht4jtr">
                      <div className="col-8 px-1 borje4">
                        <p className="mb-0 man9d">
                          {confirmData.child} Traveler(s) : Child
                        </p>
                      </div>
                      <div className="col-4 text-end px-1 borje4">
                        <p className="mb-0 prid9">
                          USD{" "}
                          {totalPriceChild.toFixed(2).split('.')[0]}<sup>.{totalPriceChild.toFixed(2).split('.')[1]}</sup>
                          {/*<sup>89</sup>*/}
                        </p>
                      </div>
                    </div>
                  ) : null}
                  {confirmData.infants !== 0 ? (
                    <div className="row align-items-center ht4jtr">
                      <div className="col-8 px-1 borje4">
                        <p className="mb-0 man9d">
                          {confirmData.infants} Traveler(s) : Infant(lap)
                        </p>
                      </div>
                      <div className="col-4 text-end px-1 borje4">
                        <p className="mb-0 prid9">
                          USD{" "}
                          {totalPriceInfant.toFixed(2).split('.')[0]}<sup>.{totalPriceInfant.toFixed(2).split('.')[1]}</sup>
                          {/*<sup>89</sup>*/}
                        </p>
                      </div>
                    </div>
                  ) : null}
                  {confirmData.infantsWs !== 0 ? (
                    <div className="row align-items-center ht4jtr">
                      <div className="col-8 px-1 borje4">
                        <p className="mb-0 man9d">
                          {confirmData.infantsWs} Traveler(s) : infant(seat)
                        </p>
                      </div>
                      <div className="col-4 text-end px-1 borje4">
                        <p className="mb-0 prid9">
                          USD{" "}
                          {totalPriceInfantWs.toFixed(2).split('.')[0]}<sup>.{totalPriceInfantWs.toFixed(2).split('.')[1]}</sup>
                          {/*<sup>89</sup>*/}
                        </p>
                      </div>
                    </div>
                  ) : null}
                  {/* <div className="row align-items-center ht4jtr">
                            <div className="col-lg-8 px-1 borje4">
                            <p className='mb-0 man9d'>Web Check-In</p>
                            </div>
                            <div className="col-lg-4 text-end px-1 borje4">
                            <p className='mb-0 prid9'>USD 10.<sup>99</sup></p>
                            </div>
                        </div> */}
                  {confirmData?.ancillaryProduct[0]?.name !== "" && (
                    <>
                      {confirmData?.ancillaryProduct?.map((item) => (
                        <div className="row align-items-center ht4jtr">
                          <div className="col-8 px-1 borje4">
                            {item.name}
                            {/* <img className="ms-1" src={dusyd} alt="" /> */}
                          </div>

                          <div
                            key={item?.id}
                            className="col-4 text-end px-1 borje4"
                          >
                            <p className="mb-0 prid9">
                              USD {item?.price?.toFixed(2).split('.')[0]}<sup>.{item?.price?.toFixed(2).split('.')[1]}</sup>
                            </p>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                  {/* <div className="row align-items-center ht4jtr">
                    <div className="col-8 px-1 borje4">
                      <p className="mb-0 man9d">Total Tax</p>
                    </div>
                    <div className="col-4 text-end px-1 borje4">
                      <p className="mb-0 prid9">
                        USD {roundedTax.split('.')[0]}<sup>.{roundedTax.split('.')[1]}</sup>
                        {/*<sup>89</sup>*
                      </p>
                    </div>
                  </div> */}
                </div>
                <div className="px-3 jxdfh62">
                  <div className="row align-items-center ht4jtr border-0">
                    <div className="col-8 px-1">
                      <p className="mb-0">Total Amount</p>
                    </div>
                    <div className="col-4 text-end px-1">
                      <p className="mb-0 prid92">
                        USD {roundedPrice.toFixed(2).split('.')[0]}<sup>.{roundedPrice.toFixed(2).split('.')[1]}</sup>
                        {/*<sup>87</sup>*/}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="fdy45 ht4jtr px-3 ">
                            <p className="mb-0">
                              <b>Note:</b> All Price are quoted in USD.Your credit/debit card may be billed in multiple charges totaling the final total price. Baggage allowance and fee amount are as per the policies. Fares are inclusive all taxes and fees.
                            </p>
                          </div>
              </div>
              <div className="fyt5u">
                <p className="mb-0 sett5">Need Help</p>
                <div className="d-flex align-items-center t4sdr57">
                  <img className="me-1 y5t6ty" src={dosrfy} alt="" />
                  <p className="mb-0 rte6t"><Link to={`tel:{displayNumber}`}>{displayNumber}</Link></p>
                </div>
                <div className="d-flex align-items-center t4sdr57">
                  <img className="me-1 y5t6ty" src={awsf5y} alt="" />
                  <p className="mb-0 rte6t"><Link to='mailto:support@rawfares.com'>support@rawfares.com</Link></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer1 />
    </>
  );
}
