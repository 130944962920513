/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { constant } from "../../constant";
import loct from "../../assest/images/icon/loct.png";
import mailu from "../../assest/images/icon/mail.png";
import cali from "../../assest/images/icon/cal.png";
import face from "../../assest/images/icon/facebook.png";
import insta from "../../assest/images/icon/instagram.png";
import pint from "../../assest/images/icon/pinterest.png";
import twit from "../../assest/images/icon/twiter.png";
import footr from "../../assest/images/footr.png";
import cro3y from "../../assest/images/icon/downd.png";
import nyu1 from "../../assest/images/icon/mail.png";
import krft from "../../assest/images/icon/phone.png";
import crossy from "../../assest/images/icon/cross.png";
export default function Footer({ tabopen }) {
  const [utmCampaign, setUtmCampaign] = useState("");
  const [displayNumber, setDisplayNumber] = useState("");
  const jsonMapping = {
    domestics_routes: "+1-833-609-8333",
    domestics_destination: "+1-833-609-7300",
    cruise: "+1-833-609-9933",
    airline: "+1-833-609-8444",
  };
  const defaultNumber = "+1-888-415-1536"; // Default number to display if UTM data is not found

  useEffect(() => {
    const updateDisplayNumber = () => {
      const utmData = JSON.parse(localStorage.getItem("utm_data")) || {};
      //   console.log(utmData)
      if (utmData?.utmData?.utm_campaign) {
        // console.log("in")
        setUtmCampaign(utmData?.utm_campaign);

        // Check if `utm_campaign` exists in the JSON mapping
        const mappedNumber = jsonMapping[utmData?.utmData?.utm_campaign];
        setDisplayNumber(mappedNumber || defaultNumber); // Use mapped number or default
      } else {
        // console.log("out")
        // No UTM data found, use the default number
        setDisplayNumber(defaultNumber);
      }
    };

    // Run initially
    updateDisplayNumber();

    // Set up interval to check every 10 seconds
    const interval = setInterval(updateDisplayNumber, 1000000000000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, [jsonMapping]);
  const [activeTab, setActiveTab] = useState("domestic");
  const [domesticAirports, setDomesticAirports] = useState([]);
  const [internationalAirports, setInternationalAirports] = useState([]);
  const [domesticAirportsRoute, setDomesticAirportsRoute] = useState([]);
  const [internationalAirportsRoute, setInternationalAirportsRoute] = useState(
    []
  );
  const [dealsData, setDealsData] = useState([]);
  const [airlines, setAirlines] = useState([]);
  const [contactOpen, setContactOpen] = useState(true);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const handleChange = (e) => {
    setEmail(e.target.value);
    setError(""); // Clear error when typing
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Simple email validation
    if (!email) {
      setError("Email is required");
      return;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setError("Enter a valid email address");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        `${constant.baseUrl}/api/ppc/subscribe`,
        {
          email,
        }
      );

      if (response.status === 200) {
        alert("Subscription successful! Check your inbox.");
      } else {
        setError("Something went wrong. Try again.");
      }
    } catch (err) {
      setError("Failed to subscribe. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleAllData = async () => {
    const payloadFirst = {
      pageType: "City_PPC",
      siteId: "16",
    };

    try {
      const apiResponse = await axios.post(
        `${constant.baseUrl}/api/ppc/sitemap`,
        payloadFirst
      );

      if (apiResponse.status === 200) {
        // Initialize lists for domestic and international airports
        const domesticAirports = [];
        const internationalAirports = [];
        const seenCities = new Set(); // Track unique city names
        // console.log("CityPPC", apiResponse.data.response);
        // Process the API response data directly
        apiResponse.data.response.forEach((item) => {
          const fromCountry = item.fromCountry
            ? item.fromCountry.trim().toUpperCase()
            : null;
          const category = fromCountry === "US" ? "Domestic" : "International";

          if (!seenCities.has(item.url.trim())) {
            seenCities.add(item.url.trim());

            const airportWithUrl = {
              ...item,
              category,
              url: item.url, // Include the URL
            };

            // Categorize the airport
            if (category === "Domestic") {
              domesticAirports.push(airportWithUrl);
            } else {
              internationalAirports.push(airportWithUrl);
            }
          }
        });

        // Log and update state
        // console.log("Domestic Airports:", domesticAirports);
        // console.log("International Airports:", internationalAirports);
        setDomesticAirports(domesticAirports);
        setInternationalAirports(internationalAirports);

        // Return or use the lists as needed
        return { domesticAirports, internationalAirports };
      } else {
        // console.log("Error: API response status is not 200");
      }
    } catch (error) {
      console.error("Error fetching sitemap data:", error.message);
    }
  };

  const handleAllDataRoute = async () => {
    const payloadFirst = {
      pageType: "Routes_PPC",
      siteId: "16",
    };

    try {
      const apiResponse = await axios.post(
        `${constant.baseUrl}/api/ppc/sitemap`,
        payloadFirst
      );

      if (apiResponse.status === 200) {
        // Initialize lists for domestic and international airports
        const domesticAirportsRoute = [];
        const internationalAirportsRoute = [];
        const seenCitiesRoute = new Set(); // Track unique city names
        // console.log("apiResponse", apiResponse);
        // Process the API response data directly
        apiResponse.data.response.forEach((item) => {
          const toCountry = item.toCountry.trim().toUpperCase();
          const category = toCountry === "US" ? "Domestic" : "International";

          // console.log(
          //   `Item: ${item.pageName}, toCountry: "${toCountry}", Category: ${category}`
          // );

          if (!seenCitiesRoute.has(item.url.trim())) {
            seenCitiesRoute.add(item.url.trim());

            const airportWithUrl = {
              ...item,
              category,
              url: item.url, // Include the URL
            };

            // Categorize the airport
            if (category === "Domestic") {
              domesticAirportsRoute.push(airportWithUrl);
            } else {
              internationalAirportsRoute.push(airportWithUrl);
            }
          }
        });

        // Log and update state
        // console.log("Domestic Airports:", domesticAirportsRoute);
        // console.log("International Airports:", internationalAirportsRoute);
        setDomesticAirportsRoute(domesticAirportsRoute);
        setInternationalAirportsRoute(internationalAirportsRoute);

        // Return or use the lists as needed
        return { domesticAirportsRoute, internationalAirportsRoute };
      } else {
        // console.log("Error: API response status is not 200");
      }
    } catch (error) {
      console.error("Error fetching sitemap data:", error.message);
    }
  };

  const handleDeals = async () => {
    const payloadFirst = {
      pageType: "Deals",
      siteId: "16",
    };

    try {
      const apiResponse = await axios.post(
        `${constant.baseUrl}/api/ppc/sitemap`,
        payloadFirst
      );
      // console.log(apiResponse);
      if (apiResponse.status === 200) {
        setDealsData(apiResponse.data.response);
      } else {
      }
    } catch {}
  };

  const handleAirline = async () => {
    const payloadFirst = {
      pageType: "Airline",
      siteId: "16",
    };

    try {
      const apiResponse = await axios.post(
        `${constant.baseUrl}/api/ppc/sitemap`,
        payloadFirst
      );
      // console.log(apiResponse);
      if (apiResponse.status === 200) {
        setAirlines(apiResponse.data.response);
      } else {
      }
    } catch {}
  };

  useEffect(() => {
    handleAllData();
    handleAllDataRoute();
    handleDeals();
    handleAirline();
  }, []);
  const openLink = (url) => {
		window.open(url, '_blank', 'width=650,height=550,left=300,top=50');
	  };

    useEffect(() => {
      setActiveTab(tabopen || "domestic");
    }, [tabopen])

  return (
    <>
      <section className="seiry53">
        <div className="container uishr">
          <div className="row">
            <div className="col-lg-12">
              <div className="jet5">
                <p className="isyhe4">
                  Subscribe to Get Notified About Latest Deals & Offers
                </p>

                <div className="jkdhtj">
                  <form
                    class="d-lg-flex align-items-center"
                    role="search"
                    onSubmit={handleSubmit}
                  >
                    <div className="jkdt">
                      <img src={nyu1} alt="" />
                      <input
                        class="form-control me-2 et45dct"
                        type="email"
                        placeholder="Your Email"
                        value={email}
                        onChange={handleChange}
                        aria-label="Search"
                      />
                    </div>
                    <button class="dtghgsr" type="submit" disabled={loading}>
                      {loading ? "Subscribing..." : "Subscribe"}
                    </button>
                    {error && (
                      <p style={{ color: "red", marginTop: "5px" }}>{error}</p>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="seiry524 mob-hide">
        <div className="container">
          <div className="row justify-content-between">
            <h4 className="mb-4">Inspiration for future getaways</h4>
            <div className="col-lg-12 ">
              <div className="et5etre">
                <p
                  className={`dtet ${activeTab === "domestic" ? "iehri" : ""}`}
                  onClick={() => handleTabClick("domestic")}
                  style={{ cursor: "pointer" }}
                >
                  Top Domestic Destinations
                </p>
                <p
                  className={`dtet ${
                    activeTab === "international" ? "iehri" : ""
                  }`}
                  onClick={() => handleTabClick("international")}
                  style={{ cursor: "pointer" }}
                >
                  Top International Destinations
                </p>
                <p
                  className={`dtet ${
                    activeTab === "flightsRoute" ? "iehri" : ""
                  }`}
                  onClick={() => handleTabClick("flightsRoute")}
                  style={{ cursor: "pointer" }}
                >
                  Top Offered Deals
                </p>
                <p
                  className={`dtet ${activeTab === "airline" ? "iehri" : ""}`}
                  onClick={() => handleTabClick("airline")}
                  style={{ cursor: "pointer" }}
                >
                  Top Domestic Routes
                </p>
              </div>
            </div>

            {activeTab === "domestic" && (
              <>
                {(() => {
                  // Divide the domesticAirports array into 4 groups
                  const chunkSize = Math.ceil(domesticAirports.length / 4);
                  const groupedAirports = [];
                  for (let i = 0; i < domesticAirports.length; i += chunkSize) {
                    groupedAirports.push(
                      domesticAirports.slice(i, i + chunkSize)
                    );
                  }

                  return groupedAirports.map((group, colIndex) => (
                    <div className="col-lg-3" key={colIndex}>
                      <ul className="ihteike">
                        {group.map((item, index) => (
                          <li key={index}>
                            <Link to={`/flights/${item.url}`} target="_blank">
                              Flights to {item.pageName}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ));
                })()}
              </>
            )}

            {activeTab === "international" && (
              <>
                {(() => {
                  // Divide the domesticAirports array into 4 groups
                  const chunkSize = Math.ceil(internationalAirports.length / 4);
                  const groupedAirports = [];
                  for (
                    let i = 0;
                    i < internationalAirports.length;
                    i += chunkSize
                  ) {
                    groupedAirports.push(
                      internationalAirports.slice(i, i + chunkSize)
                    );
                  }

                  return groupedAirports.map((group, colIndex) => (
                    <div className="col-lg-3" key={colIndex}>
                      <ul className="ihteike">
                        {group.map((item, index) => (
                          <li key={index}>
                            <Link to={`/flights/${item.url}`} target="_blank">
                              Flights to {item.pageName}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ));
                })()}
              </>
            )}

            {activeTab === "flightsRoute" && (
              <>
                {(() => {
                  // Divide the domesticAirports array into 4 groups
                  const chunkSize = Math.ceil(dealsData.length / 4);
                  const groupedAirports = [];
                  for (let i = 0; i < dealsData.length; i += chunkSize) {
                    groupedAirports.push(dealsData.slice(i, i + chunkSize));
                  }

                  return groupedAirports.map((group, colIndex) => (
                    <div className="col-lg-3" key={colIndex}>
                      <ul className="ihteike">
                        {group.map((item, index) => (
                          <li key={index}>
                            <Link to={`/deals/${item.url}`} target="_blank">
                              {item.pageName}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ));
                })()}
              </>
            )}

            {activeTab === "airline" && (
              <>
                {(() => {
                  // Divide the domesticAirports array into 4 groups
                  const chunkSize = Math.ceil(domesticAirportsRoute.length / 4);
                  const groupedAirports = [];
                  for (
                    let i = 0;
                    i < domesticAirportsRoute.length;
                    i += chunkSize
                  ) {
                    groupedAirports.push(
                      domesticAirportsRoute.slice(i, i + chunkSize)
                    );
                  }

                  return groupedAirports.map((group, colIndex) => (
                    <div className="col-lg-3" key={colIndex}>
                      <ul className="ihteike">
                        {group.map((item, index) => (
                          <li key={index}>
                            <Link to={`/flights/${item.url}`} target="_blank">
                              Flights from {item.pageName} to {item.toCity}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ));
                })()}
              </>
            )}
            {/* <div className="col-lg-2">
              <ul className='ihteike'>
                <li><Link to="">Flights to Las Vegas</Link></li>
                <li><Link to="">Flights to Chicago</Link></li>
                <li><Link to="">Flights to Houston</Link></li>
                <li><Link to="">Flights to Orlando</Link></li>
                <li><Link to="">Flights to Tampa</Link></li>
                <li><Link to="">Flights to Tampa</Link></li>
                <li><Link to="">Flights to Detroit</Link></li>
              </ul>
            </div> */}
            <Link to="/sitemap">
              {" "}
              <button className="dri4e">View More...</button>
            </Link>
          </div>
        </div>
      </section>

      <section className="py-4 wqerq45 ewtrt">
        <div className="container">
          <div className="row">
            <div className="col-6 col-lg-3 order-2 order-lg-1">
              <h6 className="seir3e">Quick Links</h6>
              <ul className="srij4e">
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
                <li>
                  <Link to="/flights">Flights</Link>
                </li>
                <li>
                  <Link to="/sitemap">Sitemap</Link>
                </li>
              </ul>
            </div>
            <div className="col-6 col-lg-3 order-1 order-lg-2">
              <h6 className="seir3e">Legal</h6>
              <ul className="srij4e">
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/terms-and-condition">Terms & Conditions</Link>
                </li>
                <li>
                  <Link to="/taxes-and-fees">Taxes & Fees</Link>
                </li>
                <li>
                  <Link to="/post-ticketing-fee">Post-Ticketing Fees</Link>
                </li>
                <li>
                  <Link to="/our-services-fee">Our Service Fees</Link>
                </li>
                <li>
                  <Link to="/online-check-in">Online Check-in</Link>
                </li>
                <li>
                  <Link to="/airline-baggage-fee">Airline Baggage Fees</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 order-3">
              <h6 className="seir3e">Talk to us</h6>
              <div className="d-flex align-items-start mb-3 esate">
                <img className="me-3" src={loct} alt="" />
                <p className="mb-0 fdktjr">
                  5830 N Valentine Ave #102, Fresno, CA 93711 USA
                </p>
              </div>
              <div className="d-flex align-items-start mb-3 esate">
                <img className="me-3" src={cali} alt="" />
                <p className="mb-0 fdktjr">
                  <Link to={`tel:${displayNumber}`}>{displayNumber}</Link>
                </p>
              </div>
              <div className="d-flex align-items-start mb-3 esate">
                <img className="me-3" src={mailu} alt="" />
                <p className="mb-0 fdktjr">
                  <Link to="mailto:support@rawfares.com">
                    support@rawfares.com
                  </Link>
                </p>
              </div>
            </div>
            <div className="col-lg-3 order-4 mob-hide">
              <h6 className="seir3e">Follow us</h6>
              <div className="scolo">
                <Link to="https://www.facebook.com/rawfares" target="_blank">
                  <img className="me-2" src={face} alt="" />
                </Link>
                <Link
                  to="https://www.instagram.com/rawfares_us/"
                  target="_blank"
                >
                  <img className="me-2" src={insta} alt="" />
                </Link>
                <Link to="https://www.pinterest.com/rawfares/" target="_blank">
                  <img className="me-2" src={pint} alt="" />
                </Link>
                <Link to="https://twitter.com/rawfares" target="_blank">
                  <img src={twit} alt="" />
                </Link>
              </div>
            </div>
            <div className="col-lg-12 order-5">
              <hr className="ieyht4" />
              <p className="eedt54">
                Rawfares is a leading travel brand operated under the trade name
                of Holiay Breakz Group LLC (Company Registration Number (EIN):
                201916510449). Our Cruise ticket supplier is CLIA. Authorized
                number is 00038244 and we are affiliated with
                Royal-Caribben-Group. We are available to provide customer
                support on all days from Monday to Sunday. Customer Service
                Number:{" "}
                <Link to={`tel:${displayNumber}`}>
                  <b className="text-decoration-underline">{displayNumber}</b>
                </Link>{" "}
                All users agree to Holiay Breakz Group LLC <span className="jisr4"><Link to="/terms-and-condition" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}}>Terms & Conditions</Link></span> and <span className="jisr4">
                                              <Link to="/privacy-policy" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}}>Privacy Policy</Link>
                                            </span>. All users are subject to <span className="jisr4"><Link to="/our-services-fee" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}}>our Service Fees</Link></span> and <span className="jisr4"><Link to="/post-ticketing-fee" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}}>Post Ticketing Fees</Link></span>. Read our Cookie Policy for details.
              </p>
              <img className="drui5b" src={footr} alt="" />
              <p className="idrhue">
                Copyrights © 2022-2025{" "}
                <Link to="/" target="_blank">
                  rawfares.com
                </Link>
                , All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="dstye">
        <div className="container">
          <div
            className={`row iher4 align-items-center ${
              contactOpen ? "" : "edt45"
            }`}
          >
            <div className="col-1 p-1 d-flex align-items-center justify-content-center">
              <img
                src={crossy}
                onClick={() => {
                  setContactOpen(false);
                }}
                alt=""
              />
            </div>
            <div className="col-9 p-1 text-center">
              <p className="mb-0 sry6ds">
                Dial In for Bigger Savings: Exclusive Deals!
              </p>
              <p className="mb-0 dyhtg4">
                <Link to={`tel:${displayNumber}`}>{displayNumber}</Link>
              </p>
            </div>
            <div className="col-2 p-1 text-center">
              <Link to={`tel:${displayNumber}`}>
                <img className="kidhte" src={krft} alt="" />
              </Link>
            </div>
            {contactOpen === false ? (
              <img
                className="sjfge"
                onClick={() => {
                  setContactOpen(true);
                }}
                src={cro3y}
                alt=""
              />
            ) : null}
          </div>
        </div>
      </section>
    </>
  );
}
