/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import airlinesName from "../AirlineName/AirlineName";
import getr5y from "../../assest/images/icon/sun1.png";
import getr6y from "../../assest/images/icon/sun2.png";
import getr7y from "../../assest/images/icon/sun3.png";
import crofyt from "../../assest/images/icon/crossy.png";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import impo from "../../assest/images/icon/impo.png";

export default function Filter({
  flights,
  initialForm,
  filteredResultsCount,
  mobFilter,
  mobOpen,
  onFilterCloseClick,
  onFilterChange,
  selectedFilters,
  settedPriceRange,
  focusSection,
  FilterReset,
  selectedMAirline,
  selectedMPrice,
  FilteredFlights,
  OnResetClick,
  onClearSelection,
}) {
  // console.log("COUNTING", FilteredFlights)
  const totalTraveler =
    initialForm.adults +
    initialForm.child +
    initialForm.infants +
    initialForm.infantsWs;
  //   const minPrice = (flights?.minPrice || 0) / (totalTraveler || 1);
  // const maxPrice = (flights?.maxPrice || 1000) / (totalTraveler || 1);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(1000);
  // const minPrice = flights?.flightResult[0]?.fare.adultFare
  // const maxPrice = flights?.flightResult[flights.flightResult.length - 1]?.fare.adultFare

  const [stops, setStops] = useState({
    direct: true, // Initially checked
    oneStop: true, // Initially checked
    twoPlusStops: true, // Initially checked
  });
  const [filters, setFilters] = useState({
    // price: [minPrice, maxPrice],
    stops: null,
    airline: null,
    departTime: null,
    ReturnTime: null,
  });
  const [resultCount, setResultCount] = useState(filteredResultsCount);
  const [priceRange, setPriceRange] = useState([minPrice, maxPrice]);

  // useEffect(() => {
  //   console.log("allPrices", allPrices)
  //   if (flights?.flightResult?.outBound && flights?.flightResult?.inBound) {
  //     const outBoundPrices = flights.flightResult.outBound.map(flight => flight.fare.adultFare);
  //     const inBoundPrices = flights.flightResult.inBound.map(flight => flight.fare.adultFare);
  //     console.log()
  //     const allPrices = [...outBoundPrices, ...inBoundPrices];
  //     console.log("allPrices", allPrices)
  //     if (allPrices.length > 0) {
  //       const minPrice = Math.min(...allPrices);
  //       const maxPrice = Math.max(...allPrices);

  //       setPriceRange([
  //         Math.round(minPrice * 100) / 100,
  //         Math.round(maxPrice * 100) / 100
  //       ]);
  //       console.log("Price Range", priceRange)

  //     }
  //   }
  // }, [flights]);

  const [selectedAirlines, setSelectedAirlines] = useState([]);

  const [selectedLayoverAirports, setSelectedLayoverAirports] = useState([]);
  const [layoverAirportsWithNames, setLayoverAirportsWithNames] = useState({});
  const [returnLayoverAirportsWithNames, setReturnLayoverAirportsWithNames] =
    useState({});
  const [selectedReturnLayoverAirports, setSelectedReturnLayoverAirports] =
    useState([]);
  const [showMore, setShowMore] = useState(false);
  const [showMoreAirports, setShowMoreAirports] = useState(false);
  const [showMoreReturnAirports, setShowMoreReturnAirports] = useState(false);
  const [showClear, setShowClear] = useState(false);

  // State for selected time slots
  const [selectedTimeSlots, setSelectedTimeSlots] = useState(new Set());
  const [selectedTimeSlotsForReturn, setSelectedTimeSlotsForReturn] = useState(
    new Set()
  );
  useEffect(() => {
    const scrollToSection = (id) => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      } else {
        setTimeout(() => scrollToSection(id), 100);
      }
    };

    if (focusSection === "timing") {
      scrollToSection("timing-section");
    } else if (focusSection === "airlines") {
      scrollToSection("airlines");
    }
  }, [focusSection]);

  const timeSlots = [
    { label: "00-06", start: 0, end: 6, image: getr5y },
    { label: "06-12", start: 6, end: 12, image: getr6y },
    { label: "12-18", start: 12, end: 18, image: getr5y },
    { label: "18-24", start: 18, end: 24, image: getr7y },
  ];
  const timeSlotsForReturn = [
    { label: "00-06", start: 0, end: 6, image: getr5y },
    { label: "06-12", start: 6, end: 12, image: getr6y },
    { label: "12-18", start: 12, end: 18, image: getr5y },
    { label: "18-24", start: 18, end: 24, image: getr7y },
  ];

  const handleStopChange = (e) => {
    const { name, checked } = e.target;
    setStops((prev) => ({ ...prev, [name]: checked }));
  };

  const handleTimeSlotChange = (index) => {
    const updatedSet = new Set(selectedTimeSlots);

    // Toggle the selection of the slot
    if (updatedSet.has(index)) {
      updatedSet.delete(index); // Remove if it's already selected
    } else {
      updatedSet.add(index); // Add if it's not selected
    }

    setSelectedTimeSlots(updatedSet); // Update the state with the modified Set
  };

  // Allow selecting only one time slot for return
  const handleTimeSlotForReturnChange = (index) => {
    const updatedSet = new Set(selectedTimeSlotsForReturn);
    if (updatedSet.has(index)) {
      updatedSet.delete(index); // Remove if it's already selected
    } else {
      updatedSet.add(index); // Add if it's not selected
    }
    setSelectedTimeSlotsForReturn(updatedSet);
  };

  const sliderRef = useRef(null);
  const calculatePosition = (clientX) => {
    const slider = sliderRef.current;
    if (!slider) return 0;

    const rect = slider.getBoundingClientRect();
    const offsetX = clientX - rect.left;
    let percentage = (offsetX / rect.width) * 100;

    // Clamp the percentage between 0 and 100
    percentage = Math.max(0, Math.min(percentage, 100));
    return Math.round((percentage / 100) * (maxPrice - minPrice) + minPrice);
  };

  const getAirlinesWithLowestPrices = () => {
    const airlines = {};

    flights?.flightResult?.forEach((flight) => {
      const airlineCodes = Array.from(
        new Set([
          ...(flight?.outBound?.map((s) => s.airline.toUpperCase()) || []),
          ...(flight?.inBound?.map((s) => s.airline.toUpperCase()) || []),
        ])
      );

      const price = flight.fare?.grandTotal;

      airlineCodes.forEach((airlineCode) => {
        if (airlineCode && typeof price === "number" && price > 0) {
          if (!airlines[airlineCode] || price < airlines[airlineCode].price) {
            airlines[airlineCode] = { code: airlineCode, price };
          }
        }
      });
    });

    // Convert to array and sort by price in ascending order
    return Object.values(airlines).sort((a, b) => a.price - b.price);
  };

  const airlinesWithLowestPrices = getAirlinesWithLowestPrices();
  const getLayoverAirports = () => {
    const layoverAirports = {};

    flights?.flightResult?.forEach((flight) => {
      if (flight?.outBound?.length > 1) {
        const layoverAirportCode = flight?.outBound[1]?.fromAirport;
        if (!layoverAirports[layoverAirportCode]) {
          layoverAirports[layoverAirportCode] = layoverAirportCode; // Store airport code
        }
      }
    });

    return Object.values(layoverAirports); // Return array of unique layover airport codes
  };

  const layoverAirports = getLayoverAirports();

  const getReturnLayoverAirports = () => {
    const returnLayoverAirports = {};

    flights?.flightResult?.forEach((flight) => {
      if (flight?.inBound?.length > 1) {
        const returnLayoverAirportCode = flight?.inBound[1].fromAirport;
        if (!returnLayoverAirports[returnLayoverAirportCode]) {
          returnLayoverAirports[returnLayoverAirportCode] =
            returnLayoverAirportCode; // Store airport code
        }
      }
    });

    return Object.values(returnLayoverAirports); // Return array of unique layover airport codes
  };

  const returnLayoverAirports = getReturnLayoverAirports();
  const airlines = [
    ...new Set(flights?.flightResult?.map((flight) => flight.airline)),
  ];

  const visibleAirlines = showMore ? airlines : airlines?.slice(0, 5);
  const handleAirlineChange = (e) => {
    const { value, checked } = e.target;
    setSelectedAirlines((prev) =>
      checked ? [...prev, value] : prev.filter((airline) => airline !== value)
    );
  };
  const handleLayoverAirportChange = (e) => {
    const { value, checked } = e.target;
    setSelectedLayoverAirports((prev) =>
      checked ? [...prev, value] : prev.filter((airport) => airport !== value)
    );
  };

  const handleReturnLayoverAirportChange = (e) => {
    const { value, checked } = e.target;
    setSelectedReturnLayoverAirports((prev) =>
      checked ? [...prev, value] : prev.filter((airport) => airport !== value)
    );
  };

  useEffect(() => {
    if (flights && flights?.flightResult && flights?.flightResult?.length > 0) {
      // Sort flights by price (low to high)
      const sortedFlights = [...flights.flightResult].sort(
        (a, b) =>
          a.fare.adultFare +
          a.fare.adultTax -
          (b.fare.adultFare + b.fare.adultTax)
      );

      // Get min and max prices
      const min =
        sortedFlights[0]?.fare.adultFare + sortedFlights[0]?.fare.adultTax;
      const max =
        sortedFlights[sortedFlights.length - 1]?.fare.adultFare +
        sortedFlights[sortedFlights.length - 1]?.fare.adultTax;

      // Update states correctly
      setMinPrice(min);
      setMaxPrice(max);
      setPriceRange([min, max]); // ✅ Uses the new values directly
    }
  }, [flights]);

  const formatPriceWithSuperscript = (price) => {
    const [integerPart, decimalPart] = price.toFixed(2).split(".");
    return (
      <>
        {integerPart}
        <sup>.{decimalPart}</sup>
      </>
    );
  };

  const handleMinChange = (e) => {
    const newMin = Math.min(Number(e.target.value), priceRange[1] - 1);
    setPriceRange([newMin, priceRange[1]]);
    filterFlights(newMin, priceRange[1]);
  };

  const handleMaxChange = (e) => {
    const newMax = Math.max(Number(e.target.value), priceRange[0] + 1);
    setPriceRange([priceRange[0], newMax]);
    filterFlights(priceRange[0], newMax);
  };

  const handleSliderChange = (value) => {
    setPriceRange([value[0], priceRange[1]]);
  };

  // Determine stop availability
  const stopAvailability = (() => {
    let hasDirect = false;
    let hasOneStop = false;
    let hasTwoPlusStops = false;

    flights?.flightResult?.forEach((flight) => {
      const leg1Stops =
        flight?.outBound?.length > 0 ? flight?.outBound?.length - 1 : 0;
      const leg2Stops =
        initialForm?.tripType !== "1" && flight?.inBound
          ? flight.inBound?.length > 0
            ? flight.inBound?.length - 1
            : 0
          : 0;

      const totalStops = leg1Stops;
      //  + leg2Stops;

      if (totalStops === 0) hasDirect = true;
      if (totalStops === 1) hasOneStop = true;
      if (totalStops >= 2) hasTwoPlusStops = true;
    });

    return { hasDirect, hasOneStop, hasTwoPlusStops };
  })();

  // Function to filter flights
  const filterFlights = () => {
    return flights?.flightResult?.filter((flight) => {
      const leg1Stops =
        flight?.outBound?.length > 0 ? flight?.outBound?.length - 1 : 0;
      const leg2Stops =
        initialForm?.tripType !== "1" && flight?.inBound
          ? flight.inBound?.length > 0
            ? flight.inBound?.length - 1
            : 0
          : 0;

      const totalStops = leg1Stops;
      // + leg2Stops;

      const matchesStops =
        (stops.direct && totalStops === 0) ||
        (stops.oneStop && totalStops === 1) ||
        (stops.twoPlusStops && totalStops >= 2);

      const price = flight.fare?.adultFare + flight.fare.adultTax || 0;
      const roundToTwo = (num) => Math.round(num * 100) / 100;
      const matchesPrice =
        // roundToTwo(price) >= roundToTwo(priceRange[0]) &&
        // roundToTwo(price) <= roundToTwo(priceRange[1]);
        price >= priceRange[0] && price <= priceRange[1];

      const departureTime = new Date(flight.outBound[0].depDate).getHours();
      const matchesTimeSlot =
        selectedTimeSlots.size === 0 ||
        Array.from(selectedTimeSlots).some((slot) => {
          const { start, end } = timeSlots[slot];
          return departureTime >= start && departureTime < end;
        });

      const returnTime =
        initialForm.tripType !== "1" && flight.inBound
          ? new Date(flight.inBound[0].depDate).getHours()
          : null;
      const matchesTimeSlotForReturn =
        initialForm?.tripType !== "1" &&
        flight.inBound &&
        (selectedTimeSlotsForReturn.size === 0 ||
          Array.from(selectedTimeSlotsForReturn).some((slot) => {
            const { start, end } = timeSlotsForReturn[slot];
            return returnTime >= start && returnTime < end;
          }));

      const layoverAirport =
        flight.outBound.length > 1 ? flight.outBound[1].fromAirport : null;
      const matchesLayoverAirport =
        selectedLayoverAirports.length === 0 ||
        (layoverAirport && selectedLayoverAirports.includes(layoverAirport));

      const returnLayoverAirport =
        flight?.inBound?.length > 1 ? flight?.inBound[1].fromAirport : null;
      const matchesReturnLayoverAirport =
        selectedReturnLayoverAirports?.length === 0 ||
        (returnLayoverAirport &&
          selectedReturnLayoverAirports.includes(returnLayoverAirport));

      const matchesAirline =
        selectedAirlines.length === 0 ||
        selectedAirlines.includes(flight.airline);

      return (
        matchesStops &&
        matchesPrice &&
        matchesTimeSlot &&
        (initialForm.tripType === "1" || matchesTimeSlotForReturn) &&
        matchesLayoverAirport &&
        matchesReturnLayoverAirport &&
        matchesAirline // ✅ Apply airline filter inside the main filter
      );
    });
  };

  const timeSlotMapping = {
    0: "00-06",
    1: "06-12",
    2: "12-18",
    3: "18-24",
  };
  const selectedTimeRanges = selectedTimeSlots.size
    ? [...selectedTimeSlots].map((slot) => timeSlotMapping[slot])
    : null;
  const selectedTimeRangesForReturn = selectedTimeSlotsForReturn.size
    ? [...selectedTimeSlotsForReturn].map((slot) => timeSlotMapping[slot])
    : null;

  useEffect(() => {
    if (mobFilter) {
      setStops({
        direct: true,
        oneStop: false,
        twoPlusStops: false,
      });
    } else {
      setStops({
        direct: true,
        oneStop: true,
        twoPlusStops: true,
      });
    }
  }, [mobFilter]);

  useEffect(() => {
    const filteredFlights = filterFlights();
    const currentFilters = {
      Price: [minPrice, maxPrice],
      Stops: stops,
      Airline: airlinesName[selectedAirlines.length ? selectedAirlines : null],
      DepartTime: selectedTimeRanges,
      ReturnTime: selectedTimeRangesForReturn,
    };

    // console.log("filtered Flights", filteredFlights);

    // Update the state with the new filters
    setFilters(currentFilters);
  }, [
    stops,
    priceRange,
    selectedTimeSlots,
    initialForm.tripType === "1" || selectedTimeSlotsForReturn,
    selectedAirlines,
    selectedLayoverAirports,
    selectedReturnLayoverAirports,
    flights,
  ]);

  // This useEffect listens to `filters` state and calls onFilterChange when it updates
  useEffect(() => {
    if (filters) {
      const filteredFlights = filterFlights();
      setResultCount(filteredFlights?.length);
      // console.log("Filtered Flights with updated filters:", filteredFlights);
      onFilterChange(filteredFlights, filters); // Pass the filtered flights and updated filters to parent
    }
  }, [filters]);
  const areObjectsEqual = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);
// console.log("selectedAirlines", selectedAirlines)
useEffect(() => {
  if (!selectedFilters || areObjectsEqual(selectedFilters, previousFilters.current)) {
    return; // Do nothing if filters haven't changed
  }

  previousFilters.current = selectedFilters; // Update the ref with the new filters

  const { Price, Stops, Airline, DepartTime, ReturnTime } = selectedFilters;

  setPriceRange((prev) => (JSON.stringify(prev) === JSON.stringify(Price) ? prev : Price));
  setStops((prev) => (JSON.stringify(prev) === JSON.stringify(Stops) ? prev : Stops));

  setSelectedTimeSlots((prev) => {
    const newSet = new Set(DepartTime ? DepartTime.map((slot) => timeSlots.findIndex((s) => s.label === slot)) : []);
    return areSetsEqual(prev, newSet) ? prev : newSet;
  });

  setSelectedTimeSlotsForReturn((prev) => {
    const newSet = new Set(ReturnTime ? ReturnTime.map((slot) => timeSlotsForReturn.findIndex((s) => s.label === slot)) : []);
    return areSetsEqual(prev, newSet) ? prev : newSet;
  });
// setTimeout(() => {
//   setSelectedAirlines((prev) => {
//     const newAirlines = Array.isArray(Airline) ? Airline : [];
//     return JSON.stringify(prev) === JSON.stringify(newAirlines) ? prev : newAirlines;
//   });
// }, 2000)

}, [selectedFilters]);

const previousFilters = useRef(null); // Store previous filters to prevent unnecessary re-renders

const areSetsEqual = (setA, setB) => {
  if (setA.size !== setB.size) return false;
  for (let item of setA) {
    if (!setB.has(item)) return false;
  }
  return true;
};
  
  const handleReset = () => {
    setStops({ direct: true, oneStop: true, twoPlusStops: true });
    setPriceRange([minPrice, maxPrice]);
    setSelectedTimeSlots(new Set());
    setSelectedTimeSlotsForReturn(new Set());
    setSelectedAirlines([]);
    setSelectedLayoverAirports([]);
    setSelectedReturnLayoverAirports([]);
    setShowClear(false);
    OnResetClick();
  };
  useEffect(() => {
    const allFiltersDefault =
      stops.direct &&
      stops.oneStop &&
      stops.twoPlusStops &&
      priceRange[0] === minPrice &&
      priceRange[1] === maxPrice &&
      // selectedAirlines?.length === 0 &&
      selectedLayoverAirports?.length === 0 &&
      selectedTimeSlots.size === 0 &&
      selectedTimeSlotsForReturn.size === 0;

    setShowClear(!allFiltersDefault); // Show "Clear" button only if filters are not default
    setShowClear(true);
  }, [
    stops,
    priceRange,
    // selectedAirlines,
    selectedLayoverAirports,
    selectedTimeSlots,
    selectedTimeSlotsForReturn,
    minPrice,
    maxPrice,
    selectedFilters,
  ]);

  useEffect(() => {
    // console.log("selectedFilters:", selectedFilters);

    if (selectedFilters && Object.keys(selectedFilters).length === 0) {
      // Reset the filters to default values
      setStops({ direct: true, oneStop: true, twoPlusStops: true });
      setPriceRange([minPrice, maxPrice]);
      setSelectedTimeSlots(new Set());
      setSelectedTimeSlotsForReturn(new Set());
      setSelectedAirlines([]);
      setSelectedLayoverAirports([]);
      setSelectedReturnLayoverAirports([]);
      setShowClear(false);
    }
  }, [selectedFilters, minPrice, maxPrice]);

  const handleAirlineChanges = (airline) => {
    setSelectedAirlines(
      (prev) =>
        prev.includes(airline)
          ? prev.filter((item) => item !== airline) // Remove if already selected
          : [...prev, airline] // Add if not selected
    );
  };

  //   const applyAirFilters = (flightData) => {
  //     console.log("Inside function", selectedAirlines);

  //     // If no airlines are selected, return all flights
  //     if (selectedAirlines.length === 0) {
  //         return flightData;
  //     }

  //     const filteredFlightsMatrix = flightData?.filter((flight) => {
  //         // Split the flight's airline string into an array
  //         const flightAirlines = flight.airline.split(',');

  //         // Convert selectedAirlines into a Set for easy comparison
  //         const selectedSet = new Set(selectedAirlines);

  //         // ✅ Condition 1: Exact match (e.g., ["JL,AS"] matches "JL,AS" or "AS,JL")
  //         const isExactMatch = selectedSet.size === flightAirlines.length &&
  //                               flightAirlines.every(code => selectedSet.has(code));

  //         // ✅ Condition 2: Check if any selected airline matches the flight's airlines
  //         // const isPartialMatch = selectedAirlines.some(selected => {
  //         //     // If the selected item contains a comma, it's a combination; check the full match
  //         //     if (selected.includes(',')) {
  //         //         const selectedAirlineCombination = selected.split(',');
  //         //         return selectedAirlineCombination.every(code => flightAirlines.includes(code));
  //         //     }
  //         //     // Otherwise, check for individual airlines
  //         //     return flightAirlines.includes(selected);
  //         // });

  //         return isExactMatch
  //         //  || isPartialMatch;
  //     });

  //     return filteredFlightsMatrix;
  // };

  //1/31/2025
  // const applyAirFilters = (flightData) => {
  //   console.log("Inside function", selectedAirlines);

  //   // If no airlines are selected, return all flights
  //   if (selectedAirlines.length === 0) {
  //       return flightData;
  //   }

  //   // Convert selectedAirlines array to a string
  //   const selectedAirlinesString = selectedAirlines.join(",");

  //   const filteredFlightsMatrix = flightData?.filter((flight) => {
  //       // ✅ Ensure both are in string format before comparison
  //       return selectedAirlinesString === flight.airline;
  //   });

  //   return filteredFlightsMatrix;
  // };
  // useEffect(() => {
  //   // const filteredFlights = filterFlights();
  //   console.log("selectedFilters", selectedFilters)
  //   // setTimeout(() => {
  //   if(selectedFilters?.DepartTime === null){
  //     console.log("yes")
  //     setSelectedTimeSlots(new Set());
  //   }
  //   if(selectedFilters?.ReturnTime === null){
  //     setSelectedTimeSlotsForReturn(new Set());
  //   }
  // // }, 4000)
  //   // onFilterChange(filteredFlights, selectedFilters);
  // }, [selectedFilters]);

  const applyAirFilters = (flightData) => {
    if (!selectedAirlines || selectedAirlines.length === 0) {
      return flightData; // Return all flights when nothing is selected
    }

    if (selectedAirlines.length === 1) {
      return flightData?.filter(
        (flight) => flight?.airline === selectedAirlines[0]
      );
    } else {
      const matchedFlights = selectedAirlines.map((airline) =>
        flightData?.filter((flight) => flight?.airline === airline)
      );
      setShowClear(true);
      return [...new Set(matchedFlights.flat())];
    }
  };

  useEffect(() => {
    const visibleFlights = applyAirFilters(flights?.flightResult);
    // setShowClear(true);
    // console.log("Before setFilteredFlights:", visibleFlights);
    onFilterChange(visibleFlights);
  }, [selectedAirlines]);
  const hasMounted = useRef(false);

  const [initialRender, setInitialRender] = useState(true);

  useEffect(() => {
    // console.log("initialRender", initialRender);
    if (initialRender === false) {
      // console.log("selectedAirlines.length", selectedAirlines.length);
      const timeout = setTimeout(() => {
        if (selectedAirlines.length === 0) {
          // console.log("selectedAirlines.length", selectedAirlines.length);
          // console.log("Calling again");
          setInitialRender(true);
          onClearSelection();
        }
      }, 300);

      return () => clearTimeout(timeout);
    }
  }, [initialRender]);

  useEffect(() => {
    // console.log("selectedMAirline", selectedMAirline);

    // Reset selectedAirlines before setting selectedMAirline
    setSelectedAirlines([]);

    // Ensure only selectedMAirline is set
    if (Array.isArray(selectedMAirline)) {
      setSelectedAirlines(selectedMAirline);
    } else {
      setSelectedAirlines([selectedMAirline]);
    }
    setShowClear(true);
    // Apply the filter logic to update visible flights
    const visibleFlights = applyAirFilters(flights?.flightResult);
    onFilterChange(visibleFlights);
  }, [selectedMAirline]);

  useEffect(() => {
    // console.log("selectedMPrice", selectedMPrice);

    if (
      selectedMPrice &&
      typeof selectedMPrice.min === "number" &&
      typeof selectedMPrice.max === "number"
    ) {
      setPriceRange([selectedMPrice.min, selectedMPrice.max]);
    } else {
      console.warn("Invalid selectedMPrice:", selectedMPrice);
    }
  }, [selectedMPrice]);

  useEffect(() => {
    // console.log("FilterReset", FilterReset)
    if (FilterReset === true) {
      // console.log("Called", selectedAirlines);
      setSelectedAirlines([]);
      handleReset();
      // console.log("Called2", selectedAirlines);
    }
  }, [FilterReset]);

  // console.log("FilterReset", FilterReset)
  // console.log("selectedMAirline", selectedMAirline)
  const [selectedLogos, setSelectedLogos] = useState([]);

  useEffect(() => {
    // Ensure the logic only runs if visibleAirlines is different from the previous state
    const usedLogos = new Set();
    const selected = visibleAirlines.map((airline) => {
      const airlineCodes = airline.split(",");
      const availableAirline =
        airlineCodes.find((code) => !usedLogos.has(code)) || airlineCodes[0];

      usedLogos.add(availableAirline);
      return { code: availableAirline, name: airlinesName[availableAirline] };
    });

    // Check if the selected logos are different from the previous state to avoid unnecessary updates
    if (JSON.stringify(selected) !== JSON.stringify(selectedLogos)) {
      setSelectedLogos(selected);
    }
  }, [visibleAirlines, selectedLogos]); // Add selectedLogos as a dependency

  const handleOnlyClick = (selectedStop) => {
    setStops({
      direct: selectedStop === "direct",
      oneStop: selectedStop === "oneStop",
      twoPlusStops: selectedStop === "twoPlusStops",
    });
  };

  // console.log("selectedFilters", selectedFilters);
  return (
    <>
      <div className={`zdujr-pop3 khetr ${mobOpen === true ? "" : "mob-hide"}`}>
        <div className="srh35">
          <div className="ajwri4 desk-hide">
            <button className="kdti4"></button>
            <img
              className="urhue4"
              onClick={() => {
                onFilterCloseClick();
              }}
              src={crofyt}
              alt=""
            />
          </div>
          <div className="ugsre">
            <div className="udtgr">
              <p className="mb-0">Result(s)</p>
              {showClear ? (
                <p
                  className="mb-0 et3xt"
                  onClick={handleReset}
                  style={{ cursor: "pointer" }}
                >
                  Reset
                </p>
              ) : null}
            </div>
            <p className="mb-0 sdet2">
              {showClear ? FilteredFlights : filteredResultsCount} Result Found
            </p>
            <hr className="ieht54" />
            <div className="udtgr">
              <p className="mb-0">Stop(s)</p>
            </div>
            {stopAvailability.hasDirect && (
              <div className="sgr34">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={stops.direct}
                    name="direct"
                    onChange={handleStopChange}
                    id="flexCheckDefault1"
                    disabled={!stopAvailability.hasDirect}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault1"
                  >
                    Non Stop
                  </label>
                  <p
                  className="mb-0 et3xt1"
                  
                  style={{ cursor: "pointer" }}
                
                    onClick={() => handleOnlyClick("direct")}
                  >
                    Only
                  </p>
                </div>
              </div>
            )}

            {stopAvailability.hasOneStop && (
              <div className="sgr34">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={stops.oneStop}
                    name="oneStop"
                    onChange={handleStopChange}
                    id="flexCheckDefault2"
                    disabled={!stopAvailability.hasOneStop}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault2"
                  >
                    One Stop
                  </label>
                  <p
                  className="mb-0 et3xt1"
                  
                  style={{ cursor: "pointer" }}
                
                    onClick={() => handleOnlyClick("oneStop")}
                  >
                    Only
                  </p>
                </div>
              </div>
            )}

            {stopAvailability.hasTwoPlusStops && (
              <div className="sgr34 pb-0">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={stops.twoPlusStops}
                    name="twoPlusStops"
                    onChange={handleStopChange}
                    id="flexCheckDefault3"
                    disabled={!stopAvailability.hasTwoPlusStops}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault3"
                  >
                    1+ Stop
                  </label>
                  <p
                  className="mb-0 et3xt1"
                  
                  style={{ cursor: "pointer" }}
                
                    onClick={() => handleOnlyClick("twoPlusStops")}
                  >
                    Only
                  </p>
                </div>
              </div>
            )}

            <hr className="ieht54" />
            <div className="udtgr" id="timing-section">
              <p className="mb-0">
                Depart from{" "}
                {initialForm?.segment[0]?.originAirport &&
                  flights?.airport?.find(
                    (a) =>
                      a.airportCode === initialForm?.segment[0]?.originAirport
                  )?.cityName}
              </p>
            </div>
            <div className="row">
              {timeSlots.map((slot, index) => (
                <div className="col-3 ps-1 pe-1" key={index}>
                  <div
                    className="jtr"
                    disabled={filteredResultsCount === 0}
                    onClick={() => handleTimeSlotChange(index)}
                  >
                    <div
                      className={`jsdt ${
                        selectedTimeSlots.has(index) ? "fy56" : ""
                      }`}
                    >
                      <img src={slot.image} alt={slot.label} />
                    </div>
                    <p className="awrje">{slot.label}</p>
                  </div>
                </div>
              ))}
            </div>

            {initialForm.tripType === "2" ? (
              <>
                <hr className="ieht54" />
                <div className="udtgr">
                  <p className="mb-0">
                    Return from{" "}
                    {initialForm?.segment[0]?.destinationAirport &&
                      flights?.airport?.find(
                        (a) =>
                          a.airportCode ===
                          initialForm?.segment[0]?.destinationAirport
                      )?.cityName}
                  </p>
                </div>
              </>
            ) : null}

            {initialForm.tripType === "2" ? (
              <div className="row">
                {timeSlotsForReturn.map((slot, index) => (
                  <div className="col-3 ps-1 pe-1" key={index}>
                    <div
                      className="jtr"
                      onClick={() => handleTimeSlotForReturnChange(index)}
                      disabled={filteredResultsCount === 0}
                    >
                      <div
                        className={`jsdt ${
                          selectedTimeSlotsForReturn.has(index) ? "fy56" : ""
                        }`}
                      >
                        <img src={slot.image} alt={slot.label} />
                      </div>
                      <p className="awrje">{slot.label}</p>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}

            <hr className="ieht54" />
            <div className="udtgr">
              <p className="mb-0" id="airlines">
                Airlines
              </p>
            </div>
            {visibleAirlines?.map((airline, index) => {
              const airlineCodes = airline.split(",");
              const secondAirlineCode = airline.split(",")[1];
              const selectedAirline = selectedLogos[index]?.code || "";
              const selectedAirlineName = selectedLogos[index]?.name || "";

              return (
                <div className="sgr34" key={index}>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value={airline}
                      // onChange={(e) => handleAirlineChange(e)}
                      onChange={() => {
                        handleAirlineChanges(airline);
                        setInitialRender(false);
                      }}
                      id={`checkbox-${airline}`}
                      // checked={selectedAirlines?.includes(airline.code)}
                      checked={
                        selectedAirlines && selectedAirlines.includes(airline)
                      }
                    />
                    <label class="form-check-label" for={`checkbox-${airline}`}>
                      {secondAirlineCode ? (
                        <>
                          <span>
                            {selectedAirlineName}
                            {/* {airlinesName[airline.split(',')[0]]}   */}
                            <img className="jher5" src={impo} alt="" />
                          </span>
                          <p class="jseri">
                            {/* {airlinesName[airline.split(',')[0]]} & {airlinesName[secondAirlineCode]} */}
                            {airlineCodes
                              .map((code) => airlinesName[code])
                              .join(" & ")}
                          </p>
                        </>
                      ) : (
                        <>
                          <span>{airlinesName[airline.split(",")[0]]}</span>
                          <p class="jseri">
                            {airlinesName[airline.split(",")[0]]}
                          </p>
                        </>
                      )}
                    </label>
                  </div>
                </div>
              );
            })}

            {airlinesWithLowestPrices?.length > 5 && (
              <p
                className="usrge mb-0"
                onClick={() => setShowMore((prev) => !prev)}
              >
                {showMore ? "Show Less" : "Show More"}
              </p>
            )}
            <hr className="ieht54" />
            <div className="udtgr">
              <p className="mb-0">Fares</p>
            </div>
            <div className="srfer">
              <p className="jgdte">
                Upto : - USD {formatPriceWithSuperscript(maxPrice)}
              </p>
            </div>
            {/* <div className="ujdht">
                <input
          type="range"
          min={minPrice.toFixed(2)}
          max={maxPrice.toFixed(2)}
          value={priceRange[0]}
          // onChange={(e) => setPriceRange([minPrice, Number(e.target.value)])}
          onChange={handleMinChange}
          className="uht"
          style={{
            width: "100%",
            background: `linear-gradient(to right, #007bff ${((priceRange[1] - minPrice) / (maxPrice - minPrice)) * 100}%, #ccc 0%)`,
          }}
        />
        <input
          type="range"
          min={minPrice.toFixed(2)}
          max={maxPrice.toFixed(2)}
          value={priceRange[1]}
          // onChange={(e) => setPriceRange([minPrice, Number(e.target.value)])}
          onChange={handleMaxChange}
          className="uht"
          style={{
            width: "100%",
            background: `linear-gradient(to right, #007bff ${((priceRange[1] - minPrice) / (maxPrice - minPrice)) * 100}%, #ccc 0%)`,
          }}
        />
                </div> */}
            <Slider
              range
              min={minPrice}
              max={maxPrice}
              value={priceRange}
              onChange={handleSliderChange}
            />
            <div className="srud">
              <p className="d5ee3f">
                USD {formatPriceWithSuperscript(priceRange[0])}
              </p>
              <p className="d5ee3f">
                USD {formatPriceWithSuperscript(priceRange[1])}
              </p>
            </div>

            <hr className="ieht54" />
            <div className="udtgr d-none">
              <p className="mb-0">Layover Airports</p>
            </div>

            {(showMoreAirports
              ? layoverAirports
              : layoverAirports.slice(0, 3)
            ).map((airport, index) => (
              <div className="sgr34 d-none">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value={airport}
                    onChange={handleLayoverAirportChange}
                    checked={selectedLayoverAirports.includes(airport)}
                    id={`checkbox-${airport}`}
                  />
                  <label class="form-check-label" for={`checkbox-${airport}`}>
                    {airport &&
                      flights.airport.find((a) => a.airportCode === airport)
                        ?.airportName}
                  </label>
                  <p class="jseri">
                    {airport &&
                      flights.airport.find((a) => a.airportCode === airport)
                        ?.airportName}
                  </p>
                </div>
              </div>
            ))}
            {layoverAirports?.length > 5 && (
              <p
                className="usrge mb-0 d-none"
                onClick={() => setShowMoreAirports(!showMoreAirports)}
              >
                {showMoreAirports ? "Show Less" : "Show More"}
              </p>
            )}
            {showClear ? (
              <p
                className="mb-0 et3xt text-end"
                onClick={handleReset}
                style={{ cursor: "pointer" }}
              >
                Reset
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
