import React from 'react'
import thankyo from '../assest/images/icon/thankyou.jpg';
import Header from '../Component/Header/header';
import Footer from '../Component/Footer/Footer';


export default function Thankyou() {
  return (
    <>
      <Header/>
      <section className='my-4 text-center'>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-10">
                    <img className='oseru4' src={thankyo} alt="" />
                    <p className='mb-0'>Thanks a bunch for filling that out. It means a lot to us, just like you do! We really appreciate you giving us a moment of your time today. thanks for being you.</p>
                </div>
            </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

