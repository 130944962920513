/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, forwardRef } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import exchange from "../../assest/images/icon/exchange.png";
import loweplan from "../../assest/images/icon/aplave.png";
import tabfly from "../../assest/images/icon/fliy.png";
import { format } from "date-fns";
import cruow from "../../assest/images/icon/cruise1.png";
import caret from "../../assest/images/icon/car1.png";
import hotet from "../../assest/images/icon/hotel1.png";
import crofyt from "../../assest/images/icon/crossy.png";
import { DateRangePicker } from "react-date-range";
import { v4 as uuidv4 } from "uuid";
import {
  browserName,
  isAndroid,
  isDesktop,
  isIOS,
  isMobile,
} from "react-device-detect";
import "react-date-range/dist/styles.css"; // Main style file
import "react-date-range/dist/theme/default.css"; // Theme css file

const formatDate = (date) => {
  return date ? format(date, "MM/dd/yyyy") : "";
};

const CalendarComponent = forwardRef((props, ref) => ( <DateRangePicker {...props} ref={ref} /> ));

export default function SearchEngine({headText, isSpecialPage, type, origin, destination, imgSpecial, img}) {
  const [originOpen, setOriginOpen] = useState(false);
  const [destinationOpen, setDestinationOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [APIData, setAPIData] = useState([]);
  const [filteredOriginResults, setFilteredOriginResults] = useState([]);
  const [filteredDestinationResults, setFilteredDestinationResults] = useState(
    []
  );
  const location = useLocation();
  const [headingText, setHeadingText] = useState(headText);
  const [searchId, setSearchId] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [tripType, setTripType] = useState("2");
  const [selectedOrigin, setSelectedOrigin] = useState(null);
  const [selectedDestination, setSelectedDestination] = useState(null);
  const [travelorVisible, setTravelorVisible] = useState(false);
  const originInputRefClick = useRef(null); // Ref for the input field
  const destinationInputRefClick = useRef(null);
  const today = new Date();
  const oneWeekFromToday = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000); // Adding milliseconds for one week
  const oneYearFromToday = new Date();
  oneYearFromToday.setFullYear(today.getFullYear() + 1); // Add one year to the current date
  const [selectedStartDate, setSelectedStartDate] = useState(today);
  const [selectedEndDate, setSelectedEndDate] = useState(new Date(selectedStartDate).setDate(selectedStartDate.getDate() + 7)
    );
  const [selectedOneDate, setSelectedOneDate] = useState(selectedStartDate);
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [totalTraveller, setTotalTraveller] = useState("Travellor(s)");
  const [adult, setAdult] = useState(1);
  const [child, setChild] = useState(0);
  const [infant, setInfant] = useState(0);
  const [infantWs, setInfantWs] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [activeField, setActiveField] = useState(null);
  const [rotation, setRotation] = useState(0);
  const formRef = useRef(null);
  const [originInputRef, setOriginInputRef] = useState(null);
  const [destinationInputRef, setDestinationInputRef] = useState(null);
  const calendarRef = useRef(null);
  const handleOutsideClick = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setCalendarVisible(false);
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (formRef.current && !formRef.current.contains(event.target)) {
        setActiveField(null); // Close all fields
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (location.pathname === "/deals/one-way-flight") {
      setTripType("1");
    } else {
      setTripType("2");
    }
  }, [location.pathname]);
  // useEffect(() => {
  //   if (calendarVisible) {
  //     document.addEventListener("mousedown", handleOutsideClick);
  //   } else {
  //     document.removeEventListener("mousedown", handleOutsideClick);
  //   }
  //   return () => {
  //     document.removeEventListener("mousedown", handleOutsideClick);
  //   };
  // }, [calendarVisible]);
  const [formValues, setFormValues] = useState({
    searchID: "", // Default value or dynamic value
    client: 2,
    segment: [
      {
        originAirport: "",
        destinationAirport: "",
        travelDate: formatDate(selectedStartDate),
        originCountry: "US",
        destinationCountry: "ALL",
      },
      {
        originAirport: "",
        destinationAirport: "",
        travelDate: formatDate(selectedEndDate),
      },
    ],
    searchDirectFlight: false,
    flexibleSearch: false,
    tripType: "2", // Default to round trip
    adults: 1,
    child: 0,
    infants: 0,
    infantsWs: 0,
    cabinType: "1",
    airline: "All",
    currencyCode: "USD",
    siteId: 16,
    source: "Online",
    media: "",
    sID: "",
    rID: "",
    locale: "en",
    isNearBy: false,
    limit: 200,
    userIP: "",
    serverIP: "",
    device: "",
    browser: "", // Define browserName if necessary
  });

  const handleOriginClick = (inputname) => {
    // console.log("called");
    if (inputname === "Origin") {
      setOriginOpen(!originOpen);
      setTimeout(() => {
        originInputRefClick.current?.focus(); // Focus the input box
      }, 0);
      setDestinationOpen(false);
    } else {
      setDestinationOpen(!destinationOpen);
      setTimeout(() => {
        destinationInputRefClick.current?.focus(); // Focus the input box
      }, 0);
      setOriginOpen(false);
    }
  };

  const handleCabinClick = () => {
    setIsActive(!isActive);
  };
  const fetchData = async (searchValue) => {
    // Try to get cached data specific to the search value
    const cachedData = localStorage.getItem(`cachedData_${searchValue}`);

    if (cachedData) {
      const parsedData = JSON.parse(cachedData);
      return parsedData;
    } else {
      try {
        const response = await axios.get(
          `https://www.tgsapi.com/flights/CrossAirportList?data=${searchValue}&authcode=farehuts`
        );
        const fetchedData = response.data;

        // Cache the data specific to the search value
        localStorage.setItem(
          `cachedData_${searchValue}`,
          JSON.stringify(fetchedData)
        );
        return fetchedData;
      } catch (error) {
        console.error("API fetch error:", error);
        return [];
      }
    }
  };

  const handleSearch = async (searchValue, isOrigin) => {
    if (searchValue.length > 2) {
      // Fetch data synchronously
      const data = await fetchData(searchValue);

      const matchingAirportCode = data.find(
        (item) => item.airportCode.toLowerCase() === searchValue.toLowerCase()
      );

      const filteredByName = data
        .filter(
          (item) =>
            item.airportCode.toLowerCase() !== searchValue.toLowerCase() &&
            (item.airportName
              .toLowerCase()
              .includes(searchValue.toLowerCase()) ||
              item.cityName.toLowerCase().includes(searchValue.toLowerCase()))
        )
        .slice(0, 9);

      const filteredResults = matchingAirportCode
        ? [matchingAirportCode, ...filteredByName]
        : filteredByName;
      // console.log("filteredResults", filteredResults);
      if (isOrigin) {
        setFilteredOriginResults(filteredResults);
      } else {
        setFilteredDestinationResults(filteredResults);
      }
    } else {
      if (isOrigin) {
        setFilteredOriginResults([]);
      } else {
        setFilteredDestinationResults([]);
      }
    }
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        // On smaller screens, display calendars vertically
        setDirection("vertical");
        setMonths(1);
      } else {
        // On larger screens, display calendars horizontally
        setDirection("horizontal");
        setMonths(2);
      }
    }

    // Initial setup
    handleResize();

    // Add event listener to update the layout when the screen size changes
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [direction, setDirection] = useState("horizontal");
  const [months, setMonths] = useState(2);
  const isDayDisabled = (date) => {
    return date < today;
  };

  const handleSelect = (item, isOrigin) => {
    if (isOrigin) {
      handleFieldChange("segment.0.originAirport", item.airportCode);
      setFilteredOriginResults([]);
      setTimeout(() => {
        setActiveField("destination");
        handleOriginClick("Destination");
        // console.log(activeField);
      }, 100);
    } else {
      // console.log("item", item);
      handleFieldChange("segment.0.destinationAirport", item.airportCode);
      setFilteredDestinationResults([]);
      setTimeout(() => {
        setActiveField(activeField === "calendar" ? null : "calendar")
        // console.log(activeField);
      }, 100);
    }
  };

  const handleFieldChange = (fieldPath, value) => {
    const keys = fieldPath.split(".");
    setFormValues((prevValues) => {
      let updatedValues = { ...prevValues };
      let currentLevel = updatedValues;
      for (let i = 0; i < keys.length - 1; i++) {
        currentLevel = currentLevel[keys[i]];
      }
      currentLevel[keys[keys.length - 1]] = value;

      // Check if we are modifying the first segment's origin or destination
      if (tripType === "2") {
      if (fieldPath === "segment.0.originAirport") {
        // Update return segment's destination airport
        updatedValues.segment[1].destinationAirport = value;
      } else if (fieldPath === "segment.0.destinationAirport") {
        // Update return segment's origin airport
        updatedValues.segment[1].originAirport = value;
      }
    }

      return updatedValues;
    });
  };

  const handleDateChange = (newValue) => {
    setDateRange(newValue);
    if (tripType === "2") {
      setFormValues((prevValues) => ({
        ...prevValues,
        segment: [
          {
            ...prevValues.segment[0],
            travelDate: newValue[0] ? formatDate(newValue[0]) : "",
          },
          {
            ...prevValues.segment[1],
            travelDate: newValue[1] ? formatDate(newValue[1]) : "",
          },
        ],
      }));
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        segment: [
          {
            ...prevValues.segment[0],
            travelDate: newValue[0] ? formatDate(newValue[0]) : "",
          },
          {
            originAirport: "",
            destinationAirport: "",
            travelDate: "",
          },
        ],
      }));
    }
  };

  const handleTripTypeChange = (type) => {
    setTripType(type);
    // console.log(tripType);
    if (type === "1") {
      // Clear return segment if One Way
      setFormValues((prevValues) => ({
        ...prevValues,
        tripType: "1",
        segment: [
          { ...prevValues.segment[0] },
          {
            originAirport: "",
            destinationAirport: "",
            travelDate: "",
          },
        ],
      }));
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        tripType: "2",
        segment: [
          { ...prevValues.segment[0] },
          {
            originAirport: prevValues.segment[0].destinationAirport,
            destinationAirport: prevValues.segment[0].originAirport,
            travelDate: dateRange[1] ? formatDate(dateRange[1]) : "",
          },
        ],
      }));
    }
  };
  const handleTravelorClick = () => {
    setTravelorVisible(!travelorVisible);
  };

  const handleMinusClick = (pax, event) => {
    event.stopPropagation();
  
    if (pax === "adult") {
      setAdult((prevAdult) => {
        const newAdult = Math.max(prevAdult - 1, 1); // Ensure at least 1 adult
        // Adjust infants and infantWs to stay within the new adult count
        setInfant((prevInfant) => Math.min(prevInfant, newAdult));
        setInfantWs((prevInfantWs) => Math.min(prevInfantWs, newAdult));
        return newAdult;
      });
    } else if (pax === "child") {
      setChild((prevChild) => Math.max(prevChild - 1, 0)); // Minimum 0 children
    } else if (pax === "infant") {
      setInfant((prevInfant) => Math.max(prevInfant - 1, 0)); // Minimum 0 infants
    } else if (pax === "infantWs") {
      setInfantWs((prevInfantWs) => Math.max(prevInfantWs - 1, 0)); // Minimum 0 infantWs
    }
  };
  
  const handlePlusClick = (pax, event) => {
    event.stopPropagation();
    const totalCount = adult + child + infant + infantWs;
  
    if (totalCount < 9) { // Ensure total travelers <= 9
      if (pax === "adult") {
        setAdult((prevAdult) => prevAdult + 1);
      } else if (pax === "child") {
        setChild((prevChild) => prevChild + 1);
      } else if (pax === "infant") {
        if (infant < adult) {
          setInfant((prevInfant) => prevInfant + 1);
        } else {
          // Handle the error condition if infant count exceeds adult count
        }
      } else if (pax === "infantWs") {
        if (infantWs < adult) {
          setInfantWs((prevInfantWs) => prevInfantWs + 1);
        } else {
          // Handle the error condition if infantWs count exceeds adult count
        }
      }
    } else {
      // Handle the error condition if the total traveler count exceeds 9
    }
  };
  

  useEffect(() => {
    setFormValues((prevState) => ({
      ...prevState,
      adults: adult,
      child: child,
      infants: infant,
      infantsWs: infantWs,
    }));
  }, [adult, child, infant, infantWs]);

  const calculateTraveller = () => {
    const totalCount = adult + child + infant + infantWs;
    setTotalTraveller(totalCount || "Travellor(s)");
  };

  useEffect(() => {
    calculateTraveller();
  }, [adult, child, infant, infantWs]);

  const getCabinTypeLabel = (cabinTypeValue) => {
    switch (cabinTypeValue) {
      case "1":
        return "Economy";
      case "2":
        return "Premium Economy";
      case "3":
        return "Business";
      case "4":
        return "First";
      default:
        return "Economy";
    }
  };

  const navigate = useNavigate();
  const [engineError, setEngineError] = useState(false)
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    
    if (
      !formValues.segment[0]?.originAirport || 
      !formValues.segment[0]?.destinationAirport
    ) {
      alert("Origin and destination airports must be filled out.");
      return; // Exit the function if validation fails
    }
    
    const deviceName = isMobile
      ? "Mobile Device"
      : isDesktop
      ? "Desktop"
      : isAndroid
      ? "Android"
      : isIOS;
    const response = await axios.get("https://api.ipify.org?format=json");
    const userIP = response.data.ip;
    const newSearchID = uuidv4().slice(0, 16);
    setSearchId(newSearchID);
    // console.log("formValues", formValues);
    let updatedSegment = [];
  if (formValues.tripType === "2") {
    // Round trip: Use both segments
    updatedSegment = [...formValues.segment];
  } else if (formValues.tripType === "1") {
    // One-way trip: Use only the first segment
    updatedSegment = [formValues.segment[0]];
  }
    const updatedFormValues = {
      ...formValues,
      segment: updatedSegment,
      searchID: newSearchID,
      userIP: userIP,
      device: deviceName,
    };
    setFormValues(updatedFormValues);
    // console.log("updatedFormValues", updatedFormValues);
    // if(searchId !== ""){
      if(formValues.segment[0].originAirport !== formValues.segment[0].destinationAirport){
        setIsLoading(true);
    try {
      navigate("/listing", { state: { updatedFormValues } });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("API fetch error:", error);
    }
  } else {
    setEngineError(true)
  }
    // }
  };

  const formatDateForDisplay = (date) => {
    const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
    const month = new Intl.DateTimeFormat("en", { month: "short" }).format(
      date
    );
    const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
      date
    );
    const weekday = new Intl.DateTimeFormat("en", { weekday: "long" }).format(
      date
    );

    return { day, month, year, weekday };
  };

  const formatDateForDisplayReturn = (date) => {
    const dayReturn = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
      date
    );
    const monthReturn = new Intl.DateTimeFormat("en", {
      month: "short",
    }).format(date);
    const yearReturn = new Intl.DateTimeFormat("en", {
      year: "numeric",
    }).format(date);
    const weekdayReturn = new Intl.DateTimeFormat("en", {
      weekday: "long",
    }).format(date);

    return { dayReturn, monthReturn, yearReturn, weekdayReturn };
  };

  // Example usage inside the component
  const { day, month, year, weekday } = formatDateForDisplay(selectedStartDate);
  const { dayReturn, monthReturn, yearReturn, weekdayReturn } =
    formatDateForDisplayReturn(selectedEndDate);

  // console.log("selectedOrigin", selectedOrigin);

  const handleExchange = () => {

    setRotation(rotation + 180);
    // Swap selected origin and destination
    const temp = selectedOrigin;
    setSelectedOrigin(selectedDestination);
    localStorage.setItem("selectedOrigin", JSON.stringify(selectedDestination));
    setSelectedDestination(temp);
    localStorage.setItem("selectedDestination", JSON.stringify(temp));

    handleFieldChange(
      "segment.0.originAirport",
      selectedDestination?.airportCode
    );
    handleFieldChange(
      "segment.0.destinationAirport",
      selectedOrigin?.airportCode
    );
  };

  const handleOriginSelect = (item) => {
    // setSelectedOrigin(item);
    localStorage.setItem("selectedOrigin", JSON.stringify(item));
  };

  const handleDestinationSelect = (item) => {
    // setSelectedOrigin(item);
    localStorage.setItem("selectedDestination", JSON.stringify(item));
  };

  useEffect(() => {
    if (isSpecialPage) {
      // console.log("SearchEngine Data:", { type, origin, destination });
  
      const setDataForSpecialPage = async () => {
        try {
          const searchValue = origin;
          const airportResponse = await axios.get(
            `https://www.tgsapi.com/flights/CrossAirportList?data=${searchValue}&authcode=farehuts`
          );
          if(type === "city"){
          if (airportResponse?.data) {
            const airports = airportResponse.data;
            const selectedOrigin = airports.find((item) => item.airportCode === origin);
            if (selectedOrigin) {
              setSelectedDestination(selectedOrigin);
                localStorage.setItem("selectedDestination", JSON.stringify(selectedOrigin));
              handleFieldChange("segment.0.destinationAirport", origin);
            }
          }
        }
          if (type === "route") {
            const searchValue2 = destination;
            const airport2Response = await axios.get(
              `https://www.tgsapi.com/flights/CrossAirportList?data=${searchValue2}&authcode=farehuts`
            );
  
            if (airport2Response?.data) {
              const airports = airport2Response.data;
              const selectedDestination = airports.find((item) => item.airportCode === destination);
              const airports2 = airportResponse.data;
            const selectedOrigin = airports2.find((item) => item.airportCode === origin);
              if (selectedDestination) {
                setSelectedOrigin(selectedOrigin);
              localStorage.setItem("selectedOrigin", JSON.stringify(selectedOrigin));
                setSelectedDestination(selectedDestination);
                localStorage.setItem("selectedDestination", JSON.stringify(selectedDestination));
                handleFieldChange("segment.0.originAirport", origin);
                handleFieldChange("segment.0.destinationAirport", destination);
              }
            }
          }
        } catch (error) {
          console.error("Error fetching airport data:", error);
        }
      };
  
      setDataForSpecialPage();
    }
  }, [isSpecialPage, type, origin, destination]);
  
const [imgDisplay, setImgDisplay] = useState(true)
  // console.log("Active", activeField)
  useEffect(() => {
    const handleResize = () => {
      setImgDisplay(window.innerWidth > 768);
    };
  
    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check
  
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  

  return (
    <>
      <section className={`fir-maib-r ${imgSpecial ? 'utghfh' : ''} `} style={imgDisplay && imgSpecial ? { backgroundImage: `url(${img})` } : {}}>
        <div className="container h-100">
          <div className="row align-items-end h-100">
            <div className="col-lg-12" ref={formRef}>
              <h1 className="kdht4">
                {headingText}
              </h1>
              <div className="d-flex mob-hide" onClick={() => {setActiveField(null)}}>
                <div className="tabuiy1 tabuiy">
                  <div className="d-flex align-items-center dttre4 edtr24 ">
                    <img className="me-1" src={tabfly} alt="" />
                    <p className="mb-0 jhtudt">Flights</p>
                  </div>
                </div>
                {/* <div className="tabuiy1">
                  <div className="d-flex align-items-center dttre4 edtr24 ">
                    <img className="me-1" src={hotet} alt="" />
                    <p className="mb-0 jhtudt">Hotel</p>
                  </div>
                </div>
                <div className="tabuiy1">
                  <div className="d-flex align-items-center dttre4 edtr24 ">
                    <img className="me-1" src={caret} alt="" />
                    <p className="mb-0 jhtudt">Cars</p>
                  </div>
                </div>
                <div className="tabuiy1">
                  <div className="d-flex align-items-center dttre4 ">
                    <img className="me-1" src={cruow} alt="" />
                    <p className="mb-0 jhtudt">Cruise</p>
                  </div>
                </div> */}
              </div>
              <div className="engiht">
                <div className="d-flex align-items-center pb-3 fyt5r" onClick={() => {setActiveField(null)}}>
                <div class={`form-check cursor-point sdrt31 ${tripType === "2" ? 'srr33' : ''}`} >
                    <input
                      class="form-check-input mob-hide cursor-point"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      defaultChecked
                      value="2"
                      checked={tripType === "2"}
                      onChange={() => handleTripTypeChange("2")}
                    />
                    <label
                      class="form-check-label font-500 cursor-point"
                      for="flexRadioDefault2"
                    >
                      {" "}
                      Round Trip{" "}
                    </label>
                  </div>

                  <div class={`form-check ms-4 cursor-point sdrt31 ${tripType === "1" ? 'srr33' : ''}`}>
                    <input
                      class="form-check-input mob-hide cursor-point"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      value="1"
                      checked={tripType === "1"}
                      onChange={() => handleTripTypeChange("1")}
                    />
                    <label
                      class="form-check-label font-500 cursor-point"
                      for="flexRadioDefault1"
                    >
                      {" "}
                      One Way{" "}
                    </label>
                  </div>

                 
                </div>

                <div className="row">
                  <div className="col-lg-5 rela p-0 sdetr">
                    <div className="row rela">
                      <div className="col-lg-6 sfsdt rela sdetr">
                        <div
                          className="enginer-se"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleOriginClick("Origin");
                            setActiveField(activeField === "origin" ? null : "origin");
                          }}
                        >
                          {originOpen === false ? (
                            <p className="weghrt">From</p>
                          ) : null}
                          {selectedOrigin ? (
                            <>
                              <p className="edtrdt">
                                {selectedOrigin?.cityName}
                              </p>
                              <p className="seroj">
                                {selectedOrigin?.airportCode},{" "}
                                {selectedOrigin?.airportName}
                              </p>
                            </>
                          ) : (
                            <>
                              <p className="edtrdt">Origin</p>
                              <p className="seroj">Search Your Origin</p>
                            </>
                          )}
                        </div>
                        {activeField === "origin" ? (
                          <>
                            <div className="zdujr-pop khetr">
                              <div className="srh">
                                <button className="kdti4"></button>
                                <img className="urhue4" onClick={() => {setActiveField(null)}} src={crofyt} alt="" />
                                <div class="form-floating r634y">
                                  <input
                                    type="text"
                                    class="form-control sdtst"
                                    id="floatingPassword"
                                    placeholder="Password"
                                    autoComplete="off"
                                    onChange={(e) => {
                                      handleFieldChange(
                                        "segment.0.originAirport",
                                        e.target.value
                                      );
                                      handleSearch(e.target.value, true);
                                    }}
                                    onFocus={() => {setFilteredOriginResults([]);}}
                                    inputRef={(ref) => setOriginInputRef(ref)}
                                    ref={originInputRefClick}
                                  />
                                  <label
                                    className="asru23"
                                    for="floatingPassword"
                                  >
                                    From
                                  </label>
                                </div>
                                {filteredOriginResults.length > 0 ? (
                                  <p className="srihe mb-0">Filtered Cities</p>
                                ) : null}
                                <div className="uge">
                                  {filteredOriginResults.map((item, index) => (
                                    <div
                                      className="kjdr d-flex align-items-center"
                                      key={index}
                                    >
                                      <img
                                        className="urer"
                                        src={loweplan}
                                        alt=""
                                      />
                                      <div
                                        className="dhit"
                                        onClick={() => {
                                          handleSelect(item, true);
                                          handleOriginSelect(item);
                                          setSelectedOrigin(item);
                                          setOriginOpen(!originOpen);
                                          setActiveField(null);
                                        }}
                                      >
                                        <p className="mb-0 wdtew">
                                          {item.airportName}
                                        </p>
                                        <p className="mb-0 sdtewt">
                                          {item.airportCode}, {item.countryCode}
                                        </p>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}
                      </div>

                      <div className="jkdgt" onClick={handleExchange}>
                        <img src={exchange} alt="" style={{ cursor: "pointer", transform: `rotate(${rotation}deg)`, transition: "transform 0.5s" }} onClick={() => {setActiveField(null)}} />
                      </div>
                      <div className="col-lg-6 sfsdt rela sdetr ">
                        <div
                          className="enginer-se"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleOriginClick("Destination");
                            setActiveField(activeField === "destination" ? null : "destination");
                          }}
                        >
                          {destinationOpen === false ? (
                            <p className="weghrt">To</p>
                          ) : null}
                          {selectedDestination ? (
                            <>
                              <p className="edtrdt">
                                {selectedDestination?.cityName}
                              </p>
                              <p className="seroj">
                                {selectedDestination?.airportCode},{" "}
                                {selectedDestination?.airportName}
                              </p>
                            </>
                          ) : (
                            <>
                              <p className="edtrdt">Destination</p>
                              <p className="seroj">Search Your Destinations</p>
                            </>
                          )}
                        </div>
                        {activeField === "destination" ? (
                          <div className="zdujr-pop khetr">
                            <div className="srh">
                              <button className="kdti4"></button>
                              <img className="urhue4" onClick={() => {setActiveField(null)}} src={crofyt} alt="" />
                              <div class="form-floating r634y">
                                <input
                                  type="text"
                                  class="form-control sdtst"
                                  id="floatingPassword"
                                  placeholder="Password"
                                  autoComplete="off"
                                  onChange={(e) => {
                                    handleFieldChange(
                                      "segment.0.destinationAirport",
                                      e.target.value
                                    );
                                    handleSearch(e.target.value, false);
                                  }}
                                  onFocus={() => {
                                    setFilteredDestinationResults([]);
                                  }
                                  }
                                  inputRef={(ref) =>
                                    setDestinationInputRef(ref)
                                  }
                                  ref={destinationInputRefClick}
                                />
                                <label
                                  className="asru23"
                                  for="floatingPassword"
                                >
                                  To
                                </label>
                              </div>
                              {filteredDestinationResults.length > 0 ? (
                                <p className="srihe mb-0">Filtered Cities</p>
                              ) : null}
                              <div className="uge">
                                {filteredDestinationResults.map(
                                  (item, index) => (
                                    <div
                                      className="kjdr d-flex align-items-center"
                                      key={index}
                                    >
                                      <img
                                        className="urer"
                                        src={loweplan}
                                        alt=""
                                      />
                                      <div
                                        className="dhit"
                                        onClick={() => {
                                          handleSelect(item, false);
                                          setSelectedDestination(item);
                                          handleDestinationSelect(item);
                                          setDestinationOpen(!destinationOpen);
                                          setActiveField(null)
                                        }}
                                      >
                                        <p className="mb-0 wdtew">
                                          {item.airportName}
                                        </p>
                                        <p className="mb-0 sdtewt">
                                          {item.airportCode}, {item.countryCode}
                                        </p>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {engineError === true ? (
                  <div><p className="orgin-eror"> Source and Destination can not be same</p></div>
                  ) : null}
                  </div>
                  <div className="col-lg-7 p-0">
                    <div className="row">
                      <div className="col-6 col-lg-3 sfsdt">
                        <div
                          className="enginer-se"
                          style={{ cursor: "pointer" }}
                          onClick={() => {setCalendarVisible(true); setActiveField(activeField === "calendar" ? null : "calendar")}}
                        >
                          <p className="weghrt">Departure</p>
                          <p className="edtrdt">
                            {day}{" "}
                            <span>
                              {month} {year}
                            </span>
                          </p>
                          <p className="seroj">{weekday}</p>
                        </div>

                        {/* <TwoMonthRangePicker /> */}
                      </div>
                      {activeField === "calendar" && (
                        <DateRangePicker
                          // ref={calendarRef}
                          onChange={(selectedDates) => {
                            // handleSelect(selectedDates);

                            const newStartDate =
                              selectedDates.selection.startDate;
                            const newEndDate = selectedDates.selection.endDate;

                            // Update selectedStartDate and selectedEndDate directly
                            setSelectedStartDate(newStartDate);
                            setSelectedEndDate(newEndDate);

                            setFormValues((prevState) => ({
                              ...prevState,
                              segment: [
                                {
                                  ...prevState.segment[0],
                                  travelDate: formatDate(newStartDate),
                                },
                                {
                                  ...prevState.segment[1],
                                  travelDate: formatDate(newEndDate),
                                },
                              ],
                            }));

                            // console.log(
                            //   "selectedDates.selection.endDate",
                            //   newEndDate
                            // );

                            if (tripType === "2") {
                              // Use a ref to track the last click time
                              const now = Date.now();
                        
                              if (
                                newEndDate &&
                                newEndDate.getTime() === newStartDate.getTime()
                              ) {
                                if (window.lastClickTime && now - window.lastClickTime < 3000) {
                                  // Double-click detected: close the calendar
                                  // console.log("Round trip for the same day selected (double-click)");
                                  setCalendarVisible(false);
                                  setActiveField(null);
                                } else {
                                  // Set the last click time for single click
                                  window.lastClickTime = now;
                                }
                              } else if (
                                newEndDate &&
                                newEndDate.getTime() !== newStartDate.getTime()
                              ) {
                                // If the dates are different, close the calendar
                                setCalendarVisible(false);
                                setActiveField(null);
                              }
                            } else {
                              // For other trip types, close the calendar after selecting the start date
                              setCalendarVisible(false);
                              setActiveField(null)
                            }
                          }}
                          ranges={[
                            {
                              startDate: selectedStartDate,
                              endDate: selectedEndDate,
                              key: "selection",
                            },
                          ]}
                          showSelectionPreview={true}
                          moveRangeOnFirstSelection={false}
                          months={months}
                          showMonthAndYearPickers={true} // Show month and year picker buttons
                          showDateDisplay={true} // Show the date display on the top
                          direction={direction}
                          minDate={today}
                          maxDate={oneYearFromToday}
                          isDayBlocked={isDayDisabled} // Disable past dates
                          style={{
                            width: "300px",
                            height: "250px",
                            borderColor: "gray",
                            borderRadius: "3px",
                            zIndex: "5",
                          }}
                        />
                      )}
                      <div className="col-6 col-lg-3 sfsdt">
                        <div
                          className="enginer-se"
                          style={{ cursor: "pointer" }}
                        >
                          <p className="weghrt">Return</p>
                          {tripType === "2" ? (
                            <div onClick={() => {setCalendarVisible(true); setActiveField(activeField === "calendar" ? null : "calendar")}}>
                              <p className="edtrdt" onClick={() => {setCalendarVisible(true); setActiveField(activeField === "calendar" ? null : "calendar")}}>
                                {dayReturn}{" "}
                                <span>
                                  {monthReturn} {yearReturn}
                                </span>
                              </p>
                              <p className="seroj">{weekdayReturn}</p>
                            </div>
                          ) : (
                            <>
                              {/* <p className='edtrdt'>6 <span>Dec 2024</span></p> */}
                              <p
                                className="seroj"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleTripTypeChange("2")}
                              >
                                Select Round trip
                                <br /> for Return Date
                              </p>
                              {/* <p className='seroj'>return date</p> */}
                            </>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-3 sfsdt rela sdetr">
                        <div
                          className="enginer-se"
                          style={{ cursor: "pointer" }}
                          onClick={() => {handleTravelorClick(); setActiveField(activeField === "traveler" ? null : "traveler")}}
                        >
                          <p className="weghrt">Traveler(s) & Class</p>
                          <p className="edtrdt">
                            {totalTraveller} <span>Traveler</span>
                          </p>
                          <p className="seroj">
                            {getCabinTypeLabel(formValues.cabinType)}
                          </p>
                        </div>
                        {activeField === "traveler" && (
                          <div className="zdujr-pop1 khetr">
                            <div className="srh">
                              <button className="kdti4"></button>
                              <img className="urhue4" onClick={() => {setActiveField(null)}} src={crofyt} alt="" />
                              <div className="row align-items-center px-2 py-lg-2 swtr3s">
                                <div className="col-lg-6 se6tet">
                                  <p className="dtstset">Adult</p>
                                  <div className="d-flex align-items-center">
                                    <button
                                      className="teiyr"
                                      onClick={(e) =>
                                        handleMinusClick("adult", e)
                                      }
                                    >
                                      -
                                    </button>
                                    <p className="mb-0 fdtyr">
                                      {adult ? adult : formValues.adults}
                                    </p>
                                    <button
                                      className="teiyr"
                                      onClick={(e) =>
                                        handlePlusClick("adult", e)
                                      }
                                    >
                                      +
                                    </button>
                                  </div>
                                </div>
                                <div className="col-lg-6 se6tet">
                                  <p className="dtstset">
                                    Child{" "}
                                    <span className="mb-0 srwsrr1">
                                      (2-11 Years)
                                    </span>
                                  </p>
                                  <div className="d-flex align-items-center">
                                    <button
                                      className="teiyr"
                                      onClick={(e) =>
                                        handleMinusClick("child", e)
                                      }
                                    >
                                      -
                                    </button>
                                    <p className="mb-0 fdtyr">
                                      {child ? child : formValues.child}
                                    </p>
                                    <button
                                      className="teiyr"
                                      onClick={(e) =>
                                        handlePlusClick("child", e)
                                      }
                                    >
                                      +
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="row align-items-center pt-2 px-2">
                              
                                <div className="col-lg-6 se6tet">
                                  <div className="fdhy5">
                                  <p className="dtstset mb-0">
                                    Infant{" "}
                                    <span className="mb-0 srwsrr1">
                                      lap
                                    </span>
                                  </p>
                                  <span className="mb-0 srwsrr1">
                                     (0-2 Years)
                                    </span>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <button
                                      className="teiyr"
                                      onClick={(e) =>
                                        handleMinusClick("infant", e)
                                      }
                                    >
                                      -
                                    </button>
                                    <p className="mb-0 fdtyr">
                                      {infant ? infant : formValues.infants}
                                    </p>
                                    <button
                                      className="teiyr"
                                      onClick={(e) =>
                                        handlePlusClick("infant", e)
                                      }
                                    >
                                      +
                                    </button>
                                  </div>
                                </div>
                                <div className="col-lg-6 se6tet">
                                <div className="fdhy5">
                                  <p className="dtstset mb-0">
                                    Infant{" "}
                                    <span className="mb-0 srwsrr1">
                                      seat
                                    </span>
                                  </p>
                                  <span className="mb-0 srwsrr1">
                                     (0-2 Years)
                                    </span>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <button
                                      className="teiyr"
                                      onClick={(e) =>
                                        handleMinusClick("infantWs", e)
                                      }
                                    >
                                      -
                                    </button>
                                    <p className="mb-0 fdtyr">
                                      {infantWs
                                        ? infantWs
                                        : formValues.infantsWs}
                                    </p>
                                    <button
                                      className="teiyr"
                                      onClick={(e) =>
                                        handlePlusClick("infantWs", e)
                                      }
                                    >
                                      +
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <hr className="d-none d-md-block" />
                              <div className="row px-2">
                                <div className="col-6">
                                  <button
                                    className={`sert4 ${
                                      formValues.cabinType === "1"
                                        ? "activrt"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      setFormValues((prevState) => ({
                                        ...prevState,
                                        cabinType: "1",
                                      }))
                                    }
                                  >
                                    Economy
                                  </button>
                                </div>
                                <div className="col-6">
                                  <button
                                    className={`sert4 ${
                                      formValues.cabinType === "2"
                                        ? "activrt"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      setFormValues((prevState) => ({
                                        ...prevState,
                                        cabinType: "2",
                                      }))
                                    }
                                  >
                                    Premium Economy
                                  </button>
                                </div>
                                <div className="col-6">
                                  <button
                                    className={`sert4 mb-0 ${
                                      formValues.cabinType === "3"
                                        ? "activrt"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      setFormValues((prevState) => ({
                                        ...prevState,
                                        cabinType: "3",
                                      }))
                                    }
                                  >
                                    Business
                                  </button>
                                </div>
                                <div
                                  className="col-6"
                                  onClick={() =>
                                    setFormValues((prevState) => ({
                                      ...prevState,
                                      cabinType: "4",
                                    }))
                                  }
                                >
                                  <button
                                    className={`sert4 mb-0 ${
                                      formValues.cabinType === "4"
                                        ? "activrt"
                                        : ""
                                    }`}
                                  >
                                    First
                                  </button>
                                </div>
                              </div>
                              <hr />
                              <div
                                className="yhsdt4 px-2 pb-3"
                                onClick={() => {setTravelorVisible(false); setActiveField(null)}}
                              >
                                <button className="sttde5">Apply</button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div
                        className="col-lg-3 sfsdt"
                        onClick={(e) => {handleFormSubmit(e); setActiveField(null)}}
                      >
                        <button className="asrije" onClick={(e) => {handleFormSubmit(e); setActiveField(null)}}>
                          {isLoading ? 
                        <section className="atfe">
             <div class="spinner12 center">
                      <div class="spinner-blade"></div>
                      <div class="spinner-blade"></div>
                      <div class="spinner-blade"></div>
                      <div class="spinner-blade"></div>
                      <div class="spinner-blade"></div>
                      <div class="spinner-blade"></div>
                      <div class="spinner-blade"></div>
                      <div class="spinner-blade"></div>
                      <div class="spinner-blade"></div>
                      <div class="spinner-blade"></div>
                      <div class="spinner-blade"></div>
                      <div class="spinner-blade"></div>
                    </div>
    </section>
     : 'SEARCH'}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
