import React from 'react';
import Routers from '../Routers/Routers';
import '../assest/css/style.css';
// import Header from '../Component/Header/header';
// import Footer from '../Component/Footer/Footer';

export default function Layout() {
  return (
    <>
    {/* <Header/> */}
    <Routers />
    {/* <Footer/> */}
    </>
  )
}
