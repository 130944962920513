/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from '../Component/Header/header';
import SearchEngine from '../Component/Search-Engine/engine';
import Footer from '../Component/Footer/Footer';
import bking from "../assest/images/icon/booking.png";
import Sport from "../assest/images/icon/support.png";
import fees from "../assest/images/flight/fees.png";
import custed from "../assest/images/flight/customer.png";
import cded from "../assest/images/flight/sec1.png";
import inner from "../assest/images/flight/innr.webp";
import callw from "../assest/images/flight/w-call.png";
import deal1 from "../assest/images/flight/deal1.webp";
import deal11 from "../assest/images/flight/deal1-1.webp";
import deal13 from "../assest/images/flight/deal1-3.webp";
import deal14 from "../assest/images/flight/deal1-4.webp";
import deal15 from "../assest/images/flight/deal1-5.webp";
import deal2 from "../assest/images/flight/deal2.webp"
import deal21 from "../assest/images/flight/deal2-1.webp";
import deal22 from "../assest/images/flight/deal2-2.png";
import deal23 from "../assest/images/flight/deal2-4.webp";
import deal24 from "../assest/images/flight/deal2-4.webp";
import deal25 from "../assest/images/flight/deal2-5.webp";
import deal31 from "../assest/images/flight/deal3-1.webp";
import deal32 from "../assest/images/flight/deal3-2.webp";
import deal33 from '../assest/images/flight/deal3-3.webp';
import deal34 from "../assest/images/flight/deal3-4.webp";
import deal35 from "../assest/images/flight/deal3-5.webp";

const getYesterdayDate = () => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const options = { weekday: 'long', month: '2-digit', day: '2-digit', year: 'numeric' };
  return yesterday.toLocaleDateString('en-US', options);
};

export default function Flight() {
  const [utmCampaign, setUtmCampaign] = useState("");
          const [displayNumber, setDisplayNumber] = useState("");
          const jsonMapping = {
              domestics_routes: "+1-833-609-8333",
              domestics_destination: "+1-833-609-7300",
              cruise : "+1-833-609-9933",
              airline: "+1-833-609-8444"
            };
            const defaultNumber = "+1-888-415-1536"; // Default number to display if UTM data is not found
      
            useEffect(() => {
              const updateDisplayNumber = () => {
                const utmData = JSON.parse(localStorage.getItem("utm_data")) || {};
              //   console.log(utmData)
                if (utmData?.utmData?.utm_campaign) {
                  // console.log("in")
                  setUtmCampaign(utmData?.utm_campaign);
          
                  // Check if `utm_campaign` exists in the JSON mapping
                  const mappedNumber = jsonMapping[utmData?.utmData?.utm_campaign];
                  setDisplayNumber(mappedNumber || defaultNumber); // Use mapped number or default
                } else {
                  // console.log("out")
                  // No UTM data found, use the default number
                  setDisplayNumber(defaultNumber);
                }
              };
          
              // Run initially
              updateDisplayNumber();
          
              // Set up interval to check every 10 seconds
              const interval = setInterval(updateDisplayNumber, 1000000000000);
          
              // Clean up interval on component unmount
              return () => clearInterval(interval);
            }, [jsonMapping]);
  return (
    <>
      <Header/>
      <SearchEngine headText={`Book Flights to Around The World`} imgSpecial={true} img='https://imgfolders.com/rawfares/rawfaresUS/flight/back1.webp' />

      <section className="dtjrrdt wqerq45 ">
        <div className="container">
          <div className="row sfs34f align-items-center">
            <div className="col-lg-12 p-0">
            <Link to={`tel:${displayNumber}`}>
              <div className="row w-100 m-auto dftgdfty jh5r">
                <div className="col-lg-3">
                  <div className="d-flex align-items-start">
                    <img src={fees} alt="" />
                    <div className="fdtd3">
                      <p className="mb-0 e5t66">No Extra Fees</p>
                      <p className="mb-0 dtgr6r4">
                      Flight Price is completely transparent on what's covered in your booking.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 rftw4 border-end-0">
                  <div className="d-flex align-items-start">
                    <img src={bking} alt="" />
                    <div className="fdtd3 ">
                      <p className="mb-0 e5t66">Best Flight Deals</p>
                      <p className="mb-0 dtgr6r4">
                      Explore cheap flights to anywhere. Search and save now with Rawfares.com
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 rftw4">
                  <div className="d-flex align-items-start">
                    <img src={custed} alt="" />
                    <div className="fdtd3 ">
                      <p className="mb-0 e5t66">Customer Satisfaction</p>
                      <p className="mb-0 dtgr6r4">
                      98% of Customers say they'd book with us again
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="d-flex align-items-start">
                    <img src={Sport} alt="" />
                    <div className="fdtd3">
                      <p className="mb-0 e5t66">24/7 SUPPORT</p>
                      <p className="mb-0 dtgr6r4">
                      Call, message or face-to-face - we're here for you 24/7, every step of your bookings.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              </Link>
            </div>
          </div>
        </div>
      </section>


      <section className='dtjrrdt wqerq45'>
        <div className="container">
          <div className="row">
            <h3 className='hrth'>Fly for Less | Unlock Exclusive Flight Deals</h3>
            <div className="col-lg-4">
              <div className="dfjt">
                <div className="jrt">
                  <img className='w-100' src={deal1} alt="" />
                  <p>Amazing Flight Deals</p>
                </div>
                <div className="ujrjt">
                  <div className="row px-2 align-items-center">
                    <div className="col-3 p-1">
                      <img className='w-100 detdr4' src={inner} alt="" />
                    </div>
                    <div className="col-4 p-1">
                      <p className='mb-0 dt4w'>Flights to</p>
                      <p className='mb-0 wae3t'>New York</p>
                    </div>
                    <div className="col-2 p-1">
                      <p className='mb-0 gyr4'>From</p>
                      <p className='mb-0 gt4e'>$99</p>
                    </div>
                    <div className="col-3 p-1">
                      <button className='edrit' onClick={() => window.location.href = `tel:${displayNumber}`}>Call</button>
                    </div>
                  </div>
                </div>
                <hr className='mx-auto w-90'/>
                <div className="ujrjt">
                  <div className="row px-2 align-items-center">
                    <div className="col-3 p-1">
                      <img className='w-100 detdr4' src={deal11} alt="" />
                    </div>
                    <div className="col-4 p-1">
                      <p className='mb-0 dt4w'>Flights to</p>
                      <p className='mb-0 wae3t'>Orlando</p>
                    </div>
                    <div className="col-2 p-1">
                      <p className='mb-0 gyr4'>From</p>
                      <p className='mb-0 gt4e'>$97</p>
                    </div>
                    <div className="col-3 p-1">
                      <button className='edrit' onClick={() => window.location.href = `tel:${displayNumber}`}>Call</button>
                    </div>
                  </div>
                </div>
                <hr className='mx-auto w-90'/>
                <div className="ujrjt">
                  <div className="row px-2 align-items-center">
                    <div className="col-3 p-1">
                      <img className='w-100 detdr4' src={deal13} alt="" />
                    </div>
                    <div className="col-4 p-1">
                      <p className='mb-0 dt4w'>Flights to</p>
                      <p className='mb-0 wae3t'>Dubai</p>
                    </div>
                    <div className="col-2 p-1">
                      <p className='mb-0 gyr4'>From</p>
                      <p className='mb-0 gt4e'>$540</p>
                    </div>
                    <div className="col-3 p-1">
                      <button className='edrit' onClick={() => window.location.href = `tel:${displayNumber}`}>Call</button>
                    </div>
                  </div>
                </div>
                <hr className='mx-auto w-90'/>
                <div className="ujrjt">
                  <div className="row px-2 align-items-center">
                    <div className="col-3 p-1">
                      <img className='w-100 detdr4' src={deal14} alt="" />
                    </div>
                    <div className="col-4 p-1">
                      <p className='mb-0 dt4w'>Flights to</p>
                      <p className='mb-0 wae3t'>Cap Town</p>
                    </div>
                    <div className="col-2 p-1">
                      <p className='mb-0 gyr4'>From</p>
                      <p className='mb-0 gt4e'>$850</p>
                    </div>
                    <div className="col-3 p-1">
                      <button className='edrit' onClick={() => window.location.href = `tel:${displayNumber}`}>Call</button>
                    </div>
                  </div>
                </div>
                <hr className='mx-auto w-90'/>
                <div className="ujrjt">
                  <div className="row px-2 align-items-center">
                    <div className="col-3 p-1">
                      <img className='w-100 detdr4' src={deal15} alt="" />
                    </div>
                    <div className="col-4 p-1">
                      <p className='mb-0 dt4w'>Flights to</p>
                      <p className='mb-0 wae3t'>Miami</p>
                    </div>
                    <div className="col-2 p-1">
                      <p className='mb-0 gyr4'>From</p>
                      <p className='mb-0 gt4e'>$102</p>
                    </div>
                    <div className="col-3 p-1">
                      <button className='edrit' onClick={() => window.location.href = `tel:${displayNumber}`}>Call</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="dfjt">
                <div className="jrt">
                  <img className='w-100' src={deal2} alt="" />
                  <p>Inexpensive Flight Deals</p>
                </div>
                <div className="ujrjt">
                  <div className="row px-2 align-items-center">
                    <div className="col-3 p-1">
                      <img className='w-100 detdr4' src={deal21} alt="" />
                    </div>
                    <div className="col-4 p-1">
                      <p className='mb-0 dt4w'>Flights to</p>
                      <p className='mb-0 wae3t'>Seattle</p>
                    </div>
                    <div className="col-2 p-1">
                      <p className='mb-0 gyr4'>From</p>
                      <p className='mb-0 gt4e'>$230</p>
                    </div>
                    <div className="col-3 p-1">
                      <button className='edrit' onClick={() => window.location.href = `tel:${displayNumber}`}>Call</button>
                    </div>
                  </div>
                </div>
                <hr className='mx-auto w-90'/>
                <div className="ujrjt">
                  <div className="row px-2 align-items-center">
                    <div className="col-3 p-1">
                      <img className='w-100 detdr4' src={deal22} alt="" />
                    </div>
                    <div className="col-4 p-1">
                      <p className='mb-0 dt4w'>Flights to</p>
                      <p className='mb-0 wae3t'>Sydney</p>
                    </div>
                    <div className="col-2 p-1">
                      <p className='mb-0 gyr4'>From</p>
                      <p className='mb-0 gt4e'>$850</p>
                    </div>
                    <div className="col-3 p-1">
                      <button className='edrit' onClick={() => window.location.href = `tel:${displayNumber}`}>Call</button>
                    </div>
                  </div>
                </div>
                <hr className='mx-auto w-90'/>
                <div className="ujrjt">
                  <div className="row px-2 align-items-center">
                    <div className="col-3 p-1">
                      <img className='w-100 detdr4' src={deal23} alt="" />
                    </div>
                    <div className="col-4 p-1">
                      <p className='mb-0 dt4w'>Flights to</p>
                      <p className='mb-0 wae3t'>Toronto</p>
                    </div>
                    <div className="col-2 p-1">
                      <p className='mb-0 gyr4'>From</p>
                      <p className='mb-0 gt4e'>$156</p>
                    </div>
                    <div className="col-3 p-1">
                      <button className='edrit' onClick={() => window.location.href = `tel:${displayNumber}`}>Call</button>
                    </div>
                  </div>
                </div>
                <hr className='mx-auto w-90'/>
                <div className="ujrjt">
                  <div className="row px-2 align-items-center">
                    <div className="col-3 p-1">
                      <img className='w-100 detdr4' src={deal24} alt="" />
                    </div>
                    <div className="col-4 p-1">
                      <p className='mb-0 dt4w'>Flights to</p>
                      <p className='mb-0 wae3t'>Calgary</p>
                    </div>
                    <div className="col-2 p-1">
                      <p className='mb-0 gyr4'>From</p>
                      <p className='mb-0 gt4e'>$254</p>
                    </div>
                    <div className="col-3 p-1">
                      <button className='edrit' onClick={() => window.location.href = `tel:${displayNumber}`}>Call</button>
                    </div>
                  </div>
                </div>
                <hr className='mx-auto w-90'/>
                <div className="ujrjt">
                  <div className="row px-2 align-items-center">
                    <div className="col-3 p-1">
                      <img className='w-100 detdr4' src={deal25} alt="" />
                    </div>
                    <div className="col-4 p-1">
                      <p className='mb-0 dt4w'>Flights to</p>
                      <p className='mb-0 wae3t'>Singapore</p>
                    </div>
                    <div className="col-2 p-1">
                      <p className='mb-0 gyr4'>From</p>
                      <p className='mb-0 gt4e'>$775</p>
                    </div>
                    <div className="col-3 p-1">
                      <button className='edrit' onClick={() => window.location.href = `tel:${displayNumber}`}>Call</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="dfjt">
                <div className="jrt">
                  <img className='w-100' src={cded} alt="" />
                  <p>Unmatchable Flight Deals</p>
                </div>
                <div className="ujrjt">
                  <div className="row px-2 align-items-center">
                    <div className="col-3 p-1">
                      <img className='w-100 detdr4' src={deal31} alt="" />
                    </div>
                    <div className="col-4 p-1">
                      <p className='mb-0 dt4w'>Flights to</p>
                      <p className='mb-0 wae3t'>Barbados</p>
                    </div>
                    <div className="col-2 p-1">
                      <p className='mb-0 gyr4'>From</p>
                      <p className='mb-0 gt4e'>$218</p>
                    </div>
                    <div className="col-3 p-1">
                      <button className='edrit' onClick={() => window.location.href = `tel:${displayNumber}`}>Call</button>
                    </div>
                  </div>
                </div>
                <hr className='mx-auto w-90'/>
                <div className="ujrjt">
                  <div className="row px-2 align-items-center">
                    <div className="col-3 p-1">
                      <img className='w-100 detdr4' src={deal32} alt="" />
                    </div>
                    <div className="col-4 p-1">
                      <p className='mb-0 dt4w'>Flights to</p>
                      <p className='mb-0 wae3t'>Kingston</p>
                    </div>
                    <div className="col-2 p-1">
                      <p className='mb-0 gyr4'>From</p>
                      <p className='mb-0 gt4e'>$265</p>
                    </div>
                    <div className="col-3 p-1">
                      <button className='edrit' onClick={() => window.location.href = `tel:${displayNumber}`}>Call</button>
                    </div>
                  </div>
                </div>
                <hr className='mx-auto w-90'/>
                <div className="ujrjt">
                  <div className="row px-2 align-items-center">
                    <div className="col-3 p-1">
                      <img className='w-100 detdr4' src={deal33} alt="" />
                    </div>
                    <div className="col-4 p-1">
                      <p className='mb-0 dt4w'>Flights to</p>
                      <p className='mb-0 wae3t'>Paris</p>
                    </div>
                    <div className="col-2 p-1">
                      <p className='mb-0 gyr4'>From</p>
                      <p className='mb-0 gt4e'>$275</p>
                    </div>
                    <div className="col-3 p-1">
                      <button className='edrit' onClick={() => window.location.href = `tel:${displayNumber}`}>Call</button>
                    </div>
                  </div>
                </div>
                <hr className='mx-auto w-90'/>
                <div className="ujrjt">
                  <div className="row px-2 align-items-center">
                    <div className="col-3 p-1">
                      <img className='w-100 detdr4' src={deal34} alt="" />
                    </div>
                    <div className="col-4 p-1">
                      <p className='mb-0 dt4w'>Flights to</p>
                      <p className='mb-0 wae3t'>Egypt</p>
                    </div>
                    <div className="col-2 p-1">
                      <p className='mb-0 gyr4'>From</p>
                      <p className='mb-0 gt4e'>$634</p>
                    </div>
                    <div className="col-3 p-1">
                      <button className='edrit' onClick={() => window.location.href = `tel:${displayNumber}`}>Call</button>
                    </div>
                  </div>
                </div>
                <hr className='mx-auto w-90'/>
                <div className="ujrjt">
                  <div className="row px-2 align-items-center">
                    <div className="col-3 p-1">
                      <img className='w-100 detdr4' src={deal35} alt="" />
                    </div>
                    <div className="col-4 p-1">
                      <p className='mb-0 dt4w'>Flights to</p>
                      <p className='mb-0 wae3t'>Barcelona</p>
                    </div>
                    <div className="col-2 p-1">
                      <p className='mb-0 gyr4'>From</p>
                      <p className='mb-0 gt4e'>$345</p>
                    </div>
                    <div className="col-3 p-1">
                      <button className='edrit' onClick={() => window.location.href = `tel:${displayNumber}`}>Call</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <p className="srtr5e3">
                <span>Note:</span> All fares are quoted in USD. Last updated on
                {getYesterdayDate()} at 05:00 AM, the fares mentioned above are
                for Round Trip flight tickets and inclusive of fuel surcharges,
                service fee and taxes . Based on historical data, these fares
                are subject to change without prior notice and cannot be
                guaranteed at the time of booking. Kindly go through our terms
                and conditions before booking
              </p>
            </div>
          </div>
          
        </div>
        
      </section>

      <section className='dtjrrdt ujegtr4'>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 rela">
              <h4 className='srth6'>Book Flight Deals from Rawfares</h4>
              <p className='kh5tsa'>Rawfares has made it simple to find the cheapest flights, which may be like trying to find a needle in a haystack. Enter your departure and destination, and a carefully chosen list of cheap options will be displayed. Our technology ensures that you get the best airline deal by comparing several airlines and optimal travel dates. You can also benefit from flash specials and stay ahead of price fluctuations thanks to our real-time alerts. You can use our Rawfares page to find exclusive information if you're searching for cheaper flight deals.</p>
            </div>
          </div>
        </div>
      </section>


      <section className='dtjrrdt wqerq45'>
       <div className="hrdt">
        <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ujrt">
                  <img className='ir66re' src={callw} alt="" />
                  <Link to={`tel:${displayNumber}`}><p className='dethik4 mb-0'>Call us at our 24/7  <span className='krh23'>|</span>  <span> {displayNumber} </span> to get Best deals!</p></Link>
                </div>
              </div>
            </div>
          </div>
       </div>
      </section>

      <section className='dtjrrdt wqerq45'>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 fy5rs3">
              <h3 className='hrth'>How to get cheap flights to your dream destinations?</h3>
              <p>Although finding cheap tickets to your desired locations may seem difficult, there are a few smart ways to do so without going over budget. Here are some tips for locating those elusive low-cost flights: waterways.</p>
              <ul className='dirh4e'>
                <li>You have a better chance of finding affordable airfare tickets if you are more flexible. Midweek flights are typically less expensive than weekend plane tickets, particularly on Tuesdays and Wednesdays. You can save a lot of money by booking reservations on trip dates in just one or two days.</li>
                <li>Sometimes making a reservation too early could result in you spending more than you need to. Aim to make your reservation six to eight weeks before your intended departure date. You can acquire great bargains here without getting sucked into the last-minute walk craze.</li>
                <li>To make sure you receive the greatest deal, tools like Rawfares compare a range of airlines and travel providers. These tools can notify you about your airfare price reductions and assist you in identifying deals that you may otherwise overlook.</li>
                <li>Think about other nearby airports if you're traveling to a big city. You may save a significant amount of money by taking a short bus or rail ride. Examine nearby airports to determine whether traveling there would save you money.</li>
                <li>Although nonstop flights are frequently more convenient, you can save a significant amount of money by making one or two stops. Connecting flights can result in big savings if you're ready to extend your journey time by a few hours.</li>
                <li>Although low-cost airlines can have amazing deals, be sure to look at their luggage fees and other extras. Do a thorough pricing comparison to make sure the ultimate cost is still less.</li>
              </ul>
              {/* <p>Whether you are traveling for work or to explore and learn or to reunite with family and friends, or simply just seeking solitude, Rawfares.com is here to fulfill your all travel needs.</p>
              <p>Doesn't matter which destination you choose for your next expedition, Rawfares.com is here to assist and ensure you get a good flight deal. We understand the importance of accommodating our customer's last-minute travel needs and requirements, which is why you can call us anytime, from anywhere to know more about available deals and book your flight</p>
              <p>You can also book your flight tickets from anywhere through our state-of-the-art mobile apps. Our cutting-edge technology and user-friendly interface ensure a hassle-free online booking experience at every step. We make affordable travel accessible on all Android and Apple devices and offer dedicated customer support around the clock. From searching for last minute flights to planning vacations, you can count on us to make travel bookings easy!</p> */}
              <h3 className='hrth'>Why Should You Choose Rawfares?</h3>
              <p>When you need assistance while traveling, Rawfares is here to provide it—something you won't find anywhere. Here are some reasons to pick Rawfares.</p>
              <ul className='dirh4e'>
                <li><span>Various Offers :</span> We have partnerships with the top airlines that give you access to various <b>affordable reservation deals</b> that are not easily available to the general public. It means you are going to save money with <b>affordable flight tickets</b>.</li>
                <li><span>Deep Research :</span> We search various airlines and travel sites to find the best affordable option, which helps in saving your money and your time so that you don't have to spend more on your journey.</li>
                <li><span>User-Friendly Platform :</span> Our platform is very easy to use; you don't need to be a technical expert to use our platform. Just open our website and start searching for affordable flight bookings wherever you are thinking of visiting. The travelers can use filters to search for price, travel duration, and more. This user-friendly platform will make your booking experience smoother and more affordable.</li>
                <li><span>Travel Information :</span> Our website provides every single piece of information related to your travel destination. Whether it’s about affordable deals or top attractions, we help you in making your journey better by helping you find the perfect balance between airfare cost and convenience.</li>
                {/* <li><span>Be flexible with travel plans :</span> You never know you can get better deals on online flight booking if you search for different dates. You can also avail better deals if you are flexible to change your destination. For instance, if you want to spend a holiday amidst serenity, you can choose another mountainous or beach destination in place of the one that has a high-ticket price.</li>
                <li><span>Join a frequent flyer program :</span> Several airlines run their frequent flyer program in which, you can earn points that can make you get cheaper tickets and upgrades. What's more! You can join these frequent flyer program for free. You must read the rules and regulations carefully before joining any frequent flyer program.</li>
                <li><span>Subscribe to email notifications :</span> Reading every email that lands in your inbox is certainly a tiring job. However, by reading the mail that talks about saving your hard-earned money on flight tickets can be worthy. Through subscribing to email notifications and newsletters, you can get frequent information on deals and book accordingly.</li>
                <li><span>Try paying in a different currency :</span> It is a hack used by very few. Many a time, you can get tickets in cheap just by paying the airfare in a different currency. You should try paying in a different currency in which you find the tickets cheap.</li>
                <li><span>Book with different airlines :</span> Return flights are undoubtedly convenient and cheaper options. But there are chances that if you search for flights offered by other airlines- and be a tad flexible about the time- you can get a low-fare flight.</li> */}
              </ul>
              <h3 className='hrth'>Which Airlines is the best for your Destination?</h3>
              <p>If you are flying from the USA, here are some of the top choices as per your comfort, price, readability, and services, which you can consider:</p>
              <ul className='dirh4e'>
                  <li><span>Alaska Airlines :</span> Alaska Airlines is a great choice if you are considering flying. These airlines carry about 40 million passengers annually to more than 100 domestic destinations as well as cities in Mexico, Costa Rica, and Canada.</li>
                  <li><span>Hawaiian Airlines :</span> Hawaiian Airlines holds its own when it comes to the best airlines in the US. Hawaiian flies up to 10 million passengers every year. significantly less than Alaska.</li>
                  <li><span>United Airlines :</span> United Airlines accommodates over 160 million passengers. If you are going to travel, United Airlines is the best choice for your next flight.</li>
              </ul>
              <p>So, why are you still waiting? Go and enter your dream itinerary details now to get affordable reservation deals and start searching for your next adventure. Your affordable airline deals are just a few clicks away from you!</p>
              <p>Start your journey today by booking <b>cheap flight tickets</b> with <b>Rawfares</b> and make your journey smoother by calling us today.</p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
