/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { format } from "date-fns";
import {Link} from 'react-router-dom';
import axios from 'axios';
import { constant } from "../constant";
import bking from '../assest/images/icon/booking.png';
import transfr from '../assest/images/icon/transfer.png';
import Sport from '../assest/images/icon/support.png';
import airoyh from '../assest/images/icon/airoj.png';
import Header from "../Component/Header/header";
import SearchEngine from "../Component/Search-Engine/engine";
import Footer from "../Component/Footer/Footer";
import { v4 as uuidv4 } from "uuid";
import {
  browserName,
  isAndroid,
  isDesktop,
  isIOS,
  isMobile,
} from "react-device-detect";
import MetaTitle from "../Component/MetaTitle/metaTitle";
const formatDate = (date) => {
  return date ? format(date, "MM/dd/yyyy") : "";
};

const useCabinClass = () => {
  const location = useLocation();
  const url = location.pathname; // Get the current URL path

  if (url.includes("business-class")) {
      return "C"; // Business Class
  } else if (url.includes("first-class")) {
      return "F"; // First Class
  } else {
      return "Y"; // Economy or Other Classes
  }
};

const useTripType = () => {
  const location = useLocation();
  const url = location.pathname; // Get the current URL path

  if (url.includes("one-way")) {
      return "1"; // Business Class
  } else {
      return "2"; // Economy or Other Classes
  }
};

const getYesterdayDate = () => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const options = { weekday: 'long', month: '2-digit', day: '2-digit', year: 'numeric' };
  return yesterday.toLocaleDateString('en-US', options);
};

export default function Deals() {
    const [utmParams, setUtmParams] = useState({});
 
  
  const location = useLocation();
  const [headText, setHeadText] = useState("");
  const [isHeadTextReady, setIsHeadTextReady] = useState(false);
  const [lineContent, setLineContent] = useState('Flight')
 const [DomesticDeals, setDomesticDeals] = useState([]);
 const [IntlDeals, setIntlDeals] = useState([]);
  const { response1, response2 } = location.state || {};
 
useEffect(() => {
        const storeUtmData = () => {
          const queryParams = new URLSearchParams(window.location.search);
          // console.log("queryParams", queryParams)
          const utmData = {};
      
          // Iterate through query parameters and extract UTM parameters
          for (let [key, value] of queryParams.entries()) {
            if (key.startsWith("utm_")) {
              utmData[key] = value;
            }
          }
      
          if (Object.keys(utmData).length > 0) {
            const expirationTime = new Date().getTime() + 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
            const dataWithExpiration = {
              utmData,
              expiresAt: expirationTime,
            };
      
            // Store UTM data in localStorage
            localStorage.setItem("utm_data", JSON.stringify(dataWithExpiration));
            setUtmParams(utmData); // Update state
          }
        };
      
        // Execute on query parameter change
        storeUtmData();
      }, [location.search]);


      useEffect(() => {
        const currentPath = location.pathname;
        const pageMatch = currentPath.endsWith("/flight-ticket");
        if (pageMatch) {
          setHeadText("Find Cheap Flight Tickets Over 500 Airlines!");
          setIsHeadTextReady(true); // Head text is now ready
        } else if(currentPath.endsWith("/business-class-flight")) {
          setHeadText("Top Business Class Deals"); // Reset if not matching
          setLineContent('Class')
          setIsHeadTextReady(true); // Even if empty, it's ready
        } else {
          setHeadText(""); // Reset if not matching
          setIsHeadTextReady(true);
        }
      }, [location.pathname]);
    
      // Render only when headText is set
      

const { airlineName } = useParams();

const parts = airlineName.split('-');

// Remove any undefined or empty parts
const filteredParts = parts.filter(Boolean);

// Get the last part as the Page Value
const pageValue = filteredParts.pop() || '';
// Get the second last part as the URL
// const url = filteredParts.slice(-1).join('-') || '';
const url = airlineName.substring(airlineName.lastIndexOf('/') + 1) || '';

const [firstResponseData, setFirstResponseData] = useState(response1?.response[0] || null);
const [secondResponseData, setSecondResponseData] = useState(response2 || null);

const [reloadData, setReloadData] = useState({
  pageType: "Deals",
  pageValue: "flight",
  siteId: "16",
  url: url
});
const cabinClass = useCabinClass();
const tripType = useTripType();
const fetchData = async () => {
    
  ////console.log(reloadData)
    try {
      let response;
      if (!response1) {
        // console.log("ReloadData", reloadData)
      response = await axios.post(`${constant.baseUrl}/api/ppc/flight-data`, reloadData);
      // console.log("RESS", response)
      // Set firstResponseData state with the response if response1 is null
      if (response.status === 200) {
        setFirstResponseData(response.data.response[0]);
        setHeadText(`${response.data.response[0]?.dealTitle}`);
        ////console.log("Response from the first API:", response.data);
        ////console.log(deals)
        
        const response2 = await axios.post(`${constant.baseUrl}/api/ppc/deals`,
          { froCity:"",
            toCity: "",
            airline: "",
            cabinClass: cabinClass,
            tripType: tripType,
            page:"Domestic",
            currency:"USD",
            siteId: "16",
            departDate:"",
            countryName:"US",
            countryCode:"US",
            cityName:"" }
        );
        // console.log("status2", response2.status)
        if(response2.status === 200){
          setDomesticDeals(response2.data.response);
          // console.log("Domestic Deals", response2.data)
          const response3 = await axios.post(`${constant.baseUrl}/api/ppc/deals`,
            { froCity:"",
              toCity: "",
              airline: "",
              cabinClass: cabinClass,
              tripType: tripType,
              page:"international",
              currency:"USD",
              siteId: "16",
              departDate:"",
              countryName:"US",
              countryCode:"US",
              cityName:"" }
          );
          if(response3.status === 200){
            setIntlDeals(response3.data.response );
          }
        }
        
        setSecondResponseData(response2.data);
        
        ////console.log("responswe23",response2);
      }
        
        
      } else {
      // Use the existing response1 if available
      setFirstResponseData(response1.response[0]);
      }
      ////console.log(response);
      // Update state with response data if needed
    } catch (error) {
      ////console.error('Error fetching data:', error);
      // Handle errors appropriately
    }
    };

   useEffect(() => {
     // Check if response1 or response2 is null, then call fetchData
     if (!response1 || !response2) {
       fetchData();
     }
   }, [url, response1, response2]);
   
   const [selectedFlight, setSelectedFlight] = useState();
   useEffect(() => {
    if (secondResponseData && secondResponseData.response.length > 0) {
      setSelectedFlight(secondResponseData.response[0]);
    }
  }, [secondResponseData]);
  const handleFroCityClick = (item) => {
    setSelectedFlight(item);
  };
     const imageNames = ['business-class.webp', 'honeymoon.webp', 'international.webp', 'last-minute.webp', 'lgbtq.webp', 'military.webp', 'senior.webp', 'student.webp', 'summer.webp'];
     const filteredImages = imageNames
     .filter(name => name !== `${url}.webp`)
     .slice(0, 3); // Limiting to 3 images

     const imageNameToAirline = {
      'business-class.webp': 'business-class',
      'honeymoon.webp': 'honeymoon',
      'international.webp': 'international',
      'last-minute.webp': 'last-minute',
      'lgbtq.webp': 'lgbtq',
      'military.webp': 'military',
      'senior.webp': 'senior',
      'student.webp': 'student',
      'summer.webp': 'summer'
    };
    
    
    // Now, in your handleSideClick function, use this mapping to get the airline name corresponding to the clicked image
    const handleSideClick = (pageValue, imageName) => {
      const airlineName = imageNameToAirline[imageName];
      if (airlineName) {
        setReloadData({
          ...reloadData,
          pageValue,
          url: airlineName,
        });
        //fetchData();
        handleAirlineClicked();
      }
    };
    

    const getDealCode = (name) => {
      // Define a mapping of airline names to their respective codes
      const airlineCodes = {
         'Business Class': 'business-class',
         'Domestic': 'domestic',
         'Honeymoon': 'honeymoon',
         'International Travel': 'international',
         'Last Minute': 'last-minute',
         'LGBTQ': 'lgbtq',
         'Military': 'military',
         'Senior': 'senior',
         'Student': 'student',
         'Summer': 'summer'
        // Add more airline names and codes as needed
      };
    
      return airlineCodes[name];
    };
    
    // Function to create a URL with the airline code appended to the airline name
    const createURL = (name) => {
      // console.log(name)
      const airlineCode = getDealCode(name);
      return `https://www.rawfares.com/deals/${airlineCode.toLowerCase()}-flight`;
    };

    const createURLIMG = (name) => {
      
      // Remove the '.webp' extension
      const nameWithoutExtension = name.replace('.webp', '');
      // console.log(nameWithoutExtension);
      // const airlineCode = getDealCode(nameWithoutExtension);
      return `https://www.rawfares.com/deals/${nameWithoutExtension.toLowerCase()}-flight`;
    };

    const [airlines, setAirlines] = useState({
      pageType: "deals",
      pageValue: "flight",
      siteId: "16",
      url: ""
    });

    const [deals, setDeals] = useState({
      froCity:"",
      toCity:"",
      airline: airlines.pageValue,
      cabinClass:"Y",
      tripType:"2",
      page:"",
      currency:"USD",
      siteId: "16",
      departDate:"",
      countryName:"US",
      countryCode:"US",
      cityName:""
  });
    const [responseData, setResponseData] = useState(null);
    //const navigate = useNavigate();
    useEffect(() => {
      // Use a useEffect to make the API call whenever airlines state changes
      if (airlines.pageValue && airlines.url) {
        handleAirlineClicked();
      }
    }, [airlines]);
const handleAirlineClicked = async () => {

  const navigationPath = airlines.pageValue.length === 2
    ? `/deals/${airlines.url}-${airlines.pageValue}`
    : `/deals/${airlines.url}-${airlines.pageValue}`;

  const newTab = window.open(navigationPath, '_blank');
  if (newTab) {
      ////////console.log('Received data in new tab:');
    newTab.onload = function() {
      newTab.postMessage(window.location.origin);
    };
  } else {
    ////////console.error('Failed to open new tab. Please check your browser settings or extensions.');
  }

  // Navigate to the new page with data passed via state
  //navigate(navigationPath);
};
    
    
  
    const handleCardClick = (pageType, pageValue, url) => {
      // Set the new values for airlines based on the card clicked
      setAirlines({
        ...airlines,
        pageType,
        pageValue : "flight",
        url,
      });
    };

    const openLink = (url) => {
  window.open(url, '_blank');
  };
  const standardizeAirlineName = (name) => {
    // Define a mapping of variations to a standard name
    const nameVariations = {
      'business-class': 'Business Class',
      'domestic': 'Domestic',
      'honeymoon': 'Honeymoon',
      'international': 'International Travel',
      'last-minute': 'Last Minute',
      'lgbtq': 'LGBTQ',
      'military': 'Military',
      'senior': 'Senior',
      'student': 'Student',
      'summer': 'Summer'
      // Add more variations as needed
    };
  
    return nameVariations[name] || name; // If a variation is found, return the standardized name, otherwise return the original name
  };
  const getAirlineCode = (name) => {
    // Define a mapping of airline names to their respective codes
    const airlineCodes = {
      "Albuquerque": "ABQ",
      "Barcelona": "BCN",
      "Boston": "BOS",
      "Cancun": "CUN",
      "Charleston": "CHS",
      "Hawaii": "HI",
      "Chicago": "ORD",
      "Las Vegas": "LAS",
      "Orlando": "MCO",
      "Seattle": "SEA",
      "San Jose": "SJC",
      "San Francisco": "SFO",
      "Dallas": "DFW",
      "Denver": "DEN",
      "Honolulu": "HNL",
      "Kahului": "OGG",
      "Los Angeles": "LAX",
      "Guadalajara": "GDL",
      "London": "LON",
      "Cuncun": "CUN",
      "New York": "NYC",
      "Paris": "PAR",
      "Toronto": "YYZ",
      "Rome" : "ROM",
      "Tokyo" : "HND",
      "Florida" : "FL",
      "Vancouver" : "YVR",
      "Sydney" : "SYD",
      "South Island": "CHC",
      "Newport" : "ONP",
      "Louisville": "SDF",
      "Washington" : "WAS",
      "Westchester": "HPN"
    };
  
    return airlineCodes[name];
  };

  const createURLNew = (name) => {
    const airlineCode = getAirlineCode(name);
    return `https://www.rawfares.com/flights/cheap-flights-to-${name?.toLowerCase().replace(/\s+/g, '-')}-${airlineCode?.toLowerCase()}`;
  };

  const createURLRoute = (origin, destination) => {
    const originCode = getAirlineCode(origin);
    const destinationCode = getAirlineCode(destination);
    return `https://www.rawfares.com/flights/cheap-flights-from-${origin?.toLowerCase().replace(/\s+/g, '-')}-to-${destination?.toLowerCase().replace(/\s+/g, '-')}-${originCode?.toLowerCase()}-${destinationCode?.toLowerCase()}`;
  };
  
  // Apply the standardization to create an array of standardized airline names from filteredImages
  const airlineNamesFromImages = filteredImages.map(imageName => standardizeAirlineName(imageNameToAirline[imageName]));
  const navigate = useNavigate();
    const [formValues, setFormValues] = useState({
      searchID: "", // Default value or dynamic value
      client: 2,
      segment: [
        {
          originAirport: "",
          destinationAirport: "",
          travelDate: "",
          originCountry: "US",
          destinationCountry: "ALL",
        },
        {
          originAirport: "",
          destinationAirport: "",
          travelDate: "",
        },
      ],
      searchDirectFlight: false,
      flexibleSearch: false,
      tripType: "2", // Default to round trip
      adults: 1,
      child: 0,
      infants: 0,
      infantsWs: 0,
      cabinType: "1",
      airline: "All",
      currencyCode: "USD",
      siteId: 16,
      source: "Online",
      media: "",
      sID: "",
      rID: "",
      locale: "en",
      isNearBy: false,
      limit: 200,
      userIP: "",
      serverIP: "",
      device: "",
      browser: "", // Define browserName if necessary
    });
  const [searchId, setSearchId] = useState("");
  const handleDealClick = async (deal) => {
    const deviceName = isMobile
      ? "Mobile Device"
      : isDesktop
      ? "Desktop"
      : isAndroid
      ? "Android"
      : isIOS;
  
    try {
      // Fetch airport data from the API
      const searchValue = `${deal.froCity}`;
      const searchValue2 = `${deal.toCity}`;
      const airportResponse = await axios.get(
        `https://www.tgsapi.com/flights/CrossAirportList?data=${searchValue}&authcode=farehuts`
      );
      // console.log("FGBG", `https://www.tgsapi.com/flights/CrossAirportList?data=${searchValue}&authcode=farehuts`)
      const airport2Response = await axios.get(
        `https://www.tgsapi.com/flights/CrossAirportList?data=${searchValue2}&authcode=farehuts`
      );
  
      if (airportResponse?.data) {
        const airports = airportResponse.data; // Assuming the API returns an array
        const selectedOrigin = airports.find((item) => item.airportCode === deal.froCity);
  
        if (selectedOrigin) {
          localStorage.setItem("selectedOrigin", JSON.stringify(selectedOrigin));
        }
      }
      if (airport2Response?.data) {
        const airports = airport2Response.data; // Assuming the API returns an array
        const selectedDestination = airports.find((item) => item.airportCode === deal.toCity);
  
        if (selectedDestination) {
          localStorage.setItem("selectedDestination", JSON.stringify(selectedDestination));
        }
      }
  
      // Fetch user IP
      const response = await axios.get("https://api.ipify.org?format=json");
      const userIP = response.data.ip;
  
      const newSearchID = uuidv4().slice(0, 16);
      setSearchId(newSearchID);
  
      // Update form state based on the selected deal
      if(deal.tripType === "1"){
      const updatedFormValues = {
        ...formValues,
        segment: [
          {
            originAirport: deal.froCity,
            destinationAirport: deal.toCity,
            travelDate: formatDate(deal.depDate),
            originCountry: "US",
            destinationCountry: "ALL",
          },
        ],
        cabinType: deal.cabinClass,
        tripType: deal.tripType,
        searchID: newSearchID,
        userIP: userIP,
        device: deviceName,
      };
  
      setFormValues(updatedFormValues);
      navigate("/listing", { state: { updatedFormValues } });
    } else {
      const updatedFormValues = {
        ...formValues,
        segment: [
          {
            originAirport: deal.froCity,
            destinationAirport: deal.toCity,
            travelDate: formatDate(deal.depDate),
            originCountry: "US",
            destinationCountry: "ALL",
          },
          {
            originAirport: deal.toCity,
            destinationAirport: deal.froCity,
            travelDate: formatDate(deal.retDate),
          },
        ],
        cabinType: deal.cabinClass,
        tripType: deal.tripType,
        searchID: newSearchID,
        userIP: userIP,
        device: deviceName,
      };
  
      setFormValues(updatedFormValues);
      navigate("/listing", { state: { updatedFormValues } });
    }
      // console.log("Selected deal:", updatedFormValues);
    } catch (error) {
      console.error("API fetch error:", error);
    }
  };
  const [utmCampaign, setUtmCampaign] = useState("");
          const [displayNumber, setDisplayNumber] = useState("");
          const jsonMapping = {
              domestics_routes: "+1-833-609-8333",
              domestics_destination: "+1-833-609-7300",
              cruise : "+1-833-609-9933",
              airline: "+1-833-609-8444"
            };
            const defaultNumber = "+1-888-415-1536"; // Default number to display if UTM data is not found
        
            useEffect(() => {
              const updateDisplayNumber = () => {
                const utmData = JSON.parse(localStorage.getItem("utm_data")) || {};
              //   console.log(utmData)
                if (utmData?.utmData?.utm_campaign) {
                  // console.log("in")
                  setUtmCampaign(utmData?.utm_campaign);
          
                  // Check if `utm_campaign` exists in the JSON mapping
                  const mappedNumber = jsonMapping[utmData?.utmData?.utm_campaign];
                  setDisplayNumber(mappedNumber || defaultNumber); // Use mapped number or default
                } else {
                  // console.log("out")
                  // No UTM data found, use the default number
                  setDisplayNumber(defaultNumber);
                }
              };
          
              // Run initially
              updateDisplayNumber();
          
              // Set up interval to check every 10 seconds
              const interval = setInterval(updateDisplayNumber, 1000000000000);
          
              // Clean up interval on component unmount
              return () => clearInterval(interval);
            }, [jsonMapping]);
            const maxDeals = 5;
            const domesticDealsToShow = DomesticDeals?.slice(0, maxDeals);
            const intlDealsToShow = IntlDeals?.slice(0, maxDeals);
            
            // If International deals are empty and Domestic has more than 5, show extra in side section
            const extraDomesticDeals = IntlDeals.length === 0 && DomesticDeals.length > maxDeals
              ? DomesticDeals.slice(maxDeals, maxDeals * 2)
              : [];
            
            // If Domestic deals are empty and International has more than 5, show extra in side section
            const extraIntlDeals = DomesticDeals.length === 0 && IntlDeals.length > maxDeals
              ? IntlDeals.slice(maxDeals, maxDeals * 2)
              : [];

              const [bgImage, setBgImage] = useState("");
              
              useEffect(() => {
                const image = new Image();
                image.src = `https://imgfolders.com/rawfaresnew/deals/${firstResponseData?.pageName}.webp`;
              
                image.onload = () => setBgImage(image.src);
                image.onerror = () =>
                  setBgImage("https://imgfolders.com/rawfares/rawfaresUS/ppc/21rawfares.webp");
              }, [firstResponseData?.pageName]);

  return (
    <>
    <MetaTitle pageTitle={firstResponseData?.metaTitle} pageDescription={firstResponseData?.metaDesc} url={`https://www.rawfares.com/deals/${firstResponseData?.url}`} />
        <Header />
        {firstResponseData && firstResponseData.pageName && isHeadTextReady ? (
        <SearchEngine headText={headText} 
        imgSpecial={true} img={bgImage} />
      ) : (
        <div>Loading...</div> // Optionally show loading spinner or text
      )}
      <div className="iur85ja">
        <section className='dtjrrdt wqerq45 mob-hide'>
        <div className="container">
             <Link to={`tel:${displayNumber}`}>
          <div className="row sfs34f align-items-center">
            <div className="col-lg-3">
              <p className='ghjge3'>Call us 24/7 at <span><Link to={`tel:{displayNumber}`}>{displayNumber}</Link></span></p>
              <p className='wri34d mb-0'>Need help booking? Our agents are ready! Choose from over 500 airlines.</p>
            </div>
            <div className="col-lg-9 ">
              <div className="row w-100 m-auto dftgdfty">
                <div className="col-lg-4">
                  <div className="d-flex align-items-start">
                  <img src={bking} alt="" />
                  <div className="fdtd3">
                    <p className='mb-0 e5t66'>EASY BOOKING</p>
                    <p className='mb-0 dtgr6r4'>We offer easy and convenient flight booking with attractive offers.</p>
                  </div>
                  </div>
                </div>
                <div className="col-lg-4 rftw4">
                  <div className="d-flex align-items-start">
                    <img src={transfr} alt="" />
                    <div className="fdtd3 ">
                      <p className='mb-0 e5t66'>LOWEST PRICE</p>
                      <p className='mb-0 dtgr6r4'>We ensure low rates on airlines reservation, holiday packages and on flight tickets.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="d-flex align-items-start">
                    <img src={Sport} alt="" />
                    <div className="fdtd3">
                      <p className='mb-0 e5t66'>24/7 SUPPORT</p>
                      <p className='mb-0 dtgr6r4'>Get assistance 24/7 on any kind of travel related query. We are happy to assist you.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </Link>
        </div>
      </section>
      <Link to={`tel:${displayNumber}`}>
              <button class="swru46y desk-hide"> Call For Special {lineContent} Deals</button>
              </Link>
      {firstResponseData && firstResponseData.pageName && (
      <section className="py-5">
        <div className="container">
        
            <div className="row">
            <h3>{firstResponseData?.fromCity}</h3>
              <p>At Rawfares, we’re dedicated to bringing you the most incredible airfare deals that are sure to elevate your travel experience. Our exclusive offers are the best we’ve discovered, offering exceptional value for your next journey. Don’t wait—these deals won’t last long. Book now and embark on your next adventure!</p>
            {domesticDealsToShow.length > 0 && (
      <div className="col-lg-6 p-0">
        <h3 className="text-center mb-4">Top Domestic Flights</h3>
        <div className="row">
          {domesticDealsToShow.map((deal, index) => (
            <div className="col-lg-12" key={index} onClick={() => handleDealClick(deal)} style={{ cursor: "pointer" }}>
              <div className="hsgr4e">
                <div className="row">
                  <div className="col-4 col-lg-3">
                    <p className="mb-0 hr59w1">{deal.froCityName}</p>
                    <p className="mb-0 hr59w2">{deal.froCity}</p>
                    <p className="mb-0 hr59w3">{formatDate(deal.depDate)}</p>
                  </div>
                  <div className="col-4 col-lg-3">
                    <div className="hr59w6">
                      <img className="hr59w7" src={airoyh} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-lg-3">
                    <p className="mb-0 hr59w1">{deal.toCityName}</p>
                    <p className="mb-0 hr59w2">{deal.toCity}</p>
                    <p className="mb-0 hr59w3">{formatDate(deal.retDate)}</p>
                  </div>
                  <div className="col-lg-3 align-self-end hr59w8">
                    <p className="mb-0 hr59w4">USD {deal.totalPrice}</p>
                    <p className="mb-0 hr59w5">{deal.tripType === "2" ? 'Round Trip' : 'One Way'}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    )}

    {intlDealsToShow.length > 0 && (
      <div className="col-lg-6 p-0">
        <h3 className="text-center mb-4">Top International Flights</h3>
        <div className="row">
          {intlDealsToShow.map((deal, index) => (
            <div className="col-lg-12" key={index} onClick={() => handleDealClick(deal)} style={{ cursor: "pointer" }}>
              <div className="hsgr4e">
                <div className="row">
                  <div className="col-4 col-lg-3">
                    <p className="mb-0 hr59w1">{deal.froCityName}</p>
                    <p className="mb-0 hr59w2">{deal.froCity}</p>
                    <p className="mb-0 hr59w3">{formatDate(deal.depDate)}</p>
                  </div>
                  <div className="col-4 col-lg-3">
                    <div className="hr59w6">
                      <img className="hr59w7" src={airoyh} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-lg-3">
                    <p className="mb-0 hr59w1">{deal.toCityName}</p>
                    <p className="mb-0 hr59w2">{deal.toCity}</p>
                    <p className="mb-0 hr59w3">{formatDate(deal.retDate)}</p>
                  </div>
                  <div className="col-lg-3 align-self-end hr59w8">
                    <p className="mb-0 hr59w4">USD {deal.totalPrice}</p>
                    <p className="mb-0 hr59w5">{deal.tripType === "2" ? 'Round Trip' : 'One Way'}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    )}

    {/* Show extra Domestic deals if Intl is empty */}
    {extraDomesticDeals.length > 0 && (
      <div className="col-lg-6 p-0">
        <h3 className="text-center mb-4">More Domestic Flights</h3>
        <div className="row">
          {extraDomesticDeals.map((deal, index) => (
            <div className="col-lg-12" key={index} onClick={() => handleDealClick(deal)} style={{ cursor: "pointer" }}>
              <div className="hsgr4e">
                <div className="row">
                  <div className="col-4 col-lg-3">
                    <p className="mb-0 hr59w1">{deal.froCityName}</p>
                    <p className="mb-0 hr59w2">{deal.froCity}</p>
                    <p className="mb-0 hr59w3">{formatDate(deal.depDate)}</p>
                  </div>
                  <div className="col-4 col-lg-3">
                    <div className="hr59w6">
                      <img className="hr59w7" src={airoyh} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-lg-3">
                    <p className="mb-0 hr59w1">{deal.toCityName}</p>
                    <p className="mb-0 hr59w2">{deal.toCity}</p>
                    <p className="mb-0 hr59w3">{formatDate(deal.retDate)}</p>
                  </div>
                  <div className="col-lg-3 align-self-end hr59w8">
                    <p className="mb-0 hr59w4">USD {deal.totalPrice}</p>
                    <p className="mb-0 hr59w5">{deal.tripType === "2" ? 'Round Trip' : 'One Way'}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    )}

    {/* Show extra Intl deals if Domestic is empty */}
    {extraIntlDeals.length > 0 && (
      <div className="col-lg-6 p-0">
        <h3 className="text-center mb-4">More International Flights</h3>
        <div className="row">
          {extraIntlDeals.map((deal, index) => (
            <div className="col-lg-12" key={index} onClick={() => handleDealClick(deal)} style={{ cursor: "pointer" }}>
              <div className="hsgr4e">
                <div className="row">
                  <div className="col-4 col-lg-3">
                    <p className="mb-0 hr59w1">{deal.froCityName}</p>
                    <p className="mb-0 hr59w2">{deal.froCity}</p>
                    <p className="mb-0 hr59w3">{formatDate(deal.depDate)}</p>
                  </div>
                  <div className="col-4 col-lg-3">
                    <div className="hr59w6">
                      <img className="hr59w7" src={airoyh} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-lg-3">
                    <p className="mb-0 hr59w1">{deal.toCityName}</p>
                    <p className="mb-0 hr59w2">{deal.toCity}</p>
                    <p className="mb-0 hr59w3">{formatDate(deal.retDate)}</p>
                  </div>
                  <div className="col-lg-3 align-self-end hr59w8">
                    <p className="mb-0 hr59w4">USD {deal.totalPrice}</p>
                    <p className="mb-0 hr59w5">{deal.tripType === "2" ? 'Round Trip' : 'One Way'}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    )}
    {(DomesticDeals.length > 0 || IntlDeals.length > 0) && (
        <p className="abcdefg"><span className="text-primary">Note: All fares are quoted in USD.</span><br></br><br/>
    
    Last updated on <span className="text-primary">{getYesterdayDate()} at 5:00 AM,</span> the fares mentioned above are for Round Trip flight tickets and inclusive of fuel surcharge, <span className="text-primary"><Link to='/taxes-and-fees' target="_blank">service fee and taxes</Link></span>. Based on historical data, these fares are 
    subject to change without prior notice and cannot be guaranteed at the time of booking. Kindly go through out <span className="text-primary"><Link to='/terms-and-condition' target="_blank">terms and conditions</Link></span> before booking.</p>
    )}
                <div className="col-lg-12">
                    <h3>{firstResponseData?.toCity}</h3>
                    
                    <p className="mb-4" dangerouslySetInnerHTML={{
                      __html: firstResponseData?.contentData,
                    }}></p>
                </div>
               <div className="col-lg-12">
               <h3>{firstResponseData?.airportHub}</h3>
                 <p className="mb-4"
                  dangerouslySetInnerHTML={{
                    __html: firstResponseData?.travelTrends,
                  }}
                ></p>
               </div>

               
            </div>
        </div>
      </section>
        )}
        </div>
        <Footer tabopen='flightsRoute' />
        </>
  )
}
