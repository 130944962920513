/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { format } from "date-fns";
import {Link} from 'react-router-dom';
import axios from 'axios';
import { constant } from "../../constant";
import bking from '../../assest/images/icon/booking.png';
import transfr from '../../assest/images/icon/transfer.png';
import Sport from '../../assest/images/icon/support.png';
import airoyh from '../../assest/images/icon/airoj.png';
import Header from "../../Component/Header/header";
import SearchEngine from "../../Component/Search-Engine/engine";
import Footer from "../../Component/Footer/Footer";
import { v4 as uuidv4 } from "uuid";
import {
  browserName,
  isAndroid,
  isDesktop,
  isIOS,
  isMobile,
} from "react-device-detect";
import MetaTitle from "../../Component/MetaTitle/metaTitle";

const formatDate = (date) => {
  return date ? format(date, "MM/dd/yyyy") : "";
};

const getYesterdayDate = () => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const options = { weekday: 'long', month: '2-digit', day: '2-digit', year: 'numeric' };
  return yesterday.toLocaleDateString('en-US', options);
};

export default function Airline() {
    const location = useLocation();
    const { response1, response2 } = location.state || {};
     const { airlineName } = useParams();
     const [utmParams, setUtmParams] = useState({});
  const parts = airlineName.split('-');
  parts.pop();
  const pageValue = parts.pop();
  const url = parts.join('-');
    const [firstResponseData, setFirstResponseData] = useState(response1?.response[0] || null);
    const [secondResponseData, setSecondResponseData] = useState(response2 || null);
    const [headText, setHeadText] = useState("");
    const [searchId, setSearchId] = useState("");
    const [DomesticDeals, setDomesticDeals] = useState([]);
    const [IntlDeals, setIntlDeals] = useState([]);
      const navigate = useNavigate();
    
     const [reloadData, setReloadData] = useState({
       pageType: "Airline",
       pageValue: pageValue,
       siteId: "16",
       url: `${url}-${pageValue}-flights`
     })
  
     const fetchData = async () => {
    
      ////console.log(reloadData)
        try {
          let response;
          if (!response1) {
            // console.log("ReloadData", reloadData)
          response = await axios.post(`${constant.baseUrl}/api/ppc/flight-data`, reloadData);
          // console.log("RESS", response)
          // Set firstResponseData state with the response if response1 is null
          if (response.status === 200) {
            setFirstResponseData(response.data.response[0]);
            setHeadText(`${response.data.response[0]?.dealTitle}`);
            // console.log("HeadText", response.data.response[0]?.dealTitle)
            ////console.log("Response from the first API:", response.data);
            ////console.log(deals)
            const response2 = await axios.post(`${constant.baseUrl}/api/ppc/deals`,
              { froCity:"",
                toCity: "",
                airline: response.data.response[0]?.pageValue?.toUpperCase(),
                cabinClass:"Y",
                tripType:"2",
                page:"Domestic",
                currency:"USD",
                siteId: "16",
                departDate:"",
                countryName:"US",
                countryCode:"US",
                cityName:"" }
            );
            // console.log("status2", response2.status)
            if(response2.status === 200){
              setDomesticDeals(response2.data.response);
              // console.log("Domestic Deals", response2.data)
              const response3 = await axios.post(`${constant.baseUrl}/api/ppc/deals`,
                { froCity:"",
                  toCity: "",
                  airline: response.data.response[0]?.pageValue?.toUpperCase(),
                  cabinClass:"Y",
                  tripType:"2",
                  page:"international",
                  currency:"USD",
                  siteId: "16",
                  departDate:"",
                  countryName:"US",
                  countryCode:"US",
                  cityName:"" }
              );
              if(response3.status === 200){
                setIntlDeals(response3.data.response );
              }
            }
            
            setSecondResponseData(response2.data);
            
            ////console.log("responswe23",response2);
          }
            
            
          } else {
          // Use the existing response1 if available
          setFirstResponseData(response1.response[0]);
          }
          ////console.log(response);
          // Update state with response data if needed
        } catch (error) {
          ////console.error('Error fetching data:', error);
          // Handle errors appropriately
        }
        };
  
     useEffect(() => {
       // Check if response1 or response2 is null, then call fetchData
       if (!response1 || !response2) {
         fetchData();
       }
     }, [url, pageValue, response1, response2]);
     
     const [selectedFlight, setSelectedFlight] = useState();
     useEffect(() => {
      if (secondResponseData && secondResponseData.response.length > 0) {
        setSelectedFlight(secondResponseData.response[0]);
      }
    }, [secondResponseData]);
    const handleFroCityClick = (item) => {
      setSelectedFlight(item);
    };
       const imageNames = ['allegiant-air.webp','frontier-airlines.webp', 'american-airlines.webp', 'hawaiian-airlines.webp', 'jetBlue-airways.webp', 'lufthansa-airlines.webp', 'spirit-airlines.webp', 'sun-country-airlines.webp', 'united-airlines.webp'];
       const filteredImages = imageNames
       .filter(name => name !== `${url}.webp`)
       .slice(0, 3); // Limiting to 3 images
  
       const imageNameToAirline = {
        'allegiant-air.webp': 'allegiant-air',
        'frontier-airlines.webp': 'frontier-airlines',
        'american-airlines.webp': 'american-airlines',
        'hawaiian-airlines.webp': 'hawaiian-airlines',
        'jetBlue-airways.webp': 'jetblue-airways',
        'lufthansa-airlines.webp': 'lufthansa-airlines',
        'spirit-airlines.webp': 'spirit-airlines',
        'sun-country-airlines.webp': 'sun-country-airlines',
        'united-airlines.webp': 'united-airlines'
      };
      const imageNameToAirlineCode = {
        'allegiant-air.webp': 'g4',
        'frontier-airlines.webp': 'f9',
        'american-airlines.webp': 'aa',
        'hawaiian-airlines.webp': 'ha',
        'jetBlue-airways.webp': 'b6',
        'lufthansa-airlines.webp': 'lh',
        'spirit-airlines.webp': 'nk',
        'sun-country-airlines.webp': 'sy',
        'united-airlines.webp': 'ua'
      };
      
      // Now, in your handleSideClick function, use this mapping to get the airline name corresponding to the clicked image
      const handleSideClick = (pageValue, imageName) => {
        const airlineName = imageNameToAirline[imageName];
        if (airlineName) {
          setReloadData({
            ...reloadData,
            pageValue,
            url: airlineName,
          });
          //fetchData();
          handleAirlineClicked();
        }
      };
      
  
      const getAirlineCode = (name) => {
        // Define a mapping of airline names to their respective codes
        const airlineCodes = {
          "Alaska Airlines": "AS",
         // "Delta Air Lines": "DL",
          "United Airlines": "UA",
          "Lufthansa Airlines": "LH",
          "Frontier Airlines": "F9",
          "Spirit Airlines": "NK",
          "JetBlue Airways": "B6",
          "Hawaiian Airlines": "HA",
          "Sun Country Airlines": "SY"
          // Add more airline names and codes as needed
        };
      
        return airlineCodes[name];
      };
      
      // Function to create a URL with the airline code appended to the airline name
      const createURL = (name) => {
        const airlineCode = getAirlineCode(name);
        return `https://www.rawfares.com/airline/${name.toLowerCase().replace(/\s+/g, '-')}-${airlineCode.toLowerCase()}-flights`;
      };
  
      const [airlines, setAirlines] = useState({
        pageType: "Airline",
        pageValue: "",
        siteId: "16",
        url: ""
      });
  
      const [deals, setDeals] = useState({
        froCity:"",
        toCity:"",
        airline: airlines.pageValue,
        cabinClass:"Y",
        tripType:"2",
        page:"",
        currency:"USD",
        siteId: "16",
        departDate:"",
        countryName:"US",
        countryCode:"US",
        cityName:""
    });
      const [responseData, setResponseData] = useState(null);
      //const navigate = useNavigate();
      useEffect(() => {
        // Use a useEffect to make the API call whenever airlines state changes
        if (airlines.pageValue && airlines.url) {
          handleAirlineClicked();
        }
      }, [airlines]);
  const handleAirlineClicked = async () => {
    const navigationPath = airlines.pageValue.length === 2
      ? `/airline/${airlines.url}-${airlines.pageValue}-flights`
      : `/flights-to/${airlines.url}-${airlines.pageValue}`;
  
    const newTab = window.open(navigationPath, '_blank');
    if (newTab) {
        ////console.log('Received data in new tab:');
      newTab.onload = function() {
        newTab.postMessage(window.location.origin);
      };
    } else {
      ////console.error('Failed to open new tab. Please check your browser settings or extensions.');
    }
  
    // Navigate to the new page with data passed via state
    //navigate(navigationPath);
  };
      
      
    
      const handleCardClick = (pageType, pageValue, url) => {
        // Set the new values for airlines based on the card clicked
        setAirlines({
          ...airlines,
          pageType,
          pageValue,
          url,
        });
      };
  
      const openLink = (url) => {
    window.open(url, '_blank');
    };
    const standardizeAirlineName = (name) => {
      // Define a mapping of variations to a standard name
      const nameVariations = {
        'allegiant-air': 'Allegiant Airlines',
        'frontier-airlines': 'Frontier Airlines',
        'american-airlines': 'American Airlines',
        'hawaiian-airlines': 'Hawaiian Airlines',
        'jetblue-airways': 'JetBlue Airways',
        'lufthansa-airlines': 'Lufthansa Airlines',
        'spirit-airlines': 'Spirit Airlines',
        'sun-country-airlines': 'Sun Country Airlines',
        'united-airlines': 'United Airlines'
        // Add more variations as needed
      };
    
      return nameVariations[name] || name; // If a variation is found, return the standardized name, otherwise return the original name
    };
    
    // Apply the standardization to create an array of standardized airline names from filteredImages
    const airlineNamesFromImages = filteredImages.map(imageName => standardizeAirlineName(imageNameToAirline[imageName]));
  
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    
    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      // Cleanup the event listener on unmount
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    useEffect(() => {
      const storeUtmData = () => {
        const queryParams = new URLSearchParams(window.location.search);
        // console.log("queryParams", queryParams)
        const utmData = {};
    
        // Iterate through query parameters and extract UTM parameters
        for (let [key, value] of queryParams.entries()) {
          if (key.startsWith("utm_")) {
            utmData[key] = value;
          }
        }
    
        if (Object.keys(utmData).length > 0) {
          const expirationTime = new Date().getTime() + 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
          const dataWithExpiration = {
            utmData,
            expiresAt: expirationTime,
          };
    
          // Store UTM data in localStorage
          localStorage.setItem("utm_data", JSON.stringify(dataWithExpiration));
          setUtmParams(utmData); // Update state
        }
      };
    
      // Execute on query parameter change
      storeUtmData();
    }, [location.search]);

  const [formValues, setFormValues] = useState({
    searchID: "", // Default value or dynamic value
    client: 2,
    segment: [
      {
        originAirport: "",
        destinationAirport: "",
        travelDate: "",
        originCountry: "US",
        destinationCountry: "ALL",
      },
      {
        originAirport: "",
        destinationAirport: "",
        travelDate: "",
      },
    ],
    searchDirectFlight: false,
    flexibleSearch: false,
    tripType: "2", // Default to round trip
    adults: 1,
    child: 0,
    infants: 0,
    infantsWs: 0,
    cabinType: "1",
    airline: "All",
    currencyCode: "USD",
    siteId: 16,
    source: "Online",
    media: "",
    sID: "",
    rID: "",
    locale: "en",
    isNearBy: false,
    limit: 200,
    userIP: "",
    serverIP: "",
    device: "",
    browser: "", // Define browserName if necessary
  });

  const handleDealClick = async (deal) => {
    const deviceName = isMobile
      ? "Mobile Device"
      : isDesktop
      ? "Desktop"
      : isAndroid
      ? "Android"
      : isIOS;
  
    try {
      // Fetch airport data from the API
      const searchValue = `${deal.froCity}`;
      const searchValue2 = `${deal.toCity}`;
      const airportResponse = await axios.get(
        `https://www.tgsapi.com/flights/CrossAirportList?data=${searchValue}&authcode=farehuts`
      );
      // console.log("FGBG", `https://www.tgsapi.com/flights/CrossAirportList?data=${searchValue}&authcode=farehuts`)
      const airport2Response = await axios.get(
        `https://www.tgsapi.com/flights/CrossAirportList?data=${searchValue2}&authcode=farehuts`
      );
  
      if (airportResponse?.data) {
        const airports = airportResponse.data; // Assuming the API returns an array
        const selectedOrigin = airports.find((item) => item.airportCode === deal.froCity);
  
        if (selectedOrigin) {
          localStorage.setItem("selectedOrigin", JSON.stringify(selectedOrigin));
        }
      }
      if (airport2Response?.data) {
        const airports = airport2Response.data; // Assuming the API returns an array
        const selectedDestination = airports.find((item) => item.airportCode === deal.toCity);
  
        if (selectedDestination) {
          localStorage.setItem("selectedDestination", JSON.stringify(selectedDestination));
        }
      }
  
      // Fetch user IP
      const response = await axios.get("https://api.ipify.org?format=json");
      const userIP = response.data.ip;
  
      const newSearchID = uuidv4().slice(0, 16);
      setSearchId(newSearchID);
  
      // Update form state based on the selected deal
      const updatedFormValues = {
        ...formValues,
        segment: [
          {
            originAirport: deal.froCity,
            destinationAirport: deal.toCity,
            travelDate: formatDate(deal.depDate),
            originCountry: "US",
            destinationCountry: "ALL",
          },
          {
            originAirport: deal.toCity,
            destinationAirport: deal.froCity,
            travelDate: formatDate(deal.retDate),
          },
        ],
        searchID: newSearchID,
        userIP: userIP,
        device: deviceName,
      };
  
      setFormValues(updatedFormValues);
      navigate("/listing", { state: { updatedFormValues } });
  
      // console.log("Selected deal:", updatedFormValues);
    } catch (error) {
      console.error("API fetch error:", error);
    }
  };

  const maxDeals = 5;
  const domesticDealsToShow = DomesticDeals?.slice(0, maxDeals);
  const intlDealsToShow = IntlDeals?.slice(0, maxDeals);
  
  // If International deals are empty and Domestic has more than 5, show extra in side section
  const extraDomesticDeals = IntlDeals.length === 0 && DomesticDeals.length > maxDeals
    ? DomesticDeals.slice(maxDeals, maxDeals * 2)
    : [];
  
  // If Domestic deals are empty and International has more than 5, show extra in side section
  const extraIntlDeals = DomesticDeals.length === 0 && IntlDeals.length > maxDeals
    ? IntlDeals.slice(maxDeals, maxDeals * 2)
    : [];

  return (
    <>
    <MetaTitle pageTitle={firstResponseData?.metaTitle} pageDescription={firstResponseData?.metaDesc} url={`www.rawfares.com/airline/${airlineName}`} />
        <Header />
        <SearchEngine headText={headText} imgSpecial={true} img={`https://imgfolders.com/rawfares/rawfaresUS/airline_detail/airlinecover_banner_plan/${firstResponseData?.pageValue}.webp`} />
        <div className="iur85ja">
        <section className='dtjrrdt wqerq45 mob-hide'>
        <div className="container">
          <Link to={`tel:+1-888-415-1536`}>
          <div className="row sfs34f align-items-center">
            <div className="col-lg-3">
              <p className='ghjge3'>Call us 24/7 at <span><Link to='tel:+1-888-415-1536'>+1-888-415-1536</Link></span></p>
              <p className='wri34d mb-0'>Need help booking? Our agents are ready! Choose from over 500 airlines.</p>
            </div>
            <div className="col-lg-9 ">
              <div className="row w-100 m-auto dftgdfty">
                <div className="col-lg-4">
                  <div className="d-flex align-items-start">
                  <img src={bking} alt="" />
                  <div className="fdtd3">
                    <p className='mb-0 e5t66'>EASY BOOKING</p>
                    <p className='mb-0 dtgr6r4'>We offer easy and convenient flight booking with attractive offers.</p>
                  </div>
                  </div>
                </div>
                <div className="col-lg-4 rftw4">
                  <div className="d-flex align-items-start">
                    <img src={transfr} alt="" />
                    <div className="fdtd3 ">
                      <p className='mb-0 e5t66'>LOWEST PRICE</p>
                      <p className='mb-0 dtgr6r4'>We ensure low rates on airlines reservation, holiday packages and on flight tickets.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="d-flex align-items-start">
                    <img src={Sport} alt="" />
                    <div className="fdtd3">
                      <p className='mb-0 e5t66'>24/7 SUPPORT</p>
                      <p className='mb-0 dtgr6r4'>Get assistance 24/7 on any kind of travel related query. We are happy to assist you.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </Link>
        </div>
      </section>
      {firstResponseData && firstResponseData.pageName && (
      <section className="py-5">
        <div className="container">
            <div className="row">
            <h3>{firstResponseData?.fromCity}</h3>
              <p>At Rawfares, we’re dedicated to bringing you the most incredible airfare deals that are sure to elevate your travel experience. Our exclusive offers are the best we’ve discovered, offering exceptional value for your next journey. Don’t wait—these deals won’t last long. Book now and embark on your next adventure!</p>
            {domesticDealsToShow.length > 0 && (
      <div className="col-lg-6 p-0">
        <h3 className="text-center mb-4">Top Domestic Flights</h3>
        <div className="row">
          {domesticDealsToShow.map((deal, index) => (
            <div className="col-lg-12" key={index} onClick={() => handleDealClick(deal)} style={{ cursor: "pointer" }}>
              <div className="hsgr4e">
                <div className="row">
                  <div className="col-4 col-lg-3">
                    <p className="mb-0 hr59w1">{deal.froCityName}</p>
                    <p className="mb-0 hr59w2">{deal.froCity}</p>
                    <p className="mb-0 hr59w3">{formatDate(deal.depDate)}</p>
                  </div>
                  <div className="col-4 col-lg-3">
                    <div className="hr59w6">
                      <img className="hr59w7" src={airoyh} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-lg-3">
                    <p className="mb-0 hr59w1">{deal.toCityName}</p>
                    <p className="mb-0 hr59w2">{deal.toCity}</p>
                    <p className="mb-0 hr59w3">{formatDate(deal.retDate)}</p>
                  </div>
                  <div className="col-lg-3 align-self-end hr59w8">
                    <p className="mb-0 hr59w4">USD {deal.totalPrice}</p>
                    <p className="mb-0 hr59w5">{deal.tripType === "2" ? 'Round Trip' : 'One Way'}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    )}

    {intlDealsToShow.length > 0 && (
      <div className="col-lg-6 p-0">
        <h3 className="text-center mb-4">Top International Flights</h3>
        <div className="row">
          {intlDealsToShow.map((deal, index) => (
            <div className="col-lg-12" key={index} onClick={() => handleDealClick(deal)} style={{ cursor: "pointer" }}>
              <div className="hsgr4e">
                <div className="row">
                  <div className="col-4 col-lg-3">
                    <p className="mb-0 hr59w1">{deal.froCityName}</p>
                    <p className="mb-0 hr59w2">{deal.froCity}</p>
                    <p className="mb-0 hr59w3">{formatDate(deal.depDate)}</p>
                  </div>
                  <div className="col-4 col-lg-3">
                    <div className="hr59w6">
                      <img className="hr59w7" src={airoyh} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-lg-3">
                    <p className="mb-0 hr59w1">{deal.toCityName}</p>
                    <p className="mb-0 hr59w2">{deal.toCity}</p>
                    <p className="mb-0 hr59w3">{formatDate(deal.retDate)}</p>
                  </div>
                  <div className="col-lg-3 align-self-end hr59w8">
                    <p className="mb-0 hr59w4">USD {deal.totalPrice}</p>
                    <p className="mb-0 hr59w5">{deal.tripType === "2" ? 'Round Trip' : 'One Way'}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    )}

    {/* Show extra Domestic deals if Intl is empty */}
    {extraDomesticDeals.length > 0 && (
      <div className="col-lg-6 p-0">
        <h3 className="text-center mb-4">More Domestic Flights</h3>
        <div className="row">
          {extraDomesticDeals.map((deal, index) => (
            <div className="col-lg-12" key={index} onClick={() => handleDealClick(deal)} style={{ cursor: "pointer" }}>
              <div className="hsgr4e">
                <div className="row">
                  <div className="col-4 col-lg-3">
                    <p className="mb-0 hr59w1">{deal.froCityName}</p>
                    <p className="mb-0 hr59w2">{deal.froCity}</p>
                    <p className="mb-0 hr59w3">{formatDate(deal.depDate)}</p>
                  </div>
                  <div className="col-4 col-lg-3">
                    <div className="hr59w6">
                      <img className="hr59w7" src={airoyh} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-lg-3">
                    <p className="mb-0 hr59w1">{deal.toCityName}</p>
                    <p className="mb-0 hr59w2">{deal.toCity}</p>
                    <p className="mb-0 hr59w3">{formatDate(deal.retDate)}</p>
                  </div>
                  <div className="col-lg-3 align-self-end hr59w8">
                    <p className="mb-0 hr59w4">USD {deal.totalPrice}</p>
                    <p className="mb-0 hr59w5">{deal.tripType === "2" ? 'Round Trip' : 'One Way'}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    )}

    {/* Show extra Intl deals if Domestic is empty */}
    {extraIntlDeals.length > 0 && (
      <div className="col-lg-6 p-0">
        <h3 className="text-center mb-4">More International Flights</h3>
        <div className="row">
          {extraIntlDeals.map((deal, index) => (
            <div className="col-lg-12" key={index} onClick={() => handleDealClick(deal)} style={{ cursor: "pointer" }}>
              <div className="hsgr4e">
                <div className="row">
                  <div className="col-4 col-lg-3">
                    <p className="mb-0 hr59w1">{deal.froCityName}</p>
                    <p className="mb-0 hr59w2">{deal.froCity}</p>
                    <p className="mb-0 hr59w3">{formatDate(deal.depDate)}</p>
                  </div>
                  <div className="col-4 col-lg-3">
                    <div className="hr59w6">
                      <img className="hr59w7" src={airoyh} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-lg-3">
                    <p className="mb-0 hr59w1">{deal.toCityName}</p>
                    <p className="mb-0 hr59w2">{deal.toCity}</p>
                    <p className="mb-0 hr59w3">{formatDate(deal.retDate)}</p>
                  </div>
                  <div className="col-lg-3 align-self-end hr59w8">
                    <p className="mb-0 hr59w4">USD {deal.totalPrice}</p>
                    <p className="mb-0 hr59w5">{deal.tripType === "2" ? 'Round Trip' : 'One Way'}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    )}
    {(DomesticDeals.length > 0 || IntlDeals.length > 0) && (
        <p className="abcdefg"><span className="text-primary">Note: All fares are quoted in USD.</span><br></br><br/>
    
    Last updated on <span className="text-primary">{getYesterdayDate()} at 5:00 AM,</span> the fares mentioned above are for Round Trip flight tickets and inclusive of fuel surcharge, <span className="text-primary"><Link to='/taxes-and-fees' target="_blank">service fee and taxes</Link></span>. Based on historical data, these fares are 
    subject to change without prior notice and cannot be guaranteed at the time of booking. Kindly go through out <span className="text-primary"><Link to='/terms-and-condition' target="_blank">terms and conditions</Link></span> before booking.</p>
    )}
                <div className="col-lg-12">
                    <h3>Flights to {firstResponseData?.pageName}({firstResponseData?.pageValue && firstResponseData.pageValue.toUpperCase()})</h3>
                   
                    <p className="mb-4" dangerouslySetInnerHTML={{
                      __html: firstResponseData?.contentData,
                    }}></p>
                </div>
               

               
            </div>
        </div>
      </section>
        )}
        </div>
        <Footer />
        </>
  )
}
