/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import airlinesName from "../Component/AirlineName/AirlineName";
import Header1 from "../Component/Header/Header1";
import lungage1 from "../assest/images/icon/luggage1.png";
import sarr3 from "../assest/images/icon/arow.png";
import lungage2 from "../assest/images/icon/luggage2.png";
import lungage3 from "../assest/images/icon/luggage3.png";
import dfge5 from "../assest/images/icon/dst4w.png";
import dgwe4 from "../assest/images/khte.png";
import d45rdt from "../assest/images/icon/contact.png";
import ds454 from "../assest/images/icon/web-check.png";
import vchj4t from "../assest/images/icon/tick.png";
import vr5645y from "../assest/images/icon/check-in.png";
import inf04 from "../assest/images/icon/info3.png";
import ine504 from "../assest/images/icon/info1.png";
import dor6y from "../assest/images/icon/downd.png";
import ine54 from "../assest/images/icon/info.png";
import y7687 from "../assest/images/icon/traveler.png";
import y7et87 from "../assest/images/icon/cancel.png";
import inf05 from "../assest/images/icon/info4.png";
import y7etr87 from "../assest/images/icon/cancellation.png";
import yu987 from "../assest/images/icon/payment.png";
import yp8ed from "../assest/images/icon/payment1.png";
import ydw3d from "../assest/images/icon/card.png";
import yw45d from "../assest/images/icon/minus.png";
import y75td from "../assest/images/icon/billing.png";
import dusyd from "../assest/images/icon/dustbin.png";
import chec5 from "../assest/images/icon/check.png";
import erh67 from "../assest/images/icon/error.png";
import carfg3 from "../assest/images/cardy1.png";
import carfg2 from "../assest/images/cardy2.png";
import yw55d from "../assest/images/icon/plus.png";
import Footer1 from "../Component/Footer/Footer1";
import { constant } from "../constant";
import countryList from "../Component/Controller/US_Controller";
import USState from "../Component/Controller/US_States";
import INState from "../Component/Controller/IN_States";
import CAState from "../Component/Controller/CA_States";
import AUState from "../Component/Controller/AU_States";
import MetaTitle from "../Component/MetaTitle/metaTitle";
import dustsw from "../assest/images/icon/dubit.png";
import apwhu7 from "../assest/images/icon/appro.png";
import card1 from "../assest/images/icon/card1.png";
import card2 from "../assest/images/icon/card2.png";
import card3 from "../assest/images/icon/card3.png";
import card4 from "../assest/images/icon/card4.png";

function formatDateAndTime(isoDate) {
  const dateOptions = {
    weekday: "short",
    month: "short",
    day: "numeric",
  };

  const timeOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const formattedDate = new Date(isoDate);
  const formattedDateString = new Intl.DateTimeFormat(
    "en-US",
    dateOptions
  ).format(formattedDate);
  const formattedTimeString = new Intl.DateTimeFormat(
    "en-US",
    timeOptions
  ).format(formattedDate);

  return { formattedDateString, formattedTimeString };
}

function convertMinutesToHoursAndMinutes(minutes) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours}h ${remainingMinutes}m`;
}

const maskCardNumber = (number) => {
  return number.replace(/.(?=.{4})/g, "*");
};

const maskCVV = (value) => {
  return value.replace(/\d/g, "*");
};

export default function Payment() {
  const location = useLocation();
  const { flight, formValue } = location.state?.dataTransfer || {};
  // console.log("flights", formValue);
  const [utmCampaign, setUtmCampaign] = useState("");
    const [displayNumber, setDisplayNumber] = useState("");
    const jsonMapping = {
        domestics_routes: "+1-833-609-8333",
        domestics_destination: "+1-833-609-7300",
        cruise : "+1-833-609-9933",
        airline: "+1-833-609-8444"
      };
      const defaultNumber = "+1-888-415-1536"; // Default number to display if UTM data is not found
  
      useEffect(() => {
        const updateDisplayNumber = () => {
          const utmData = JSON.parse(localStorage.getItem("utm_data")) || {};
        //   console.log(utmData)
          if (utmData?.utmData?.utm_campaign) {
            // console.log("in")
            setUtmCampaign(utmData?.utm_campaign);
    
            // Check if `utm_campaign` exists in the JSON mapping
            const mappedNumber = jsonMapping[utmData?.utmData?.utm_campaign];
            setDisplayNumber(mappedNumber || defaultNumber); // Use mapped number or default
          } else {
            // console.log("out")
            // No UTM data found, use the default number
            setDisplayNumber(defaultNumber);
          }
        };
    
        // Run initially
        updateDisplayNumber();
    
        // Set up interval to check every 10 seconds
        const interval = setInterval(updateDisplayNumber, 1000000000000);
    
        // Clean up interval on component unmount
        return () => clearInterval(interval);
      }, [jsonMapping]);
  const [airportName, setAirportName] = useState(flight.airports);
  const [contactInfoOpen, setContactInfoOpen] = useState(false);
  const [webCheckInOpen, setWebCheckInOpen] = useState(false);
  const [travelerOpen, setTravelerOpen] = useState(false);
  const [flightCancelOpen, setFlightCancelOpen] = useState(false);
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [billingInfoOpen, setBillingInfoOpen] = useState(false);
  const [isAncillary1Selected, setIsAncillary1Selected] = useState(false);
  const [isAncillary2Selected, setIsAncillary2Selected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOptionalRequestsVisible, setIsOptionalRequestsVisible] =
    useState({});
    const [initialdestination, setInitialdestination] = useState(localStorage.getItem("selectedDestination")
      ? JSON.parse(localStorage.getItem("selectedDestination"))
      : null
      );
      // console.log("initialdestination", initialdestination)

  const ancillary1Price = 29.99;
  const ancillary2Price = 10.99;
  const [totalAmount, setTotalAmount] = useState(flight.fare.grandOrgTotal);
  const [ancillaryProduct, setAncillaryProduct] = useState([]); // To store selected ancillary products
  const [selectedCountry, setSelectedCountry] = useState("");
  const [states, setStates] = useState([]);
  const [postalCodePattern, setPostalCodePattern] = useState("");
  const [cardType, setCardType] = useState("Unknown");
  const [cardNumber, setCardNumber] = useState("");
  // console.log("ancillary Product", ancillaryProduct);
  const totalTravelor =
    formValue.adults +
    formValue.child +
    // formValue.infants +
    formValue.infantsWs;
  // Function to toggle Ancillary 1
  const toggleAncillary1 = () => {
    setIsAncillary1Selected((prevSelected) => {
      const newSelected = !prevSelected;

      // Calculate total ancillary price based on the number of adults
      const totalAncillaryPrice = ancillary1Price * totalTravelor;

      // Update total amount based on selection
      setTotalAmount((prevTotal) =>
        newSelected
          ? prevTotal + totalAncillaryPrice
          : prevTotal - totalAncillaryPrice
      );

      // Update ancillary products list
      setAncillaryProduct((prev) => {
        if (newSelected) {
          // Check if Ancillary 1 is already in the list
          if (!prev.some((item) => item.id === 1)) {
            return [
              ...prev,
              {
                id: 1,
                name: "Cancellation Protection",
                price: totalAncillaryPrice,
              },
            ];
          }
          // console.log()
        } else {
          // Remove Ancillary 1 by ID
          return prev.filter((item) => item.id !== 1);
        }
        return prev;
      });

      return newSelected;
    });
  };

  const toggleOptionalRequests = (index) => {
    setIsOptionalRequestsVisible((prev) => ({
      ...prev,
      [index]: !prev[index], // Toggle visibility for the specific passenger
    }));
  };

  // Function to toggle Ancillary 2
  const toggleAncillary2 = () => {
    setIsAncillary2Selected((prevSelected) => {
      const newSelected = !prevSelected;

      const totalAncillaryPrice = ancillary2Price * totalTravelor;
      // Update total amount based on selection
      setTotalAmount((prevTotal) =>
        newSelected
          ? prevTotal + totalAncillaryPrice
          : prevTotal - totalAncillaryPrice
      );

      // Update ancillary products list
      setAncillaryProduct((prev) => {
        if (newSelected) {
          // Check if Ancillary 2 is already in the list
          if (!prev.some((item) => item.id === 2)) {
            return [
              ...prev,
              { id: 2, name: "Web Check-In", price: totalAncillaryPrice },
            ];
          }
        } else {
          // Remove Ancillary 2 by ID
          return prev.filter((item) => item.id !== 2);
        }
        return prev;
      });

      return newSelected;
    });
  };

  const calculateTotalTime = (segments) => {
    // if (segments.length === 0) return null;

    // Get the departure time of the first segment
    const firstDepartureTime = new Date(segments[0].depDate);
    // Get the arrival time of the last segment
    const lastArrivalTime = new Date(segments[segments.length - 1].reachDate);

    // Calculate the total duration in milliseconds
    const durationInMs = lastArrivalTime - firstDepartureTime;

    // Convert milliseconds to hours and minutes
    const totalHours = Math.floor(durationInMs / (1000 * 60 * 60));
    const totalMinutes = Math.floor(
      (durationInMs % (1000 * 60 * 60)) / (1000 * 60)
    );

    return (
      <span>
        {totalHours}h {totalMinutes}m
      </span>
    );
  };
  const months = [
    "Jan 01",
    "Feb 02",
    "Mar 03",
    "Apr 04",
    "May 05",
    "Jun 06",
    "Jul 07",
    "Aug 08",
    "Sep 09",
    "Oct 10",
    "Nov 11",
    "Dec 12",
  ];

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 10 }, (_, i) => currentYear + i);
  const yearsForDob = Array.from({ length: 100 }, (_, i) => currentYear - i);
  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const hasStates = (countryId) => {
    // Check if the selected country has states
    return states.length > 0; // Returns true if states exist
  };

  const isAllSameCharacters = (value) => {
    return /^(\w)\1+$/.test(value); // Checks if all characters are the same
  };

  const validationSchema = Yup.object().shape({
    emailID: Yup.string()
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, "Invalid email format")
      .required("Email is required"),
    phoneNo: Yup.string()
      .matches(/^\d{10}$/, "Phone number must be 10 digits")
      .required("Phone number is required"),
    paymentDetails: Yup.object().shape({
      cardNumber: Yup.string()
        .matches(/^\d{15,16}$/, "Card number must be 15 or 16 digits")
        .matches(/^[1-9]\d*$/, "Card number cannot start with zero")
        .required("Card number is required"),
        
  
        cardHolderName: Yup.string()
        .matches(/^[A-Za-z ]+$/, "Only alphabets are allowed")
        .required("Cardholder name is required")
        .test(
          "not-all-same",
          "Card Holder Name cannot have all characters the same",
          (value) => !isAllSameCharacters(value)
        ),
        expiryMonth: Yup.string()
        .required("Expiry month is required")
        .test("valid-month", "Invalid expiry date", function (value) {
          const year = this.parent.expiryYear;
          if (!year) return false; // Ensure year is provided
          const currentYear = new Date().getFullYear();
          const currentMonth = new Date().getMonth() + 1;
          return Number(year) > currentYear || (Number(year) === currentYear && Number(value) >= currentMonth);
        }),
        expiryYear: Yup.string()
        .required("Expiry year is required")
        .test("valid-year", "Expiry year cannot be in the past", function (value) {
          const currentYear = new Date().getFullYear();
          return Number(value) >= currentYear;
        }),
      cvvNo: Yup.string()
        .test("cvv-validation", "Invalid CVV format", function (value) {
          const cardNumber = this.resolve(Yup.ref("cardNumber"));
          if (cardNumber?.length === 16) {
            return /^\d{3}$/.test(value); // CVV must be 3 digits for 16-digit cards
          } else if (cardNumber?.length === 15) {
            return /^\d{4}$/.test(value); // CVV must be 4 digits for 15-digit cards
          }
          return false; // Fails validation if cardNumber is not 15 or 16 digits
        })
        .required("CVV is required"),

      address1: Yup.string().required("Address is required")
      .test(
        "not-all-same",
        "Address cannot have all characters the same",
        (value) => !isAllSameCharacters(value)
      )
      ,
      country: Yup.string().required("Country is required"),
      state: Yup.string().when("country", {
        is: (country) => hasStates(country), // Function to check if country has states
        then: (schema) => schema.required("State is required"),
        otherwise: (schema) => schema.notRequired(), // State not required if no states exist
      }),
      city: Yup.string().required("City name is required")
      .test(
        "not-all-same",
        "City cannot have all characters the same",
        (value) => !isAllSameCharacters(value)
      )
      ,
      postalCode: Yup.string()
        // .matches(/^\d{5}(-\d{4})?$/, 'Invalid postal code') // Validates 5 digits or 9 digits with hyphen
        .matches(/^\d+$/, "Postal code must only contain numbers")
        .required("Postal code is required"),
    }),
    passengerDetails: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string()
        .matches(/^[A-Za-z]+$/, "Only alphabets are allowed")
        .required("First name is required")
        .test(
          "not-all-same",
          "First name cannot have all characters the same",
          (value) => !isAllSameCharacters(value)
        ),
        lastName: Yup.string()
        .matches(/^[A-Za-z]+$/, "Only alphabets are allowed")
        .required("Last name is required")
        .test(
          "not-all-same",
          "Last name cannot have all characters the same",
          (value) => !isAllSameCharacters(value)
        ),
        dateOfBirth: Yup.date()
  .required("Date of birth is required")
  .test("is-valid-age", "Invalid age for passenger type", function (value) {
    const today = new Date();
    const birthDate = new Date(value);
    const age = today.getFullYear() - birthDate.getFullYear();
    
    if (birthDate > today) {
      return false; // Future date is invalid
    }

    if (this.parent.passengerType === 1 && age >= 12) {
      return true;
    }
    if (this.parent.passengerType === 2 && age >= 2 && age <= 12) {
      return true;
    }
    if (this.parent.passengerType === 3 && age <= 2) {
      return true;
    }
    return false;
  })
  .test("is-not-future", "Date of birth cannot be in the future", function (value) {
    return new Date(value) <= new Date();
  }),
  issueDate: Yup.date()
        .nullable()
        .typeError("Invalid date format (MM/DD/YYYY)")
        .max(new Date(), "Issue date cannot be in the future"),

        expiryDate: Yup.date()
        .nullable()
        .typeError("Invalid date format (MM/DD/YYYY)")
        .min(new Date(), "Expire date cannot be this"),

        // passportnumber: Yup.string().required("Passport Number is required"),
        // issueDate: Yup.string()
        //   .matches(
        //     /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/,
        //     "Invalid date format, must be MM/dd/yyyy"
        //   )
        //   .required("Issue Date is required"),
        // expiryDate: Yup.string()
        //   .matches(
        //     /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/,
        //     "Invalid date format, must be MM/dd/yyyy"
        //   )
        //   .required("Expiry Date is required"),
        // countryName: Yup.string().required("Country Name is required"),
      })
    ),
  });
  const initialValues = {
    emailID: "",
    phoneNo: "",
    mobileNo: "",
    paymentDetails: {
      cardNumber: "",
      maskCardNumber: "",
      cardHolderName: "",
      expiryMonth: "",
      expiryYear: "",
      cvvNo: "",
      address1: "",
      address2: "",
      city: "",
      country: "",
      state: "",
      postalCode: "",
      billingMobile: "",
      cardCode: cardType,
    },
    passengerDetails: [
      ...Array(formValue.adults || 0).fill({
        title: "",
        firstName: "",
        middleName: "",
        lastName: "",
        gender: "",
        dobMonth: "",
        dobDay: "",
        dobYear: "",
        dateOfBirth: "",
        contactName: "",
        contactNumber: "",
        tsaPrecheck: "",
        reDressNumber: "",
        splRequest: "",
        passengerType: 1,
        passportnumber: "",
        issueDate: "",
        expiryDate: "",
        countryName: "",
      }),
      ...Array(formValue.child || 0).fill({
        title: "",
        firstName: "",
        middleName: "",
        lastName: "",
        gender: "",
        dobMonth: "",
        dobDay: "",
        dobYear: "",
        dateOfBirth: "",
        contactName: "",
        contactNumber: "",
        tsaPrecheck: "",
        reDressNumber: "",
        splRequest: "",
        passengerType: 2,
        passportnumber: "",
        issueDate: "",
        expiryDate: "",
        countryName: "",
      }),
      ...Array(formValue.infants || 0).fill({
        title: "",
        firstName: "",
        middleName: "",
        lastName: "",
        gender: "",
        dobMonth: "",
        dobDay: "",
        dobYear: "",
        dateOfBirth: "",
        contactName: "",
        contactNumber: "",
        tsaPrecheck: "",
        reDressNumber: "",
        splRequest: "",
        passengerType: 3,
        passportnumber: "",
        issueDate: "",
        expiryDate: "",
        countryName: "",
      }),
      ...Array(formValue.infantsWs || 0).fill({
        title: "",
        firstName: "",
        middleName: "",
        lastName: "",
        gender: "",
        dobMonth: "",
        dobDay: "",
        dobYear: "",
        dateOfBirth: "",
        contactName: "",
        contactNumber: "",
        tsaPrecheck: "",
        reDressNumber: "",
        splRequest: "",
        passengerType: 4,
        passportnumber: "",
        issueDate: "",
        expiryDate: "",
        countryName: "",
      }),
    ],
    flightResult: flight,
    adults: formValue.adults,
    child: formValue.child,
    infants: formValue.infants,
    infantsWs: formValue.infantsWs,
    SearchID: formValue.searchID,
    bookingID: "0",
    siteID: "16",
    sourceMedia: 'Online',
    userIP: formValue.userIP,
    device: formValue.device,
    userSessionID: formValue.searchID,
    currencyCode: formValue.currencyCode,
    departDate: formValue.segment[0].travelDate,
    ancillaryProduct: [
      {
        productId: "",
        name: "",
        mostPopular: "",
        price: "",
        conditionList: [],
        selectionType: "",
        supplierNumber: "",
        supplierLookup: "",
        fullName: "",
      },
    ],
  };

  const setFormattedDateOfBirth = (passengerDetails) => {
    passengerDetails.forEach((passenger) => {
      passenger.dateOfBirth = `${passenger.dobYear}-${passenger.dobMonth}-${passenger.dobDay}`;
    });
  };

  const navigate = useNavigate();

  const handleSubmit = async (values, { setSubmitting }) => {
    setIsLoading(true);
    
    const updatedValues = {
      ...values,
      ancillaryProduct: ancillaryProduct, // Use latest selected ancillary products
    };
    // console.log("updatedValues", updatedValues)
    try {
      // console.log("Submitting values:", values.paymentDetails.cardCode);

      // Make API request
      const response = await axios.post(
        `${constant.baseUrl}/api/website/flight-confirm`,
        updatedValues,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const responseData = {
        confirmData: response.data,
        values: updatedValues,
      };
      navigate("/confirmation", { state: { responseData } });
      const secondApiResponse = await axios.post(
        `${constant.baseUrl}/api/website/mail`, // Replace with actual API
        responseData, // Pass data from first API
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      // console.log("22", secondApiResponse);
      

      // console.log("API Response:", response.data);

      // Handle success (e.g., show a success message)
      // alert("Payment submitted successfully!");
    } catch (error) {
      console.error("API Error:", error);
      alert("Failed to process payment. Please try again.");
      setIsLoading(false);
    } finally {
      setIsLoading(false);
      setSubmitting(false); // Reset form submitting state
    }
  };
  const openLink = (url) => {
		window.open(url, '_blank', 'width=650,height=550,left=300,top=50');
	  };
  const handleBack = () => {
    const updatedFormValues = formValue;
    // console.log("updatedFormValues payment", formValue);
    navigate("/listing", { state: { updatedFormValues } });
    // navigate(-1);
  };
  const EmailFieldWithValidation = () => {
    const { errors, touched } = useFormikContext();
    const hasError = errors.emailID && touched.emailID;
    const isTouched = touched.emailID && !errors.emailID;
    return (
      <div className={`form-floating mb-3`}>
        {" "}
        <Field
          type="email"
          className={`form-control ${hasError ? "set48f" : ""}`}
          id="floatingInput"
          name="emailID"
          placeholder="name@example.com"
          autoComplete="off"
        />{" "}
        <label htmlFor="floatingInput">Email address*</label>{" "}
        {/* <ErrorMessage name="emailID" component="div" />{" "} */}
        {!isTouched ? null : (
          <img className="uer42" src={chec5} alt="Checked" />
        )}{" "}
        {hasError && <img className="uer42" src={erh67} alt="Error" />}{" "}
        <ErrorMessage name={`emailID`} component="div" className="error" />
      </div>
    );
  };
  const PhoneFieldWithValidation = () => {
    const { values, errors, touched, setFieldValue } = useFormikContext();
    const hasError = errors.phoneNo && touched.phoneNo;
    const isTouched = touched.phoneNo && !errors.phoneNo;

    const handlePhoneChange = (event) => {
      const phoneValue = event.target.value;

      // Allow only numeric input and limit to 10 digits
      const numericValue = phoneValue.replace(/\D/g, ""); // Remove non-numeric characters
      const limitedValue = numericValue.slice(0, 10); // Limit to 10 digits

      setFieldValue("phoneNo", limitedValue); // Update phoneNo field
      setFieldValue("mobileNo", limitedValue);
      setFieldValue("paymentDetails.billingMobile", limitedValue); // Sync with billingMobile
    };

    return (
      <div className={`form-floating mb-3`}>
        <Field
          type="text"
          className={`form-control ${hasError ? "set48f" : ""}`}
          id="floatingInput"
          name="phoneNo"
          placeholder="Mobile Number"
          autoComplete="off"  // Disable autofill
  inputMode="numeric"
          value={values.phoneNo} // Bind the field value
          onChange={handlePhoneChange} // Listen to input changes
        />
        <label htmlFor="floatingInput">Mobile Number*</label>
        {!isTouched ? null : (
          <img className="uer42" src={chec5} alt="Checked" />
        )}
        {hasError && <img className="uer42" src={erh67} alt="Error" />}
        <ErrorMessage name={`phoneNo`} component="div" className="error" />
      </div>
    );
  };

  const totalTax = flight.fare.totalTax;
  let totalPrice = flight.fare.adultFare + totalTax;

  const totalPriceAdults =
    formValue.adults * flight.fare.adultFare +
    formValue.adults * flight.fare.adultTax;

  const totalPriceChild =
    formValue.child * flight.fare.childFare +
    formValue.child * flight.fare.childTax;

  const totalPriceInfant =
    formValue.infants * flight.fare.infantFare +
    formValue.infants * flight.fare.infantTax;

  const totalPriceInfantWs =
    formValue.infantsWs * flight.fare.infantWsFare +
    formValue.infantsWs * flight.fare.infantWsTax;

  useEffect(() => {
    if (!cardNumber || cardNumber.length < 4) {
      setCardType(null); // Reset if too short
      return;
    }
    // Determine the card type based on the first digit
    if (/^4/.test(cardNumber)) {
      setCardType("Visa");
    } else if (/^5[1-5]/.test(cardNumber)) {
      setCardType("MasterCard");
    } else if (/^3[47]/.test(cardNumber)) {
      setCardType("AmericanExpress"); // American Express cards start with 34 or 37
    } else if (/^6(?:011|5[0-9]{2})/.test(cardNumber)) {
      setCardType("Discover"); // Discover cards start with 6011 or 65
    } else {
      setCardType("Unknown");
    }
  }, [cardNumber]);

  const roundedPrice = totalPrice.toFixed(2);

  // console.log("initialValues", initialValues);
  return (
    <>
      <MetaTitle url={`${constant.baseUrl}/payment/`} />
      {isLoading ? (
        <section className="atfe">
          <div class="spinner12 center">
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
          </div>
        </section>
      ) : null}
      <div className={`${isLoading ? "jgur67" : ""}`}>
        <Header1 />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            isSubmitting,
            values,
            errors,
            touched,
            handleChange,
            setFieldValue,
          }) => {
            const [isMasked, setIsMasked] = useState(false);
            const [isCvvMasked, setIsCvvMasked] = useState(false);
            useEffect(() => {
              // Log Formik validation errors whenever they change
              if (Object.keys(errors).length > 0) {
                // console.log("Validation Errors:", errors);
              }
            }, [errors]);
            // console.log("Formik values:", values);
            // console.log("Formik isSubmitting:", isSubmitting);
            // console.log("Formik errors:", errors);
            // console.log("Formik touched:", touched);

            useEffect(() => {
              if (Array.isArray(values.passengerDetails)) {
                values.passengerDetails.forEach((passenger, index) => {
                  const dateOfBirth = `${passenger.dobYear}-${passenger.dobMonth}-${passenger.dobDay}`;
                  setFieldValue(
                    `passengerDetails[${index}].dateOfBirth`,
                    dateOfBirth
                  );
                });
              }
            }, [values.passengerDetails, setFieldValue]);

            const handleBlur = () => {
              const maskedValue = maskCardNumber(
                values.paymentDetails.cardNumber
              );

              // Save both unmasked and masked values in Formik state
              setFieldValue("paymentDetails.maskCardNumber", maskedValue);
              setFieldValue(
                "paymentDetails.cardNumber",
                values.paymentDetails.cardNumber
              );
              setCardNumber(values.paymentDetails.cardNumber);
              // Show masked value in UI
              setIsMasked(true);
            };
            useEffect(() => {
              // Synchronize the card type with initialValues.paymentDetails.cardCode

              setFieldValue("paymentDetails.cardCode", cardType);
            }, [cardType]);

            // const handleCvvBlur = () => {
            //   // Mask CVV as '***'
            //   setFieldValue("paymentDetails.maskCvv", "***");
            //   setFieldValue(
            //     "paymentDetails.cvvNo",
            //     values.paymentDetails.cvvNo
            //   );

            //   // Show masked value in UI
            //   setIsCvvMasked(true);
            // };
            const handleCvvBlur = () => {
              const cvvValue = values.paymentDetails.cvvNo; // Get the entered CVV value
              if (cvvValue.length > 0) { // Only mask if there is a value
                const mask = cvvValue.length === 3 ? "***" : "****"; // Set mask based on length
                setFieldValue("paymentDetails.maskCvv", mask);
                setFieldValue("paymentDetails.cvvNo", cvvValue);
                setIsCvvMasked(true);
              }
            };
            
            const handleCardNumberChange = (event) => {
              let cardNumber = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
              cardNumber = cardNumber.slice(0, 16); // Limit to 16 digits

              setFieldValue("paymentDetails.cardNumber", cardNumber); // Update card number

              // Reset CVV if card number length is 15 (American Express)
              if (cardNumber.length === 15) {
                setFieldValue("paymentDetails.cvvNo", ""); // Reset CVV if card is 15 digits
              }
              if (cardNumber.startsWith("0")) {
                setFieldValue("paymentDetails.cardType", null);
              }
            };

            const handleCvvChange = (event) => {
              const cvvValue = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
              const cvvLength =
                values.paymentDetails.cardNumber.length === 15 ? 4 : 3; // CVV length based on card type
              const limitedCvvValue = cvvValue.slice(0, cvvLength); // Limit to 4 digits for 15-digit cards, 3 digits for 16-digit cards

              setFieldValue("paymentDetails.cvvNo", limitedCvvValue); // Update CVV
            };

            // const handleCvvChange = (event) => {
            //   const cvvValue = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
            //   const cvvLength =
            //     values.paymentDetails.cardNumber.length === 15 ? 4 : 3; // CVV length based on card type
            //   const limitedCvvValue = cvvValue.slice(0, cvvLength); // Limit to 4 digits for 15-digit cards, 3 digits for 16-digit cards

            //   setFieldValue("paymentDetails.cvvNo", limitedCvvValue); // Update CVV
            // };

            const postalCodePatterns = {
              US: /^\d{5}(-\d{4})?$/, // US postal code (5 or 9 digits)
              IN: /[^0-9]/g, // India postal code (6 digits)
              CA: /[A-Z]{1,2}\d{1,2}[A-Z]{0,1} ?\d[A-Z]{2}/gi, // Canada postal code (A1A 1A1)
              AU: /[^0-9]/g, // Australia postal code (4 digits)
              UK: /[^0-9]/g, // UK postal code (e.g., A1 1AA or AA1 1AA)
            };
            const handleCountryChange = (event) => {
              const countryId = event.target.value;
              const { value } = event.target;
              setSelectedCountry(countryId);
              setFieldValue("paymentDetails.country", value);
            };

            // Set states based on selected country
            useEffect(() => {
              switch (selectedCountry) {
                case "US":
                  setStates(USState);
                  setPostalCodePattern(postalCodePatterns.US);
                  break;
                case "IN":
                  setStates(INState);
                  setPostalCodePattern(postalCodePatterns.IN);
                  break;
                case "CA":
                  setStates(CAState);
                  setPostalCodePattern(postalCodePatterns.CA);
                  break;
                case "AU":
                  setStates(AUState);
                  setPostalCodePattern(postalCodePatterns.AU);
                  break;
                default:
                  setStates([]);
                  setPostalCodePattern("");
                  break;
              }
            }, [selectedCountry]);

            const formatDate = (value) => {
              // Remove non-numeric characters (if any)
              value = value.replace(/\D/g, "");

              // Ensure that the input value has a maximum length of 8 characters (MMDDYYYY)
              if (value.length > 8) {
                value = value.substring(0, 8);
              }

              // Format the value to MM/DD/YYYY
              if (value.length >= 5) {
                value = value.replace(/^(\d{2})(\d{2})(\d{4})$/, "$1/$2/$3");
              } else if (value.length >= 3) {
                value = value.replace(/^(\d{2})(\d{2})$/, "$1/$2");
              }

              return value;
            };

            const handleInputChange = (e) => {
              const formattedValue = formatDate(e.target.value);
              e.target.value = formattedValue;

              // Call Formik's handleChange
              handleChange(e);
            };
            return (
              <Form>
                <section className="iure4">
                  <div className="container p-0">
                    <div className="row">
                      <div className="col-lg-8">
                        <section>
                          <div className="container p-0">
                            <div className="row">
                              <div className="col-lg-12">
                                {/* <div className="bsckhr">
                                  <p
                                    className="hriut8"
                                    onClick={handleBack}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {" "}
                                    <img src={sarr3} alt="" /> Back
                                  </p>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </section>
                        <div className="jda34 et643">
                          {/* {flight.outBound.map((flight, index) => ( */}
                          <div className="jtest24">
                            <div className="row align-items-center pt-3 px-2 pb-2">
                              <div className="col-2 col-lg-6">
                                <p className="mb-0 dstge3">Depart</p>
                              </div>
                              <div className="col-10 col-lg-6 text-end">
                                <p className="mb-0 dt54s">
                                  Total Trip time:{" "}
                                  <span>
                                    {convertMinutesToHoursAndMinutes(
                                      flight.outBound[0].estimateTime
                                    )}
                                  </span>
                                </p>
                              </div>
                            </div>
                            {flight.outBound.map((flights, index) => (
                              <div>
                                <div className="row px-2">
                                  <div className="col-8 col-lg-6">
                                    <div className="d-flex align-items-center">
                                      <img
                                        className="hftrr"
                                        src={`https://imgfolders.com/farehutz/${flights.airlineName}.png`}
                                        alt={flights.airlineName}
                                      />
                                      <div className="iksr">
                                        <p className="ikrtr">
                                          {airlinesName[flights.airlineName]}
                                        </p>
                                        <p className="mb-0 dst342">
                                          {flights.airlineName}{" "}
                                          {flights.flightNo} | Aircraft{" "}
                                          <span>
                                            {flights.equipmentType}{" "}
                                            {/* <p className="dsfed4">
                                              Embraer EMB 175 94-106 STD Seats
                                            </p> */}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-4 col-lg-6 text-end">
                                    <p className="mb-0 dt54s">
                                      {" "}
                                      <span>
                                        {flights.cabinClass === 1
                                          ? "Economy"
                                          : flights.cabinClass === 2
                                          ? "Premium Economy"
                                          : flights.cabinClass === 3
                                          ? "Business"
                                          : flights.cabinClass === 4
                                          ? "First"
                                          : ""}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div className="row px-2">
                                  <div className="col-lg-12">
                                    <div className="jjeisr4">
                                      <span>Operated by :</span>{" "}
                                      <span className="ioheri">
                                        {airlinesName[flights.opratingAirline]}
                                      </span>
                                    </div>
                                    <p className="jioshr mb-0">
                                      Flight Duration:{" "}
                                      {convertMinutesToHoursAndMinutes(
                                        flights.eft
                                      )}{" "}
                                      <span></span>
                                    </p>

                                    <div className="row align-items-center">
                                      <div className="col-2 col-lg-1">
                                        <div className="dtges"></div>
                                        <div className="destde"></div>
                                        <div className="iehte3"></div>
                                      </div>
                                      <div className="col-10 col-lg-11">
                                        <div className="row my-3">
                                          <div className="col-lg-2">
                                            <p className="edjhire">
                                              {new Date(
                                                flights.depDate
                                              ).toLocaleTimeString([], {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                hour12: true,
                                              })}
                                            </p>
                                            <p className="kijdte mb-0">
                                              {
                                                formatDateAndTime(
                                                  flights.depDate
                                                ).formattedDateString
                                              }
                                            </p>
                                          </div>
                                          <div className="col-lg-10">
                                            <p className="edjhire">
                                              {flights.fromAirport &&
                                                airportName.find(
                                                  (a) =>
                                                    a.airportCode ===
                                                    flights.fromAirport
                                                )?.airportName}{" "}
                                              Airport,
                                            </p>
                                            <p className="kijdte mb-0">
                                              {flights.fromAirport},{" "}
                                              {flights.fromAirport &&
                                                airportName.find(
                                                  (a) =>
                                                    a.airportCode ===
                                                    flights.fromAirport
                                                )?.countryName}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="row my-3 gap-2 bagdis">
                                          <div className="det4">
                                            <img
                                              className="ihtg45"
                                              src={lungage1}
                                              alt=""
                                            />
                                            <p className="mb-0">
                                              Personal Item (45 x 35 x 20 cm)
                                            </p>
                                          </div>
                                          <div className="det4 edtt">
                                            <img
                                              className="ihtg45"
                                              src={lungage2}
                                              alt=""
                                            />
                                            <p className="mb-0">
                                              No Carry on bag
                                            </p>
                                            <img
                                              className="ms-3"
                                              src={dfge5}
                                              alt=""
                                            />
                                          </div>
                                          <div className="det4 edtt">
                                            <img
                                              className="ihtg45"
                                              src={lungage3}
                                              alt=""
                                            />
                                            <p className="mb-0">
                                              No checked bag
                                            </p>
                                            <img
                                              className="ms-3"
                                              src={dfge5}
                                              alt=""
                                            />
                                          </div>
                                        </div>
                                        <div className="row my-3">
                                          <div className="col-lg-2">
                                            <p className="edjhire">
                                              {new Date(
                                                flights.reachDate
                                              ).toLocaleTimeString([], {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                hour12: true,
                                              })}
                                            </p>
                                            <p className="kijdte mb-0">
                                              {
                                                formatDateAndTime(
                                                  flights.reachDate
                                                ).formattedDateString
                                              }
                                            </p>
                                          </div>
                                          <div className="col-lg-10 rela">
                                            <p className="edjhire dseaf rela">
                                              {flights.toAirport &&
                                                airportName.find(
                                                  (a) =>
                                                    a.airportCode ===
                                                    flights.toAirport
                                                )?.airportName}{" "}
                                              Airport,
                                              <p className="mb-0 jhrt5">
                                                {(() => {
                                                  const depDate = new Date(
                                                    flights.depDate
                                                  );
                                                  const reachDate = new Date(
                                                    flights.reachDate
                                                  );

                                                  // Normalize the dates to midnight to accurately compare days
                                                  const depDateMidnight =
                                                    new Date(
                                                      depDate.getFullYear(),
                                                      depDate.getMonth(),
                                                      depDate.getDate()
                                                    );
                                                  const reachDateMidnight =
                                                    new Date(
                                                      reachDate.getFullYear(),
                                                      reachDate.getMonth(),
                                                      reachDate.getDate()
                                                    );

                                                  const timeDifference =
                                                    reachDateMidnight -
                                                    depDateMidnight; // Time difference in milliseconds
                                                  const dayDifference =
                                                    timeDifference /
                                                    (1000 * 60 * 60 * 24); // Convert to days

                                                  return dayDifference > 0
                                                    ? `+${dayDifference} Day${
                                                        dayDifference > 1
                                                          ? "s"
                                                          : ""
                                                      }`
                                                    : "";
                                                })()}
                                              </p>
                                            </p>
                                            <p className="kijdte mb-0">
                                              {flights.toAirport},{" "}
                                              {flights.toAirport &&
                                                airportName.find(
                                                  (a) =>
                                                    a.airportCode ===
                                                    flights.toAirport
                                                )?.countryName}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {flights.layOverTime > 0 && (
                                  <div className="row align-items-center et4dsf">
                                    <div className="col-1">
                                      <div className="jgdr">
                                        <img src={apwhu7} alt="" />
                                      </div>
                                    </div>
                                    <div className="col-11">
                                      <p className="mb-0 dser34">
                                        Layover:{" "}
                                        {convertMinutesToHoursAndMinutes(
                                          flights.layOverTime
                                        )}{" "}
                                        <span>
                                          {flights.toAirport &&
                                            airportName.find(
                                              (a) =>
                                                a.airportCode ===
                                                flights.toAirport
                                            )?.airportName}{" "}
                                          ({flights?.toAirport})
                                        </span>
                                        {flights?.toAirport !==
                                          flight?.outBound[index + 1]
                                            ?.fromAirport && (
                                          <span className="hawurh">
                                            Change of Airport Required
                                          </span>
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                          {/* ))} */}
                          <hr className="jhed24" />
                          <div className="jtest24 pb-3">
                            {flight?.inBound?.length > 0 && (
                              <div className="row align-items-center px-2 pb-2">
                                <div className="col-2 col-lg-6">
                                  <p className="mb-0 dstge3">Return</p>
                                </div>
                                <div className="col-10 col-lg-6 text-end">
                                  <p className="mb-0 dt54s">
                                    Total Trip time:{" "}
                                    <span>
                                      {convertMinutesToHoursAndMinutes(
                                        flight.inBound[0].estimateTime
                                      )}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            )}

                            {flight?.inBound?.map((flights, index) => (
                              <div key={index}>
                                <div className="row px-2">
                                  <div className="col-8 col-lg-6">
                                    <div className="d-flex align-items-center">
                                      <img
                                        className="hftrr"
                                        src={`https://imgfolders.com/farehutz/${flights.airlineName}.png`}
                                        alt=""
                                      />
                                      <div className="iksr">
                                        <p className="ikrtr">
                                          {airlinesName[flights.airlineName]}
                                        </p>
                                        <p className="mb-0 dst342">
                                          {flights.airlineName}{" "}
                                          {flights.flightNo} | Aircraft{" "}
                                          <span>
                                            {flights.equipmentType}{" "}
                                            {/* <p className="dsfed4">
                                              Embraer EMB 175 94-106 STD Seats
                                            </p> */}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-4 col-lg-6 text-end">
                                    <p className="mb-0 dt54s">
                                      {" "}
                                      <span>
                                        {flights.cabinClass === 1
                                          ? "Economy"
                                          : flights.cabinClass === 2
                                          ? "Premium Economy"
                                          : flights.cabinClass === 3
                                          ? "Business"
                                          : flights.cabinClass === 4
                                          ? "First"
                                          : ""}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div className="row px-2">
                                  <div className="col-lg-12">
                                    <div className="jjeisr4">
                                      <span>Operated by :</span>{" "}
                                      <span className="ioheri">
                                        {airlinesName[flights.opratingAirline]}
                                      </span>
                                    </div>
                                    <p className="jioshr mb-0">
                                      Flight Duration:{" "}
                                      {convertMinutesToHoursAndMinutes(
                                        flights.eft
                                      )}{" "}
                                      <span></span>
                                    </p>

                                    <div className="row align-items-center">
                                      <div className="col-2 col-lg-1">
                                        <div className="dtges"></div>
                                        <div className="destde"></div>
                                        <div className="iehte3"></div>
                                      </div>
                                      <div className="col-10 col-lg-11">
                                        <div className="row my-3">
                                          <div className="col-lg-2">
                                            <p className="edjhire">
                                              {new Date(
                                                flights.depDate
                                              ).toLocaleTimeString([], {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                hour12: true,
                                              })}
                                            </p>
                                            <p className="kijdte mb-0">
                                              {
                                                formatDateAndTime(
                                                  flights.depDate
                                                ).formattedDateString
                                              }
                                            </p>
                                          </div>
                                          <div className="col-lg-10">
                                            <p className="edjhire">
                                              {flights.fromAirport &&
                                                airportName.find(
                                                  (a) =>
                                                    a.airportCode ===
                                                    flights.fromAirport
                                                )?.airportName}{" "}
                                              Airport,
                                            </p>
                                            <p className="kijdte mb-0">
                                              {flights.fromAirport},{" "}
                                              {flights.fromAirport &&
                                                airportName.find(
                                                  (a) =>
                                                    a.airportCode ===
                                                    flights.fromAirport
                                                )?.countryName}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="row my-3 gap-2 bagdis">
                                          <div className="det4">
                                            <img
                                              className="ihtg45"
                                              src={lungage1}
                                              alt=""
                                            />
                                            <p className="mb-0">
                                              Personal Item (45 x 35 x 20 cm)
                                            </p>
                                          </div>
                                          <div className="det4 edtt">
                                            <img
                                              className="ihtg45"
                                              src={lungage2}
                                              alt=""
                                            />
                                            <p className="mb-0">
                                              No Carry on bag
                                            </p>
                                            <img
                                              className="ms-3"
                                              src={dfge5}
                                              alt=""
                                            />
                                          </div>
                                          <div className="det4 edtt">
                                            <img
                                              className="ihtg45"
                                              src={lungage3}
                                              alt=""
                                            />
                                            <p className="mb-0">
                                              No checked bag
                                            </p>
                                            <img
                                              className="ms-3"
                                              src={dfge5}
                                              alt=""
                                            />
                                          </div>
                                        </div>
                                        <div className="row my-3">
                                          <div className="col-lg-2">
                                            <p className="edjhire">
                                              {new Date(
                                                flights.reachDate
                                              ).toLocaleTimeString([], {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                hour12: true,
                                              })}
                                            </p>
                                            <p className="kijdte mb-0">
                                              {
                                                formatDateAndTime(
                                                  flights.reachDate
                                                ).formattedDateString
                                              }
                                            </p>
                                          </div>
                                          <div className="col-lg-10 mb-0 rela">
                                            <p className="edjhire dseaf rela">
                                              {flights.toAirport &&
                                                airportName.find(
                                                  (a) =>
                                                    a.airportCode ===
                                                    flights.toAirport
                                                )?.airportName}{" "}
                                              Airport,
                                              <p className="mb-0 jhrt5">
                                                {(() => {
                                                  const depDate = new Date(
                                                    flights.depDate
                                                  );
                                                  const reachDate = new Date(
                                                    flights.reachDate
                                                  );

                                                  // Normalize the dates to midnight to accurately compare days
                                                  const depDateMidnight =
                                                    new Date(
                                                      depDate.getFullYear(),
                                                      depDate.getMonth(),
                                                      depDate.getDate()
                                                    );
                                                  const reachDateMidnight =
                                                    new Date(
                                                      reachDate.getFullYear(),
                                                      reachDate.getMonth(),
                                                      reachDate.getDate()
                                                    );

                                                  const timeDifference =
                                                    reachDateMidnight -
                                                    depDateMidnight; // Time difference in milliseconds
                                                  const dayDifference =
                                                    timeDifference /
                                                    (1000 * 60 * 60 * 24); // Convert to days

                                                  return dayDifference > 0
                                                    ? `+${dayDifference} Day${
                                                        dayDifference > 1
                                                          ? "s"
                                                          : ""
                                                      }`
                                                    : "";
                                                })()}
                                              </p>
                                            </p>
                                            <p className="kijdte mb-0">
                                              {flights.toAirport},{" "}
                                              {flights.toAirport &&
                                                airportName.find(
                                                  (a) =>
                                                    a.airportCode ===
                                                    flights.toAirport
                                                )?.countryName}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {flights.layOverTime > 0 && (
                                  <div className="row align-items-center et4dsf">
                                    <div className="col-1">
                                      <div className="jgdr">
                                        <img src={apwhu7} alt="" />
                                      </div>
                                    </div>
                                    <div className="col-11">
                                      <p className="mb-0 dser34">
                                        Layover:{" "}
                                        {convertMinutesToHoursAndMinutes(
                                          flights.layOverTime
                                        )}{" "}
                                        <span>
                                          {flights.toAirport &&
                                            airportName.find(
                                              (a) =>
                                                a.airportCode ===
                                                flights.toAirport
                                            )?.airportName}{" "}
                                          ({flights.toAirport})
                                        </span>
                                        {flights?.toAirport !==
                                          flight?.inBound[index + 1]
                                            ?.fromAirport && (
                                          <span className="hawurh">
                                            Change of Airport Required
                                          </span>
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                        <div
                          className={`hgdr679 ${
                            contactInfoOpen ? "u4rtwr" : ""
                          }`}
                        >
                          <div className="dsctgj4 d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              <div className="uiety4">
                                <img src={d45rdt} alt="" />{" "}
                              </div>
                              <p className="mb-0 jsryt7"> Contact Details</p>
                            </div>
                            <img
                              className="yr8t5"
                              src={dor6y}
                              alt=""
                              onClick={() =>
                                setContactInfoOpen(!contactInfoOpen)
                              }
                            />
                          </div>
                          <div className="row mt-4 rwt64">
                            <div className="col-lg-6">
                              {/* <div class="form-floating mb-3"> */}
                              {/* <Field
                            type="email"
                            class="form-control"
                            id="floatingInput"
                            name="emailID"
                            placeholder="name@example.com"
                          />
                          <label for="floatingInput">Email address*</label>
                          <img className="uer42" src={chec5} alt="" /> */}
                              <EmailFieldWithValidation />
                              {/* </div> */}
                            </div>
                            <div className="col-lg-6">
                              {/* <div class="form-floating mb-3">
                            <Field
                              type="text"
                              name="phoneNo"
                              class="form-control set48f"
                              id="floatingInput"
                              placeholder="name@example.com"
                            />
                            <label for="floatingInput">Mobile Number*</label>
                            <img className="uer42" src={erh67} alt="" />
                          </div> */}
                              <PhoneFieldWithValidation />
                            </div>
                          </div>
                        </div>
                        <div
                          className={`hgdr679 ${
                            webCheckInOpen ? "u4rtwr" : ""
                          }`}
                        >
                          <div className="dsctgj4 d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              <div className="uiety4">
                                <img src={ds454} alt="" />
                              </div>
                              <p className="mb-0 jsryt7">Web Check-In</p>
                            </div>
                            <img
                              className="yr8t5"
                              src={dor6y}
                              alt=""
                              onClick={() => setWebCheckInOpen(!webCheckInOpen)}
                            />
                          </div>
                          <div className="row mt-4 rwt64">
                            <div className="col-lg-7">
                              <p className="i665r">
                                Save time and rest easy with Web Check-in
                              </p>
                              <p className="dki97">
                                {" "}
                                <img
                                  className="me-1"
                                  src={vchj4t}
                                  alt=""
                                />{" "}
                                We'll check you in, so you don't have to
                                remember
                              </p>
                              <p className="dki97">
                                {" "}
                                <img
                                  className="me-1"
                                  src={vchj4t}
                                  alt=""
                                />{" "}
                                Skip the counter/kiosk and head to the gate
                              </p>
                              <p className="dki97">
                                {" "}
                                <img className="me-1" src={vchj4t} alt="" /> Get
                                an assigned seat before reaching the airport*
                              </p>
                              <p className="dki97">
                                {" "}
                                <img
                                  className="me-1"
                                  src={vchj4t}
                                  alt=""
                                />{" "}
                                Avoid airlines fees for printing a boarding pass
                              </p>
                            </div>
                            <div className="col-lg-5 text-lg-end text-center pt-lg-0 pt-3 jhrfy">
                              <img src={vr5645y} alt="" />
                              <p className="dgeh45">
                                USD {ancillary2Price.toFixed(2).split(".")[0]}
                                <sup>
                                  .{ancillary2Price.toFixed(2).split(".")[1]}
                                </sup>
                              </p>
                              <p className="jge57d">Each way Per Passenger</p>
                              <div type="button" onClick={toggleAncillary2}>
                                {" "}
                                {isAncillary2Selected ? (
                                  <div className="uje57 e38ie3">
                                    Remove{" "}
                                    <img className="ms-2" src={dustsw} alt="" />
                                  </div>
                                ) : (
                                  <div className="uje57">Add To Booking</div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="fdy45">
                            <p className="mb-0 ikdg5">
                              <img src={inf04} alt="" />
                              Note :
                            </p>
                            <p className="mb-0">
                              <span className="mdui56">
                                Save time. Enjoy a more relaxed trip.
                              </span>{" "}
                              Boarding pass will be emailed. Print them and take
                              with you. For seats and Bags related questions you
                              can call us at{" "}
                              <Link to={`tel:${displayNumber}`}>
                                {displayNumber}
                              </Link>
                              .
                            </p>
                          </div>
                        </div>
                        <div
                          className={`hgdr679 ${travelerOpen ? "u4rtwr" : ""}`}
                        >
                          <div className="dsctgj4 d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              <div className="uiety4">
                                {" "}
                                <img src={y7687} alt="" />{" "}
                              </div>{" "}
                              <p className="mb-0 jsryt7">Traveler's Details</p>
                            </div>
                            <img
                              className="yr8t5"
                              src={dor6y}
                              alt=""
                              onClick={() => setTravelerOpen(!travelerOpen)}
                            />
                          </div>
                          <div className="rwt64">
                            {values.passengerDetails.map((passenger, index) => {
                              const currentYear = new Date().getFullYear();
                              let minYear, maxYear;

                              if (passenger.passengerType === 1) {
                                minYear = currentYear - 100; // Max age: 100 years old
                                maxYear = currentYear - 12; // Min age: 18 years old
                              } else if (passenger.passengerType === 2) {
                                minYear = currentYear - 12;
                                maxYear = currentYear - 2;
                              } else if (passenger.passengerType === 3) {
                                minYear = currentYear - 2;
                                maxYear = currentYear;
                              }

                              const filteredYears = yearsForDob.filter(
                                (year) => year >= minYear && year <= maxYear
                              );
                              return (
                                <div key={index}>
                                  <div className="row mt-4">
                                    <div className="col-lg-3 p-0">
                                      <div className="row align-items-center">
                                        <div className="col-lg-5 pe-0">
                                          <p className="mb-lg-0 hg78i">
                                            {passenger.passengerType === 1
                                              ? "Adult"
                                              : passenger.passengerType === 2
                                              ? "Child"
                                              : passenger.passengerType === 3
                                              ? "Infant"
                                              : passenger.passengerType === 4
                                              ? "InfantWs"
                                              : ""}{" "}
                                            {index + 1}
                                          </p>
                                        </div>
                                        <div className="col-lg-7 px-lg-1">
                                          <Field
                                            as="select"
                                            class="form-select awrzr6"
                                            name={`passengerDetails[${index}].title`}
                                            onChange={(e) => {
                                              const selectedTitle =
                                                e.target.value;
                                              setFieldValue(
                                                `passengerDetails[${index}].title`,
                                                selectedTitle
                                              );

                                              // Automatically set gender based on title but allow user to change it
                                              if (selectedTitle === "Mr." || selectedTitle === "Mstr.") {
                                                setFieldValue(
                                                  `passengerDetails[${index}].gender`,
                                                  "1"
                                                );
                                              } else if (
                                                selectedTitle === "Ms." ||
                                                selectedTitle === "Mrs." ||
                                                selectedTitle === "Miss"
                                              ) {
                                                setFieldValue(
                                                  `passengerDetails[${index}].gender`,
                                                  "2"
                                                );
                                              }
                                            }}
                                            aria-label="Default select example"
                                          >
                                            <option selected>Select</option>
                                            <option value="Mr.">Mr.</option>
                                            <option value="Mrs.">Mrs.</option>
                                            <option value="Mstr.">Mstr.</option>
                                            <option value="Miss">Miss</option>
                                          </Field>
                                          <ErrorMessage
                                            name={`passengerDetails[${index}].title`}
                                            component="div"
                                            className="error"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-9 p-0 dry5e">
                                      <div className="row">
                                        <div className="col-lg-4">
                                          <div class="form-floating mb-3">
                                            <Field
                                              type="text"
                                              name={`passengerDetails[${index}].firstName`}
                                              class="form-control"
                                              id="floatingInput"
                                              placeholder="First name*"
                                              autoComplete="off"
                                            />
                                            <label for="floatingInput">
                                              First name*
                                            </label>
                                            <ErrorMessage
                                              name={`passengerDetails[${index}].firstName`}
                                              component="div"
                                              className="error"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-lg-4">
                                          <div class="form-floating mb-3">
                                            <Field
                                              type="text"
                                              class="form-control"
                                              name={`passengerDetails[${index}].middleName`}
                                              id="floatingInput"
                                              placeholder="Middle Name"
                                              maxlength="100"
                                              autoComplete="off"
                                            />
                                            <label for="floatingInput">
                                              Middle Name
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col-lg-4">
                                          <div class="form-floating mb-3">
                                            <Field
                                              type="text"
                                              name={`passengerDetails[${index}].lastName`}
                                              class="form-control"
                                              id="floatingInput"
                                              placeholder="name@example.com"
                                              autoComplete="off"
                                            />
                                            <label for="floatingInput">
                                              Last Name*
                                            </label>
                                            <ErrorMessage
                                              name={`passengerDetails[${index}].lastName`}
                                              component="div"
                                              className="error"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-lg-4">
                                          <div class="form-floating mb-3">
                                            <Field
                                              as="select"
                                              class="form-select awrzr6 sdtg4r"
                                              name={`passengerDetails[${index}].gender`}
                                              aria-label="Default select example"
                                              disabled
                                            >
                                              <option selected>
                                                Select Gender
                                              </option>
                                              <option value="1">Male</option>
                                              <option value="2">Female</option>
                                              {/* <option value="3">Other</option> */}
                                            </Field>
                                            <ErrorMessage
                                              name={`passengerDetails[${index}].gender`}
                                              component="div"
                                              className="error"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-lg-7">
                                          <div className="row align-items-center dshgt4 px-2">
                                            <div className="col-4 p-0 sret56">
                                              <Field
                                                as="select"
                                                class="form-select satw5r7"
                                                name={`passengerDetails[${index}].dobDay`}
                                                aria-label="Default select example"
                                              >
                                                <option selected>Day*</option>
                                                {days.map((day) => (
                                                  <option key={day} value={day}>
                                                    {day}
                                                  </option>
                                                ))}
                                              </Field>
                                              <ErrorMessage
                                                name={`passengerDetails[${index}].dobDay`}
                                                component="div"
                                                className="error"
                                              />
                                            </div>
                                            <div className="col-4 p-0 sret56">
                                              <Field
                                                as="select"
                                                class="form-select satw5r7"
                                                name={`passengerDetails[${index}].dobMonth`}
                                                aria-label="Default select example"
                                              >
                                                <option selected>Month*</option>
                                                {months.map((month, index) => (
                                                  <option
                                                    key={index}
                                                    value={index + 1}
                                                  >
                                                    {month}
                                                  </option>
                                                ))}
                                              </Field>
                                              <ErrorMessage
                                                name={`passengerDetails[${index}].dobMonth`}
                                                component="div"
                                                className="error"
                                              />
                                            </div>
                                            
                                            <div className="col-4 p-0">
                                              <Field
                                                as="select"
                                                class="form-select satw5r7"
                                                name={`passengerDetails[${index}].dobYear`}
                                                aria-label="Default select example"
                                              >
                                                <option selected>Year*</option>
                                                {filteredYears.map((year) => (
                                                  <option
                                                    key={year}
                                                    value={year}
                                                  >
                                                    {year}
                                                  </option>
                                                ))}
                                              </Field>
                                              <ErrorMessage
                                                name={`passengerDetails[${index}].dobYear`}
                                                component="div"
                                                className="error"
                                              />
                                            </div>
                                            
                                          </div>
                                          <ErrorMessage
                                            name={`passengerDetails[${index}].dateOfBirth`}
                                            component="div"
                                            className="error"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {initialdestination?.countryCode !== "US" && 
                                  <div className="row align-items-center mb-3">
                                    <div className="col-lg-3 p-0 mb-3">
                                      <div className="row align-items-center">
                                        <div className="col-lg-12">
                                          <p className="mb-0 dstr64">
                                            Passport Info
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-9 p-0">
                                      <div className="row">
                                        <div className="col-lg-4 pe-lg-1 mb-3">
                                          <div class="form-floating">
                                            <Field
                                              type="text"
                                              class="form-control"
                                              name={`passengerDetails[${index}].passportnumber`}
                                              id="floatingInput"
                                              placeholder="Passport Number*"
                                              autoComplete="off"
                                              inputMode="numeric"
                                            />
                                            <label for="floatingInput">
                                              Passport Number
                                            </label>
                                            {/* <ErrorMessage
                                            name={`passengerDetails[${index}].passportnumber`}
                                            component="div"
                                            className="error"
                                          /> */}
                                          </div>
                                        </div>
                                        <div className="col-lg-5 px-lg-1 ">
                                          <div className="row">
                                            <div className="col-lg-6 px-0 pe-lg-2 mb-3 fhhd">
                                              <div class="form-floating">
                                              <DatePicker
        selected={values.passengerDetails[index]?.issueDate || null}
        onChange={(date) => setFieldValue(`passengerDetails[${index}].issueDate`, date)}
        dateFormat="MM/dd/yyyy"
        placeholderText="Issue Date"
        className="form-control fgered"
        id="floatingInput"
        autoComplete="off"
      />
                                                {/* <label for="floatingInput">
                                                  Issue Date
                                                </label> */}
                                                <ErrorMessage
                                                name={`passengerDetails[${index}].issueDate`}
                                                component="div"
                                                className="error"
                                              />
                                              </div>
                                            </div>
                                            <div className="col-lg-6 px-0 mb-3 fhhd1">
                                              <div class="form-floating">
                                                {/* <Field
                                                  type="text"
                                                  className="form-control"
                                                  name={`passengerDetails[${index}].expiryDate`}
                                                  id="floatingInput"
                                                  placeholder="MM/DD/YYYY"
                                                  onChange={handleInputChange}
                                                  onBlur={handleBlur}
                                                /> */}
                                                <DatePicker
        selected={values.passengerDetails[index]?.expiryDate || null}
        onChange={(date) => setFieldValue(`passengerDetails[${index}].expiryDate`, date)}
        dateFormat="MM/dd/yyyy"
        placeholderText="Expiry Date"
        className="form-control fgered"
        id={`floatingInput${index}`}
        autoComplete="off"
      />
                                                {/* <label for="floatingInput">
                                                  Expiry Date
                                                </label> */}
                                                <ErrorMessage
                                                name={`passengerDetails[${index}].expiryDate`}
                                                component="div"
                                                className="error"
                                              />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-lg-3 ps-lg-1 mb-3">
                                          <div class="form-floating">
                                            <Field
                                              type="text"
                                              class="form-control"
                                              name={`passengerDetails[${index}].countryName`}
                                              id="floatingInput"
                                              placeholder="Country Name*"
                                              autoComplete="off"
                                            />
                                            <label for="floatingInput">
                                              Country Name
                                            </label>
                                            {/* <ErrorMessage
                                            name={`passengerDetails[${index}].countryName`}
                                            component="div"
                                            className="error"
                                          /> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                            }
                                  <div className="row justify-content-center align-items-center mt-3">
                            <p className="ieyh65">
                              {" "}
                              <img
                                className="me-2"
                                src={isOptionalRequestsVisible[index] ? yw45d : yw55d}
                                onClick={() => toggleOptionalRequests(index)}
                                alt=""
                              />
                              Optional Requests{" "}
                              <br class="d-lg-none " />
                              <span>
                                (TSA Precheck / Redress Number)
                              </span>
                            </p>
                            {isOptionalRequestsVisible[index] && (
                              <>
                                <p className="jis67">Emergency Contact</p>

                                <div className="col-lg-6">
                                  <div class="form-floating mb-3">
                                    <Field
                                    name={`passengerDetails[${index}].contactName`}
                                      type="text"
                                      class="form-control"
                                      id="floatingInput"
                                      placeholder="Emergency Contact Name"
                                      onInput={(e) => (e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, ""))}
                                      maxlength="100"
                                      autoComplete="off"
                                    />
                                    <label for="floatingInput">
                                      Emergency Contact Name
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div class="form-floating mb-3">
                                    <Field
                                    name={`passengerDetails[${index}].contactNumber`}
                                      type="text"
                                      class="form-control"
                                      id="floatingInput"
                                      placeholder="Emergency Contact Number"
                                      onInput={(e) => (e.target.value = e.target.value.replace(/\D/g, ""))}
                                      maxlength="10"
                                      autoComplete="off"
                                      inputMode="numeric"
                                    />
                                    <label for="floatingInput">
                                      Emergency Contact Number
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div class="form-floating">
                                    <Field
                                      type="text"
                                      name={`passengerDetails[${index}].tsaPrecheck`}
                                      class="form-control pe-4"
                                      id="floatingInput"
                                      placeholder="TSA Precheck"
                                      autoComplete="off"
                                    />
                                    <label for="floatingInput">
                                      TSA Precheck
                                    </label>
                                    <div className="uyr76">
                                      <img src={ine54} alt="" />
                                      <div class="uy897">
                                        <div class="p-se5"></div>
                                        <p class="mb-0">
                                          If you are a member of a trusted
                                          traveler program such as TSA PreCheck,
                                          Global Entry, NEXUS, or SENTRI, enter
                                          your number here.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6 mt-lg-0 mt-3">
                                  <div class="form-floating ">
                                    <Field
                                    name={`passengerDetails[${index}].reDressNumber`}
                                      type="text"
                                      class="form-control"
                                      id="floatingInput"
                                      placeholder="Redress Number"
                                      autoComplete="off"
                                    />
                                    <label for="floatingInput">
                                      Redress Number
                                    </label>
                                    <div className="uyr76">
                                      <img src={ine54} alt="" />
                                      <div class="uy897">
                                        <div class="p-se5"></div>
                                        <p class="mb-0">
                                        Either your United States Department of Homeland Security Redress Control Number or Canadian Travel Number, this is a number assigned to passengers to promote resolution with watch list alerts.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="col-lg-12">
                                  <div className="fdy45 border-0 px-0">
                                    <p className="mb-0 fry547 me-1">Note: </p>
                                    <p className="mb-0">
                                      {" "}
                                      The above-mentioned services will depend
                                      on the airline's confirmation. These
                                      services are solely at the discretion of
                                      the airline.
                                    </p>
                                  </div>
                                </div> */}
                              </>
                            )}
                          </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className="fdy45">
                            <p className="mb-0">
                              <span className="mdui56">Important Note :</span>{" "}
                              Please enter traveler's name and date of birth
                              exactly as shown on the passport (for
                              international flights) or valid government-issued
                              photo ID ( for domestic flights) Name changes are
                              not permitted after booking.
                            </p>
                          </div>
                        </div>
                        <div
                          className={`hgdr679 ${
                            flightCancelOpen ? "u4rtwr" : ""
                          }`}
                        >
                          <div className="dsctgj4 d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              {" "}
                              <div className="uiety4">
                                <img src={y7et87} alt="" />{" "}
                              </div>{" "}
                              <p className="mb-0 jsryt7">
                                Flight Cancellation Coverage
                              </p>
                            </div>
                            <img
                              className="yr8t5"
                              src={dor6y}
                              alt=""
                              onClick={() =>
                                setFlightCancelOpen(!flightCancelOpen)
                              }
                            />
                          </div>

                          <div className="row mt-4 rwt64 pb-4 align-items-center">
                            <div className="col-lg-9">
                              <p className="i665r">Additional Perks include</p>
                              <div className="dki97">
                                {" "}
                                <img
                                  className="me-1"
                                  src={vchj4t}
                                  alt=""
                                />{" "}
                                <p className="mb-0">
                                  Cancellation without a fee and getting a full
                                  refund as well within 24 hours from booking.
                                </p>
                              </div>
                              <div className="dki97">
                                {" "}
                                <img
                                  className="me-1"
                                  src={vchj4t}
                                  alt=""
                                />{" "}
                                <p className="mb-0">
                                  Option to lock in the cheapest and most
                                  incredible airfare before the crowd comes
                                  running after it.
                                </p>
                              </div>
                              <div className="dki97">
                                {" "}
                                <img
                                  className="me-1"
                                  src={vchj4t}
                                  alt=""
                                />{" "}
                                <p className="mb-0">
                                  When there's an error in the reservation,
                                  simply cancel and rebook your flights with the
                                  correct information at no additional cost.
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-3 text-center jhrfy pt-lg-0 pt-2">
                              <img src={y7etr87} alt="" />
                              <p className="mb-0 ryh69">
                                USD {ancillary1Price.toFixed(2).split(".")[0]}
                                <sup>
                                  .{ancillary2Price.toFixed(2).split(".")[1]}
                                </sup>
                                {/*<sup>99</sup>*/}  /PP*
                              </p>
                            </div>
                            <div className="col-lg-6">
                              <div
                                className={`mb-0 dsr97 ${
                                  isAncillary1Selected ? "se54hu8" : ""
                                }`}
                                // onClick={toggleAncillary1}
                              >
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                  onChange={toggleAncillary1}
                                  checked={isAncillary1Selected}
                                />
                                <label
                                  class="form-check-label"
                                  for="flexRadioDefault1"
                                >
                                  Yes, please add the benefits of
                                  Pro-Cancellation at $29.99/PP*
                                </label>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div
                                className={`mb-0 dsr97 ${
                                  !isAncillary1Selected ? "se54hu8" : ""
                                }`}
                                // onClick={toggleAncillary1}
                              >
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault2"
                                  onChange={toggleAncillary1}
                                  checked={!isAncillary1Selected}
                                  defaultChecked
                                />
                                <label
                                  class="form-check-label"
                                  for="flexRadioDefault2"
                                >
                                  No, I'd pass on the benefits but I'll consider
                                  it maybe later.
                                </label>
                              </div>
                            </div>
                            <div className="fdy4555">
                            <p className="mb-0">
                              <span className="mdui56">PP* -</span>{" "}
                               Per Person
                            </p>

                            </div>
                          </div>
                          
                        </div>
                        <div
                          className={`hgdr679 ${paymentOpen ? "u4rtwr" : ""}`}
                        >
                          <div className="dsctgj4 d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              {" "}
                              <div className="uiety4">
                                <img src={yp8ed} alt="" />
                              </div>{" "}
                              <p className="mb-0 jsryt7">
                                Payment and Billing{" "}
                                <span className="mob-hide">
                                  (Secure SSL Encrypted Transaction)
                                </span>
                              </p>
                            </div>
                            <img
                              className="yr8t5"
                              src={dor6y}
                              alt=""
                              onClick={() => setPaymentOpen(!paymentOpen)}
                            />
                          </div>
                          <div className="rwt64">
                            <div className="row justify-content-center align-items-center mb-3">
                              <div className="col-lg-11 text-end">
                                <div className="ou5tft7">
                                  <div
                                    className={`ir95w ${
                                      cardType === "MasterCard" ? "set54f" : ""
                                    }`}
                                  >
                                    <img src={card1} alt="" />
                                  </div>
                                  <div
                                    className={`ir95w ${
                                      cardType === "Discover" ? "set54f" : ""
                                    }`}
                                  >
                                    <img src={card2} alt="" />
                                  </div>
                                  <div
                                    className={`ir95w ${
                                      cardType === "AmericanExpress"
                                        ? "set54f"
                                        : ""
                                    }`}
                                  >
                                    <img src={card3} alt="" />
                                  </div>
                                  <div
                                    className={`ir95w ${
                                      cardType === "Visa" ? "set54f" : ""
                                    }`}
                                  >
                                    <img src={card4} alt="" />
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <p className="jshr8 mb-lg-0 mb-2">
                                  Credit/Debit Card No <span>*</span>
                                </p>
                              </div>
                              <div className="col-lg-8">
                                <div class="form-floating">
                                  <Field
                                    type="text"
                                    name="paymentDetails.cardNumber"
                                    class="form-control"
                                    id="floatingInput"
                                    placeholder="Credit/Debit Card No."
                                    autoComplete="off"  // Disable autofill
  inputMode="numeric"
                                    value={
                                      isMasked
                                        ? values.paymentDetails.maskCardNumber
                                        : values.paymentDetails.cardNumber
                                    } // Show masked only on blur
                                    onChange={(e) => {
                                      setFieldValue(
                                        "paymentDetails.cardNumber",
                                        e.target.value
                                      );
                                      setIsMasked(false); // Show actual number while typing
                                      handleCardNumberChange(e);
                                    }}
                                    // onChange={handleCardNumberChange}
                                    onBlur={handleBlur} // Mask on blur
                                    onFocus={() => setIsMasked(false)}
                                  />
                                  <label for="floatingInput">
                                    Credit/Debit Card No.
                                  </label>
                                  <ErrorMessage
                                    name="paymentDetails.cardNumber"
                                    component="div"
                                    className="error"
                                  />
                                  {/* <ErrorMessage name="paymentDetails.cardType" component="div" className="error" /> */}
                                </div>
                              </div>
                            </div>
                            <div className="row justify-content-center align-items-center mb-3">
                              <div className="col-lg-4">
                                <p className="jshr8 mb-lg-0 mb-2">
                                  Card Holder's Name <span>*</span>
                                </p>
                              </div>
                              <div className="col-lg-8">
                                <div class="form-floating">
                                  <Field
                                    type="text"
                                    name="paymentDetails.cardHolderName"
                                    class="form-control"
                                    id="floatingInput"
                                    placeholder="Card Holder's Name"
                                    autoComplete="off"
                                  />
                                  <label for="floatingInput">
                                    Card Holder's Name
                                  </label>
                                  <ErrorMessage
                                    name="paymentDetails.cardHolderName"
                                    component="div"
                                    className="error"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row justify-content-center align-items-center mb-3">
                              <div className="col-lg-4">
                                <p className="jshr8 mb-0">
                                  Expiration Date / CVV <span>*</span>
                                </p>
                              </div>
                              <div className="col-lg-8">
                                <div className="row align-items-center mt-3 mt-lg-0">
                                  <div className="col-6 col-lg-3 ps-lg-0 pe-lg-1">
                                    <Field
                                      as="select"
                                      class="form-select awrzr6"
                                      name="paymentDetails.expiryMonth"
                                      aria-label="Default select example"
                                    >
                                      <option selected>Month</option>
                                      {months.map((month, index) => (
                                        <option key={index} value={index + 1}>
                                          {month}
                                        </option>
                                      ))}
                                    </Field>
                                    <ErrorMessage
                                      name="paymentDetails.expiryMonth"
                                      component="div"
                                      className="error"
                                    />
                                  </div>
                                  <div className="col-6 col-lg-3 px-lg-1">
                                    <Field
                                      as="select"
                                      class="form-select awrzr6"
                                      name="paymentDetails.expiryYear"
                                      aria-label="Default select example"
                                    >
                                      <option selected>Year</option>
                                      {years.map((year, index) => (
                                        <option key={index} value={year}>
                                          {year}
                                        </option>
                                      ))}
                                    </Field>
                                    <ErrorMessage
                                      name="paymentDetails.expiryYear"
                                      component="div"
                                      className="error"
                                    />
                                  </div>
                                  <div className="col-6 col-lg-3 px-lg-1 ut4rs4">
                                    <div className="form-floating">
                                      <Field
                                        type="text"
                                        name="paymentDetails.cvvNo"
                                        className="form-control"
                                        id="floatingInput"
                                        placeholder="CVV"
                                        autoComplete="off"
                                        value={
                                          values.paymentDetails.cvv !== "" ?
                                          isCvvMasked
                                            ? values.paymentDetails.maskCvv
                                            : values.paymentDetails.cvv
                                            : ''
                                        }
                                        maxLength={
                                          values.paymentDetails.cardNumber
                                            ?.length === 15
                                            ? 4
                                            : 3
                                        }
                                        onChange={(e) => {
                                          setFieldValue(
                                            "paymentDetails.cvvNo",
                                            e.target.value
                                          );
                                          setIsCvvMasked(false); // Always show the real CVV while typing
                                          handleCvvChange(e);
                                        }}
                                        onBlur={handleCvvBlur} // Call blur handler to mask only if empty
                                        onFocus={() => setIsCvvMasked(false)} // Show real CVV on focus
                                      />
                                      <label htmlFor="floatingInput">CVV</label>
                                      <ErrorMessage
                                        name="paymentDetails.cvvNo"
                                        component="div"
                                        className="error"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-6 col-lg-3 px-lg-1 sdt5uy ut4rs4">
                                    <div className="d-flex align-items-center ">
                                      <img
                                        className="me-1"
                                        src={ine504}
                                        alt=""
                                      />
                                      <p className="mb-0 dst4u8">
                                        3-4 Digit Number on your Card
                                      </p>
                                    </div>
                                    <div className="dsfed4e se673ty">
                                      <div className="d-lg-flex">
                                        <div className="text-start me-lg-2">
                                          <img
                                            className="mb-2"
                                            src={carfg3}
                                            alt=""
                                          />
                                          <p className="mb-1 deut">
                                            For Visa , Mastercard, Discover,
                                            Diner Club And Carte Blanche:
                                          </p>
                                          <p className="mb-lg-0 fonted">
                                            The Verification number is the
                                            3-Digit number located on the front
                                            of the credit card
                                          </p>
                                        </div>
                                        <div className="text-start ms-lg-2">
                                          <img
                                            className="mb-2"
                                            src={carfg2}
                                            alt=""
                                          />
                                          <p className="mb-1 deut">
                                            For American Express
                                          </p>
                                          <p className="mb-0 fonted">
                                            The Verification number is the
                                            4-Digit number located on the front
                                            of the credit card
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="fdy451 ">
                              <p className="mb-0">
                                <span className="mdui56">
                                  {" "}
                                  <img className="me-1" src={yu987} alt="" />
                                  Safe and Secure Billing :
                                </span>{" "}
                                Your credit/debit card information is protected
                                by a secure SSL Encrypted Transaction
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`hgdr679 ${
                            billingInfoOpen ? "u4rtwr" : ""
                          }`}
                        >
                          <div className="dsctgj4 d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              {" "}
                              <div className="uiety4">
                                <img src={y75td} alt="" />
                              </div>{" "}
                              <p className="mb-0">Billing Information</p>
                            </div>
                            <img
                              className="yr8t5"
                              src={dor6y}
                              alt=""
                              onClick={() =>
                                setBillingInfoOpen(!billingInfoOpen)
                              }
                            />
                          </div>
                          <div className="row justify-content-center align-items-center mt-4 rwt64">
                            <div className="col-lg-12">
                              <div class="form-floating mb-3">
                                <Field
                                  type="text"
                                  name="paymentDetails.address1"
                                  class="form-control"
                                  id="floatingInput"
                                  placeholder="Address*"
                                  autoComplete="off"
                                />
                                <label for="floatingInput">Address*</label>
                                <ErrorMessage
                                  name="paymentDetails.address1"
                                  component="div"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div class="form-floating mb-3">
                                <Field
                                  as="select"
                                  name="paymentDetails.country"
                                  class="form-control"
                                  id="floatingInput"
                                  placeholder="Select Country*"
                                  // value={values.paymentDetails.country}
                                  onChange={handleCountryChange}
                                >
                                  {countryList.map((country) => (
                                    <option key={country.ID} value={country.ID}>
                                      {country.Name}
                                    </option>
                                  ))}
                                </Field>
                                <label for="floatingInput">
                                  Select Country*
                                </label>
                                <ErrorMessage
                                  name="paymentDetails.country"
                                  component="div"
                                  className="error"
                                />
                              </div>
                            </div>
                            {states.length > 0 ? (
                              <div className="col-lg-6">
                                <div className="form-floating mb-3">
                                  <Field
                                    as="select"
                                    name="paymentDetails.state"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="Select State*"
                                  >
                                    <option value="">Select State*</option>
                                    {states.map((state) => (
                                      <option key={state.ID} value={state.ID}>
                                        {state.Name}
                                      </option>
                                    ))}
                                  </Field>
                                  <label for="floatingInput">
                                    Select State*
                                  </label>
                                  <ErrorMessage
                                    name="paymentDetails.state"
                                    component="div"
                                    className="error"
                                  />
                                </div>
                              </div>
                            ) : (
                              // If no states are available for the selected country, show an input field instead
                              <div className="col-lg-6">
                                <div className="form-floating mb-3">
                                  <Field
                                    type="text"
                                    name="paymentDetails.state"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="Enter State*"
                                    autoComplete="off"
                                  />
                                  <label for="floatingInput">Enter State</label>
                                  {/* <ErrorMessage
                                    name="paymentDetails.state"
                                    component="div"
                                    className="error"
                                  /> */}
                                </div>
                              </div>
                            )}
                            <div className="col-lg-6">
                              <div class="form-floating mb-3">
                                <Field
                                  type="text"
                                  name="paymentDetails.city"
                                  class="form-control"
                                  id="floatingInput"
                                  placeholder="City / Town*"
                                  autoComplete="off"
                                />
                                <label for="floatingInput">City / Town*</label>
                                <ErrorMessage
                                  name="paymentDetails.city"
                                  component="div"
                                  className="error"
                                />
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div class="form-floating mb-3">
                                <Field
                                  type="text"
                                  name="paymentDetails.postalCode"
                                  class="form-control"
                                  id="floatingInput"
                                  placeholder="Postal / Zip Code*"
                                  autoComplete="off"
                                  // pattern={postalCodePattern}
                                />
                                <label for="floatingInput">
                                  Postal / Zip Code*
                                </label>
                                <ErrorMessage
                                  name="paymentDetails.postalCode"
                                  component="div"
                                  className="error"
                                />
                              </div>
                            </div>
                          </div>
                          
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="hgdr679 eri45">
                          <div className="row jueg5 align-items-center">
                            <div className="col-lg-12">
                              <p className="jd679 mb-0">Price Details (USD)</p>
                            </div>
                          </div>
                          <div className="px-3">
                            <div className="row align-items-center ht4jtr">
                              <div className="col-8 px-1 borje4">
                                <p className="mb-0 man9d">
                                  {formValue.adults} Traveler(s) : Adult
                                </p>
                              </div>
                              <div className="col-4 text-end px-1 borje4">
                                <p className="mb-0 prid9">
                                  USD{" "}
                                  {totalPriceAdults.toFixed(2).split(".")[0]}
                                  <sup>
                                    .{totalPriceAdults.toFixed(2).split(".")[1]}
                                  </sup>
                                  {/*.<sup>89</sup> */}
                                </p>
                              </div>
                            </div>
                            {formValue.child !== 0 ? (
                              <div className="row align-items-center ht4jtr">
                                <div className="col-8 px-1 borje4">
                                  <p className="mb-0 man9d">
                                    {formValue.child} Traveler(s) : Child
                                  </p>
                                </div>
                                <div className="col-4 text-end px-1 borje4">
                                  <p className="mb-0 prid9">
                                    USD{" "}
                                    {totalPriceChild.toFixed(2).split(".")[0]}
                                    <sup>
                                      .
                                      {totalPriceChild.toFixed(2).split(".")[1]}
                                    </sup>
                                    {/*.<sup>89</sup> */}
                                  </p>
                                </div>
                              </div>
                            ) : null}
                            {formValue.infants !== 0 ? (
                              <div className="row align-items-center ht4jtr">
                                <div className="col-8 px-1 borje4">
                                  <p className="mb-0 man9d">
                                    {formValue.infants} Traveler(s) : Infant(lap)
                                  </p>
                                </div>
                                <div className="col-4 text-end px-1 borje4">
                                  <p className="mb-0 prid9">
                                    USD{" "}
                                    {totalPriceInfant.toFixed(2).split(".")[0]}
                                    <sup>
                                      .
                                      {
                                        totalPriceInfant
                                          .toFixed(2)
                                          .split(".")[1]
                                      }
                                    </sup>
                                    {/*.<sup>89</sup> */}
                                  </p>
                                </div>
                              </div>
                            ) : null}
                            {formValue.infantsWs !== 0 ? (
                              <div className="row align-items-center ht4jtr">
                                <div className="col-8 px-1 borje4">
                                  <p className="mb-0 man9d">
                                    {formValue.infantsWs} Traveler(s) :
                                    Infant(seat)
                                  </p>
                                </div>
                                <div className="col-4 text-end px-1 borje4">
                                  <p className="mb-0 prid9">
                                    USD{" "}
                                    {
                                      totalPriceInfantWs
                                        .toFixed(2)
                                        .split(".")[0]
                                    }
                                    <sup>
                                      .
                                      {
                                        totalPriceInfantWs
                                          .toFixed(2)
                                          .split(".")[1]
                                      }
                                    </sup>
                                    {/*.<sup>89</sup> */}
                                  </p>
                                </div>
                              </div>
                            ) : null}
                            {ancillaryProduct.length > 0 && (
                              <>
                                {ancillaryProduct.map((item) => (
                                  <div className="row align-items-center ht4jtr">
                                    <div className="col-8 px-1 borje4">
                                      {item.name}
                                      <img
                                        className="ms-1"
                                        src={dusyd}
                                        onClick={() => {
                                          if (item.name === "Web Check-In") {
                                            toggleAncillary2();
                                          } else {
                                            toggleAncillary1();
                                          }
                                        }}
                                        alt=""
                                      />
                                    </div>

                                    <div
                                      key={item.id}
                                      className="col-4 text-end px-1 borje4"
                                    >
                                      <p className="mb-0 prid9">
                                        USD{" "}
                                        {item.price.toFixed(2).split(".")[0]}
                                        <sup>
                                          .{item.price.toFixed(2).split(".")[1]}
                                        </sup>
                                      </p>
                                    </div>
                                  </div>
                                ))}
                              </>
                            )}
                            {/* <div className="row align-items-center ht4jtr">
                          <div className="col-lg-8 px-1 borje4">
                            <p className="mb-0 man9d">
                              Web Check-In{" "}
                              <img className="ms-1" src={dusyd} alt="" />
                            </p>
                          </div>
                          <div className="col-lg-4 text-end px-1 borje4">
                            <p className="mb-0 prid9">
                              USD 10.<sup>99</sup>
                            </p>
                          </div>
                        </div>
                        <div className="row align-items-center ht4jtr border-0">
                          <div className="col-lg-8 px-1">
                            <p className="mb-0 man9d">
                              Cancellation Protection{" "}
                              <img className="ms-1" src={dusyd} alt="" />
                            </p>
                          </div>
                          <div className="col-lg-4 text-end px-1">
                            <p className="mb-0 prid9">
                              USD 29.<sup>99</sup>
                            </p>
                          </div>
                        </div> */}
                          </div>
                          <div className="px-3 jxdfh6">
                            <div className="row align-items-center ht4jtr border-0">
                              <div className="col-7 col-lg-8 px-1">
                                <p className="mb-0 text-white jdtr54">Total Amount
                                <div className="uyr76 uyr761">
                                      <img src={inf05} alt="" />
                                      <div class="uy897 uy8971">
                                        <div class="p-se5"></div>
                                        <p class="mb-0">
                                        Total price is inclusive of all taxes and fees
                                        </p>
                                      </div>
                                    </div>
                                </p>
                              </div>
                              <div className="col-5 col-lg-4 text-end px-1">
                                <p className="mb-0 prid9 text-white">
                                  USD {totalAmount.toFixed(2).split(".")[0]}
                                  <sup>
                                    .{totalAmount.toFixed(2).split(".")[1]}
                                  </sup>
                                  {/*.<sup>87</sup> */}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="fdy45 border-0 ht4jtr px-3 ">
                            <p className="mb-0">
                              <b>Note:</b> All Price are quoted in USD.Your credit/debit card may be billed in multiple charges totaling the final total price. Baggage allowance and fee amount are as per the policies. Fares are inclusive all taxes and fees.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="dsjt">
                          <p>
                            By Clicking Confirm & Book, I agree that I have read
                            and accepted Rawfares{" "}
                            <span className="jisr4">
                              <Link to="/terms-and-condition" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}}>
                                Terms and Conditions
                              </Link>
                            </span>{" "}
                            and{" "}
                            <span className="jisr4">
                              <Link to="/privacy-policy" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}}>Privacy Policy.</Link>
                            </span>
                          </p>
                        </div>
                        <button
                          className="uet54"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Confirm & Book
                        </button>
                      </div>
                    </div>
                  </div>
                </section>
              </Form>
            );
          }}
        </Formik>
        <Footer1 />
      </div>
    </>
  );
}
