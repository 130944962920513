/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

import Footer from '../Footer/Footer';
import SearchEngine from '../Search-Engine/engine';
import Header from '../Header/header';
import sarr3 from '../../assest/images/icon/arow.png';
import {Link} from 'react-router-dom';

export default function Landing({ firstResponseData }) {
    const { airlineName } = useParams();
    const [utmParams, setUtmParams] = useState({});
      console.log("Airline name", airlineName)
      const cleanedAirlineName = airlineName.replace(/^cheap-flights-to-/, '');
      const parts = cleanedAirlineName.split('-');
    const cityCode = parts.pop(); // Get "chi"
    const cityName = parts.join('-'); // Get "chicago"
    const pageValue = cityCode; // Set pageValue to "chi"
    const url = `cheap-flights-to-${cityName}-${cityCode}`;
    const imageNames = ['albuquerque.webp', 'amsterdam.webp','atlanta.webp', 'chicago.webp', 'las-vegas.webp', 'hawaii.webp', 'charleston.webp', 'boston.webp', 'cancun.webp', 'barcelona.webp', 'orlando.webp', 'seattle.webp', 'san-jose.webp', 'dallas.webp', 'denver.webp', 'london.webp', 'paris.webp', 'rome.webp', 'tokyo.webp', 'florida.webp'];
      const filteredImages = imageNames
      .filter(name => name !== `${url}.webp`)
      .slice(0, 3);

      const imageNameToAirline = {
        'albuquerque.webp': 'albuquerque',
        'amsterdam.webp': 'amsterdam',
        'atlanta.webp': 'atlanta',
        'chicago.webp': 'chicago',
        'las-vegas.webp': 'las-vegas',
        'hawaii.webp': 'hawaii',
        'charleston.webp': 'charleston',
        'boston.webp': 'boston',
        'cancun.webp': 'cancun',
        'barcelona.webp': 'barcelona',
        'orlando.webp' : 'orlando',
        'seattle.webp' : 'seattle',
        'san-jose.webp' : 'san-jose',
        'dallas.webp' : 'dallas',
        'denver.webp' : 'denver',
        'london.webp' : 'london',
        'paris.webp' : 'paris',
        'rome.webp' : 'rome',
        'tokyo.webp' : 'tokyo',
        'florida.webp': 'florida'
      };
      const imageNameToAirlineCode = {
        'albuquerque.webp': 'abq',
        'amsterdam.webp': 'ams',
        'atlanta.webp': 'atl',
        'chicago.webp': 'ord',
        'las-vegas.webp': 'las',
        'hawaii.webp': 'hi',
        'charleston.webp': 'chs',
        'boston.webp': 'bos',
        'cancun.webp': 'cun',
        'barcelona.webp': 'bcn',
        'orlando' : 'mco',
        'seattle' : 'sea',
        'san-jose' : 'sjc',
        'florida' : 'fl'
      };

      const standardizeAirlineName = (name) => {
        // Define a mapping of variations to a standard name
        const nameVariations = {
          'albuquerque': 'Albuquerque',
          'amsterdam': 'Amsterdam',
          'atlanta': 'Atlanta',
          'chicago': 'Chicago',
          'las-vegas': 'Las Vegas',
          'hawaii': 'Hawaii',
          'charleston': 'Charleston',
          'boston': 'Boston',
          'cancun': 'Cancun',
          'barcelona': 'Barcelona',
          'orlando': 'Orlando',
          'seattle': 'Seattle',
          'san-jose': 'San Jose',
          'dallas' : 'Dallas',
          'denver' : 'Denver',
          'london' : 'London',
          'paris' : 'Paris',
          'rome' : 'Rome',
          'tokyo' : 'Tokyo',
          'florida' : 'Florida'
          // Add more variations as needed
        };
      
        return nameVariations[name] || name; // If a variation is found, return the standardized name, otherwise return the original name
      };

    const airlineNamesFromImages = filteredImages.map(imageName => standardizeAirlineName(imageNameToAirline[imageName]));

    const getAirlineCode = (name) => {
        const airlineCodes = {
          "Albuquerque": "ABQ",
          "Barcelona": "BCN",
          "Boston": "BOS",
          "Cancun": "CUN",
          "Charleston": "CHS",
          "Hawaii": "HI",
          "Chicago": "ORD",
          "Las Vegas": "LAS",
          "Orlando": "MCO",
          "Seattle": "SEA",
          "San Jose": "SJC",
          "San Francisco": "SFO",
          "Dallas": "DFW",
          "Denver": "DEN",
          "Honolulu": "HNL",
          "Kahului": "OGG",
          "Los Angeles": "LAX",
          "Guadalajara": "GDL",
          "London": "LON",
          "Cuncun": "CUN",
          "New York": "NYC",
          "Paris": "PAR",
          "Toronto": "YYZ",
          "Rome" : "ROM",
          "Tokyo" : "HND",
          "Florida" : "FL",
          "Vancouver" : "YVR",
          "Sydney" : "SYD",
          "South Island": "CHC",
          "Newport" : "ONP",
          "Louisville": "SDF",
          "Washington" : "WAS",
          "Westchester": "HPN"
        };
      
        return airlineCodes[name];
      };

    const createURL = (name) => {
        const airlineCode = getAirlineCode(name);
        return `https://www.rawfares.com/flights/cheap-flights-to-${name?.toLowerCase().replace(/\s+/g, '-')}-${airlineCode?.toLowerCase()}`;
      };

      const createURLRoute = (origin, destination) => {
        const originCode = getAirlineCode(origin);
        const destinationCode = getAirlineCode(destination);
        return `https://www.rawfares.com/flights/cheap-flights-from-${origin?.toLowerCase().replace(/\s+/g, '-')}-to-${destination?.toLowerCase().replace(/\s+/g, '-')}-${originCode?.toLowerCase()}-${destinationCode?.toLowerCase()}`;
      };

  return (
    <>
    <Header />
    <SearchEngine />
    <section className='py-4'>
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h3>Flights to {firstResponseData?.pageName}({firstResponseData?.pageValue && firstResponseData.pageValue.toUpperCase()})</h3>
                    <p dangerouslySetInnerHTML={{
                    __html: firstResponseData?.contentData,
                  }}></p>
                    
                </div>
            </div>
            <div className="row pt-3">
                <div className="col-lg-4">
                    <div className="kjdh6">
                        <h5>Top Flights Domestic</h5>
                        {["Dallas", /*"Delta Air Lines" */ "Boston", "Charleston", "Hawaii", "Chicago", "Las Vegas", "Orlando", "Seattle", "San Jose", "Florida"].filter(airlineName => (
      // Check if the airline name is not equal to firstResponseData.pageName and not present in airlineNamesFromImages
      firstResponseData?.pageName !== airlineName && !airlineNamesFromImages.includes(airlineName)
    )).map(airlineName => (
                            <Link to={createURL(airlineName)} target='_blank' className="jgug87" key={airlineName}> 
                                <p className='mb-0'>{airlineName}</p>
                                <img src={sarr3} alt="" />
                            </Link>
                            ))}
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="kjdh6">
                        <h5>Top Flight Routes</h5>
                        {[
      { origin: "Boston", destination: "Newport" },
      { origin: "Los Angeles", destination: "Chicago" },
      { origin: "Denver", destination: "Chicago" },
      { origin: "Honolulu", destination: "Kahului" },
      { origin: "Las Vegas", destination: "Los Angeles" },
      { origin: "Louisville", destination: "Chicago" },
      { origin: "Los Angeles", destination: "San Francisco" },
      { origin: "New York", destination: "Washington" },
      { origin: "Seattle", destination: "Boston" },
      { origin: "Westchester", destination: "Chicago" }
      // Add more origin-destination pairs
    ].map(({ origin, destination }) => (
                        <Link to={createURLRoute(origin, destination)} target="_blank" className="jgug87" key={`${origin}-${destination}`}>
                            <p className='mb-0'>From {origin} to {destination}</p>
                            <img src={sarr3} alt="" />
                        </Link>
                        ))} 
                        <Link to="" className="jgug87">
                            <p className='mb-0'>From Boston to Newport</p>
                            <img src={sarr3} alt="" />
                        </Link>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="kjdh6">
                        <h5>Top International Flights</h5>
                        {["Barcelona", /*"Delta Air Lines"*/  "Cancun", "London", "Paris", "Rome", "Tokyo", "Toronto", "Vancouver", "Sydney", "South Island"].filter(airlineName => (
      // Check if the airline name is not equal to firstResponseData.pageName and not present in airlineNamesFromImages
      firstResponseData?.pageName !== airlineName && !airlineNamesFromImages.includes(airlineName)
    )).map(airlineName => (
                            <Link to={createURL(airlineName)} target='_blank' className="jgug87" key={airlineName}> 
                                <p className='mb-0'>{airlineName}</p>
                                <img src={sarr3} alt="" />
                            </Link>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <Footer />
    </>
  )
}
