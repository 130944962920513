/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Footer from '../Component/Footer/Footer';
import Header from "../Component/Header/header";
import { Link } from "react-router-dom";
import MetaTitle from "../Component/MetaTitle/metaTitle";
import { constant } from "../constant";

export default function Disclaimer() {
    const [utmCampaign, setUtmCampaign] = useState("");
              const [displayNumber, setDisplayNumber] = useState("");
              const jsonMapping = {
                  domestics_routes: "+1-833-609-8333",
                  domestics_destination: "+1-833-609-7300",
                  cruise : "+1-833-609-9933",
                  airline: "+1-833-609-8444"
                };
                const defaultNumber = "+1-888-415-1536"; // Default number to display if UTM data is not found
            
                useEffect(() => {
                  const updateDisplayNumber = () => {
                    const utmData = JSON.parse(localStorage.getItem("utm_data")) || {};
                  //   console.log(utmData)
                    if (utmData?.utmData?.utm_campaign) {
                      // console.log("in")
                      setUtmCampaign(utmData?.utm_campaign);
              
                      // Check if `utm_campaign` exists in the JSON mapping
                      const mappedNumber = jsonMapping[utmData?.utmData?.utm_campaign];
                      setDisplayNumber(mappedNumber || defaultNumber); // Use mapped number or default
                    } else {
                      // console.log("out")
                      // No UTM data found, use the default number
                      setDisplayNumber(defaultNumber);
                    }
                  };
              
                  // Run initially
                  updateDisplayNumber();
              
                  // Set up interval to check every 10 seconds
                  const interval = setInterval(updateDisplayNumber, 1000000000000);
              
                  // Clean up interval on component unmount
                  return () => clearInterval(interval);
                }, [jsonMapping]);
    return (
        <>
        <MetaTitle pageTitle='Disclaimer - RawFares' pageDescription="RawFares takes pride in being your gateway to major destinations and the off-the-beaten-path gems that await your discovery." url={`${constant.baseUrl}/disclaimer`} />
        <Header />
        <section className='py-4'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Disclaimer for RawFares</h1>
                        <h2>Accuracy of Information</h2>
                        <p>RawFares strives to provide accurate and current information on our website. However, as we depend on travel partners and suppliers, the details provided may change frequently. While we make every effort to make sure that the information is up-to-date, we cannot vouch for its accuracy or completeness at all times. Flight prices and availability are subject to change without prior notice due to the dynamic nature of the travel industry.</p>
                        <h2>Website Responsibility</h2>
                        <p>RawFares is not liable for any damages, losses, or issues that arise from reliance on the information presented on our site or any external links provided. We encourage users to verify details with relevant travel providers or through direct communication with us.</p>
                        <h2>Discount Policy</h2>
                        <p>Discounts offered by RawFares may vary based on market conditions, including booking dates, travel periods, and special restrictions. Changes to fares may occur without notice. If you wish to claim a discount, we will assess your request thoroughly before processing it.</p>
                        <h2>Price Match Guarantee</h2>
                        <p>When booking with RawFares, we aim to offer competitive rates and an efficient booking experience. If you find a lower price (including taxes and fees) for the same travel arrangements on a major competitor’s site within 24 hours of your booking, we will match that price. You can choose to either receive a refund for the price difference or cancel your booking for a full refund.</p>
                        <h2>Exceptions to the Price Match Guarantee</h2>
                        <p>Our Price Match Guarantee does not apply to certain fares, including membership or rewards program fares, group bookings, corporate discounts, opaque tickets, auction-based fares, or fare errors.</p>
                        <h2>Promo Codes</h2>
                        <p>RawFares may offer promo codes for online flight bookings. These codes are subject to the following
                conditions:</p>
                        <p><ol>
                    <li>Receiving Promo Codes: Sign up for our newsletters to get promo codes via email.</li>
                    <li>Maximum Discount: Promo codes provide discounts up to the value of our service fees (up to
                        70%).</li>
                        <li>Non-Transferable: Promo codes cannot be transferred or sold and hold no cash value.</li>
                        <li>Application of Codes: Enter the promo code on the payment page to apply the discount. If you
                        experience technical issues, we cannot retroactively apply the discount.</li>
                        <li>Code Validity: We may revise or withdraw promo codes at any time without prior notice. Once
                        withdrawn, promo codes will no longer be accepted.</li>
                        <li>Technical Issues: We are not responsible for technical issues affecting promo code application.
                        You may choose not to proceed with the purchase if the code is not accepted.</li>
                        <li>Final Pricing: The final price displayed (with or without a promo code) is your billed amount. No
                        additional discounts will be applied post-purchase.</li>
                        <li>Combination with Other Offers: Promo codes may be used with other offers unless stated
                        otherwise.</li>
                    </ol></p>
                    <p>
                RawFares reserves the right to refuse any transactions involving incorrect promo code values or other
discrepancies even after the booking process is completed. If you need further details or clarifications,
give us a call at ({displayNumber}) at any hour of the day, we will be happy to help you out.</p>
                    </div>
                </div>
                
            </div>
        </section>
        
        <Footer />
        </>
      )
};