/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import downh3 from "../../assest/images/icon/down2.png";
import airlinesName from "../AirlineName/AirlineName";
import lungage1 from "../../assest/images/icon/luggage1.png";
import lungage2 from "../../assest/images/icon/luggage2.png";
import lungage3 from "../../assest/images/icon/luggage3.png";
import dfge5 from "../../assest/images/icon/dst4w.png";
import apwhu7 from "../../assest/images/icon/appro.png";
import { DateTime } from "luxon";
import trainModel from "../AI/errorTracker";
import * as tf from "@tensorflow/tfjs";

function formatDateAndTime(isoDate) {
  const dateOptions = {
    weekday: "short",
    month: "short",
    day: "numeric",
  };

  const timeOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const formattedDate = new Date(isoDate);
  const formattedDateString = new Intl.DateTimeFormat(
    "en-US",
    dateOptions
  ).format(formattedDate);
  const formattedTimeString = new Intl.DateTimeFormat(
    "en-US",
    timeOptions
  ).format(formattedDate);

  return { formattedDateString, formattedTimeString };
}

function convertMinutesToHoursAndMinutes(minutes) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours}h ${remainingMinutes}m`;
}

export default function FlightCard({
  isLoding,
  resultId,
  airline,
  cabinClass,
  currencyCode,
  fare,
  fareType,
  gdsType,
  inBound,
  inEFT,
  index,
  maxSeat,
  outBound,
  outEFT,
  valCarrier,
  airports,
  initialForm,
  itineraryVisible,
}) {
  const navigate = useNavigate();
  const [itineraryOpen, setItineraryOpen] = useState(false);
  const [alternateDep, setAlternateDep] = useState(false);
  const [alternateRet, setAlternateRet] = useState(false);
  const [airportChange, setAirportChange] = useState(false);
  const cardRef = useRef(null);
  const handleWheel = (e) => {
    // Prevent the itinerary from closing on scroll
    e.stopPropagation();
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Get the viewport width (excluding scrollbar)
      const viewportWidth = document.documentElement.clientWidth;

      // Check if the click happened on the scrollbar
      if (event.clientX >= viewportWidth) {
        return; // Ignore clicks on the scrollbar
      }

      // Check if the click is outside the card
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        setItineraryOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [itineraryVisible]);

  const calculateTotalTime = (segments) => {
    if (!segments || segments?.length === 0) return null;

    // Parse departure and arrival times using Luxon
    const firstDepartureTime = DateTime.fromISO(segments[0]?.depDate, {
      zone: "utc",
    });
    const lastArrivalTime = DateTime.fromISO(
      segments[segments.length - 1]?.reachDate,
      { zone: "utc" }
    );

    // Check if the lastArrivalTime is before the firstDepartureTime
    if (lastArrivalTime < firstDepartureTime) {
      console.error(
        "Arrival time is earlier than departure time! Check your data."
      );
      return <span>Error in flight times</span>;
    }

    // Calculate duration
    const duration = lastArrivalTime.diff(firstDepartureTime, [
      "hours",
      "minutes",
    ]);

    // Extract hours and minutes
    const totalHours = Math.floor(duration?.hours);
    const totalMinutes = Math.round(duration?.minutes);

    return (
      <span>
        {totalHours}h {totalMinutes}m
      </span>
    );
  };

  const handleFlightSelect = async (flight) => {
    const dataTransfer = {
      flight: flight,
      formValue: initialForm,
    };
    navigate("/payment", { state: { dataTransfer } });
  };

  const formatPriceWithSuperscript = (price) => {
    const [integerPart, decimalPart] = price?.toFixed(2).split(".");
    return (
      <>
        {integerPart}
        <sup>.{decimalPart}</sup>
      </>
    );
  };

  const totalTax = fare?.adultTax;
  let totalPrice = fare?.adultFare + totalTax;

  const roundedPrice = totalPrice;

  const [model, setModel] = useState(null);
  const [errorDetected, setErrorDetected] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const loadModel = async () => {
      try {
        const trainedModel = await trainModel();
        if (isMounted) {
          setModel(trainedModel);
        }
      } catch (error) {
        console.error("Model Loading Error:", error);
      }
    };

    loadModel();

    return () => {
      isMounted = false;
    };
  }, []);

  const safeFind = () => {
    try {
      if (!airports || !Array.isArray(airports))
        throw new Error("Flights data is missing!");
      const result = airports.find(
        (a) => a.airportCode === outBound?.[0]?.fromAirport
      )?.cityName;
      if (!result) throw new Error("Flight not found!");
      return result;
    } catch (error) {
      console.error("Runtime Error:", error.message);
      detectError();
      return null;
    }
  };

  const detectError = async () => {
    if (!model) return; // Don't run if model isn't ready

    try {
      const input = tf.tensor2d([[1, 0, 1, 0, 1]]); // Example input
      const prediction = model.predict(input);
      const result = (await prediction.data())[0];

      if (result > 0.5) {
        setErrorDetected(true);
      }
    } catch (error) {
      console.error("AI Error Detection Failed:", error);
    }
  };

  return (
    <div
      ref={cardRef}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {errorDetected && (
        <p style={{ color: "red" }}>⚠ AI Detected a Runtime Error!</p>
      )}
      {safeFind() ? (
        <>
          <div className="lists-all">
            <div className="row">
              {(() => {
                // Normalize the searched date
                const formOutboundDate = initialForm?.segment?.[0]?.travelDate
                  ? new Date(initialForm.segment[0].travelDate).toDateString()
                  : null;
                const formInboundDate = initialForm?.segment?.[1]?.travelDate
                  ? new Date(initialForm.segment[1].travelDate).toDateString()
                  : null;

                // Normalize the flight dates
                const outboundDepDate = outBound?.[0]?.depDate
                  ? new Date(outBound[0].depDate).toDateString()
                  : null;
                const inboundReachDate =
                  initialForm?.tripType === "2" && inBound?.[0]?.depDate
                    ? new Date(inBound[0].depDate).toDateString()
                    : null;
                const isAlternateOutboundDate =
                  formOutboundDate &&
                  outboundDepDate &&
                  formOutboundDate !== outboundDepDate;
                const isAlternateInboundDate =
                  formInboundDate &&
                  inboundReachDate &&
                  formInboundDate !== inboundReachDate;
                // Check for Alternate Date
                const isAlternateDate =
                  formOutboundDate !== outboundDepDate ||
                  (formInboundDate && formInboundDate !== inboundReachDate);

                // Check for Change of Airport
                let hasChangeOfAirport = false;
                for (let i = 0; i < outBound?.length - 1; i++) {
                  if (outBound[i].toAirport !== outBound[i + 1].fromAirport) {
                    hasChangeOfAirport = true;
                    // setAirportChange(true);
                    break;
                  }
                }

                if (isAlternateOutboundDate) {
                  setAlternateDep(true);
                }

                if (isAlternateInboundDate) {
                  setAlternateRet(true);
                }

                // Check if any content should be displayed
                if (!isAlternateDate && !hasChangeOfAirport) {
                  return null; // Hide the entire div
                }

                return (
                  <div class="col-lg-12 swrq">
                    <div className="hujdgt">
                      {isAlternateDate && <span>Alternate Date</span>}
                      {hasChangeOfAirport && <span>Change of Airport</span>}
                    </div>
                  </div>
                );
              })()}

              <div className="col-lg-9 p-0 align-self-center wrerew">
                {outBound?.length > 0 && (
                  <div className="dort ">
                    <div className="row">
                      <div className="col-lg-3 ps-0 pe-1">
                        <div className="d-flex align-items-center">
                          <img
                            className="hftrr"
                            src={`https://imgfolders.com/farehutz/${outBound[0]?.airline}.png`}
                            alt=""
                          />
                          <p className="ikrtr">
                            {airlinesName[outBound[0]?.airline]}
                          </p>
                        </div>
                        {outBound[0]?.airline !==
                          outBound[0]?.opratingAirline && (
                          <div className="jjeisr4">
                            <span>Operated by :</span>{" "}
                            <span
                              className="ioheri"
                              title={`${
                                airlinesName[outBound[0]?.opratingAirline]
                              }`}
                            >
                              {airlinesName[outBound[0]?.opratingAirline]}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="col-lg-9 pe-lg-1 ps-lg-1 p-0">
                        <div className="rihr">
                          <div className="row">
                            <div className="col-4">
                              <p
                                className={`edjhire ${
                                  alternateDep ? "sdt4e" : ""
                                }`}
                              >
                                {new Date(
                                  outBound[0]?.depDate
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: true,
                                })}
                              </p>
                              <p className="kijdte mb-0">
                                {outBound[0]?.fromAirport}
                                <span>
                                  (
                                  {outBound[0]?.fromAirport &&
                                    airports.find(
                                      (a) =>
                                        a.airportCode ===
                                        outBound[0]?.fromAirport
                                    )?.cityName}
                                  )
                                </span>
                              </p>
                            </div>
                            <div className="col-4 text-center">
                              <p className="ikdhte mb-0">
                                {convertMinutesToHoursAndMinutes(
                                  outBound[0]?.estimateTime
                                )}
                              </p>

                              <div className="kiejr">
                                {outBound?.length === 1 ? (
                                  // Case 1: Nothing to display for length === 1
                                  ""
                                ) : outBound?.length === 2 ? (
                                  // Case 2: Display edt block when length > 1 but less than 2
                                  <>
                                    <div className="edt"></div>
                                    <div className="dsfed4e">
                                      <div className="d-flex align-items-center">
                                        <div className="deut text-start me-3">
                                          <p className="mb-0">Layover Time</p>
                                          <p className="mb-0">Stop 1</p>
                                        </div>
                                        <div className="jkhsdt text-start">
                                          <p className="mb-0">
                                            {convertMinutesToHoursAndMinutes(
                                              outBound[0]?.layOverTime
                                            )}
                                          </p>
                                          <p className="mb-0">
                                            {outBound[0]?.toAirport &&
                                              airports.find(
                                                (a) =>
                                                  a.airportCode ===
                                                  outBound[0]?.toAirport
                                              )?.airportName}
                                            ({outBound[0]?.toAirport})
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : outBound?.length === 3 ? (
                                  // Case 3: Display full block for length >= 2
                                  <>
                                    {outBound?.length > 2 && (
                                      <>
                                        <div className="two1"></div>
                                        <div className="two2"></div>
                                        <div className="dsfed4e">
                                          <div className="d-flex align-items-center mb-2">
                                            <div className="deut text-start me-3">
                                              <p className="mb-0">
                                                Layover Time
                                              </p>
                                              <p className="mb-0">Stop 1</p>
                                            </div>
                                            <div className="jkhsdt text-start">
                                              <p className="mb-0">
                                                {convertMinutesToHoursAndMinutes(
                                                  outBound[0]?.layOverTime
                                                )}
                                              </p>
                                              <p className="mb-0">
                                                {outBound[0]?.toAirport &&
                                                  airports.find(
                                                    (a) =>
                                                      a.airportCode ===
                                                      outBound[0]?.toAirport
                                                  )?.airportName}
                                                ({outBound[0]?.toAirport})
                                              </p>
                                            </div>
                                          </div>
                                          <div className="d-flex align-items-center">
                                            <div className="deut text-start me-3">
                                              <p className="mb-0">
                                                Layover Time
                                              </p>
                                              <p className="mb-0">Stop 2</p>
                                            </div>
                                            <div className="jkhsdt text-start">
                                              <p className="mb-0">
                                                {convertMinutesToHoursAndMinutes(
                                                  outBound[outBound?.length - 2]
                                                    ?.layOverTime
                                                )}
                                              </p>
                                              <p className="mb-0">
                                                {outBound[outBound?.length - 2]
                                                  ?.toAirport &&
                                                  airports.find(
                                                    (a) =>
                                                      a.airportCode ===
                                                      outBound[
                                                        outBound?.length - 2
                                                      ]?.toAirport
                                                  )?.airportName}
                                                (
                                                {
                                                  outBound[outBound?.length - 2]
                                                    ?.toAirport
                                                }
                                                )
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {outBound?.length > 3 && (
                                      <>
                                        <div className="edt"></div>
                                        <div className="two1"></div>
                                        <div className="two2"></div>
                                        <div className="dsfed4e">
                                          <div className="d-flex align-items-center mb-2">
                                            <div className="deut text-start me-3">
                                              <p className="mb-0">
                                                Layover Time
                                              </p>
                                              <p className="mb-0">Stop 1</p>
                                            </div>
                                            <div className="jkhsdt text-start">
                                              <p className="mb-0">
                                                {convertMinutesToHoursAndMinutes(
                                                  outBound[0]?.layOverTime
                                                )}
                                              </p>
                                              <p className="mb-0">
                                                {outBound[0]?.toAirport &&
                                                  airports.find(
                                                    (a) =>
                                                      a.airportCode ===
                                                      outBound[0]?.toAirport
                                                  )?.airportName}
                                                ({outBound[0]?.toAirport})
                                              </p>
                                            </div>
                                          </div>
                                          <div className="d-flex align-items-center">
                                            <div className="deut text-start me-3">
                                              <p className="mb-0">
                                                Layover Time
                                              </p>
                                              <p className="mb-0">Stop 2</p>
                                            </div>
                                            <div className="jkhsdt text-start">
                                              <p className="mb-0">
                                                {convertMinutesToHoursAndMinutes(
                                                  outBound[outBound?.length - 3]
                                                    ?.layOverTime
                                                )}
                                              </p>
                                              <p className="mb-0">
                                                {outBound[outBound?.length - 3]
                                                  ?.toAirport &&
                                                  airports.find(
                                                    (a) =>
                                                      a.airportCode ===
                                                      outBound[
                                                        outBound?.length - 3
                                                      ]?.toAirport
                                                  )?.airportName}
                                                (
                                                {
                                                  outBound[outBound?.length - 3]
                                                    ?.toAirport
                                                }
                                                )
                                              </p>
                                            </div>
                                          </div>
                                          <div className="d-flex align-items-center">
                                            <div className="deut text-start me-3">
                                              <p className="mb-0">
                                                Layover Time
                                              </p>
                                              <p className="mb-0">Stop 3</p>
                                            </div>
                                            <div className="jkhsdt text-start">
                                              <p className="mb-0">
                                                {convertMinutesToHoursAndMinutes(
                                                  outBound[outBound?.length - 2]
                                                    ?.layOverTime
                                                )}
                                              </p>
                                              <p className="mb-0">
                                                {outBound[outBound?.length - 2]
                                                  ?.toAirport &&
                                                  airports.find(
                                                    (a) =>
                                                      a.airportCode ===
                                                      outBound[
                                                        outBound?.length - 2
                                                      ]?.toAirport
                                                  )?.airportName}
                                                (
                                                {
                                                  outBound[outBound?.length - 2]
                                                    ?.toAirport
                                                }
                                                )
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </>
                                )}
                              </div>

                              <p className="ikdhte mb-0">
                                {outBound?.length === 1
                                  ? "Non-stop"
                                  : `${outBound?.length - 1} Stop${
                                      outBound?.length > 2 ? "s" : ""
                                    }`}
                              </p>
                            </div>
                            <div className="col-4 text-end rela">
                              <p className="mb-0 jhrt5">
                                {(() => {
                                  const depDate = new Date(
                                    outBound[0]?.depDate
                                  );
                                  const reachDate = new Date(
                                    outBound[outBound?.length - 1]?.reachDate
                                  );

                                  // Normalize the dates to midnight to accurately compare days
                                  const depDateMidnight = new Date(
                                    depDate.getFullYear(),
                                    depDate.getMonth(),
                                    depDate.getDate()
                                  );
                                  const reachDateMidnight = new Date(
                                    reachDate.getFullYear(),
                                    reachDate.getMonth(),
                                    reachDate.getDate()
                                  );

                                  const timeDifference =
                                    reachDateMidnight - depDateMidnight; // Time difference in milliseconds
                                  const dayDifference =
                                    timeDifference / (1000 * 60 * 60 * 24); // Convert to days

                                  return dayDifference > 0
                                    ? `+${dayDifference} Day${
                                        dayDifference > 1 ? "s" : ""
                                      }`
                                    : "";
                                })()}
                              </p>
                              <p className="edjhire">
                                {new Date(
                                  outBound[outBound?.length - 1]?.reachDate
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: true,
                                })}
                              </p>
                              <p className="kijdte mb-0">
                                {outBound[outBound?.length - 1]?.toAirport}
                                <span>
                                  (
                                  {outBound[outBound?.length - 1]?.toAirport &&
                                    airports.find(
                                      (a) =>
                                        a.airportCode ===
                                        outBound[outBound?.length - 1]
                                          ?.toAirport
                                    )?.cityName}
                                  )
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {inBound?.length > 0 && (
                  <div className="dort ">
                    <div className="row">
                      <div className="col-lg-3 ps-0 pe-1">
                        <div className="d-flex align-items-center">
                          <img
                            className="hftrr"
                            src={`https://imgfolders.com/farehutz/${inBound[0]?.airline}.png`}
                            alt=""
                          />
                          <p className="ikrtr">
                            {airlinesName[inBound[0]?.airline]}
                          </p>
                        </div>
                        {inBound[0]?.airline !==
                          inBound[0]?.opratingAirline && (
                          <div className="jjeisr4">
                            <span>Operated by :</span>{" "}
                            <span
                              className="ioheri"
                              title={`${
                                airlinesName[inBound[0]?.opratingAirline]
                              }`}
                            >
                              {airlinesName[inBound[0]?.opratingAirline]}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="col-lg-9 pe-1 ps-1">
                        <div className="rihr">
                          <div className="row">
                            <div className="col-4">
                              <p
                                className={`edjhire ${
                                  alternateRet ? "sdt4e" : ""
                                }`}
                              >
                                {new Date(
                                  inBound[0]?.depDate
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: true,
                                })}
                              </p>
                              <p className="kijdte mb-0">
                                {inBound[0]?.fromAirport}
                                <span>
                                  (
                                  {inBound[0]?.fromAirport &&
                                    airports.find(
                                      (a) =>
                                        a.airportCode ===
                                        inBound[0]?.fromAirport
                                    )?.cityName}
                                  )
                                </span>
                              </p>
                            </div>
                            <div className="col-4 text-center">
                              <p className="ikdhte mb-0">
                                {convertMinutesToHoursAndMinutes(
                                  inBound[0]?.estimateTime
                                )}
                              </p>
                              <div className="kiejr">
                                {inBound?.length === 1 ? (
                                  // Case 1: Nothing to display for length === 1
                                  ""
                                ) : inBound?.length === 2 ? (
                                  // Case 2: Display edt block when length > 1 but less than 2
                                  <>
                                    <div className="edt"></div>
                                    <div className="dsfed4e">
                                      <div className="d-flex align-items-center">
                                        <div className="deut text-start me-3">
                                          <p className="mb-0">Layover Time</p>
                                          <p className="mb-0">Stop 1</p>
                                        </div>
                                        <div className="jkhsdt text-start">
                                          <p className="mb-0">
                                            {convertMinutesToHoursAndMinutes(
                                              inBound[0]?.layOverTime
                                            )}
                                          </p>
                                          <p className="mb-0">
                                            {inBound[0]?.toAirport &&
                                              airports.find(
                                                (a) =>
                                                  a.airportCode ===
                                                  inBound[0]?.toAirport
                                              )?.airportName}
                                            ({inBound[0]?.toAirport})
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : inBound?.length === 3 ? (
                                  // Case 3: Display full block for length >= 2
                                  <>
                                    {inBound?.length > 2 && (
                                      <>
                                        <div className="two1"></div>
                                        <div className="two2"></div>
                                        <div className="dsfed4e">
                                          <div className="d-flex align-items-center mb-2">
                                            <div className="deut text-start me-3">
                                              <p className="mb-0">
                                                Layover Time
                                              </p>
                                              <p className="mb-0">Stop 1</p>
                                            </div>
                                            <div className="jkhsdt text-start">
                                              <p className="mb-0">
                                                {convertMinutesToHoursAndMinutes(
                                                  inBound[0]?.layOverTime
                                                )}
                                              </p>
                                              <p className="mb-0">
                                                {inBound[0]?.toAirport &&
                                                  airports.find(
                                                    (a) =>
                                                      a.airportCode ===
                                                      inBound[0]?.toAirport
                                                  )?.airportName}
                                                ({inBound[0]?.toAirport})
                                              </p>
                                            </div>
                                          </div>
                                          <div className="d-flex align-items-center">
                                            <div className="deut text-start me-3">
                                              <p className="mb-0">
                                                Layover Time
                                              </p>
                                              <p className="mb-0">Stop 2</p>
                                            </div>
                                            <div className="jkhsdt text-start">
                                              <p className="mb-0">
                                                {convertMinutesToHoursAndMinutes(
                                                  inBound[inBound?.length - 2]
                                                    ?.layOverTime
                                                )}
                                              </p>
                                              <p className="mb-0">
                                                {inBound[inBound?.length - 2]
                                                  ?.toAirport &&
                                                  airports.find(
                                                    (a) =>
                                                      a.airportCode ===
                                                      inBound[
                                                        inBound?.length - 2
                                                      ]?.toAirport
                                                  )?.airportName}
                                                (
                                                {
                                                  inBound[inBound?.length - 2]
                                                    ?.toAirport
                                                }
                                                )
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {inBound?.length > 3 && (
                                      <>
                                        <div className="edt"></div>
                                        <div className="two1"></div>
                                        <div className="two2"></div>
                                        <div className="dsfed4e">
                                          <div className="d-flex align-items-center mb-2">
                                            <div className="deut text-start me-3">
                                              <p className="mb-0">
                                                Layover Time
                                              </p>
                                              <p className="mb-0">Stop 1</p>
                                            </div>
                                            <div className="jkhsdt text-start">
                                              <p className="mb-0">
                                                {convertMinutesToHoursAndMinutes(
                                                  inBound[0]?.layOverTime
                                                )}
                                              </p>
                                              <p className="mb-0">
                                                {inBound[0]?.toAirport &&
                                                  airports.find(
                                                    (a) =>
                                                      a.airportCode ===
                                                      inBound[0]?.toAirport
                                                  )?.airportName}
                                                ({inBound[0]?.toAirport})
                                              </p>
                                            </div>
                                          </div>
                                          <div className="d-flex align-items-center">
                                            <div className="deut text-start me-3">
                                              <p className="mb-0">
                                                Layover Time
                                              </p>
                                              <p className="mb-0">Stop 2</p>
                                            </div>
                                            <div className="jkhsdt text-start">
                                              <p className="mb-0">
                                                {convertMinutesToHoursAndMinutes(
                                                  inBound[inBound?.length - 3]
                                                    ?.layOverTime
                                                )}
                                              </p>
                                              <p className="mb-0">
                                                {inBound[inBound?.length - 3]
                                                  ?.toAirport &&
                                                  airports.find(
                                                    (a) =>
                                                      a.airportCode ===
                                                      inBound[
                                                        inBound?.length - 3
                                                      ]?.toAirport
                                                  )?.airportName}
                                                (
                                                {
                                                  inBound[inBound?.length - 3]
                                                    ?.toAirport
                                                }
                                                )
                                              </p>
                                            </div>
                                          </div>
                                          <div className="d-flex align-items-center">
                                            <div className="deut text-start me-3">
                                              <p className="mb-0">
                                                Layover Time
                                              </p>
                                              <p className="mb-0">Stop 3</p>
                                            </div>
                                            <div className="jkhsdt text-start">
                                              <p className="mb-0">
                                                {convertMinutesToHoursAndMinutes(
                                                  inBound[inBound?.length - 2]
                                                    ?.layOverTime
                                                )}
                                              </p>
                                              <p className="mb-0">
                                                {inBound[inBound?.length - 2]
                                                  ?.toAirport &&
                                                  airports.find(
                                                    (a) =>
                                                      a.airportCode ===
                                                      inBound[
                                                        inBound?.length - 2
                                                      ]?.toAirport
                                                  )?.airportName}
                                                (
                                                {
                                                  inBound[inBound?.length - 2]
                                                    ?.toAirport
                                                }
                                                )
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                              <p className="ikdhte mb-0">
                                {inBound?.length === 1
                                  ? "Non-stop"
                                  : `${inBound?.length - 1} Stop${
                                      inBound?.length > 2 ? "s" : ""
                                    }`}
                              </p>
                            </div>
                            <div className="col-4 text-end rela">
                              <p className="mb-0 jhrt5">
                                {(() => {
                                  const depDate = new Date(inBound[0]?.depDate);
                                  const reachDate = new Date(
                                    inBound[inBound?.length - 1]?.reachDate
                                  );

                                  // Normalize the dates to midnight to accurately compare days
                                  const depDateMidnight = new Date(
                                    depDate.getFullYear(),
                                    depDate.getMonth(),
                                    depDate.getDate()
                                  );
                                  const reachDateMidnight = new Date(
                                    reachDate.getFullYear(),
                                    reachDate.getMonth(),
                                    reachDate.getDate()
                                  );

                                  const timeDifference =
                                    reachDateMidnight - depDateMidnight; // Time difference in milliseconds
                                  const dayDifference =
                                    timeDifference / (1000 * 60 * 60 * 24); // Convert to days

                                  return dayDifference > 0
                                    ? `+${dayDifference} Day${
                                        dayDifference > 1 ? "s" : ""
                                      }`
                                    : "";
                                })()}
                              </p>
                              <p className="edjhire">
                                {new Date(
                                  inBound[inBound?.length - 1]?.reachDate
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: true,
                                })}
                              </p>
                              <p className="kijdte mb-0">
                                {inBound[inBound?.length - 1]?.toAirport}
                                <span>
                                  (
                                  {inBound[inBound?.length - 1]?.toAirport &&
                                    airports.find(
                                      (a) =>
                                        a.airportCode ===
                                        inBound[inBound?.length - 1]?.toAirport
                                    )?.cityName}
                                  )
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-lg-3 text-md-end dstegr4 my-2 ">
                <div className="row align-items-center">
                  <div className="col-7 col-lg-12">
                    <p className="ikhee">
                      {cabinClass === 1
                        ? "Economy"
                        : cabinClass === 2
                        ? "Premium Economy"
                        : cabinClass === 3
                        ? "Business"
                        : cabinClass === 4
                        ? "First"
                        : ""}
                    </p>
                    <p className="ikhere">
                      USD {formatPriceWithSuperscript(roundedPrice)}
                      {/*<sup>66</sup>*/}
                    </p>
                    <p className="oejrsxr mb-1">Price Per Adult</p>
                    {/* <p className="iyedt">(incl, taxes & fees)</p> */}
                  </div>
                  <div className="col-5 col-lg-12">
                    <button
                      className="idrhts"
                      onClick={() =>
                        handleFlightSelect({
                          resultId,
                          airline,
                          cabinClass,
                          currencyCode,
                          fare,
                          fareType,
                          gdsType,
                          inBound,
                          inEFT,
                          index,
                          maxSeat,
                          outBound,
                          outEFT,
                          valCarrier,
                          airports,
                        })
                      }
                    >
                      Select
                    </button>
                  </div>
                </div>
              </div>
              <hr className="mb-2" />
              <div className="col-lg-9 align-self-center">
                <p
                  className="mb-2 et4ft"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setItineraryOpen(!itineraryOpen);
                  }}
                >
                  {itineraryOpen ? "Close Details" : "Flight Details"}{" "}
                  <img
                    src={downh3}
                    className={itineraryOpen ? "rotate-180" : ""}
                    alt=""
                  />
                </p>
              </div>
              <div className="col-lg-3 bagdis">
                <div className="d-flex justify-content-end">
                  <div className="jgsr select-jige">
                    <img src={lungage1} alt="" />
                  </div>
                  <div className="jgsr">
                    <img src={lungage2} alt="" />
                  </div>
                  <div className="jgsr">
                    <img src={lungage3} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {itineraryOpen ? (
            <div className="usyhrt">
              <div className="jda34">
                <div className="jtest24">
                  <div className="row align-items-center pt-3 px-2 pb-2">
                    <div className="col-2 col-lg-6">
                      <p className="mb-0 dstge3">Depart</p>
                    </div>
                    <div className="col-10 col-lg-6 text-end">
                      <p className="mb-0 dt54s">
                        Total Trip time:{" "}
                        {convertMinutesToHoursAndMinutes(
                          outBound[0]?.estimateTime
                        )}
                      </p>
                    </div>
                  </div>
                  {outBound.map((flight, index) => (
                    <>
                      <div key={index}>
                        <div className="row px-2">
                          <div className="col-8 col-lg-6">
                            <div className="d-flex align-items-center">
                              <img
                                className="hftrr"
                                src={`https://imgfolders.com/farehutz/${flight?.airlineName}.png`}
                                alt={flight?.airlineName}
                              />
                              <div className="iksr">
                                <p className="ikrtr">
                                  {airlinesName[flight?.airlineName]}
                                </p>
                                <p className="mb-0 dst342">
                                  {" "}
                                  {flight?.airlineName} {flight?.flightNo} |
                                  Aircraft{" "}
                                  <span>
                                    {flight?.equipmentType}{" "}
                                    {/* <p className="dsfed4">
                                Embraer EMB 175 94-106 STD Seats
                              </p> */}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-4 col-lg-6 text-end">
                            <p className="mb-0 dt54s">
                              {" "}
                              <span>
                                {flight?.cabinClass === 1
                                  ? "Economy"
                                  : flight?.cabinClass === 2
                                  ? "Premium Economy"
                                  : flight?.cabinClass === 3
                                  ? "Business"
                                  : flight?.cabinClass === 4
                                  ? "First"
                                  : ""}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="row px-2">
                          <div className="col-lg-12">
                            {flight?.airline !== flight?.opratingAirline && (
                              <div className="jjeisr4">
                                <span>Operated by :</span>{" "}
                                <span className="ioheri">
                                  {airlinesName[flight?.opratingAirline]}
                                </span>
                              </div>
                            )}
                            <p className="jioshr mb-0">
                              Flight Duration:{" "}
                              {convertMinutesToHoursAndMinutes(flight?.eft)}{" "}
                              <span></span>
                            </p>

                            <div className="row align-items-center">
                              <div className="col-2 col-lg-1">
                                <div className="dtges"></div>
                                <div className="destde"></div>
                                <div className="iehte3"></div>
                              </div>
                              <div className="col-10 col-lg-11">
                                <div className="row my-3">
                                  <div className="col-lg-2">
                                    <p
                                      className={`edjhire ${
                                        alternateDep ? "sdt4e" : ""
                                      }`}
                                    >
                                      {new Date(
                                        flight?.depDate
                                      ).toLocaleTimeString([], {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: true,
                                      })}
                                    </p>
                                    <p className="kijdte mb-0">
                                      {
                                        formatDateAndTime(flight?.depDate)
                                          .formattedDateString
                                      }
                                    </p>
                                  </div>
                                  <div className="col-lg-10">
                                    <p className="edjhire">
                                      {flight?.fromAirport &&
                                        airports.find(
                                          (a) =>
                                            a.airportCode ===
                                            flight?.fromAirport
                                        )?.airportName}{" "}
                                      Airport,
                                    </p>
                                    <p className="kijdte mb-0">
                                      {flight?.fromAirport},{" "}
                                      {flight?.fromAirport &&
                                        airports.find(
                                          (a) =>
                                            a.airportCode ===
                                            flight?.fromAirport
                                        )?.countryName}
                                    </p>
                                  </div>
                                </div>
                                <div className="row my-3 gap-2 bagdis">
                                  <div className="det4">
                                    <img
                                      className="ihtg45"
                                      src={lungage1}
                                      alt=""
                                    />
                                    <p className="mb-0">
                                      Personal Item (45 x 35 x 20 cm)
                                    </p>
                                  </div>
                                  <div className="det4 edtt">
                                    <img
                                      className="ihtg45"
                                      src={lungage2}
                                      alt=""
                                    />
                                    <p className="mb-0">No Carry on bag</p>
                                    <img className="ms-3" src={dfge5} alt="" />
                                  </div>
                                  <div className="det4 edtt">
                                    <img
                                      className="ihtg45"
                                      src={lungage3}
                                      alt=""
                                    />
                                    <p className="mb-0">No checked bag</p>
                                    <img className="ms-3" src={dfge5} alt="" />
                                  </div>
                                </div>
                                <div className="row my-3">
                                  <div className="col-lg-2">
                                    <p className="edjhire">
                                      {new Date(
                                        flight?.reachDate
                                      ).toLocaleTimeString([], {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: true,
                                      })}
                                    </p>
                                    <p className="kijdte mb-0">
                                      {
                                        formatDateAndTime(flight?.reachDate)
                                          .formattedDateString
                                      }
                                    </p>
                                  </div>
                                  <div className="col-lg-10">
                                    <p className="edjhire  dseaf rela">
                                      {flight?.toAirport &&
                                        airports.find(
                                          (a) =>
                                            a.airportCode === flight?.toAirport
                                        )?.airportName}{" "}
                                      Airport,
                                      <p className="mb-0 jhrt5">
                                        {(() => {
                                          const depDate = new Date(
                                            flight?.depDate
                                          );
                                          const reachDate = new Date(
                                            flight?.reachDate
                                          );

                                          // Normalize the dates to midnight to accurately compare days
                                          const depDateMidnight = new Date(
                                            depDate.getFullYear(),
                                            depDate.getMonth(),
                                            depDate.getDate()
                                          );
                                          const reachDateMidnight = new Date(
                                            reachDate.getFullYear(),
                                            reachDate.getMonth(),
                                            reachDate.getDate()
                                          );

                                          const timeDifference =
                                            reachDateMidnight - depDateMidnight; // Time difference in milliseconds
                                          const dayDifference =
                                            timeDifference /
                                            (1000 * 60 * 60 * 24); // Convert to days

                                          return dayDifference > 0
                                            ? `+${dayDifference} Day${
                                                dayDifference > 1 ? "s" : ""
                                              }`
                                            : "";
                                        })()}
                                      </p>
                                    </p>
                                    <p className="kijdte mb-0">
                                      {flight?.toAirport},{" "}
                                      {flight?.toAirport &&
                                        airports.find(
                                          (a) =>
                                            a.airportCode === flight?.toAirport
                                        )?.countryName}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {flight?.layOverTime > 0 && (
                        <div className="row align-items-center et4dsf">
                          <div className="col-1">
                            <div className="jgdr">
                              <img src={apwhu7} alt="" />
                            </div>
                          </div>
                          <div className="col-11">
                            <p className="mb-0 dser34">
                              Layover:{" "}
                              {convertMinutesToHoursAndMinutes(
                                flight?.layOverTime
                              )}{" "}
                              <span>
                                {flight?.toAirport &&
                                  airports.find(
                                    (a) => a.airportCode === flight?.toAirport
                                  )?.airportName}{" "}
                                ({flight?.toAirport})
                              </span>
                              {flight?.toAirport !==
                                outBound[index + 1]?.fromAirport && (
                                <span className="hawurh">
                                  Change of Airport Required
                                </span>
                              )}
                            </p>
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                </div>
                <hr className="jhed24" />
                <div className="jtest24 pb-3">
                  <div className="row align-items-center px-2 pb-2">
                    {inBound?.length > 0 && (
                      <div className="col-2 col-lg-6">
                        <p className="mb-0 dstge3">Return</p>
                      </div>
                    )}
                    <div className="col-10 col-lg-6 text-end">
                      {inBound?.length > 0 && (
                        <p className="mb-0 dt54s">
                          Total Trip time:{" "}
                          {convertMinutesToHoursAndMinutes(
                            inBound[0].estimateTime
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                  {inBound?.map((flight, index) => (
                    <>
                      <div key={index}>
                        <div className="row px-2">
                          <div className="col-8 col-lg-6">
                            <div className="d-flex align-items-center">
                              <img
                                className="hftrr"
                                src={`https://imgfolders.com/farehutz/${flight?.airlineName}.png`}
                                alt=""
                              />
                              <div className="iksr">
                                <p className="ikrtr">
                                  {airlinesName[flight?.airlineName]}
                                </p>
                                <p className="mb-0 dst342">
                                  {flight?.airlineName} {flight?.flightNo} |
                                  Aircraft{" "}
                                  <span>
                                    {flight?.equipmentType}{" "}
                                    {/* <p className="dsfed4">
                                Embraer EMB 175 94-106 STD Seats
                              </p> */}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-4 col-lg-6 text-end">
                            <p className="mb-0 dt54s">
                              {" "}
                              <span>
                                {flight?.cabinClass === 1
                                  ? "Economy"
                                  : flight?.cabinClass === 2
                                  ? "Premium Economy"
                                  : flight?.cabinClass === 3
                                  ? "Business"
                                  : flight?.cabinClass === 4
                                  ? "First"
                                  : ""}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="row px-2">
                          <div className="col-lg-12">
                            {flight?.airline !== flight?.opratingAirline && (
                              <div className="jjeisr4">
                                <span>Operated by :</span>{" "}
                                <span className="ioheri">
                                  {airlinesName[flight?.opratingAirline]}
                                </span>
                              </div>
                            )}
                            <p className="jioshr mb-0">
                              Flight Duration:{" "}
                              {convertMinutesToHoursAndMinutes(flight?.eft)}{" "}
                              <span></span>
                            </p>

                            <div className="row align-items-center">
                              <div className="col-2 col-lg-1">
                                <div className="dtges"></div>
                                <div className="destde"></div>
                                <div className="iehte3"></div>
                              </div>
                              <div className="col-10 col-lg-11">
                                <div className="row my-3">
                                  <div className="col-lg-2">
                                    <p
                                      className={`edjhire ${
                                        alternateRet ? "sdt4e" : ""
                                      }`}
                                    >
                                      {new Date(
                                        flight?.depDate
                                      ).toLocaleTimeString([], {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: true,
                                      })}
                                    </p>
                                    <p className="kijdte mb-0">
                                      {
                                        formatDateAndTime(flight?.depDate)
                                          .formattedDateString
                                      }
                                    </p>
                                  </div>
                                  <div className="col-lg-10">
                                    <p className="edjhire">
                                      {flight?.fromAirport &&
                                        airports.find(
                                          (a) =>
                                            a.airportCode ===
                                            flight?.fromAirport
                                        )?.airportName}{" "}
                                      Airport,
                                    </p>
                                    <p className="kijdte mb-0">
                                      {flight?.fromAirport},{" "}
                                      {flight?.fromAirport &&
                                        airports.find(
                                          (a) =>
                                            a.airportCode ===
                                            flight?.fromAirport
                                        )?.countryName}
                                    </p>
                                  </div>
                                </div>
                                <div className="row my-3 gap-2 bagdis">
                                  <div className="det4">
                                    <img
                                      className="ihtg45"
                                      src={lungage1}
                                      alt=""
                                    />
                                    <p className="mb-0">
                                      Personal Item (45 x 35 x 20 cm)
                                    </p>
                                  </div>
                                  <div className="det4 edtt">
                                    <img
                                      className="ihtg45"
                                      src={lungage2}
                                      alt=""
                                    />
                                    <p className="mb-0">No Carry on bag</p>
                                    <img className="ms-3" src={dfge5} alt="" />
                                  </div>
                                  <div className="det4 edtt">
                                    <img
                                      className="ihtg45"
                                      src={lungage3}
                                      alt=""
                                    />
                                    <p className="mb-0">No checked bag</p>
                                    <img className="ms-3" src={dfge5} alt="" />
                                  </div>
                                </div>
                                <div className="row my-3">
                                  <div className="col-lg-2">
                                    <p className="edjhire">
                                      {new Date(
                                        flight?.reachDate
                                      ).toLocaleTimeString([], {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: true,
                                      })}
                                    </p>
                                    <p className="kijdte mb-0">
                                      {
                                        formatDateAndTime(flight?.reachDate)
                                          .formattedDateString
                                      }
                                    </p>
                                  </div>
                                  <div className="col-lg-10">
                                    <p className="edjhire dseaf rela">
                                      {flight?.toAirport &&
                                        airports.find(
                                          (a) =>
                                            a.airportCode === flight?.toAirport
                                        )?.airportName}{" "}
                                      Airport,
                                      <p className="mb-0 jhrt5">
                                        {(() => {
                                          const depDate = new Date(
                                            flight?.depDate
                                          );
                                          const reachDate = new Date(
                                            flight?.reachDate
                                          );

                                          // Normalize the dates to midnight to accurately compare days
                                          const depDateMidnight = new Date(
                                            depDate.getFullYear(),
                                            depDate.getMonth(),
                                            depDate.getDate()
                                          );
                                          const reachDateMidnight = new Date(
                                            reachDate.getFullYear(),
                                            reachDate.getMonth(),
                                            reachDate.getDate()
                                          );

                                          const timeDifference =
                                            reachDateMidnight - depDateMidnight; // Time difference in milliseconds
                                          const dayDifference =
                                            timeDifference /
                                            (1000 * 60 * 60 * 24); // Convert to days

                                          return dayDifference > 0
                                            ? `+${dayDifference} Day${
                                                dayDifference > 1 ? "s" : ""
                                              }`
                                            : "";
                                        })()}
                                      </p>
                                    </p>
                                    <p className="kijdte mb-0">
                                      {flight?.toAirport},{" "}
                                      {flight?.toAirport &&
                                        airports.find(
                                          (a) =>
                                            a.airportCode === flight?.toAirport
                                        )?.countryName}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {flight?.layOverTime > 0 && (
                        <div className="row align-items-center et4dsf">
                          <div className="col-2 col-lg-1">
                            <div className="jgdr">
                              <img src={apwhu7} alt="" />
                            </div>
                          </div>
                          <div className="col-10 col-lg-11">
                            <p className="mb-0 dser34">
                              Layover:{" "}
                              {convertMinutesToHoursAndMinutes(
                                flight?.layOverTime
                              )}{" "}
                              <span>
                                {flight?.toAirport &&
                                  airports.find(
                                    (a) => a.airportCode === flight?.toAirport
                                  )?.airportName}{" "}
                                ({flight?.toAirport})
                              </span>
                              {flight?.toAirport !==
                                inBound[index + 1]?.fromAirport && (
                                <span className="hawurh">
                                  Change of Airport Required
                                </span>
                              )}
                            </p>
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
}
