import * as tf from "@tensorflow/tfjs";

let trainedModel = null;

const trainModel = async () => {
  if (trainedModel) return trainedModel; // Prevents re-initialization

  const model = tf.sequential();
  model.add(tf.layers.dense({ units: 5, inputShape: [5], activation: "relu" }));
  model.add(tf.layers.dense({ units: 1, activation: "sigmoid" }));

  model.compile({ optimizer: "adam", loss: "binaryCrossentropy", metrics: ["accuracy"] });

  await model.fit(tf.tensor2d([[1, 0, 1, 0, 1]]), tf.tensor2d([[1]]), { epochs: 10 });

  trainedModel = model; // Store model instance
  return trainedModel;
};

export default trainModel;