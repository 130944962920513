/* eslint-disable no-unused-vars */
import React from "react";
import Footer from '../Component/Footer/Footer';
import Header from "../Component/Header/header";
import { Link } from "react-router-dom";
import MetaTitle from "../Component/MetaTitle/metaTitle";
import { constant } from "../constant";

export default function Aboutus() {
    return (
        <>
        <MetaTitle pageTitle='Learn About us - RawFares' pageDescription="RawFares takes pride in being your gateway to major destinations and the off-the-beaten-path gems that await your discovery." url={`${constant.baseUrl}/about`} />
        <Header />
        <section className='uyut7'>
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h1>About Us</h1>
                </div>
            </div>
        </div>
    </section>
        <section className='py-4'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        
                        <p>At RawFares, we transcend the ordinary and redefine the travel landscape, unlocking a world of possibilities for every adventurer. Our commitment is simple yet profound: to provide you with the best flights, not just to major destinations, but to the uncharted corners that beckon the curious spirit.</p>
                        <p>Our story begins with a passion for exploration, a love for wanderlust, and a vision to make travel accessible to all. RawFares emerged as a one-stop haven for those seeking not just flights but an immersive and cost-effective travel experience. Whether you dream of strolling through the bustling streets of a metropolis or yearn for the tranquility of a hidden gem, RawFares is your compass in the world of travel.</p>
                        <p>RawFares takes pride in being your gateway to major destinations and the off-the-beaten-path gems that await your discovery. From the iconic skylines to the hidden wonders, our platform opens doors to a myriad of destinations, each with its unique charm and allure. We believe that every journey should be a tapestry of diverse experiences, and our extensive flight options reflect this philosophy.</p>
                        <h2>Benefits of Choosing RawFares</h2>
                        <p>Understanding the nuances of airline policies can be a labyrinthine task. That's where RawFares steps in to simplify the complexities. We not only provide you with an array of flight options but also demystify airline policies, ensuring that you embark on your journey well-informed and empowered. From baggage regulations to cancellation policies, we've got you covered, giving you the confidence to navigate the skies seamlessly.</p>
                        <p>RawFares isn't just a conduit for flights; it's a curator of exclusive deals crafted to elevate your travel experience. Through strategic collaborations with airlines, we bring you deals that transcend the ordinary. Imagine unlocking discounts and perks that make your journey not only affordable but also enriched with added value. At RawFares, we believe that every traveler deserves the VIP treatment, and our exclusive deals are designed with this ethos in mind.</p>
                        <h2>Our Promise: Transparent, and Authentic</h2>
                        <p>Transparency is the cornerstone of RawFares. We understand that trust is earned, and we are committed to fostering a relationship built on authenticity. Our platform is a reflection of our dedication to providing you with accurate information, unbiased insights, and a humanized touch to the digital realm of travel planning.</p>
                        <p>Embarking on a journey with RawFares is not just about reaching a destination; it's about savoring the anticipation, relishing the discovery, and cherishing the moments in between. Our user-friendly interface ensures that finding the perfect flight is a seamless experience. Navigating through a myriad of options, exploring exclusive deals, and understanding airline policies become a joyous prelude to your upcoming adventure.</p>
                        <h2>Connect with Us</h2>
                        <p>At RawFares, we believe in the power of connection. Our customer support is not a mere service; it's a personal connection, a bridge between your queries and our dedication to ensuring your travel aspirations take flight. Have a question, a special request, or seeking advice on your travel plans? Our team is here, ready to assist and guide you every step of the way.</p>
                        <p>In the vast expanse of the digital travel realm, RawFares emerges as more than a website; it's a companion, a guide, and a facilitator of dreams. Join us as we redefine travel, one seamless journey at a time. RawFares - Navigating Dreams, Elevating Journeys.</p>
                    </div>
                </div>
                
            </div>
        </section>
        
        <Footer />
        </>
      )
};