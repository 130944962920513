/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
export default function Footer1() {
  const [utmCampaign, setUtmCampaign] = useState("");
      const [displayNumber, setDisplayNumber] = useState("");
      const jsonMapping = {
          domestics_routes: "+1-833-609-8333",
          domestics_destination: "+1-833-609-7300",
          cruise : "+1-833-609-9933",
          airline: "+1-833-609-8444"
        };
        const defaultNumber = "+1-888-415-1536"; // Default number to display if UTM data is not found
  
        useEffect(() => {
          const updateDisplayNumber = () => {
            const utmData = JSON.parse(localStorage.getItem("utm_data")) || {};
          //   console.log(utmData)
            if (utmData?.utmData?.utm_campaign) {
              // console.log("in")
              setUtmCampaign(utmData?.utm_campaign);
      
              // Check if `utm_campaign` exists in the JSON mapping
              const mappedNumber = jsonMapping[utmData?.utmData?.utm_campaign];
              setDisplayNumber(mappedNumber || defaultNumber); // Use mapped number or default
            } else {
              // console.log("out")
              // No UTM data found, use the default number
              setDisplayNumber(defaultNumber);
            }
          };
      
          // Run initially
          updateDisplayNumber();
      
          // Set up interval to check every 10 seconds
          const interval = setInterval(updateDisplayNumber, 1000000000000);
      
          // Clean up interval on component unmount
          return () => clearInterval(interval);
        }, [jsonMapping]);
        const openLink = (url) => {
          window.open(url, '_blank', 'width=650,height=550,left=300,top=50');
          };
  return (
    <>
      <section className='py-4 text-center'>
        <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <p className='mb-0 iujt4'>Rawfares is a leading travel brand operated under the trade name
                                of Holiay Breakz Group LLC (Company Registration Number (EIN):
                                201916510449). Our Cruise ticket supplier is CLIA. Authorized
                                number is 00038244 and we are affiliated with
                                Royal-Caribben-Group. We are available to provide customer
                                support on all days from Monday to Sunday. Customer Service
                                Number:{" "}
                                <Link to={`tel:${displayNumber}`}>
                                  <b className="text-decoration-underline">{displayNumber}</b>
                                </Link>{" "}
                                All users agree to Holiay Breakz Group LLC <span className="jisr4"><Link to="/terms-and-condition" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}}>Terms & Conditions</Link></span> and <span className="jisr4">
                                                              <Link to="/privacy-policy" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}}>Privacy Policy</Link>
                                                            </span>. All users are subject to <span className="jisr4"><Link to="/our-services-fee" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}}>our Service Fees</Link></span> and <span className="jisr4"><Link to="/post-ticketing-fee" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}}>Post Ticketing Fees</Link></span>. Read our Cookie Policy for details.</p>

              </div>
            </div>
        </div>
        <hr />
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                <p className='mb-0 iujt4'>Copyrights © 2022-2025 <Link to='/' target='_blank'>rawfares.com</Link>, All Rights Reserved.</p>
                </div>
            </div>
        </div>
      </section>
    </>
  )
}
