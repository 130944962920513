/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useLocation, useNavigate } from "react-router-dom";
import Footer from '../../Component/Footer/Footer';
import SearchEngine from '../../Component/Search-Engine/engine';
import sarr3 from '../../assest/images/icon/arow.png';
import {Link} from 'react-router-dom';
import usePageData from "../Flights/usePageData";
import { format } from "date-fns";
import axios from 'axios';
import Header from "../../Component/Header/header";
import { constant } from "../../constant";
import bking from '../../assest/images/icon/booking.png';
import transfr from '../../assest/images/icon/transfer.png';
import Sport from '../../assest/images/icon/support.png';
import airoyh from '../../assest/images/icon/airoj.png';
import { v4 as uuidv4 } from "uuid";
import {
  browserName,
  isAndroid,
  isDesktop,
  isIOS,
  isMobile,
} from "react-device-detect";
import MetaTitle from "../../Component/MetaTitle/metaTitle";

function formatDateAndTime(isoDate) {
  const dateOptions = {
    weekday: "short",
    month: "short",
    day: "numeric",
  };

  const timeOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const formattedDate = new Date(isoDate);
  const formattedDateString = new Intl.DateTimeFormat(
    "en-US",
    dateOptions
  ).format(formattedDate);
  const formattedTimeString = new Intl.DateTimeFormat(
    "en-US",
    timeOptions
  ).format(formattedDate);

  return { formattedDateString, formattedTimeString };
}

const formatDate = (date) => {
  return date ? format(date, "MM/dd/yyyy") : "";
};

const getYesterdayDate = () => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const options = { weekday: 'long', month: '2-digit', day: '2-digit', year: 'numeric' };
  return yesterday.toLocaleDateString('en-US', options);
};


export default function RoutesPPC() {
  const location = useLocation();
  const { response1, response2 } = location.state || {};
   const { airlineName } = useParams();
   const [utmParams, setUtmParams] = useState({});
   const pageData = usePageData();
  //  console.log("PageData", pageData)
  // console.log("Airline name", airlineName)
  const cleanedAirlineName = airlineName.replace(/^cheap-flights-from-/, '');
  const parts = cleanedAirlineName.split('-');
  
  const pageValue = parts[parts.length - 2]; // Get the second-to-last part, which is "sfo"
  const url = airlineName; // Use the full airlineName as the URL
  const navigate = useNavigate();
  const [destination, setDestination] = useState("");
  const [destinationCode, setDestinationCode] = useState("");
  const [DomesticDeals, setDomesticDeals] = useState([]);
  const [IntlDeals, setIntlDeals] = useState([]);
  const processUrl = (url) => {
    // Extract the destination city
    const parts = url.split("cheap-flights-from-")[1];
    // console.log("Parts", parts);
    if (parts) {
      const toSection = parts.split("-to-")[1]; // Get the part after '-to-'
      // console.log("toSection", toSection);
      if (toSection) {
        const cityParts = toSection.split("-"); // Split the destination section
        let cityName = cityParts.slice(0, cityParts.length - 2).join(" "); // Remove the last two parts (codes)
  
        // Format the city name: Capitalize each word
        cityName = cityName
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
        
        setDestination(cityName);
        const destinationCodeState = airlineName
    .split('-') // Split the string by '-'
    .pop() // Get the last part
    .toUpperCase(); // Convert to uppercase
    setDestinationCode(destinationCodeState)
        // console.log("Destination", destinationCodeState);
      }
    }
  };
  

  useEffect(() => {
    if (airlineName) {
      processUrl(airlineName);
    }
  }, [airlineName]);
  

  const [firstResponseData, setFirstResponseData] = useState(response1?.response[0] || null);
  const [secondResponseData, setSecondResponseData] = useState(response2 || null);
  const [searchId, setSearchId] = useState("");
  
  const [reloadData, setReloadData] = useState({
    pageType: "Routes_PPC",
    pageValue: pageValue,  // "sfo"
    siteId: "16",
    url: url               // Full URL: "cheap-flights-from-san-francisco-to-atlanta-sfo-atl"
  });

  // Other code as needed...

  useEffect(() => {
    const storeUtmData = () => {
      const queryParams = new URLSearchParams(window.location.search);
      // console.log("queryParams", queryParams)
      const utmData = {};
  
      // Iterate through query parameters and extract UTM parameters
      for (let [key, value] of queryParams.entries()) {
        if (key.startsWith("utm_")) {
          utmData[key] = value;
        }
      }
  
      if (Object.keys(utmData).length > 0) {
        const expirationTime = new Date().getTime() + 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
        const dataWithExpiration = {
          utmData,
          expiresAt: expirationTime,
        };
  
        // Store UTM data in localStorage
        localStorage.setItem("utm_data", JSON.stringify(dataWithExpiration));
        setUtmParams(utmData); // Update state
      }
    };
  
    // Execute on query parameter change
    storeUtmData();
  }, [location.search]); // Depend on the `location.search` to detect query string changes
  
  


  const fetchData = async () => {
    
    ////console.log(reloadData)
      try {
        let response;
        if (!response1) {
          // console.log("ReloadData", reloadData)
        response = await axios.post(`${constant.baseUrl}/api/ppc/flight-data`, reloadData);
        // console.log("RESS", response)
        // Set firstResponseData state with the response if response1 is null
        if (response.status === 200) {
          setFirstResponseData(response.data.response[0]);
          ////console.log("Response from the first API:", response.data);
          ////console.log(deals)
          // console.log({ froCity: response.data.response[0]?.pageValue?.toUpperCase(),
          //   toCity: destinationCode,
          //   airline: "",
          //   cabinClass:"Y",
          //   tripType:"2",
          //   page:"Domestic",
          //   currency:"USD",
          //   siteId: "16",
          //   departDate:"",
          //   countryName:"US",
          //   countryCode:"US",
          //   cityName:"" })
          const response2 = await axios.post(`${constant.baseUrl}/api/ppc/deals`,
            { froCity: response.data.response[0]?.pageValue?.toUpperCase(),
              toCity: destinationCode,
              airline: "",
              cabinClass:"Y",
              tripType:"2",
              page:"Domestic",
              currency:"USD",
              siteId: "16",
              departDate:"",
              countryName:"US",
              countryCode:"US",
              cityName:"" }
          );
          // console.log("status2", response2.status)
          if(response2.status === 200){
            setDomesticDeals(response2.data.response);
            // console.log("Domestic Deals", response2.data)
            const response3 = await axios.post(`${constant.baseUrl}/api/ppc/deals`,
              { froCity: response.data.response[0]?.pageValue?.toUpperCase(),
                toCity: destinationCode,
                airline: "",
                cabinClass:"Y",
                tripType:"2",
                page:"international",
                currency:"USD",
                siteId: "16",
                departDate:"",
                countryName:"US",
                countryCode:"US",
                cityName:"" }
            );
            if(response3.status === 200){
              setIntlDeals(response3.data.response );
            }
          }
          
          setSecondResponseData(response2.data);
          
          ////console.log("responswe23",response2);
        }
          
          
        } else {
        // Use the existing response1 if available
        setFirstResponseData(response1.response[0]);
        }
        ////console.log(response);
        // Update state with response data if needed
      } catch (error) {
        ////console.error('Error fetching data:', error);
        // Handle errors appropriately
      }
      };
    
      useEffect(() => {
      // Check if response1 or response2 is null, then call fetchData
      // if (!response1 || !response2) {
      //   fetchData();
      // }
      if (destinationCode) {
        // processUrl(airlineName);
        // console.log("name", destinationCode)
        fetchData();
      }
      }, [url, pageValue, response1, response2, airlineName, destinationCode]);
  
    const [selectedFlight, setSelectedFlight] = useState();
    useEffect(() => {
      if (secondResponseData && secondResponseData.response.length > 0) {
        setSelectedFlight(secondResponseData.response[0]);
      }
    }, [secondResponseData]);
  
    const handleFroCityClick = (item) => {
      setSelectedFlight(item);
    };
  
    const imageNames = ['albuquerque.webp', 'amsterdam.webp','atlanta.webp', 'chicago.webp', 'las-vegas.webp', 'hawaii.webp', 'charleston.webp', 'boston.webp', 'cancun.webp', 'barcelona.webp', 'orlando.webp', 'seattle.webp', 'san-jose.webp', 'dallas.webp', 'denver.webp', 'london.webp', 'paris.webp', 'rome.webp', 'tokyo.webp'];
      const filteredImages = imageNames
      .filter(name => name !== `${url}.webp`)
      .slice(0, 3); // Limiting to 3 images
  
      const imageNameToAirline = {
        'albuquerque.webp': 'albuquerque',
        'amsterdam.webp': 'amsterdam',
        'atlanta.webp': 'atlanta',
        'chicago.webp': 'chicago',
        'vas-vegas.webp': 'las-vegas',
        'hawaii.webp': 'hawaii',
        'charleston.webp': 'charleston',
        'boston.webp': 'boston',
        'cancun.webp': 'cancun',
        'barcelona.webp': 'barcelona',
        'orlando.webp' : 'orlando',
        'seattle.webp' : 'seattle',
        'san-jose.webp' : 'san-jose',
        'dallas.webp' : 'dallas',
        'denver.webp' : 'denver',
        'london.webp' : 'london',
        'paris.webp' : 'paris',
        'rome.webp' : 'rome',
        'tokyo.webp' : 'tokyo'
      };
      const imageNameToAirlineCode = {
        'albuquerque.webp': 'abq',
        'amsterdam.webp': 'ams',
        'atlanta.webp': 'atl',
        'chicago.webp': 'ord',
        'las-vegas.webp': 'las',
        'hawaii.webp': 'hi',
        'charleston.webp': 'chs',
        'boston.webp': 'bos',
        'cancun.webp': 'cun',
        'barcelona.webp': 'bcn',
        'orlando' : 'mco',
        'seattle' : 'sea',
        'san-jose' : 'sjc'
      };
  
      const handleSideClick = (pageValue, url) => {
        setReloadData({
          ...reloadData,
          pageValue,
          url,
        });
      };
  
      const getAirlineCode = (name) => {
        const airlineCodes = {
          "Albuquerque": "ABQ",
          "Barcelona": "BCN",
          "Boston": "BOS",
          "Cancun": "CUN",
          "Charleston": "CHS",
          "Hawaii": "HI",
          "Chicago": "ORD",
          "Las Vegas": "LAS",
          "Orlando": "MCO",
          "Seattle": "SEA",
          "San Jose": "SJC",
          "San Francisco": "SFO",
          "Dallas": "DFW",
          "Denver": "DEN",
          "Honolulu": "HNL",
          "Kahului": "OGG",
          "Los Angeles": "LAX",
          "Guadalajara": "GDL",
          "London": "LON",
          "Cuncun": "CUN",
          "New York": "NYC",
          "Paris": "PAR",
          "Toronto": "YYZ",
          "Rome" : "ROM",
          "Tokyo" : "HND",
          "Florida" : "FL",
          "Vancouver" : "YVR",
          "Sydney" : "SYD",
          "South Island": "CHC",
          "Newport" : "ONP",
          "Louisville": "SDF",
          "Washington" : "WAS",
          "Westchester": "HPN"
        };
      
        return airlineCodes[name];
      };
      
      // Function to create a URL with the airline code appended to the airline name
      const createURL = (name) => {
        const airlineCode = getAirlineCode(name);
        return `https://www.rawfares.com/flights/cheap-flights-to-${name?.toLowerCase().replace(/\s+/g, '-')}-${airlineCode?.toLowerCase()}`;
      };

      // const createURLRoute = (origin, destination) => {
      //   const originCode = getAirlineCode(origin);
      //   const destinationCode = getAirlineCode(destination);
      //   return `https://www.rawfares.com/flights/cheap-flights-from-${origin?.toLowerCase().replace(/\s+/g, '-')}-to-${destination?.toLowerCase().replace(/\s+/g, '-')}-${originCode?.toLowerCase()}-${destinationCode?.toLowerCase()}`;
      // };
  
    const [airlines, setAirlines] = useState({
          pageType: "Airline",
          pageValue: "",
          siteId: "16",
          url: ""
        });
  
        const [deals, setDeals] = useState({
          froCity:"",
          toCity:"",
          airline: airlines.pageValue,
          cabinClass:"Y",
          tripType:"2",
          page:"",
          currency:"USD",
          siteId: "16",
          departDate:"",
          countryName:"US",
          countryCode:"US",
          cityName:""
      });
      const standardizeAirlineName = (name) => {
        const nameVariations = {
          'albuquerque': 'Albuquerque',
          'amsterdam': 'Amsterdam',
          'atlanta': 'Atlanta',
          'chicago': 'Chicago',
          'las-vegas': 'Las Vegas',
          'hawaii': 'Hawaii',
          'charleston': 'Charleston',
          'boston': 'Boston',
          'cancun': 'Cancun',
          'barcelona': 'Barcelona',
          'orlando': 'Orlando',
          'seattle': 'Seattle',
          'san-jose': 'San Jose',
          'dallas' : 'Dallas',
          'denver' : 'Denver',
          'london' : 'London',
          'paris' : 'Paris',
          'rome' : 'Rome',
          'tokyo' : 'Tokyo'
          // Add more variations as needed
        };
        return nameVariations[name.toLowerCase()] || name; // Return the standardized name
      };
        
        // Apply the standardization to create an array of standardized airline names from filteredImages
        const airlineNamesFromImages = filteredImages.map(imageName => standardizeAirlineName(imageNameToAirline[imageName]));
  
  
        const createURLRoute = (origin, destination) => {
          const originCode = getAirlineCode(origin);
          const destinationCode = getAirlineCode(destination);
          return `https://www.rawfares.com/flights/cheap-flights-from-${origin
            ?.toLowerCase()
            .replace(/\s+/g, "-")}-to-${destination
            ?.toLowerCase()
            .replace(/\s+/g, "-")}-${originCode?.toLowerCase()}-${destinationCode?.toLowerCase()}`;
        };
      
        // Extracting the current origin and destination
        const urlParts = airlineName.split("-");
        const currentOrigin = standardizeAirlineName(urlParts[urlParts.length - 5]);
        const currentDestination = standardizeAirlineName(urlParts[urlParts.length - 3]);
      
        // console.log("currentOrigin", currentOrigin);
        // console.log("currentDestination", currentDestination);
      
        // Flight routes data
        const flightRoutes = [
          { origin: "Boston", destination: "Newport" },
          { origin: "Los Angeles", destination: "Chicago" },
          { origin: "Denver", destination: "Chicago" },
          { origin: "Honolulu", destination: "Kahului" },
          { origin: "Las Vegas", destination: "Los Angeles" },
          { origin: "Louisville", destination: "Chicago" },
          { origin: "Los Angeles", destination: "San Francisco" },
          { origin: "New York", destination: "Washington" },
          { origin: "Seattle", destination: "Boston" },
          { origin: "Westchester", destination: "Chicago" }
        ];

        const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    
    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      // Cleanup the event listener on unmount
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    const handleScrollToSitemap = () => {
      navigate('/sitemap'); // Navigate to /sitemap
    };
  // console.log(firstResponseData?.pageName)

  const [formValues, setFormValues] = useState({
      searchID: "", // Default value or dynamic value
      client: 2,
      segment: [
        {
          originAirport: "",
          destinationAirport: "",
          travelDate: "",
          originCountry: "US",
          destinationCountry: "ALL",
        },
        {
          originAirport: "",
          destinationAirport: "",
          travelDate: "",
        },
      ],
      searchDirectFlight: false,
      flexibleSearch: false,
      tripType: "2", // Default to round trip
      adults: 1,
      child: 0,
      infants: 0,
      infantsWs: 0,
      cabinType: "1",
      airline: "All",
      currencyCode: "USD",
      siteId: 16,
      source: "Online",
      media: "",
      sID: "",
      rID: "",
      locale: "en",
      isNearBy: false,
      limit: 200,
      userIP: "",
      serverIP: "",
      device: "",
      browser: "", // Define browserName if necessary
    });

    const handleDealClick = async (deal) => {
      const deviceName = isMobile
        ? "Mobile Device"
        : isDesktop
        ? "Desktop"
        : isAndroid
        ? "Android"
        : isIOS;
    
      try {
        // Fetch airport data from the API
        const searchValue = `${deal.froCity}`;
        const searchValue2 = `${deal.toCity}`;
        const airportResponse = await axios.get(
          `https://www.tgsapi.com/flights/CrossAirportList?data=${searchValue}&authcode=farehuts`
        );
        // console.log("FGBG", `https://www.tgsapi.com/flights/CrossAirportList?data=${searchValue}&authcode=farehuts`)
        const airport2Response = await axios.get(
          `https://www.tgsapi.com/flights/CrossAirportList?data=${searchValue2}&authcode=farehuts`
        );
    
        if (airportResponse?.data) {
          const airports = airportResponse.data; // Assuming the API returns an array
          const selectedOrigin = airports.find((item) => item.airportCode === deal.froCity);
    
          if (selectedOrigin) {
            localStorage.setItem("selectedOrigin", JSON.stringify(selectedOrigin));
          }
        }
        if (airport2Response?.data) {
          const airports = airport2Response.data; // Assuming the API returns an array
          const selectedDestination = airports.find((item) => item.airportCode === deal.toCity);
    
          if (selectedDestination) {
            localStorage.setItem("selectedDestination", JSON.stringify(selectedDestination));
          }
        }
    
        // Fetch user IP
        const response = await axios.get("https://api.ipify.org?format=json");
        const userIP = response.data.ip;
    
        const newSearchID = uuidv4().slice(0, 16);
        setSearchId(newSearchID);
    
        // Update form state based on the selected deal
        const updatedFormValues = {
          ...formValues,
          segment: [
            {
              originAirport: deal.froCity,
              destinationAirport: deal.toCity,
              travelDate: formatDate(deal.depDate),
              originCountry: "US",
              destinationCountry: "ALL",
            },
            {
              originAirport: deal.toCity,
              destinationAirport: deal.froCity,
              travelDate: formatDate(deal.retDate),
            },
          ],
          searchID: newSearchID,
          userIP: userIP,
          device: deviceName,
        };
    
        setFormValues(updatedFormValues);
        navigate("/listing", { state: { updatedFormValues } });
    
        // console.log("Selected deal:", updatedFormValues);
      } catch (error) {
        console.error("API fetch error:", error);
      }
    };
    

  
  const [utmCampaign, setUtmCampaign] = useState("");
        const [displayNumber, setDisplayNumber] = useState("");
        const jsonMapping = {
            domestics_routes: "+1-833-609-8333",
            domestics_destination: "+1-833-609-7300",
            cruise : "+1-833-609-9933",
            airline: "+1-833-609-8444"
          };
          const defaultNumber = "+1-888-415-1536"; // Default number to display if UTM data is not found
    
          useEffect(() => {
            const updateDisplayNumber = () => {
              const utmData = JSON.parse(localStorage.getItem("utm_data")) || {};
            //   console.log(utmData)
              if (utmData?.utmData?.utm_campaign) {
                // console.log("in")
                setUtmCampaign(utmData?.utm_campaign);
        
                // Check if `utm_campaign` exists in the JSON mapping
                const mappedNumber = jsonMapping[utmData?.utmData?.utm_campaign];
                setDisplayNumber(mappedNumber || defaultNumber); // Use mapped number or default
              } else {
                // console.log("out")
                // No UTM data found, use the default number
                setDisplayNumber(defaultNumber);
              }
            };
        
            // Run initially
            updateDisplayNumber();
        
            // Set up interval to check every 10 seconds
            const interval = setInterval(updateDisplayNumber, 1000000000000);
        
            // Clean up interval on component unmount
            return () => clearInterval(interval);
          }, [jsonMapping]);
          const maxDeals = 5;
const domesticDealsToShow = DomesticDeals?.slice(0, maxDeals);
const intlDealsToShow = IntlDeals?.slice(0, maxDeals);

// If International deals are empty and Domestic has more than 5, show extra in side section
const extraDomesticDeals = IntlDeals.length === 0 && DomesticDeals.length > maxDeals
  ? DomesticDeals.slice(maxDeals, maxDeals * 2)
  : [];

// If Domestic deals are empty and International has more than 5, show extra in side section
const extraIntlDeals = DomesticDeals.length === 0 && IntlDeals.length > maxDeals
  ? IntlDeals.slice(maxDeals, maxDeals * 2)
  : [];
  const [bgImage, setBgImage] = useState("");
  
  useEffect(() => {
    const image = new Image();
    image.src = `https://imgfolders.com/rawfaresnew/routes/${firstResponseData?.pageName}${destination}.webp`;
  
    image.onload = () => setBgImage(image.src);
    image.onerror = () =>
      setBgImage("https://imgfolders.com/rawfares/rawfaresUS/ppc/21rawfares.webp");
  }, [firstResponseData?.toCity]);
  return (
    <>
    <MetaTitle pageTitle={firstResponseData?.metaTitle} pageDescription={firstResponseData?.metaDesc} url={`${constant.baseUrl}/airline/${airlineName}`} />
    <Header />
    {firstResponseData && firstResponseData.pageName && pageData.isSpecialPage && (
            <SearchEngine headText={`Cheap Flights from ${firstResponseData?.pageName} to ${destination}`} isSpecialPage={pageData.isSpecialPage} type={pageData.type} origin={pageData.origin} destination={pageData.destination} imgSpecial={true} img={bgImage} />
    )}
    <div className="iur85ja">
    <section className='dtjrrdt wqerq45 mob-hide'>
    <div className="container">
      <Link to={`tel:${displayNumber}`}>
      <div className="row sfs34f align-items-center">
        <div className="col-lg-3">
          <p className='ghjge3'>Call us 24/7 at <span><Link to={`tel:${displayNumber}`}>{displayNumber}</Link></span></p>
          <p className='wri34d mb-0'>Need help booking? Our agents are ready! Choose from over 500 airlines.</p>
        </div>
        <div className="col-lg-9 ">
          <div className="row w-100 m-auto dftgdfty">
            <div className="col-lg-4">
              <div className="d-flex align-items-start">
              <img src={bking} alt="" />
              <div className="fdtd3">
                <p className='mb-0 e5t66'>EASY BOOKING</p>
                <p className='mb-0 dtgr6r4'>We offer easy and convenient flight booking with attractive offers.</p>
              </div>
              </div>
            </div>
            <div className="col-lg-4 rftw4">
              <div className="d-flex align-items-start">
                <img src={transfr} alt="" />
                <div className="fdtd3 ">
                  <p className='mb-0 e5t66'>LOWEST PRICE</p>
                  <p className='mb-0 dtgr6r4'>We ensure low rates on airlines reservation, holiday packages and on flight tickets.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex align-items-start">
                <img src={Sport} alt="" />
                <div className="fdtd3">
                  <p className='mb-0 e5t66'>24/7 SUPPORT</p>
                  <p className='mb-0 dtgr6r4'>Get assistance 24/7 on any kind of travel related query. We are happy to assist you.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </Link>
    </div>
  </section>
  {firstResponseData && firstResponseData.pageName && (
  <section className="py-5">
    <div className="container">
        <div className="row">
        <h2>{firstResponseData?.pageName} to {destination} Flight Deals</h2>
          <p>At Rawfares, we’re dedicated to bringing you the most incredible airfare deals that are sure to elevate your travel experience. Our exclusive offers are the best we’ve discovered, offering exceptional value for your next journey. Don’t wait—these deals won’t last long. Book now and embark on your next adventure!</p>
        {domesticDealsToShow.length > 0 && (
      <div className="col-lg-6 p-0">
        <h3 className="text-center mb-4">Top Domestic Flights</h3>
        <div className="row">
          {domesticDealsToShow.map((deal, index) => (
            <div className="col-lg-12" key={index} onClick={() => handleDealClick(deal)} style={{ cursor: "pointer" }}>
              <div className="hsgr4e">
                <div className="row">
                  <div className="col-4 col-lg-3">
                    <p className="mb-0 hr59w1">{deal.froCityName}</p>
                    <p className="mb-0 hr59w2">{deal.froCity}</p>
                    <p className="mb-0 hr59w3">{formatDate(deal.depDate)}</p>
                  </div>
                  <div className="col-4 col-lg-3">
                    <div className="hr59w6">
                      <img className="hr59w7" src={airoyh} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-lg-3">
                    <p className="mb-0 hr59w1">{deal.toCityName}</p>
                    <p className="mb-0 hr59w2">{deal.toCity}</p>
                    <p className="mb-0 hr59w3">{formatDate(deal.retDate)}</p>
                  </div>
                  <div className="col-lg-3 align-self-end hr59w8">
                    <p className="mb-0 hr59w4">USD {deal.totalPrice}</p>
                    <p className="mb-0 hr59w5">{deal.tripType === "2" ? 'Round Trip' : 'One Way'}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    )}

    {intlDealsToShow.length > 0 && (
      <div className="col-lg-6 p-0">
        <h3 className="text-center mb-4">Top International Flights</h3>
        <div className="row">
          {intlDealsToShow.map((deal, index) => (
            <div className="col-lg-12" key={index} onClick={() => handleDealClick(deal)} style={{ cursor: "pointer" }}>
              <div className="hsgr4e">
                <div className="row">
                  <div className="col-4 col-lg-3">
                    <p className="mb-0 hr59w1">{deal.froCityName}</p>
                    <p className="mb-0 hr59w2">{deal.froCity}</p>
                    <p className="mb-0 hr59w3">{formatDate(deal.depDate)}</p>
                  </div>
                  <div className="col-4 col-lg-3">
                    <div className="hr59w6">
                      <img className="hr59w7" src={airoyh} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-lg-3">
                    <p className="mb-0 hr59w1">{deal.toCityName}</p>
                    <p className="mb-0 hr59w2">{deal.toCity}</p>
                    <p className="mb-0 hr59w3">{formatDate(deal.retDate)}</p>
                  </div>
                  <div className="col-lg-3 align-self-end hr59w8">
                    <p className="mb-0 hr59w4">USD {deal.totalPrice}</p>
                    <p className="mb-0 hr59w5">{deal.tripType === "2" ? 'Round Trip' : 'One Way'}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    )}

    {/* Show extra Domestic deals if Intl is empty */}
    {extraDomesticDeals.length > 0 && (
      <div className="col-lg-6 p-0">
        <h3 className="text-center mb-4">More Domestic Flights</h3>
        <div className="row">
          {extraDomesticDeals.map((deal, index) => (
            <div className="col-lg-12" key={index} onClick={() => handleDealClick(deal)} style={{ cursor: "pointer" }}>
              <div className="hsgr4e">
                <div className="row">
                  <div className="col-4 col-lg-3">
                    <p className="mb-0 hr59w1">{deal.froCityName}</p>
                    <p className="mb-0 hr59w2">{deal.froCity}</p>
                    <p className="mb-0 hr59w3">{formatDate(deal.depDate)}</p>
                  </div>
                  <div className="col-4 col-lg-3">
                    <div className="hr59w6">
                      <img className="hr59w7" src={airoyh} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-lg-3">
                    <p className="mb-0 hr59w1">{deal.toCityName}</p>
                    <p className="mb-0 hr59w2">{deal.toCity}</p>
                    <p className="mb-0 hr59w3">{formatDate(deal.retDate)}</p>
                  </div>
                  <div className="col-lg-3 align-self-end hr59w8">
                    <p className="mb-0 hr59w4">USD {deal.totalPrice}</p>
                    <p className="mb-0 hr59w5">{deal.tripType === "2" ? 'Round Trip' : 'One Way'}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    )}

    {/* Show extra Intl deals if Domestic is empty */}
    {extraIntlDeals.length > 0 && (
      <div className="col-lg-6 p-0">
        <h3 className="text-center mb-4">More International Flights</h3>
        <div className="row">
          {extraIntlDeals.map((deal, index) => (
            <div className="col-lg-12" key={index} onClick={() => handleDealClick(deal)} style={{ cursor: "pointer" }}>
              <div className="hsgr4e">
                <div className="row">
                  <div className="col-4 col-lg-3">
                    <p className="mb-0 hr59w1">{deal.froCityName}</p>
                    <p className="mb-0 hr59w2">{deal.froCity}</p>
                    <p className="mb-0 hr59w3">{formatDate(deal.depDate)}</p>
                  </div>
                  <div className="col-4 col-lg-3">
                    <div className="hr59w6">
                      <img className="hr59w7" src={airoyh} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-lg-3">
                    <p className="mb-0 hr59w1">{deal.toCityName}</p>
                    <p className="mb-0 hr59w2">{deal.toCity}</p>
                    <p className="mb-0 hr59w3">{formatDate(deal.retDate)}</p>
                  </div>
                  <div className="col-lg-3 align-self-end hr59w8">
                    <p className="mb-0 hr59w4">USD {deal.totalPrice}</p>
                    <p className="mb-0 hr59w5">{deal.tripType === "2" ? 'Round Trip' : 'One Way'}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    )}
    {(DomesticDeals.length > 0 || IntlDeals.length > 0) && (
    <p className="abcdefg"><span className="text-primary">Note: All fares are quoted in USD.</span><br></br><br/>

Last updated on <span className="text-primary">{getYesterdayDate()} at 5:00 AM,</span> the fares mentioned above are for Round Trip flight tickets and inclusive of fuel surcharge, <span className="text-primary"><Link to='/taxes-and-fees' target="_blank">service fee and taxes</Link></span>. Based on historical data, these fares are 
subject to change without prior notice and cannot be guaranteed at the time of booking. Kindly go through out <span className="text-primary"><Link to='/terms-and-condition' target="_blank">terms and conditions</Link></span> before booking.</p>
)}
            <div className="col-lg-12">
                <h2>Flights from {firstResponseData?.pageName}({firstResponseData?.pageValue && firstResponseData.pageValue.toUpperCase()}) to {destination} ({destinationCode})</h2>
               
                <p className="mb-4" dangerouslySetInnerHTML={{
                  __html: firstResponseData?.contentData,
                }}></p>
            </div>
            

        </div>
    </div>
  </section>
    )}
    </div>
    <Footer tabopen='airline' />
    </>
  );
}