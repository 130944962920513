/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Phone from  '../../assest/images/icon/phone.png';
import logot from '../../assest/images/logo.png';
import dsatet from '../../assest/images/dsatet.webp';
import {Link} from 'react-router-dom';
export default function Header1() {
  const [utmCampaign, setUtmCampaign] = useState("");
    const [displayNumber, setDisplayNumber] = useState("");
    const jsonMapping = {
        domestics_routes: "+1-833-609-8333",
        domestics_destination: "+1-833-609-7300",
        cruise : "+1-833-609-9933",
        airline: "+1-833-609-8444"
      };
      const defaultNumber = "+1-888-415-1536"; // Default number to display if UTM data is not found
  
      useEffect(() => {
        const updateDisplayNumber = () => {
          const utmData = JSON.parse(localStorage.getItem("utm_data")) || {};
        //   console.log(utmData)
          if (utmData?.utmData?.utm_campaign) {
            // console.log("in")
            setUtmCampaign(utmData.utm_campaign);
    
            // Check if `utm_campaign` exists in the JSON mapping
            const mappedNumber = jsonMapping[utmData?.utmData?.utm_campaign];
            setDisplayNumber(mappedNumber || defaultNumber); // Use mapped number or default
          } else {
            // console.log("out")
            // No UTM data found, use the default number
            setDisplayNumber(defaultNumber);
          }
        };
    
        // Run initially
        updateDisplayNumber();
    
        // Set up interval to check every 10 seconds
        const interval = setInterval(updateDisplayNumber, 10000);
    
        // Clean up interval on component unmount
        return () => clearInterval(interval);
      }, [jsonMapping]);
  return (
    <>
    <header>
          <nav class="navbar navbar-expand-lg bg-body-tertiary dfgsdg">
  <div class="container">
    <Link class="navbar-brand" to="/">
      <img className='iutit' src={logot} alt="" />
    </Link>
    <Link className='uigds' to={`tel:${displayNumber}`}>
    <div className="d-flex align-items-end ">
        <img className='me-2 srtwe' src={dsatet} alt="" />
        <div className="dfhuje">
        <p className='mb-0 dfsdfh text-white'>Call for Phone Deals</p>
        <p className='mb-0 dfyhr5 text-white'>{displayNumber}</p>
        </div>
      </div>
      </Link>


    <button class="navbar-toggler mob-hide" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end mob-hide" id="navbarSupportedContent">
     <Link to={`tel:${displayNumber}`}>
      <div className="d-flex align-items-end justify-content-end">
        <img className='me-2' src={Phone} alt="" />
        <div className="dfhuje text-end">
        <p className='mb-0 dfsdfh'>Call 24/7 for our best deals </p>
        <p className='mb-0 dfyhr5'>{displayNumber}</p>
        </div>
      </div>
      </Link>
    </div>
  </div>
</nav>
          
    </header>
    </>
  )
}
