/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Phone from  '../../assest/images/icon/phone.png';
import logot from '../../assest/images/logo.png';
import downj from '../../assest/images/icon/down.png';
import dsatet from '../../assest/images/dsatet.webp';
import {Link} from 'react-router-dom';
export default function Header() {
  const [utmCampaign, setUtmCampaign] = useState("");
  const [displayNumber, setDisplayNumber] = useState("");
  const jsonMapping = {
      domestics_routes: "+1-833-609-8333",
      domestics_destination: "+1-833-609-7300",
      cruise : "+1-833-609-9933",
      airline: "+1-833-609-8444"
    };
    const defaultNumber = "+1-888-415-1536"; // Default number to display if UTM data is not found

    useEffect(() => {
      const updateDisplayNumber = () =>  {
        const utmData = JSON.parse(localStorage.getItem("utm_data")) || {};
      //   console.log(utmData)
        if (utmData?.utmData?.utm_campaign) {
          // console.log("in")
          setUtmCampaign(utmData.utm_campaign);
  
          // Check if `utm_campaign` exists in the JSON mapping
          const mappedNumber = jsonMapping[utmData?.utmData?.utm_campaign];
          setDisplayNumber(mappedNumber || defaultNumber); // Use mapped number or default
        } else {
          // console.log("out")
          // No UTM data found, use the default number
          setDisplayNumber(defaultNumber);
        }
      };
  
      // Run initially
      updateDisplayNumber();
  
      // Set up interval to check every 10 seconds
      const interval = setInterval(updateDisplayNumber, 10000);
  
      // Clean up interval on component unmount
      return () => clearInterval(interval);
    }, [jsonMapping]);
  return (
    <>
    <header>
          <nav class="navbar navbar-expand-lg bg-body-tertiary dfgsdg">
  <div class="container">
    <Link class="navbar-brand" to="/">
      <img className='iutit' src={logot} alt="" />
    </Link>
    <Link className='uigds' to={`tel:${displayNumber}`}>
    <div className="d-flex align-items-end ">
        <img className='me-2 srtwe' src={dsatet} alt="" />
        <div className="dfhuje">
        <p className='mb-0 dfsdfh text-white'>Call for Phone Deals</p>
        <p className='mb-0 dfyhr5 text-white'>{displayNumber}</p>
        </div>
      </div>
      </Link>
    <button class="navbar-toggler mob-hide" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse mob-hide" id="navbarSupportedContent">
      <ul class="navbar-nav m-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <Link class="nav-link active" aria-current="page" to="/flights">Flights</Link>
        </li>
        <li class="nav-item d-none">
          <Link class="nav-link " to="/">Hotel</Link>
        </li>
        <li class="nav-item d-none">
          <Link class="nav-link " to="/">Cars</Link>
        </li>
        <li class="nav-item">
          <Link class="nav-link" to="/cruise">Cruise</Link>
        </li>
        <li class="nav-item d-none">
          <Link class="nav-link " to="/">Vacation</Link>
        </li>
        <li class="nav-item dropdown">
                    <Link class="nav-link dropdown-toggle" to="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Deals <img className='ms-1' src={downj} alt="" />
                    </Link>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <div class="row justify-content-between">
                            <div class="col-md-4">
                                <h6 class="dropdown-header">Top Deals</h6>
                                <Link class="dropdown-item" to="/deals/business-class-flight" target="_blank">Business Class</Link>
                                {/* <Link class="dropdown-item" to="/deals/group-flight" target="_blank">Group Flight</Link> */}
                                <Link class="dropdown-item" to="/deals/last-minute-flight" target="_blank">Last Minute Flight</Link>
                                <Link class="dropdown-item" to="/deals/round-trip-flight" target="_blank">Round Trip Flight</Link>
                                <Link class="dropdown-item" to="/deals/christmas-flight" target="_blank">Christmas Flight</Link>
                                <Link class="dropdown-item" to="/deals/domestic-flight" target="_blank">Domestic Flight</Link>
                                <Link class="dropdown-item" to="/deals/flight-ticket" target="_blank">Flight Ticket</Link>
                            </div>
                            <div class="col-md-4">
                                <h6 class="dropdown-header">Travel by Interest</h6>
                                <Link class="dropdown-item" to="/deals/honeymoon-flight" target="_blank">Honeymoon Travel</Link>
                                <Link class="dropdown-item" to="/deals/military-flight" target="_blank">Military Travel</Link>
                                <Link class="dropdown-item" to="/deals/senior-flight" target="_blank">Senior Travel</Link>
                                <Link class="dropdown-item" to="/deals/student-flight" target="_blank">Student Travel</Link>
                                <Link class="dropdown-item" to="/deals/valentines-day-flight" target="_blank">Valentine's Day Flights</Link>
                                <Link class="dropdown-item" to="/deals/lgbtq-flight" target="_blank">LGBTQ Flights</Link>
                            </div>
                            {/* <div class="col-md-2">
                                <h6 class="dropdown-header">Travel by Region</h6>
                                <Link class="dropdown-item" to="/">USA Flights</Link>
                                <Link class="dropdown-item" to="/">Mexico Flights</Link>
                                <Link class="dropdown-item" to="/">Caribbean Flights</Link>
                                <Link class="dropdown-item" to="/">Europe Flights</Link>
                                <Link class="dropdown-item" to="/">Multi City Flights</Link>
                            </div> */}
                            <div class="col-md-4">
                                <h6 class="dropdown-header">Travel by Price</h6>
                                <Link class="dropdown-item" to="/deals/under-199-flights" target="_blank">Flight Under $199</Link>
                                <Link class="dropdown-item" to="/deals/under-99-flights" target="_blank">Flight Under $99</Link>
                                <Link class="dropdown-item" to="/deals/one-way-flight" target="_blank">One Way Flights</Link>
                                <Link class="dropdown-item" to="/deals/summer-flight" target="_blank">Summer Flights</Link>
                                <Link class="dropdown-item" to="/deals/new-year-flight" target="_blank">New Year Tickets</Link>
                            </div>
                        </div>
                    </ul>
                </li>
      </ul>
      <Link to={`tel:${displayNumber}`}>
      <div className="d-flex align-items-end ">
       
        <img className='me-2' src={Phone} alt="" />
        <div className="dfhuje text-end">
        <p className='mb-0 dfsdfh'>Call 24/7 for our best deals </p>
        <p className='mb-0 dfyhr5'>{displayNumber}</p>
        </div>
       
      </div>
      </Link>
    </div>
  </div>
</nav>
          
    </header>
    </>
  )
}
