const INState = [ 
    { 'ID': 'AN', 'Name': 'AN-Andaman and Nicobar Islands'  },
    { 'ID': 'AP', 'Name': 'AP-Andhra Pradesh'  },
    { 'ID': 'AR', 'Name': 'AR-Arunachal Pradesh'  },
    { 'ID': 'AS', 'Name': 'AS-Assam'  },
    { 'ID': 'BR', 'Name': 'BR-Bihar'  },
    { 'ID': 'CH', 'Name': 'CH-Chandigarh'  },
    { 'ID': 'CT', 'Name': 'CT-Chhattisgarh'  },
    { 'ID': 'DN', 'Name': 'DN-Dadra and Nagar Haveli'  },
    { 'ID': 'DD', 'Name': 'DD-Daman and Diu'  },
    { 'ID': 'DL', 'Name': 'DL-Delhi'  },
    { 'ID': 'GA', 'Name': 'GA-Goa'  },
    { 'ID': 'GJ', 'Name': 'GJ-Gujarat'  },
    { 'ID': 'HR', 'Name': 'HR-Haryana'  },
    { 'ID': 'HP', 'Name': 'HP-Himachal Pradesh'  },
    { 'ID': 'JK', 'Name': 'JK-Jammu and Kashmir'  },
    { 'ID': 'JH', 'Name': 'JH-Jharkhand'  },
    { 'ID': 'KA', 'Name': 'KA-Karnataka'  },
    { 'ID': 'KL', 'Name': 'KL-Kerala'  },
    { 'ID': 'LD', 'Name': 'LD-Lakshadweep'  },
    { 'ID': 'MP', 'Name': 'MP-Madhya Pradesh'  },
    { 'ID': 'MH', 'Name': 'MH-Maharashtra'  },
    { 'ID': 'MN', 'Name': 'MN-Manipur'  },
    { 'ID': 'ML', 'Name': 'ML-Meghalaya'  },
    { 'ID': 'MZ', 'Name': 'MZ-Mizoram'  },
    { 'ID': 'NL', 'Name': 'NL-Nagaland'  },
    { 'ID': 'OR', 'Name': 'OR-Orissa'  },
    { 'ID': 'PY', 'Name': 'PY-Pondicherry'  },
    { 'ID': 'PB', 'Name': 'PB-Punjab'  },
    { 'ID': 'RJ', 'Name': 'RJ-Rajasthan'  },
    { 'ID': 'SK', 'Name': 'SK-Sikkim'  },
    { 'ID': 'TN', 'Name': 'TN-Tamil Nadu'  },
    { 'ID': 'TR', 'Name': 'TR-Tripura'  },
    { 'ID': 'UP', 'Name': 'UP-Uttar Pradesh'  },
    { 'ID': 'UL', 'Name': 'UL-Uttaranchal'  },
    { 'ID': 'WB', 'Name': 'WB-West Bengal'  },];

    export default INState;