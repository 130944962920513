/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
// import { IconButton, Flex } from '@chakra-ui/react';
// import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import Header1 from "../Component/Header/Header1";
import Modifyengine from "../Component/Search-Engine/Modify-engine";
import leftyu from "../assest/images/icon/lefty.png";
import rightyu from "../assest/images/icon/righty.png";
import alade from "../assest/images/dtetd.png";
import crdty from "../assest/images/icon/dsr.png";
import stsra from "../assest/images/icon/star.png";
import nesr from "../assest/images/icon/nearby.png";
import alterfj from "../assest/images/icon/alternate.png";
import cheay from "../assest/images/icon/cheap.png";
import downh3 from "../assest/images/icon/down2.png";
import lungage1 from "../assest/images/icon/luggage1.png";
import lungage2 from "../assest/images/icon/luggage2.png";
import lungage3 from "../assest/images/icon/luggage3.png";
import dfge5 from "../assest/images/icon/dst4w.png";
import dgwe4 from "../assest/images/khte.png";
import apwhu7 from "../assest/images/icon/appro.png";
import noteju from "../assest/images/icon/no-result.png";
import dstji4e from "../assest/images/icon/caet.png";
import support from "../assest/images/icon/noresult/Support.png";
import comm from "../assest/images/icon/noresult/telephone.png";
import unpub from "../assest/images/icon/noresult/Unpublish-fares.png";
import major from "../assest/images/icon/noresult/Major-Airlines.png";
import { constant } from "../constant";
import axios from "axios";
import Filter from "../Component/Filter/Filter";
import FlightCard from "../Component/FlightCard/flightCard";
import jhsdru from "../assest/images/icon/filter2.png";
import crofyt from "../assest/images/icon/crossy.png";
import MetaTitle from "../Component/MetaTitle/metaTitle";
import impo from "../assest/images/icon/impo.png";
import airlinesName from "../Component/AirlineName/AirlineName";
import Footer from "../Component/Footer/Footer1";
import ReactPaginate from "react-paginate";
import crossf from "../assest/images/popup/cross.png";
import maind from "../assest/images/popup/main.jpg";
import excgvf from "../assest/images/popup/exchng.png";
import singleEx from "../assest/images/icon/one-way.png"

function formatDateAndTime(isoDate) {
  const dateOptions = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  };

  const timeOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const formattedDate = new Date(isoDate);
  const formattedDateString = new Intl.DateTimeFormat('en-US', dateOptions).format(formattedDate);
  const formattedTimeString = new Intl.DateTimeFormat('en-US', timeOptions).format(formattedDate);

  return { formattedDateString, formattedTimeString };
}

export default function Listing() {
  const location = useLocation();
  const { updatedFormValues } = location.state || {};
  // console.log('updatedFormValues', updatedFormValues);
  const [isLoding, setIsLoding] = useState(true);
  const [initialForm, setInitialForm] = useState(updatedFormValues);
  const [modifyData, setModifyData] = useState({});
  const [flights, setFlights] = useState([]);
  const [filteredFlights, setFilteredFlights] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsCount, setResultsCount] = useState(0);
  const [filteredResultsCount, setFilteredResultsCount] = useState(0);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const matrixResultFrame = 5;
  const [matrixFrame, setMatrixFrame] = useState(0);
  const [selectedMatrixAirline, setSelectedMatrixAirline] = useState(null);
  const [matAirline, setMatAirline] = useState([]);
  const [filterReset, setFilterReset] = useState(false);
  const [itemsToShow, setItemsToShow] = useState(50);
  const [activeFilter, setActiveFilter] = useState("best");
  const [recommendOpen, setRecommendOpen] = useState(false);
  const [matFrom, setMatFrom] = useState(
    localStorage.getItem("selectedOrigin")
      ? JSON.parse(localStorage.getItem("selectedOrigin"))
      : null
  );
  // console.log("matFrom", matFrom)
  const [matTo, setMatTo] = useState(
    localStorage.getItem("selectedDestination")
      ? JSON.parse(localStorage.getItem("selectedDestination"))
      : null
  );
  const [utmCampaign, setUtmCampaign] = useState("");
  const [displayNumber, setDisplayNumber] = useState("");
  const jsonMapping = {
    domestics_routes: "+1-833-609-8333",
    domestics_destination: "+1-833-609-7300",
    cruise: "+1-833-609-9933",
    airline: "+1-833-609-8444",
  };
  const defaultNumber = "+1-888-415-1536"; // Default number to display if UTM data is not found

  useEffect(() => {
    const updateDisplayNumber = () => {
      const utmData = JSON.parse(localStorage.getItem("utm_data")) || {};
      //   console.log(utmData)
      if (utmData?.utmData?.utm_campaign) {
        // console.log("in")
        setUtmCampaign(utmData?.utm_campaign);

        // Check if `utm_campaign` exists in the JSON mapping
        const mappedNumber = jsonMapping[utmData?.utmData?.utm_campaign];
        setDisplayNumber(mappedNumber || defaultNumber); // Use mapped number or default
      } else {
        // console.log("out")
        // No UTM data found, use the default number
        setDisplayNumber(defaultNumber);
      }
    };

    // Run initially
    updateDisplayNumber();

    // Set up interval to check every 10 seconds
    const interval = setInterval(updateDisplayNumber, 1000000000000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, [jsonMapping]);

  const [selectedMatrixFlightType, setSelectedMatrixFlightType] =
    useState(null);
  const [selectedMatrixPriceRange, setSelectedMatrixPriceRange] =
    useState(null);
  const [noResult, setNoResult] = useState(false);
  const [mobNonClick, setMobNonClick] = useState(false);
  const [mobFilterOpen, setMobFilterOpen] = useState(false);
  const [focusSection, setFocusSection] = useState(null);
  const [activeFilters, setActiveFilters] = useState(0);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [resetOnsearch, setResetOnSearch] = useState(false);
  const [timeLeft, setTimeLeft] = useState('');
  const [timerStarted, setTimerStarted] = useState(false);
  const [ bannerDisplay, setBannerDisplay ] = useState(false);
  const minPrice = flights?.minPrice || 0;
  const maxPrice = flights?.maxPrice || 1000;
  const [priceRange, setPriceRange] = useState([
    Math.round(minPrice * 100) / 100,
    Math.round(maxPrice * 100) / 100,
  ]);

  const flightsPerPage = 50;
  useEffect(() => {
    if (updatedFormValues) {
      setInitialForm(updatedFormValues);
    }
  }, [updatedFormValues]);

  useEffect(() => {
    const fetchData = async () => {
      if (!initialForm) return;
      // console.log(initialForm);
      setIsLoding(true);
      setFlights([]);
      try {
        // const responseData = await fetch(`${constant.baseUrl}/api/flights`)
        const responseData = await axios.post(
          `${constant.baseUrl}/api/website/flight-data`,
          initialForm
        );
        // console.log("responseData", responseData.data.flightResult);
        setFlights(responseData.data);
        setFilteredResultsCount(responseData.data.flightResult.length);
        setFilteredFlights(responseData?.data?.flightResult);
        setIsLoding(false);
        setNoResult(false);
        setFilterReset(false);
        setMatFrom(
          localStorage.getItem("selectedOrigin")
            ? JSON.parse(localStorage.getItem("selectedOrigin"))
            : null
        );
        setMatTo(
          localStorage.getItem("selectedDestination")
            ? JSON.parse(localStorage.getItem("selectedDestination"))
            : null
        );
        // console.log("flights", responseData.data);
      } catch (error) {
        // console.log("error", error);
        setNoResult(true);
        setIsLoding(false);
      }
    };
    fetchData();
  }, [initialForm]);
  const totalTax = flights?.flightResult?.fare?.totalTax;
  let totalPrice = flights?.flightResult?.fare?.adultFare + totalTax;

  const roundedPrice = totalPrice;
  // const [displayedLogosSet, setDisplayedLogosSet] = useState(new Set());

  const applyMatrixFilters = (flightData) => {
    return flightData?.filter((flight) => {
      const totalFare =
        (flight.fare.adultFare || 0) + (flight.fare.adultTax || 0);

      const airlineMatch =
        !selectedMatrixAirline || flight.airline === selectedMatrixAirline;

      const isNonStop =
        flight.outBound.every((segment) => segment.layOverTime === 0) &&
        (initialForm.tripType !== "2" ||
          flight.inBound.every((segment) => segment.layOverTime === 0));

      const isOneStop =
        flight.outBound.some((segment) => segment.layOverTime > 0) &&
        (initialForm.tripType !== "2" ||
          flight.inBound.some((segment) => segment.layOverTime > 0));

      const isTwoStop =
        flight.outBound.some((segment) => segment.layOverTime > 1) &&
        (initialForm.tripType !== "2" ||
          flight.inBound.some((segment) => segment.layOverTime > 1));

      const flightTypeMatch =
        !selectedMatrixFlightType ||
        (selectedMatrixFlightType === "nonStop" && isNonStop) ||
        (selectedMatrixFlightType === "1Stop" && isOneStop) ||
        (selectedMatrixFlightType === "2Stop" && isTwoStop);

      const priceMatch =
        !selectedMatrixPriceRange ||
        (totalFare >= selectedMatrixPriceRange.min &&
          totalFare <= selectedMatrixPriceRange.max);

      return airlineMatch && flightTypeMatch && priceMatch;
    });
  };

  useEffect(() => {
    const visibleFlights = applyMatrixFilters(flights?.flightResult);
    // console.log("Before setFilteredFlights:", visibleFlights);
    setFilteredFlights(visibleFlights);
  }, [
    selectedMatrixAirline,
    selectedMatrixFlightType,
    // selectedMatrixPriceRange,
  ]);

  const sortedFlights = [...(filteredFlights || [])].sort((a, b) => {
    return (
      Number(a.fare.adultFare + a.fare.adultTax) -
      Number(b.fare.adultFare + b.fare.adultTax)
    ); // Ensures numerical sorting
  });
  // Ensure filteredFlights is an array
  const indexOfLastFlight = currentPage * flightsPerPage;
  const indexOfFirstFlight = indexOfLastFlight - flightsPerPage;
  const currentFlights = sortedFlights.slice(
    indexOfFirstFlight,
    indexOfLastFlight
  );
  // console.log("currentFlights", currentFlights)

  // Pagination button click handler
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total pages
  const totalPages = filteredFlights?.length
    ? Math.ceil(filteredFlights.length / flightsPerPage)
    : 1;
  const handleFilterChange = (filteredFlights, filters) => {
    // console.log("filteredData", filteredFlights);

    // Calculate the number of active filters, excluding the 'Price' filter
    const activeFilterCount = filters
      ? Object.entries(filters).reduce((count, [key, filter]) => {
          // if (key === "Price") {
          //   return count; // Skip the 'Price' filter
          // }
          if (key === "Stops" && typeof filter === "object") {
            // Check if any stop is false
            if (Object.values(filter).some((value) => value === false)) {
              return count + 1;
            }
            return count;
          }
          if (filter === null || filter === undefined) {
            return count;
          } else if (
            typeof filter === "object" &&
            Object.values(filter).some(Boolean)
          ) {
            return count + 1;
          } else if (filter) {
            return count + 1;
          }
          return count;
        }, 0)
      : 0;

    // Update state with the filtered flights and active filter count
    setFilteredFlights(filteredFlights);
    setSelectedFilters(filters);
    setActiveFilters(activeFilterCount);
  };

  const removeFilter = (filterKey) => {
    const updatedFilters = { ...selectedFilters };
    delete updatedFilters[filterKey];
    setSelectedFilters(updatedFilters);

    setFilteredFlights(
      flights.flightResult.filter((flight) => {
        return Object.keys(updatedFilters).every((key) => {
          if (key === "price") {
            return (
              flight.fare.baseFare >= updatedFilters[key][0] &&
              flight.fare.baseFare <= updatedFilters[key][1]
            );
          }
          return flight[key] === updatedFilters[key];
        });
      })
    );
  };

  const airlines = [
    ...new Set(flights?.flightResult?.map((flight) => flight.airline)),
  ];
  const totalMatrixFrame = Math.ceil(airlines.length / matrixResultFrame);

  const startIndexMatrix = matrixFrame * matrixResultFrame;
  const endIndexMatrix = startIndexMatrix + matrixResultFrame;

  // Slice the airlines array to get the results for the current page
  const displayedAirlines = airlines.slice(startIndexMatrix, endIndexMatrix);

  const handleLeftArrowClick = () => {
    if (matrixFrame > 0) {
      setMatrixFrame(matrixFrame - 1);
    }
  };

  // Function to handle right arrow button click
  const handleRightArrowClick = () => {
    if (matrixFrame < totalMatrixFrame - 1) {
      setMatrixFrame(matrixFrame + 1);
    }
  };

  const handleSearchClick = () => {
    // console.log("Reset triggered");
    // Reset flights data
    setLoading(true); // Show loader
    setResultsCount(0);
    setFilteredResultsCount(0);
    setFilteredFlights([]);
    setSelectedFilters({});
    setSelectedMatrixAirline(null);
    setSelectedMatrixFlightType(null);
    setSelectedMatrixPriceRange(null);
    setFilterReset(true);
    setMatFrom(null);
    setMatTo(null);
    setMatrixFrame(null);
    setMatAirline([]);

    setFlights([]);
    // console.log("Flights", flights);
    // setInitialForm(modifyData);
    // Here you can call the API to fetch new flights data or handle any other logic
  };

  // console.log("mob filter", mobFilterOpen);

  const [itineraryVisible, setItineraryVisible] = useState(false);

  const handleOutsideClick = (e) => {
    e.stopPropagation();
    setItineraryVisible(false);
  };

  const handleRemoveFilter = (filterKey) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (
        filterKey === "Stops" &&
        typeof updatedFilters[filterKey] === "object"
      ) {
        Object.keys(updatedFilters[filterKey]).forEach((stopKey) => {
          updatedFilters[filterKey][stopKey] = true;
        });
      } else if (filterKey === "DepartTime" || filterKey === "ReturnTime") {
        updatedFilters[filterKey] = null;
      } else {
        delete updatedFilters[filterKey];
      }

      // Call filter change function
      handleFilterChange(flights.flightResult, updatedFilters);

      return updatedFilters;
    });
  };

  const ResetFunctionCall = () => {
    setSelectedFilters({});
    setSelectedMatrixAirline(null);
    setSelectedMatrixFlightType(null);
    setSelectedMatrixPriceRange(null);
    // setFilterReset(true);
    setMatrixFrame(null);
    setMatAirline([]);
  };

  const handleChange = useCallback(
    (value) => {
      // setCurrentPage(value);
      setCurrentPage(value);
    },
    [setCurrentPage]
  );

  const [selectedLogos, setSelectedLogos] = useState([]);

  useEffect(() => {
    const usedLogos = new Set();
    const selected = displayedAirlines.map((airline) => {
      const airlineCodes = airline.split(",");
      const availableAirline =
        airlineCodes.find((code) => !usedLogos.has(code)) || airlineCodes[0];

      usedLogos.add(availableAirline);
      return { code: availableAirline, name: airlinesName[availableAirline] };
    });
    if (JSON.stringify(selected) !== JSON.stringify(selectedLogos)) {
      setSelectedLogos(selected);
    }
  }, [displayedAirlines]);

  // console.log("Selected Origin", localStorage.getItem("selectedOrigin")
  // ? JSON.parse(localStorage.getItem("selectedOrigin"))
  // : null)
  // console.log("Selected Destination", )
  // const displayedAirlinesSet = new Set();

  const hasNonStopFlights = flights?.flightResult?.some(
    (flight) => flight.outBound.every((segment) => segment.layOverTime === 0)
    //  &&
    //   (initialForm.tripType !== "2" ||
    //     flight.inBound.every((segment) => segment.layOverTime === 0))
  );

  const hasOneStopFlights = flights?.flightResult?.some(
    (flight) =>
      flight.outBound.some((segment) => segment.layOverTime > 0) &&
      flight.outBound.every((segment) => segment.layOverTime <= 1)
    // &&
    // (initialForm.tripType !== "2" ||
    //   (flight.inBound.some((segment) => segment.layOverTime > 0) &&
    //     flight.inBound.every((segment) => segment.layOverTime <= 1)))
  );

  const hasMultiStopFlights = flights?.flightResult?.some(
    (flight) => flight.outBound.some((segment) => segment.layOverTime > 1)
    // &&
    //   (initialForm.tripType !== "2" ||
    //     flight.inBound.some((segment) => segment.layOverTime > 1))
  );
  if (flights && flights?.flightResult) {
    // If none of the flight options are available, don't render the div
    if (!hasNonStopFlights && !hasOneStopFlights && !hasMultiStopFlights) {
      return null;
    }
  }

  const handleClearSelection = () => {
    setMatAirline([]); // Reset in the parent component
  };
  const openLink = (url) => {
    window.open(url, "_blank", "width=650,height=550,left=300,top=50");
  };

  // const handleScroll = () => {
  //   if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 100) {
  //     if (itemsToShow < filteredFlights?.length) {
  //       setItemsToShow((prev) => prev + flightsPerPage);
  //     }
  //   }
  // };

  // // Update visible data when itemsToShow changes
  // useEffect(() => {
  //   setCurrentPage(filteredFlights?.slice(0, itemsToShow));
  // }, [itemsToShow, filteredFlights]);
  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);
  const calculateAveragePrice = (flights = []) => {
    if (!flights || flights.length === 0) return "0<sup>.00</sup>"; // Guard clause for empty array
  
    const price = Math.min(
      ...flights.map(
        (flight) =>
          (flight?.fare?.adultFare ?? 0) + (flight?.fare?.adultTax ?? 0)
      )
    ).toFixed(2); // Ensures 2 decimal places
  
    const [integerPart, decimalPart] = price.split(".");
  
    return (<>{integerPart}<sup>.{decimalPart}</sup></>);
  };
  

  const calculateMinPrice = (flights) => {
    if (!flights || flights.length === 0) return "0.00"; // Guard clause for empty array

    const price = Math.min(
      ...flights.map(
        (flight) =>
          (flight?.fare?.adultFare ?? 0) + (flight?.fare?.adultTax ?? 0)
      )
    ).toFixed(2);
    const [integerPart, decimalPart] = price.split(".");
  
    return (<>{integerPart}<sup>.{decimalPart}</sup></>);
  };

  const calculateMaxPrice = (flights) => {
    if (flights?.length === 0) return "0.00"; // Guard clause for empty flights array

    // Find the fastest flight based on calculated duration
    const fastestFlight = flights?.reduce((fastest, flight) => {
      const duration = flight?.calculatedDuration ?? Infinity;
      return duration < (fastest?.calculatedDuration ?? Infinity)
        ? flight
        : fastest;
    }, flights[0]); // Initialize with the first flight

    return fastestFlight?.fare?.adultFare ?? "0.00";
  };

  const calculateAlternatePrice = (flights) => {
    if (!flights || flights.length === 0) return "0.00"; // Guard clause for empty flights array

    const formOutboundDate = initialForm?.segment?.[0]?.travelDate
      ? new Date(initialForm.segment[0].travelDate).toDateString()
      : null;
    const formInboundDate = initialForm?.segment?.[1]?.travelDate
      ? new Date(initialForm.segment[1].travelDate).toDateString()
      : null;

    // Find the fastest flight based on calculated duration
    const fastestFlight = flights.reduce((fastest, flight) => {
      const outboundDepDate = flight.outBound?.[0]?.depDate
        ? new Date(flight.outBound[0].depDate).toDateString()
        : null;
      const inboundReachDate =
        initialForm?.tripType === "2" && flight.inBound?.[0]?.depDate
          ? new Date(flight.inBound[0].depDate).toDateString()
          : null;

      const isAlternateOutboundDate =
        formOutboundDate &&
        outboundDepDate &&
        formOutboundDate !== outboundDepDate;
      const isAlternateInboundDate =
        formInboundDate &&
        inboundReachDate &&
        formInboundDate !== inboundReachDate;

      const isAlternateDate = isAlternateOutboundDate || isAlternateInboundDate;

      // Choose the fastest flight based on duration if it's an alternate date
      if (isAlternateDate) {
        return !fastest || flight.duration < fastest.duration
          ? flight
          : fastest;
      }
      return fastest;
    }, null);

    return fastestFlight?.fare?.adultFare ?? "0.00";
  };

  const calculateNearbyPrice = (flights) => {
    if (!flights || flights.length === 0) return "0.00"; // Guard clause for empty flights array

    const formOutboundDate = initialForm?.segment?.[0]?.originAirport
      ? initialForm.segment[0].originAirport
      : null;
    const formInboundDate = initialForm?.segment?.[0]?.destinationAirport
      ? initialForm.segment[0].destinationAirport
      : null;

    // Find the fastest flight based on calculated duration
    const NearbyFlight = flights.reduce((nearby, flight) => {
      const outboundDepDate = flight.outBound?.[0]?.fromAirport
        ? flight.outBound[0].fromAirport
        : null;
      const inboundReachDate = flight.inBound?.[0]?.fromAirport
        ? flight.inBound[0].fromAirport
        : null;

      const isAlternateOutboundDate =
        formOutboundDate &&
        outboundDepDate &&
        formOutboundDate !== outboundDepDate;
      const isAlternateInboundDate =
        formInboundDate &&
        inboundReachDate &&
        formInboundDate !== inboundReachDate;

      const isAlternateDate = isAlternateOutboundDate || isAlternateInboundDate;

      // Choose the fastest flight based on duration if it's an alternate date
      if (isAlternateDate) {
        return nearby;
      }
      return nearby;
    }, null);

    return NearbyFlight?.fare?.adultFare ?? "0.00";
  };

  const calculateAndFilterFlights = (criteria) => {
    // console.log("Filtering flights based on:", criteria); // Debugging

    if (!filteredFlights || filteredFlights.length === 0) {
      // console.log("No flights available to filter.");
      return;
    }

    let sortedFlights = [...filteredFlights]; // Copy array

    // Calculate durations and add to each flight
    const calculateDuration = (flight) => {
      if (flight?.outBound) {
        // Get the first segment of the first leg (departure)
        const firstSegment = flight?.outBound[0];

        // Get the last segment of the second leg (return)
        const lastSegment = flight.outBound[flight.outBound.length - 1];

        // console.log("First Segment:", firstSegment); // Debugging
        // console.log("Last Segment:", lastSegment); // Debugging

        if (firstSegment && lastSegment) {
          const departureTimeStr = firstSegment.depDate;
          const arrivalTimeStr = lastSegment.reachDate;

          // Log the raw string values
          // console.log("Departure DateTime String:", departureTimeStr);
          // console.log("Arrival DateTime String:", arrivalTimeStr);

          // Parse the date strings
          const departureTime = new Date(departureTimeStr).getTime(); // Departure time of first segment
          const arrivalTime = new Date(arrivalTimeStr).getTime(); // Arrival time of last segment

          // console.log("Parsed Departure Time:", departureTime); // Debugging
          // console.log("Parsed Arrival Time:", arrivalTime); // Debugging

          if (!isNaN(departureTime) && !isNaN(arrivalTime)) {
            return (arrivalTime - departureTime) / (1000 * 60); // Duration in minutes
          } else {
            // console.log("Invalid departure or arrival time found.");
          }
        }
      }

      return Infinity; // Default to a high value if no valid duration is found
    };

    // Calculate and assign duration to each flight
    sortedFlights = sortedFlights.map((flight) => ({
      ...flight,
      calculatedDuration: calculateDuration(flight),
    }));

    // console.log("Flights before sorting:", sortedFlights);

    // Apply sorting based on criteria
    switch (criteria) {
      case "best":
        sortedFlights.sort((a, b) => {
          const priceA = a.fare?.adultFare ?? Infinity;
          const priceB = b.fare?.adultFare ?? Infinity;

          // Log for debugging
          // console.log("Comparing prices:", priceA, priceB);

          if (priceA === priceB) {
            const durationA = a.calculatedDuration ?? Infinity;
            const durationB = b.calculatedDuration ?? Infinity;
            // console.log("Comparing durations:", durationA, durationB);

            // If prices are equal, compare by duration
            if (durationA === durationB) {
              return 0; // If both price and duration are equal, return 0 to keep their order intact
            }
            return durationA - durationB; // Sort by duration
          }
          return priceA - priceB; // Sort by price first
        });

        // Calculate Average Price for "best" criteria
        // const averagePrice = calculateAveragePrice(sortedFlights);
        // console.log(`Average Price for Best Criteria: $${averagePrice}`);
        break;

      case "cheapest":
        sortedFlights.sort((a, b) => a.fare.adultFare - b.fare.adultFare);

        // Calculate Minimum Price for "cheapest" criteria
        // const minPrice = calculateMinPrice(sortedFlights);
        // console.log(`Min Price for Cheapest Criteria: $${minPrice}`);
        break;

      case "fastest":
        sortedFlights.sort((a, b) => {
          const durationA = a.calculatedDuration ?? Infinity;
          const durationB = b.calculatedDuration ?? Infinity;
          return durationA - durationB;
        });

        // Calculate Maximum Price for "fastest" criteria
        // const maxPrice = calculateMaxPrice(sortedFlights);
        // console.log(`Max Price for Fastest Criteria: $${maxPrice}`);
        break;

      case "alternate":
        sortedFlights = sortedFlights.filter((flight) => {
          const segment0Date = new Date(
            initialForm.segment[0].travelDate
          ).toDateString();
          const flightOutDate = new Date(
            flight.outBound[0].depDate
          ).toDateString();

          let isAlternate = segment0Date !== flightOutDate;

          if (initialForm.tripType === "2") {
            const segment1Date = new Date(
              initialForm.segment[1].travelDate
            ).toDateString();
            const flightInDate = new Date(
              flight.inBound[0].depDate
            ).toDateString();

            isAlternate = isAlternate || segment1Date !== flightInDate;
          }

          return isAlternate;
        });

        // Calculate Maximum Price for "fastest" criteria
        // const alterPrice = calculateAlternatePrice(sortedFlights);
        // console.log(`Max Price for Fastest Criteria: $${alterPrice}`);
        break;

      case "nearby":
        sortedFlights = sortedFlights.filter((flight) => {
          const segmentOrigin = initialForm.segment[0].originAirport;
          const segmentDest = initialForm.segment[0].destinationAirport;

          const flightOrigin = flight.outBound[0]?.fromAirport;
          const flightDest = flight.inBound[0]?.fromAirport;

          return segmentOrigin !== flightOrigin || segmentDest !== flightDest;
        });

        // Calculate Maximum Price for "fastest" criteria
        // const nearbyPrice = calculateNearbyPrice(sortedFlights);
        // console.log(`Max Price for Fastest Criteria: $${nearbyPrice}`);
        break;

      default:
        // console.log("Invalid filter criteria:", criteria);
        return;
    }

    // Ensure state updates correctly
    setFilteredFlights([...sortedFlights]); // Force state update
    setFilteredResultsCount(sortedFlights.length);
  };

  // console.log("selectedFilters", selectedFilters);
  useEffect(() => {
    if (timerStarted === false) {
        // Set your end time here
        const endTime = new Date();
        endTime.setMinutes(endTime.getMinutes() + 15); // Setting end time to 15 minutes from now

        const timer = setInterval(() => {
            const now = new Date().getTime();
            const timeDifference = endTime - now;

            if (timeDifference > 0) {
                // Calculate hours, minutes, and seconds from the time difference
                const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

                const timeString = formatTime(hours, minutes, seconds);
                setTimeLeft(timeString);
            } else {
                clearInterval(timer);
                setTimeLeft('00:00:00');
            }
        }, 1000);

        setTimerStarted(true);

        return () => clearInterval(timer); // Clean up timer on unmount
    } else {
        setTimerStarted(false);
    }
}, []);
const formatTime = (hours, minutes, seconds) => {
  // Add leading zeros if necessary
  const hoursString = (hours < 10 ? '0' : '') + hours;
  const minutesString = (minutes < 10 ? '0' : '') + minutes;
  const secondsString = (seconds < 10 ? '0' : '') + seconds;

  return `${hoursString}:${minutesString}:${secondsString}`;
};
const totalPassengers = parseInt(initialForm.adults) + parseInt(initialForm.child) + parseInt(initialForm.infants) + parseInt(initialForm.infantsWs);
const getCabinTypeLabel = (cabinTypeValue) => {
  switch (cabinTypeValue) {
    case "1":
      return "Economy";
    case "2":
      return "Business";
    case "3":
      return "Premium Economy";
    case "4":
      return "First";
    default:
      return "Unknown";
  }
};

useEffect(() => {
  let sessionTimeout;
  let userActivityTimer;

  const handleUserActivity = () => {
    clearTimeout(userActivityTimer);
    clearTimeout(sessionTimeout);
    //setSessionExpired(false);
    //setBannerDisplay(false)
    
    userActivityTimer = setTimeout(() => {
      sessionTimeout = setTimeout(() => {
        if(bannerDisplay === false){
        setBannerDisplay(true);
      } else {
        setBannerDisplay(false);
      }
        //console.log(sessionExpired)
      }, 30 * 1000); // 5 minutes in milliseconds
    }, 1000); // Reset session timeout after 1 second of inactivity
  };

  handleUserActivity();

  window.addEventListener('mousemove', handleUserActivity);
  window.addEventListener('keydown', handleUserActivity);

  return () => {
    window.removeEventListener('mousemove', handleUserActivity);
    window.removeEventListener('keydown', handleUserActivity);
    clearTimeout(sessionTimeout);
    clearTimeout(userActivityTimer);
  };
}, []);

  return (
    <>
      <MetaTitle url={`${constant.baseUrl}/listing`} />
      {isLoding ? (
        <section className="atfe">
          <div class="spinner12 center">
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
          </div>
        </section>
      ) : null}
      <div className={`${isLoding ? "jgur67" : ""}`}>
        <Header1 />
        <div onClick={(e) => handleOutsideClick(e)}>
          <Modifyengine
            formValues={initialForm}
            airportsData={flights?.airport}
            onSearchClick={handleSearchClick}
            // setNewForm={setInitialForm}
          />
        </div>
        {bannerDisplay && !noResult && (
        <section>
  <div id="boxed">
    <div className="home-page-popup">
      <div id="dialog" className="window">
        <div id="close_id" className="closed" onClick={() => {
        setBannerDisplay(false);
        }} >
          <img src={crossf} alt="cheap flight"/>
        </div>
        <div className="popup-dsgn-box">
          <div className="content_bxmain">
            <div className="content_left">
              <img className='jsdhr' src={maind} alt="supp" />
              <span className="WatchThisTrip__alarm__fc">{timeLeft}</span>
            </div>
            <div className="content_right">
              <div className="count-content-list">
                <div className="type-tripclass" >
                  <p className="timeoffer">Limited Time Offer <br/><span className="price_">${((flights?.flightResult?.[0]?.fare?.grandTotal - 25) / totalPassengers).toFixed(2)}*</span></p>
                </div>
                <div className="section-itenerary relative">
                  <span className="icon-trip-type">{initialForm.tripType === "2" ? (<img src={excgvf} alt="trip"/>) : (<img src={singleEx} alt="trip"/>)}</span>
                  <div className="city-code2 right"><b>{initialForm.segment[0].destinationAirport}</b>{initialForm.tripType === "2" ? (
										<div class="name-return">										
										{formatDateAndTime(initialForm.segment[1].travelDate).formattedDateString}
									</div>
                  ) : (<div class="name-return">										
                  {formatDateAndTime(initialForm.segment[0].travelDate).formattedDateString}
                </div>)}</div>
                  <div className="city-code2"><b>{initialForm.segment[0].originAirport}</b><div className="name-return">{formatDateAndTime(initialForm.segment[0].travelDate).formattedDateString}</div></div>
                  <div className='text-center'><p>{initialForm.tripType === "2" ? ("Round Trip") : ("One Way")},{getCabinTypeLabel(initialForm.cabinType)}</p></div></div>
                  <div className="callnow-btn">
                    <Link id="callTracker" to={`tel:${displayNumber}`}><p className='mb-0 '><img className='me-2' src={dstji4e} alt="" />{displayNumber}</p></Link>
                  </div>
                  <div className="txt-disclaimer">* Fares are subject to seat availability and not guaranteed until ticketed.T&amp;C Applied</div>
                </div>
              </div>
              <div ></div>
            </div>
          </div>
        </div>
        <div id="mask"></div>
      </div>
    </div>
</section>
        )}
        <div className="bg-white desk-hide">
          <nav class="_2ibL0">
            <div
              class="_39tHq"
              onClick={() => {
                setMobFilterOpen(true);
              }}
            >
              <span class="">
                <img className="ioer5" src={jhsdru} alt="" />
                {activeFilters === 0 ? (
                  <div class="_2iaTS mob-hide">Filters</div>
                ) : (
                  <div className="auri4">
                    <p>{activeFilters}</p>
                  </div>
                )}
              </span>
            </div>
            {/* <span class="">
            Sort: Cheapest
            
          </span> */}
            <span
              class=""
              onClick={() => {
                setRecommendOpen(!recommendOpen);
              }}
            >
              Sort: {activeFilter}
              <img src={downh3} className="ibL0" alt="" />
            </span>
            <span
              class=""
              onClick={() => {
                setMobNonClick(!mobNonClick);
              }}
            >
              Non-Stop
            </span>
            <span
              class=""
              onClick={() => {
                setFocusSection("timing");
                setMobFilterOpen(true);
              }}
            >
              Timings
            </span>
            <span
              class=""
              onClick={() => {
                setFocusSection("airlines");
                setMobFilterOpen(true);
              }}
            >
              Airlines
            </span>
          </nav>
        </div>

        <section className="iure4">
          <div className="container p-0">
            <div className="row">
              {noResult ? (
                // { /*<div className="col-lg-12">
                //   <div className="jf58u7 text-center">
                //     <img src={noteju} alt="" />
                //     <p className="khrt5i8">No Result Found!</p>
                //   </div>
                // </div> */}
                <Link to={`tel:${displayNumber}`}>
                  <div className="bar34er">
                    <div className="row justify-content-center align-items-center">
                      <div className="col-lg-4 text-center">
                        <p className="sarr5">No Result Found</p>
                        <p className="mb-0 f4rt ">Save big on call deal. </p>
                      </div>
                      <div className="col-lg-1"></div>
                      <div className="col-lg-4">
                        <p className="mb-0 fg56">
                          <img className="me-2" src={dstji4e} alt="" />
                          {displayNumber}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              ) : (
                <>
                  <div
                    className="col-lg-3"
                    onClick={(e) => handleOutsideClick(e)}
                  >
                    <Filter
                      flights={flights}
                      initialForm={initialForm}
                      filteredResultsCount={filteredResultsCount}
                      mobFilter={mobNonClick}
                      mobOpen={mobFilterOpen}
                      onFilterCloseClick={() => {
                        setMobFilterOpen(false);
                      }}
                      setingPrice={setPriceRange}
                      onFilterChange={handleFilterChange}
                      selectedFilters={selectedFilters}
                      selectedMAirline={matAirline}
                      selectedMPrice={selectedMatrixPriceRange}
                      focusSection={focusSection}
                      FilterReset={filterReset}
                      FilteredFlights={filteredFlights?.length}
                      OnResetClick={ResetFunctionCall}
                      onClearSelection={handleClearSelection}
                      // mobileFilter={mobileFilterOpen}
                    />
                  </div>
                  {filteredFlights?.length === 0 ? (
                    <div className="col-lg-9">
                      <div
                        className="kjdgsr mob-hide"
                        onClick={(e) => handleOutsideClick(e)}
                      >
                        <div className="kjer">
                          <div className="row align-items-center">
                            <div className="col-lg-9">
                              <p className="mb-0">
                                Showing {filteredResultsCount} flights from{" "}
                                {matFrom?.cityName}({matFrom?.countryName}) to{" "}
                                {matTo?.cityName}({matTo?.countryName})
                              </p>
                            </div>
                            <div className="col-lg-3">
                              <div className="skhrw">
                                <div
                                  className="krt ft6i1"
                                  onClick={handleLeftArrowClick}
                                >
                                  <img src={leftyu} alt="" />
                                </div>
                                <div
                                  className="krt ft6i2"
                                  onClick={handleRightArrowClick}
                                >
                                  <img src={rightyu} alt="" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="dszr">
                          <div className="row">
                            <div className="col-lg-3 fhyfy p-0">
                              <div class="eithn">
                                <p class="mb-0 foent justify-content-center font-500 wte56">
                                  Airline Matrix
                                </p>
                                {hasNonStopFlights && (
                                  <p
                                    class="mb-0 foent1 font-500"
                                    // onClick={() => {
                                    //   setSelectedMatrixFlightType("nonStop");
                                    //   // setMatAirline([]);
                                    // }}
                                  >
                                    Non Stop
                                  </p>
                                )}
                                {flights?.flightResult?.some((flight) =>
                                  // flight.airline === displayedAirlines &&
                                  flight.outBound.some(
                                    (segment) => segment.layOverTime > 0
                                  )
                                ) ? (
                                  <>
                                    {hasMultiStopFlights && (
                                      <p
                                        class="mb-0 foent1 font-500"
                                        // onClick={() => {
                                        //   setSelectedMatrixFlightType("2Stop");
                                        //   setMatAirline([]);
                                        // }}
                                      >
                                        1+ Stop
                                      </p>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {hasOneStopFlights && (
                                      <p
                                        class="mb-0 foent1 font-500"
                                        // onClick={() => {
                                        //   setSelectedMatrixFlightType("1Stop");
                                        //   setMatAirline([]);
                                        // }}
                                      >
                                        1 Stop
                                      </p>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-9 p-0 fhyfy1">
                              {displayedAirlines.map((airline, index) => {
                                const airlineCodes = airline.split(",");
                                const selectedAirline =
                                  selectedLogos[index]?.code;
                                const selectedAirlineName =
                                  selectedLogos[index]?.name;

                                const isSelected =
                                  matAirline.length > 0 &&
                                  matAirline.includes(airline);

                                const airlineFlights =
                                  flights?.flightResult.filter(
                                    (flight) => flight.airline === airline
                                  );

                                // Filter Non-Stop Flights
                                const nonStopFlights = airlineFlights?.filter(
                                  (flight) =>
                                    flight.outBound.every(
                                      (segment) => segment.layOverTime === 0
                                    ) &&
                                    (initialForm.tripType !== "2" ||
                                      flight.inBound.every(
                                        (segment) => segment.layOverTime === 0
                                      ))
                                );

                                // Filter Stopover Flights
                                const stopFlights = airlineFlights?.filter(
                                  (flight) =>
                                    flight.outBound.some(
                                      (segment) => segment.layOverTime > 0
                                    ) &&
                                    (initialForm.tripType !== "2" ||
                                      flight.inBound.some(
                                        (segment) => segment.layOverTime > 0
                                      ))
                                );

                                const nonStopOutBoundFlights =
                                  airlineFlights?.filter((flight) =>
                                    flight.outBound.every(
                                      (segment) => segment.layOverTime === 0
                                    )
                                  );

                                const stopOutBoundFlights =
                                  airlineFlights?.filter((flight) =>
                                    flight.outBound.some(
                                      (segment) => segment.layOverTime > 0
                                    )
                                  );

                                // Find lowest fares from Outbound flights only
                                const lowestNonStopOutBoundFare =
                                  nonStopOutBoundFlights?.length
                                    ? Math.min(
                                        ...nonStopOutBoundFlights.map(
                                          (f) =>
                                            (f.fare.adultFare || 0) +
                                            (f.fare.adultTax || 0)
                                        )
                                      )
                                    : null;

                                const lowestStopOutBoundFare =
                                  stopOutBoundFlights?.length
                                    ? Math.min(
                                        ...stopOutBoundFlights.map(
                                          (f) =>
                                            (f.fare.adultFare || 0) +
                                            (f.fare.adultTax || 0)
                                        )
                                      )
                                    : null;

                                const lowestNonStopFare = nonStopFlights?.length
                                  ? Math.min(
                                      ...nonStopFlights.map(
                                        (f) =>
                                          (f.fare.adultFare || 0) +
                                          (f.fare.adultTax || 0)
                                      )
                                    )
                                  : null;

                                const lowestStopFare = stopFlights?.length
                                  ? Math.min(
                                      ...stopFlights.map(
                                        (f) =>
                                          (f.fare.adultFare || 0) +
                                          (f.fare.adultTax || 0)
                                      )
                                    )
                                  : null;

                                const hasNonStop = nonStopFlights?.length > 0;
                                const hasStop = stopFlights?.length > 0;

                                return (
                                  <div
                                    className={`eithn2 even ${
                                      isSelected ? "select-mat" : ""
                                    }`}
                                    key={airline}
                                  >
                                    <div
                                      className="foent"
                                      onClick={() => setMatAirline([airline])}
                                    >
                                      {airlineCodes.length > 1 ? (
                                        <>
                                          <img
                                            src={`https://imgfolders.com/farehutz/${selectedAirline}.png`}
                                            alt=""
                                          />
                                          <img
                                            className="jher56"
                                            src={impo}
                                            alt=""
                                          />
                                          <p className="wsrjk4">
                                            {/* {airlineCodes
                                                  .map(
                                                    (code) => airlinesName[code]
                                                  )
                                                  .join(" & ")} */}
                                            {selectedAirlineName}
                                          </p>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            src={`https://imgfolders.com/farehutz/${selectedAirline}.png`}
                                            alt=""
                                          />
                                          <p className="wsrjk4">
                                            {airlinesName[selectedAirline]}
                                          </p>
                                        </>
                                      )}
                                    </div>

                                    <div
                                      className={`rate-col ${
                                        !hasNonStopFlights ? "d-none" : ""
                                      }`}
                                    >
                                      {hasNonStop ? (
                                        <p
                                          className={`mb-0 foent1 `} //${selectedPrice === lowestNonStopFare ? 'select-mat' : ''}
                                          onClick={() => {
                                            setMatAirline([airline]); // Set the airline immediately

                                            setTimeout(() => {
                                              setSelectedMatrixPriceRange({
                                                min: lowestNonStopFare,
                                                max: lowestNonStopFare,
                                              });
                                              setSelectedPrice(
                                                lowestNonStopFare
                                              );
                                            }, 1000); // 1-second delay
                                          }}
                                        >
                                          USD{" "}
                                          {lowestNonStopFare ? (
                                            <>
                                              {
                                                lowestNonStopFare
                                                  .toFixed(2)
                                                  .split(".")[0]
                                              }
                                              <sup>
                                                .
                                                {
                                                  lowestNonStopFare
                                                    .toFixed(2)
                                                    .split(".")[1]
                                                }
                                              </sup>
                                            </>
                                          ) : (
                                            "----"
                                          )}
                                        </p>
                                      ) : lowestNonStopOutBoundFare ? (
                                        <p
                                          className={`mb-0 foent1 `} //${selectedPrice === lowestNonStopOutBoundFare ? 'select-mat' : ''}
                                          onClick={() => {
                                            setMatAirline([airline]);
                                            setTimeout(() => {
                                              setSelectedMatrixPriceRange({
                                                min: lowestNonStopOutBoundFare,
                                                max: lowestNonStopOutBoundFare,
                                              });
                                              setSelectedPrice(
                                                lowestNonStopOutBoundFare
                                              );
                                            }, 1000);
                                          }}
                                        >
                                          USD{" "}
                                          <>
                                            {
                                              lowestNonStopOutBoundFare
                                                .toFixed(2)
                                                .split(".")[0]
                                            }
                                            <sup>
                                              .
                                              {
                                                lowestNonStopOutBoundFare
                                                  .toFixed(2)
                                                  .split(".")[1]
                                              }
                                            </sup>
                                          </>
                                        </p>
                                      ) : (
                                        <p className="mb-0 foent1">----</p>
                                      )}
                                    </div>

                                    <div
                                      className={`rate-col ${
                                        !hasOneStopFlights &&
                                        !hasMultiStopFlights
                                          ? "d-none"
                                          : ""
                                      }`}
                                    >
                                      {hasStop ? (
                                        <p
                                          className={`mb-0 foent1 `} //${selectedPrice === lowestStopFare ? 'select-mat' : ''}
                                          onClick={() => {
                                            setMatAirline([airline]);
                                            setTimeout(() => {
                                              setSelectedMatrixPriceRange({
                                                min: lowestStopFare,
                                                max: lowestStopFare,
                                              });
                                              setSelectedPrice(lowestStopFare);
                                            }, 1000);
                                          }}
                                        >
                                          USD{" "}
                                          {lowestStopFare ? (
                                            <>
                                              {
                                                lowestStopFare
                                                  .toFixed(2)
                                                  .split(".")[0]
                                              }
                                              <sup>
                                                .
                                                {
                                                  lowestStopFare
                                                    .toFixed(2)
                                                    .split(".")[1]
                                                }
                                              </sup>
                                            </>
                                          ) : (
                                            "----"
                                          )}
                                        </p>
                                      ) : lowestStopOutBoundFare ? (
                                        <p
                                          className={`mb-0 foent1 `} //${selectedPrice === lowestStopOutBoundFare ? 'select-mat' : ''}
                                          onClick={() => {
                                            setMatAirline([airline]);
                                            setTimeout(() => {
                                              setSelectedMatrixPriceRange({
                                                min: lowestStopOutBoundFare,
                                                max: lowestStopOutBoundFare,
                                              });
                                              setSelectedPrice(
                                                lowestStopOutBoundFare
                                              );
                                            }, 1000);
                                          }}
                                        >
                                          USD{" "}
                                          <>
                                            {
                                              lowestStopOutBoundFare
                                                .toFixed(2)
                                                .split(".")[0]
                                            }
                                            <sup>
                                              .
                                              {
                                                lowestStopOutBoundFare
                                                  .toFixed(2)
                                                  .split(".")[1]
                                              }
                                            </sup>
                                          </>
                                        </p>
                                      ) : (
                                        <p className="mb-0 foent1">----</p>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                              {/* <div class="eithn2 ">
                        <div class="foent">
                          <img src={alade} alt="" />
                        </div>

                        <div class="rate-col">
                          <p class="mb-0 foent1">
                            USD 381.<sup>97</sup>
                          </p>
                          <p class="mb-0 foent1">---</p>
                        </div>
                      </div>
                      <div class="eithn2 even">
                        <div class="foent">
                          <img src={alade} alt="" />
                        </div>

                        <div class="rate-col">
                          <p class="mb-0 foent1">
                            USD 381.<sup>97</sup>
                          </p>
                          <p class="mb-0 foent1">---</p>
                        </div>
                      </div>
                      <div class="eithn2  ">
                        <div class="foent">
                          <img src={alade} alt="" />
                        </div>

                        <div class="rate-col">
                          <p class="mb-0 foent1">
                            USD 381.<sup>97</sup>
                          </p>
                          <p class="mb-0 foent1">---</p>
                        </div>
                      </div>
                      <div class="eithn2 even">
                        <div class="foent">
                          <img src={alade} alt="" />
                        </div>

                        <div class="rate-col">
                          <p class="mb-0 foent1">
                            USD 381.<sup>97</sup>
                          </p>
                          <p class="mb-0 foent1">---</p>
                        </div>
                      </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <p
                        className="idhte mob-hide"
                        onClick={(e) => handleOutsideClick(e)}
                      >
                        <span>Note: *</span> All the fares displayed are for{" "}
                        {initialForm?.tripType === "2"
                          ? "Round Trip"
                          : "One Way Trip"}{" "}
                        and are in USD, Fares are inclusive of base fare, taxes
                        and service fees.{" "}
                        <span className="jisr4">
                        <Link
                          to="/airline-baggage-fee"
                          onClick={(e) => {
                            e.preventDefault();
                            openLink(e.currentTarget.href);
                          }}
                        >
                          Additional baggage fees
                        </Link>{" "}
                        </span>
                        may apply as per the policies. Some of the flights
                        results shown could either be for other dates or nearby
                        airport(s).
                      </p>

                      {Object.keys(selectedFilters || {}).length > 0 &&
                        Object.entries(selectedFilters).some(
                          ([key, value]) =>
                            key !== "Price" &&
                            (Array.isArray(value)
                              ? value.length > 0
                              : value !== null && value !== "any")
                        ) && (
                          <div className="sjrtg mob-hide">
                            <div className="row align-items-center">
                              <div className="col-lg-12 p-0">
                                <div className="d-flex align-items-center flex-wrap">
                                  {Object.entries(selectedFilters || {}).map(
                                    ([key, value]) => {
                                      if (key === "Price") return null; // Skip Price filter

                                      let displayValue = value;

                                      // ✅ Handle Stops (display only if any stop is false)
                                      if (
                                        key === "Stops" &&
                                        typeof value === "object"
                                      ) {
                                        const stopValues = Object.values(value);
                                        if (
                                          stopValues.every(
                                            (isSelected) => isSelected === true
                                          )
                                        ) {
                                          return null; // Hide "Stops" if all are true
                                        }
                                        displayValue = Object.entries(value)
                                          .filter(
                                            ([_, isSelected]) => !isSelected
                                          ) // Show only false stops
                                          .map(([stopKey]) => stopKey)
                                          .join(", ");
                                      }

                                      // Skip null or empty filters
                                      if (
                                        !displayValue ||
                                        displayValue === "null" ||
                                        displayValue.length === 0
                                      )
                                        return null;

                                      return (
                                        <span className="jiseth" key={key}>
                                          {`${key}: ${displayValue}`}
                                          {/* ✅ Hide the cross button for "Airline" */}
                                          {key !== "Airline" && (
                                            <img
                                              src={crdty}
                                              alt=""
                                              onClick={() =>
                                                handleRemoveFilter(key)
                                              }
                                            />
                                          )}
                                        </span>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      <div
                        className={`zdujr-pop2 khetr ${
                          recommendOpen === true ? "" : "mob-hide"
                        }`}
                      >
                        <div className="srh2">
                          <button className="kdti4"></button>
                          <img
                            className="urhue4"
                            onClick={() => setRecommendOpen(true)}
                            src={crofyt}
                            alt=""
                          />
                          <div className="dstkje">
                            <div
                              className={`kheewr ${
                                activeFilter === "best" ? "sdtew" : ""
                              }`}
                            >
                              <img src={stsra} alt="" />
                              <div
                                className="jde4"
                                onClick={() => {
                                  calculateAndFilterFlights("best");
                                  setActiveFilter("best");
                                }}
                              >
                                <p className="mb-0 fwy5e5">Recommended</p>
                                <p className="mb-0 dst45">
                                  USD{" "}
                                  {/* {Math.round( */}
                                    {calculateAveragePrice(filteredFlights)}
                                  {/* )} */}
                                </p>
                              </div>
                            </div>
                            <div
                              className={`kheewr ${
                                activeFilter === "cheapest" ? "sdtew" : ""
                              }`}
                            >
                              <img src={nesr} alt="" />
                              <div
                                className="jde4"
                                onClick={() => {
                                  calculateAndFilterFlights("cheapest");
                                  setActiveFilter("cheapest");
                                }}
                              >
                                <p className="mb-0 fwy5e5">Cheapest</p>
                                <p className="mb-0 dst45">
                                  USD{" "}
                                  {/* {Math.round( */}
                                    {calculateMinPrice(filteredFlights)}
                                  {/* )} */}
                                </p>
                              </div>
                            </div>
                            {calculateAlternatePrice(filteredFlights) !== 0 && (
                              <div
                                className={`kheewr ${
                                  activeFilter === "alternate" ? "sdtew" : ""
                                }`}
                              >
                                <img src={alterfj} alt="" />
                                <div
                                  className="jde4"
                                  onClick={() => {
                                    calculateAndFilterFlights("alternate");
                                    setActiveFilter("alternate");
                                  }}
                                >
                                  <p className="mb-0 fwy5e5">Alternate Date</p>
                                  <p className="mb-0 dst45">
                                    USD{" "}
                                    {/* {Math.round( */}
                                      {calculateAlternatePrice(filteredFlights)}
                                    {/* )} */}
                                  </p>
                                </div>
                              </div>
                            )}
                            {calculateNearbyPrice(filteredFlights) !== 0 && (
                              <div
                                className={`kheewr ${
                                  activeFilter === "nearby" ? "sdtew" : ""
                                }`}
                              >
                                <img src={cheay} alt="" />
                                <div
                                  className="jde4"
                                  onClick={() => {
                                    calculateAndFilterFlights("nearby");
                                    setActiveFilter("nearby");
                                  }}
                                >
                                  <p className="mb-0 fwy5e5">Nearby Airports</p>
                                  <p className="mb-0 dst45">
                                    USD{" "}
                                    {/* {Math.round( */}
                                      {calculateNearbyPrice(filteredFlights)}
                                    {/* )} */}
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="jf58u7 text-center">
                        <img src={noteju} alt="" />
                        <p className="khrt5i8">No Filtered Result!</p>
                      </div>
                    </div>
                  ) : (
                    <div className="col-lg-9">
                      {filteredFlights?.length !== 0 ? (
                        <>
                          <div
                            className="kjdgsr mob-hide"
                            onClick={(e) => handleOutsideClick(e)}
                          >
                            <div className="kjer">
                              <div className="row align-items-center">
                                <div className="col-lg-9">
                                  <p className="mb-0">
                                    Showing {filteredResultsCount} flights from{" "}
                                    {matFrom?.cityName}({matFrom?.countryName})
                                    to {matTo?.cityName}({matTo?.countryName})
                                  </p>
                                </div>
                                <div className="col-lg-3">
                                  <div className="skhrw">
                                    <div
                                      className="krt ft6i1"
                                      onClick={
                                        matrixFrame > 0
                                          ? handleLeftArrowClick
                                          : null
                                      }
                                      style={{
                                        opacity: matrixFrame === 0 ? 0.5 : 1,
                                        pointerEvents:
                                          matrixFrame === 0 ? "none" : "auto",
                                      }}
                                    >
                                      <img src={leftyu} alt="" />
                                    </div>
                                    <div
                                      className="krt ft6i2"
                                      onClick={
                                        matrixFrame < totalMatrixFrame - 1
                                          ? handleRightArrowClick
                                          : null
                                      }
                                      style={{
                                        opacity:
                                          matrixFrame >= totalMatrixFrame - 1
                                            ? 0.5
                                            : 1,
                                        pointerEvents:
                                          matrixFrame >= totalMatrixFrame - 1
                                            ? "none"
                                            : "auto",
                                      }}
                                    >
                                      <img src={rightyu} alt="" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="dszr">
                              <div className="row">
                                <div className="col-lg-3 fhyfy p-0">
                                  <div class="eithn">
                                    <p class="mb-0 foent justify-content-center font-500 wte56">
                                      Airline Matrix
                                    </p>
                                    {hasNonStopFlights && (
                                      <p
                                        class="mb-0 foent1 font-500"
                                        // onClick={() => {
                                        //   setSelectedMatrixFlightType("nonStop");
                                        //   // setMatAirline([]);
                                        // }}
                                      >
                                        Non Stop
                                      </p>
                                    )}
                                    {flights?.flightResult?.some((flight) =>
                                      // flight.airline === displayedAirlines &&
                                      flight.outBound.some(
                                        (segment) => segment.layOverTime > 0
                                      )
                                    ) ? (
                                      <>
                                        {hasMultiStopFlights && (
                                          <p
                                            class="mb-0 foent1 font-500"
                                            // onClick={() => {
                                            //   setSelectedMatrixFlightType("2Stop");
                                            //   setMatAirline([]);
                                            // }}
                                          >
                                            1+ Stop
                                          </p>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {hasOneStopFlights && (
                                          <p
                                            class="mb-0 foent1 font-500"
                                            // onClick={() => {
                                            //   setSelectedMatrixFlightType("1Stop");
                                            //   setMatAirline([]);
                                            // }}
                                          >
                                            1 Stop
                                          </p>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-9 p-0 fhyfy1">
                                  {displayedAirlines.map((airline, index) => {
                                    const airlineCodes = airline.split(",");
                                    const selectedAirline =
                                      selectedLogos[index]?.code;
                                    const selectedAirlineName =
                                      selectedLogos[index]?.name;

                                    const isSelected =
                                      matAirline.length > 0 &&
                                      matAirline.includes(airline);

                                    const airlineFlights =
                                      flights?.flightResult.filter(
                                        (flight) => flight.airline === airline
                                      );

                                    // Filter Non-Stop Flights
                                    const nonStopFlights =
                                      airlineFlights?.filter(
                                        (flight) =>
                                          flight.outBound.every(
                                            (segment) =>
                                              segment.layOverTime === 0
                                          ) &&
                                          (initialForm.tripType !== "2" ||
                                            flight.inBound.every(
                                              (segment) =>
                                                segment.layOverTime === 0
                                            ))
                                      );

                                    // Filter Stopover Flights
                                    const stopFlights = airlineFlights?.filter(
                                      (flight) =>
                                        flight.outBound.some(
                                          (segment) => segment.layOverTime > 0
                                        ) &&
                                        (initialForm.tripType !== "2" ||
                                          flight.inBound.some(
                                            (segment) => segment.layOverTime > 0
                                          ))
                                    );

                                    const nonStopOutBoundFlights =
                                      airlineFlights?.filter((flight) =>
                                        flight.outBound.every(
                                          (segment) => segment.layOverTime === 0
                                        )
                                      );

                                    const stopOutBoundFlights =
                                      airlineFlights?.filter((flight) =>
                                        flight.outBound.some(
                                          (segment) => segment.layOverTime > 0
                                        )
                                      );

                                    // Find lowest fares from Outbound flights only
                                    const lowestNonStopOutBoundFare =
                                      nonStopOutBoundFlights?.length
                                        ? Math.min(
                                            ...nonStopOutBoundFlights.map(
                                              (f) =>
                                                (f.fare.adultFare || 0) +
                                                (f.fare.adultTax || 0)
                                            )
                                          )
                                        : null;

                                    const lowestStopOutBoundFare =
                                      stopOutBoundFlights?.length
                                        ? Math.min(
                                            ...stopOutBoundFlights.map(
                                              (f) =>
                                                (f.fare.adultFare || 0) +
                                                (f.fare.adultTax || 0)
                                            )
                                          )
                                        : null;

                                    const lowestNonStopFare =
                                      nonStopFlights?.length
                                        ? Math.min(
                                            ...nonStopFlights.map(
                                              (f) =>
                                                (f.fare.adultFare || 0) +
                                                (f.fare.adultTax || 0)
                                            )
                                          )
                                        : null;

                                    const lowestStopFare = stopFlights?.length
                                      ? Math.min(
                                          ...stopFlights.map(
                                            (f) =>
                                              (f.fare.adultFare || 0) +
                                              (f.fare.adultTax || 0)
                                          )
                                        )
                                      : null;

                                    const hasNonStop =
                                      nonStopFlights?.length > 0;
                                    const hasStop = stopFlights?.length > 0;

                                    return (
                                      <div
                                        className={`eithn2 even ${
                                          isSelected ? "select-mat" : ""
                                        }`}
                                        key={airline}
                                      >
                                        <div
                                          className="foent"
                                          onClick={() =>
                                            setMatAirline([airline])
                                          }
                                        >
                                          {airlineCodes.length > 1 ? (
                                            <>
                                              <img
                                                src={`https://imgfolders.com/farehutz/${selectedAirline}.png`}
                                                alt=""
                                              />
                                              <img
                                                className="jher56"
                                                src={impo}
                                                alt=""
                                              />
                                              <p className="wsrjk4">
                                                {/* {airlineCodes
                                                  .map(
                                                    (code) => airlinesName[code]
                                                  )
                                                  .join(" & ")} */}
                                                {selectedAirlineName}
                                              </p>
                                            </>
                                          ) : (
                                            <>
                                              <img
                                                src={`https://imgfolders.com/farehutz/${selectedAirline}.png`}
                                                alt=""
                                              />
                                              <p className="wsrjk4">
                                                {airlinesName[selectedAirline]}
                                              </p>
                                            </>
                                          )}
                                        </div>

                                        <div
                                          className={`rate-col ${
                                            !hasNonStopFlights ? "d-none" : ""
                                          }`}
                                        >
                                          {hasNonStop ? (
                                            <p
                                              className={`mb-0 foent1 `} //${selectedPrice === lowestNonStopFare ? 'select-mat' : ''}
                                              onClick={() => {
                                                setMatAirline([airline]); // Set the airline immediately

                                                setTimeout(() => {
                                                  setSelectedMatrixPriceRange({
                                                    min: lowestNonStopFare,
                                                    max: lowestNonStopFare,
                                                  });
                                                  setSelectedPrice(
                                                    lowestNonStopFare
                                                  );
                                                }, 1000); // 1-second delay
                                              }}
                                            >
                                              USD{" "}
                                              {lowestNonStopFare ? (
                                                <>
                                                  {
                                                    lowestNonStopFare
                                                      .toFixed(2)
                                                      .split(".")[0]
                                                  }
                                                  <sup>
                                                    .
                                                    {
                                                      lowestNonStopFare
                                                        .toFixed(2)
                                                        .split(".")[1]
                                                    }
                                                  </sup>
                                                </>
                                              ) : (
                                                "----"
                                              )}
                                            </p>
                                          ) : lowestNonStopOutBoundFare ? (
                                            <p
                                              className={`mb-0 foent1 `} //${selectedPrice === lowestNonStopOutBoundFare ? 'select-mat' : ''}
                                              onClick={() => {
                                                setMatAirline([airline]);
                                                setTimeout(() => {
                                                  setSelectedMatrixPriceRange({
                                                    min: lowestNonStopOutBoundFare,
                                                    max: lowestNonStopOutBoundFare,
                                                  });
                                                  setSelectedPrice(
                                                    lowestNonStopOutBoundFare
                                                  );
                                                }, 1000);
                                              }}
                                            >
                                              USD{" "}
                                              <>
                                                {
                                                  lowestNonStopOutBoundFare
                                                    .toFixed(2)
                                                    .split(".")[0]
                                                }
                                                <sup>
                                                  .
                                                  {
                                                    lowestNonStopOutBoundFare
                                                      .toFixed(2)
                                                      .split(".")[1]
                                                  }
                                                </sup>
                                              </>
                                            </p>
                                          ) : (
                                            <p className="mb-0 foent1">----</p>
                                          )}
                                        </div>

                                        <div
                                          className={`rate-col ${
                                            !hasOneStopFlights &&
                                            !hasMultiStopFlights
                                              ? "d-none"
                                              : ""
                                          }`}
                                        >
                                          {hasStop ? (
                                            <p
                                              className={`mb-0 foent1 `} //${selectedPrice === lowestStopFare ? 'select-mat' : ''}
                                              onClick={() => {
                                                setMatAirline([airline]);
                                                setTimeout(() => {
                                                  setSelectedMatrixPriceRange({
                                                    min: lowestStopFare,
                                                    max: lowestStopFare,
                                                  });
                                                  setSelectedPrice(
                                                    lowestStopFare
                                                  );
                                                }, 1000);
                                              }}
                                            >
                                              USD{" "}
                                              {lowestStopFare ? (
                                                <>
                                                  {
                                                    lowestStopFare
                                                      .toFixed(2)
                                                      .split(".")[0]
                                                  }
                                                  <sup>
                                                    .
                                                    {
                                                      lowestStopFare
                                                        .toFixed(2)
                                                        .split(".")[1]
                                                    }
                                                  </sup>
                                                </>
                                              ) : (
                                                "----"
                                              )}
                                            </p>
                                          ) : lowestStopOutBoundFare ? (
                                            <p
                                              className={`mb-0 foent1 `} //${selectedPrice === lowestStopOutBoundFare ? 'select-mat' : ''}
                                              onClick={() => {
                                                setMatAirline([airline]);
                                                setTimeout(() => {
                                                  setSelectedMatrixPriceRange({
                                                    min: lowestStopOutBoundFare,
                                                    max: lowestStopOutBoundFare,
                                                  });
                                                  setSelectedPrice(
                                                    lowestStopOutBoundFare
                                                  );
                                                }, 1000);
                                              }}
                                            >
                                              USD{" "}
                                              <>
                                                {
                                                  lowestStopOutBoundFare
                                                    .toFixed(2)
                                                    .split(".")[0]
                                                }
                                                <sup>
                                                  .
                                                  {
                                                    lowestStopOutBoundFare
                                                      .toFixed(2)
                                                      .split(".")[1]
                                                  }
                                                </sup>
                                              </>
                                            </p>
                                          ) : (
                                            <p className="mb-0 foent1">----</p>
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}
                                  {/* <div class="eithn2 ">
                        <div class="foent">
                          <img src={alade} alt="" />
                        </div>

                        <div class="rate-col">
                          <p class="mb-0 foent1">
                            USD 381.<sup>97</sup>
                          </p>
                          <p class="mb-0 foent1">---</p>
                        </div>
                      </div>
                      <div class="eithn2 even">
                        <div class="foent">
                          <img src={alade} alt="" />
                        </div>

                        <div class="rate-col">
                          <p class="mb-0 foent1">
                            USD 381.<sup>97</sup>
                          </p>
                          <p class="mb-0 foent1">---</p>
                        </div>
                      </div>
                      <div class="eithn2  ">
                        <div class="foent">
                          <img src={alade} alt="" />
                        </div>

                        <div class="rate-col">
                          <p class="mb-0 foent1">
                            USD 381.<sup>97</sup>
                          </p>
                          <p class="mb-0 foent1">---</p>
                        </div>
                      </div>
                      <div class="eithn2 even">
                        <div class="foent">
                          <img src={alade} alt="" />
                        </div>

                        <div class="rate-col">
                          <p class="mb-0 foent1">
                            USD 381.<sup>97</sup>
                          </p>
                          <p class="mb-0 foent1">---</p>
                        </div>
                      </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          <p
                            className="idhte mob-hide"
                            onClick={(e) => handleOutsideClick(e)}
                          >
                            <span>Note: *</span> All the fares displayed are for{" "}
                            {initialForm?.tripType === "2"
                              ? "Round Trip"
                              : "One Way Trip"}{" "}
                            and are in USD, Fares are inclusive of base fare,
                            taxes and service fees.{" "}
                            <span className="jisr4">
                            <Link
                              to="/airline-baggage-fee"
                              onClick={(e) => {
                                e.preventDefault();
                                openLink(e.currentTarget.href);
                              }}
                            >
                              Additional baggage fees
                            </Link>{" "}
                            </span>
                            may apply as per the policies. Some of the flights
                            results shown could either be for other dates or
                            nearby airport(s).
                          </p>
                        </>
                      ) : null}
                      {Object.keys(selectedFilters || {}).length > 0 &&
                        Object.entries(selectedFilters).some(
                          ([key, value]) =>
                            key !== "Price" &&
                            (Array.isArray(value)
                              ? value.length > 0
                              : value !== null && value !== "any")
                        ) && (
                          <div className="sjrtg mob-hide">
                            <div className="row align-items-center">
                              <div className="col-lg-12 p-0">
                                <div className="d-flex align-items-center flex-wrap">
                                  {Object.entries(selectedFilters || {}).map(
                                    ([key, value]) => {
                                      if (key === "Price") return null; // Skip Price filter

                                      let displayValue = value;

                                      // ✅ Handle Stops (display only if any stop is false)
                                      if (
                                        key === "Stops" &&
                                        typeof value === "object"
                                      ) {
                                        const stopValues = Object.values(value);
                                        if (
                                          stopValues.every(
                                            (isSelected) => isSelected === true
                                          )
                                        ) {
                                          return null; // Hide "Stops" if all are true
                                        }
                                        displayValue = Object.entries(value)
                                          .filter(
                                            ([_, isSelected]) => !isSelected
                                          ) // Show only false stops
                                          .map(([stopKey]) => stopKey)
                                          .join(", ");
                                      }

                                      // Skip null or empty filters
                                      if (
                                        !displayValue ||
                                        displayValue === "null" ||
                                        displayValue.length === 0
                                      )
                                        return null;

                                      return (
                                        <span className="jiseth" key={key}>
                                          {`${key}: ${displayValue}`}
                                          {/* ✅ Hide the cross button for "Airline" */}
                                          {key !== "Airline" && (
                                            <img
                                              src={crdty}
                                              alt=""
                                              onClick={() =>
                                                handleRemoveFilter(key)
                                              }
                                            />
                                          )}
                                        </span>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      <div
                        className={`zdujr-pop2 khetr ${
                          recommendOpen === true ? "" : "mob-hide"
                        }`}
                      >
                        <div className="srh2">
                          <button className="kdti4"></button>
                          <img
                            className="urhue4"
                            onClick={() => setRecommendOpen(false)}
                            src={crofyt}
                            alt=""
                          />
                          <div className="dstkje">
                            <div
                              className={`kheewr ${
                                activeFilter === "best" ? "sdtew" : ""
                              }`}
                            >
                              <img src={stsra} alt="" />
                              <div
                                className="jde4"
                                onClick={() => {
                                  calculateAndFilterFlights("best");
                                  setActiveFilter("best");
                                }}
                              >
                                <p className="mb-0 fwy5e5">Recommended</p>
                                <p className="mb-0 dst45">
                                  USD{" "}
                                  {/* {Math.round( */}
                                    {calculateAveragePrice(filteredFlights)}
                                  {/* )} */}
                                </p>
                              </div>
                            </div>
                            <div
                              className={`kheewr ${
                                activeFilter === "cheapest" ? "sdtew" : ""
                              }`}
                            >
                              <img src={nesr} alt="" />
                              <div
                                className="jde4"
                                onClick={() => {
                                  calculateAndFilterFlights("cheapest");
                                  setActiveFilter("cheapest");
                                }}
                              >
                                <p className="mb-0 fwy5e5">Cheapest</p>
                                <p className="mb-0 dst45">
                                  USD{" "}
                                  {/* {Math.round( */}
                                    {calculateMinPrice(filteredFlights)}
                                  {/* )} */}
                                </p>
                              </div>
                            </div>
                            {calculateAlternatePrice(filteredFlights) > 0 && (
                              <div
                                className={`kheewr ${
                                  activeFilter === "alternate" ? "sdtew" : ""
                                }`}
                              >
                                <img src={alterfj} alt="" />
                                <div
                                  className="jde4"
                                  onClick={() => {
                                    calculateAndFilterFlights("alternate");
                                    setActiveFilter("alternate");
                                  }}
                                >
                                  <p className="mb-0 fwy5e5">Alternate Date</p>
                                  <p className="mb-0 dst45">
                                    USD{" "}
                                    {/* {Math.round( */}
                                      {calculateAlternatePrice(filteredFlights)}
                                    {/* )} */}
                                  </p>
                                </div>
                              </div>
                            )}
                            {calculateNearbyPrice(filteredFlights) > 0 && (
                              <div
                                className={`kheewr ${
                                  activeFilter === "nearby" ? "sdtew" : ""
                                }`}
                              >
                                <img src={cheay} alt="" />
                                <div
                                  className="jde4"
                                  onClick={() => {
                                    calculateAndFilterFlights("nearby");
                                    setActiveFilter("nearby");
                                  }}
                                >
                                  <p className="mb-0 fwy5e5">Nearby Airports</p>
                                  <p className="mb-0 dst45">
                                    USD{" "}
                                    {Math.round(
                                      calculateNearbyPrice(filteredFlights)
                                    )}
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div>
                        {currentFlights.map((flight, index) => {
                          const flightWithLoading = {
                            ...flight,
                            isLoding,
                            index,
                            initialForm,
                            airports: flights?.airport,
                          };
                          return (
                            <React.Fragment key={index}>
                            <FlightCard
                              key={index}
                              {...flightWithLoading}
                              itineraryVisible={itineraryVisible}
                            />
                            {(index === 1 || index === 5) && (
                              <div className="bar34er">
                                <Link to={`tel:${displayNumber}`}>
                              <div className="row justify-content-center align-items-center">
                              <div className="col-lg-4 text-center fdhdfg">
                                <p className='sarr5'>Benefits of booking with an agent</p>
                                <p className='mb-0 f4rt '>Phone-only deals available</p>
                                <p className='mb-0 f4rt '>Personalized travel assistance</p>
                              </div>
                              <div className="col-lg-1 fdhdfg"></div>
                              <div className="col-lg-4 fdhdfg">
                                <p className='mb-0 fg56'><img className='me-2' src={dstji4e} alt="" />{displayNumber}</p>
                              </div>
                              </div>
                              </Link>
                            </div>
                               )}
                            </React.Fragment>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
{!noResult ? (
  <>
          {totalPages > 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
                gap: "8px",
              }}
            >
              {/* Prev Button */}
              <button
                onClick={() => handleChange(currentPage - 1)}
                disabled={currentPage === 1}
                style={{
                  padding: "8px 12px",
                  backgroundColor: currentPage === 1 ? "#ccc" : "#6c63ff",
                  color: "#fff",
                  border: "none",
                  borderRadius: "5px",
                  cursor: currentPage === 1 ? "not-allowed" : "pointer",
                  opacity: currentPage === 1 ? 0.6 : 1,
                  transition: "background 0.3s",
                }}
              >
                Prev
              </button>

              {/* Page Numbers */}
              {[...Array(totalPages)].map((_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handleChange(index + 1)}
                  style={{
                    padding: "8px 12px",
                    backgroundColor:
                      currentPage === index + 1 ? "#ff5722" : "#f0f0f0",
                    color: currentPage === index + 1 ? "#fff" : "#333",
                    border: "1px solid #ddd",
                    borderRadius: "5px",
                    cursor: "pointer",
                    fontWeight: currentPage === index + 1 ? "bold" : "normal",
                    transition: "background 0.3s, transform 0.2s",
                  }}
                >
                  {index + 1}
                </button>
              ))}

              {/* Next Button */}
              <button
                onClick={() => handleChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                style={{
                  padding: "8px 12px",
                  backgroundColor:
                    currentPage === totalPages ? "#ccc" : "#6c63ff",
                  color: "#fff",
                  border: "none",
                  borderRadius: "5px",
                  cursor:
                    currentPage === totalPages ? "not-allowed" : "pointer",
                  opacity: currentPage === totalPages ? 0.6 : 1,
                  transition: "background 0.3s",
                }}
              >
                Next
              </button>
            </div>
          )}
          </>
        ) : null}
        </section>

        <Footer />
      </div>
    </>
  );
}
