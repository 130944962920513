/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import axios from "axios";
import * as Yup from "yup";
import Header from '../Component/Header/header'
import Footer from '../Component/Footer/Footer'
import uyset from '../assest/images/icon/contact.jpg'
import loct from '../assest/images/icon/loct.png';
import mailu from '../assest/images/icon/mail.png';
import cali from '../assest/images/icon/cal.png';
import MetaTitle from '../Component/MetaTitle/metaTitle'
import { constant } from "../constant";

export default function Contact() {
    const [isLoading, setIsLoading] = React.useState(false);
    const navigate = useNavigate();
    const [utmCampaign, setUtmCampaign] = useState("");
        const [displayNumber, setDisplayNumber] = useState("");
        const jsonMapping = {
            domestics_routes: "+1-833-609-8333",
            domestics_destination: "+1-833-609-7300",
            cruise : "+1-833-609-9933",
            airline: "+1-833-609-8444"
          };
          const defaultNumber = "+1-888-415-1536"; // Default number to display if UTM data is not found
      
          useEffect(() => {
            const updateDisplayNumber = () => {
              const utmData = JSON.parse(localStorage.getItem("utm_data")) || {};
            //   console.log(utmData)
              if (utmData?.utmData?.utm_campaign) {
                // console.log("in")
                setUtmCampaign(utmData?.utm_campaign);
        
                // Check if `utm_campaign` exists in the JSON mapping
                const mappedNumber = jsonMapping[utmData?.utmData?.utm_campaign];
                setDisplayNumber(mappedNumber || defaultNumber); // Use mapped number or default
              } else {
                // console.log("out")
                // No UTM data found, use the default number
                setDisplayNumber(defaultNumber);
              }
            };
        
            // Run initially
            updateDisplayNumber();
        
            // Set up interval to check every 10 seconds
            const interval = setInterval(updateDisplayNumber, 1000000000000);
        
            // Clean up interval on component unmount
            return () => clearInterval(interval);
          }, [jsonMapping]);

    const validationSchema = Yup.object().shape({
        email: Yup.string()
          .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, "Invalid email format")
          .required("Email is required"),
          phoneNumber: Yup.string()
          .matches(/^\d{10}$/, "Phone number must be 10 digits")
          .required("Phone number is required"),
            firstName: Yup.string()
            .required("First name is required"),
            lastName: Yup.string()
            .required("Last name is required"),
            // remarks: Yup.string()
            // .required("Remarks is required")
      });
      const initialValues = {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        remarks: ''
      };
    
    const handleSubmitForm = async (values, { setSubmitting }) => {
        setIsLoading(true);
        try {
          // console.log("Submitting values:", values);
    
          // Make API request
          const response = await axios.post(
            `${constant.baseUrl}/api/ppc/contact`,
            values,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          const responseData = {
            confirmData: response.data,
          };
        //   navigate("/confirmation", { state: { responseData } });
    
          // console.log("API Response:", response.data);
    
          // Handle success (e.g., show a success message)
          // alert("Payment submitted successfully!");
        } catch (error) {
          console.error("API Error:", error);
          alert("Failed to process Request. Please try again.");
          setIsLoading(false);
        } finally {
          setIsLoading(false);
          setSubmitting(false); // Reset form submitting state
        }
      };
  return (
    <>
    <MetaTitle pageTitle='Contact with Rawfares for Customer Support' pageDescription='You can Contact with Rawfares for Customer Support of booking, baggage, cancellation, and refund issues.' url={`${constant.baseUrl}/contact-us`} />
      <Header/>
      <section className='uyut7'>
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h1>Contact Us</h1>
                </div>
            </div>
        </div>
    </section>
    <section className='dsry4'>
        <div className="container">
            <div className="row">
                <div className="col-lg-5">
                    <div className="d-flex align-items-center justify-content-center esate1" >
                        <div className="hr6">
                            <img src={loct} alt="" />
                        </div>
                        <p className='mb-0 fdktjr'>5830 N Valentine Ave #102, Fresno, CA 93711 USA</p>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="d-flex align-items-center justify-content-center esate1" >
                        <div className="hr6">
                            <img src={cali} alt="" />
                        </div>
                        <p className='mb-0 fdktjr'><Link to={`tel:${displayNumber}`}>{displayNumber}</Link></p>
                    </div>
                    
                </div>
                <div className="col-lg-4">
                <div className="d-flex align-items-center justify-content-center esate1 mb-0" >
                        <div className="hr6">
                        <img src={mailu} alt="" />
                        </div>
                        <p className='mb-0 fdktjr'><Link to='mailto:support@rawfares.com'>support@rawfares.com</Link></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className='pt-4'>
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-6">
                    <div className="hgedt65">
                    <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmitForm}
        >
          {({ isSubmitting, values, errors, touched }) => (
            <Form>
                        <div className="row">
                            <div className="col-lg-12">
                            <h3 className='mb-4'>Get in Touch with Us</h3>
                            </div>
                            <div className="col-lg-6">
                                    <div class="form-floating mb-3">
                                        <Field type="text" class="form-control" name="firstName" id="floatingInput"  placeholder="First name*" />
                                        <label for="floatingInput">First name*</label>
                                        <ErrorMessage
                                    name="firstName"
                                    component="div"
                                    className="error"
                                  />
                                    </div>
                            </div>
                            <div className="col-lg-6">
                                    <div class="form-floating mb-3">
                                        <Field type="text" class="form-control" id="floatingInput" name="lastName" placeholder="Last name*" />
                                        <label for="floatingInput">Last name*</label>
                                        <ErrorMessage
                                    name="lastName"
                                    component="div"
                                    className="error"
                                  />
                                    </div>
                            </div>
                            <div className="col-lg-6">
                                    <div class="form-floating mb-3">
                                        <Field type="text" class="form-control" id="floatingInput" name="phoneNumber" placeholder="Phone Number*" maxlength="10" />
                                        <label for="floatingInput">Phone Number*</label>
                                        <ErrorMessage
                                    name="phoneNumber"
                                    component="div"
                                    className="error"
                                  />
                                    </div>
                            </div>
                            <div className="col-lg-6">
                                    <div class="form-floating mb-3">
                                        <Field type="text" class="form-control" id="floatingInput" name="email" placeholder="Email Address*" />
                                        <label for="floatingInput">Email Address*</label>
                                        <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="error"
                                  />
                                    </div>
                            </div>
                            <div className="col-lg-12">
                                    <div class="form-floating mb-3">
                                        <Field type="text" class="form-control es56" id="floatingTextarea2" name="remarks" placeholder="Your Comment*" />
                                        <label for="floatingTextarea2">Your Comment*</label>
                                        
                                    </div>
                            </div>
                            <div className="col-lg-12">
                                <button  className="uet54" type="submit"
                          disabled={isSubmitting}> Submit </button>
                            </div>
                        </div>
                        </Form>
                        )}
                        </Formik>
                    </div>
                </div>
                <div className="col-lg-6">
                    <img className='ieyt54' src={uyset} alt="" />
                </div>
            </div>
        </div>
    </section>


      <Footer />
    </>
  )
}
