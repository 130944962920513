/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SearchEngine from "../Component/Search-Engine/engine";
import { Helmet } from "react-helmet";
import { constant } from "../constant";
import { format } from "date-fns";
import { motion, useMotionValue, useSpring } from "framer-motion";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import {
  browserName,
  isAndroid,
  isDesktop,
  isIOS,
  isMobile,
} from "react-device-detect";
import bking from "../assest/images/icon/booking.png";
import transfr from "../assest/images/icon/transfer.png";
import Sport from "../assest/images/icon/support.png";
import rown from "../assest/images/icon/riem.png";
import sec1 from "../assest/images/sec1.webp";
import sec2 from "../assest/images/sec2.webp";
import sec3 from "../assest/images/sec3.webp";
import sec4 from "../assest/images/sec4.webp";
import sec5 from "../assest/images/sec5.webp";
import sec6 from "../assest/images/sec6.webp";
import sarr3 from "../assest/images/icon/arow.png";
import drt5e from "../assest/images/drt4.png";
import faew from "../assest/images/icon/faq.png";
import faew1 from "../assest/images/icon/faq-aro.png";
import orl from "../assest/images/home-deals/Orlando.png";
import nyork from "../assest/images/home-deals/New-York.png";
import newport from "../assest/images/home-deals/Newport.png";
import lasvegas from "../assest/images/home-deals/Las-Vegas.png";
import mex from "../assest/images/home-deals/Mexico-City.png";
import toro from "../assest/images/home-deals/Toronto.png";
import mont from "../assest/images/home-deals/Monterrey.png";
import cun from "../assest/images/home-deals/Cancun.png";

import { Link } from "react-router-dom";
import Header from "../Component/Header/header";
import Footer from "../Component/Footer/Footer";

const formatDate = (date) => {
  return date ? format(date, "MM/dd/yyyy") : "";
};

// const CountUp = ({ from, to, duration = 2, delay = 0.5, separator = "," }) => {
//   const count = useMotionValue(from);
//   const spring = useSpring(count, { damping: 20, stiffness: 100 });
//   const [displayValue, setDisplayValue] = useState(from);

//   useEffect(() => {
//     const startAnimation = () => count.set(to);
//     const timeoutId = setTimeout(startAnimation, delay * 1000); // Delay start if needed

//     return () => clearTimeout(timeoutId);
//   }, [count, to, delay]);

//   useEffect(() => {
//     const unsubscribe = spring.on("change", (latest) => {
//       setDisplayValue(
//         Math.floor(latest).toLocaleString(undefined, { useGrouping: true })
//       );
//     });

//     return () => unsubscribe();
//   }, [spring]);

//   return <motion.span>{displayValue}</motion.span>;
// };
const getYesterdayDate = () => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const options = { weekday: 'long', month: '2-digit', day: '2-digit', year: 'numeric' };
  return yesterday.toLocaleDateString('en-US', options);
};

export default function Home() {
  const [utmCampaign, setUtmCampaign] = useState("");
  const [displayNumber, setDisplayNumber] = useState("");
  const jsonMapping = {
    domestics_routes: "+1-833-609-8333",
    domestics_destination: "+1-833-609-7300",
    cruise: "+1-833-609-9933",
    airline: "+1-833-609-8444",
  };
  const defaultNumber = "+1-888-415-1536"; // Default number to display if UTM data is not found

  useEffect(() => {
    const updateDisplayNumber = () => {
      const utmData = JSON.parse(localStorage.getItem("utm_data")) || {};
      //   console.log(utmData)
      if (utmData?.utmData?.utm_campaign) {
        // console.log("in")
        setUtmCampaign(utmData?.utm_campaign);

        // Check if `utm_campaign` exists in the JSON mapping
        const mappedNumber = jsonMapping[utmData?.utmData?.utm_campaign];
        setDisplayNumber(mappedNumber || defaultNumber); // Use mapped number or default
      } else {
        // console.log("out")
        // No UTM data found, use the default number
        setDisplayNumber(defaultNumber);
      }
    };

    // Run initially
    updateDisplayNumber();

    // Set up interval to check every 10 seconds
    const interval = setInterval(updateDisplayNumber, 1000000000000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, [jsonMapping]);
  const [domesticOpen, setDomesticOpen] = React.useState(true);
  const [intlOpen, setIntlOpen] = React.useState(false);
  const [firstQuestion, setFirstQuestion] = React.useState(false);
  const [secondQuestion, setSecondQuestion] = React.useState(false);
  const [thirdQuestion, setThirdQuestion] = React.useState(false);
  const [fourthQuestion, setFourthQuestion] = React.useState(false);
  const [fifthQuestion, setFifthQuestion] = React.useState(false);
  const [sixthQuestion, setSixthQuestion] = React.useState(false);
  const [seventhQuestion, setSeventhQuestion] = React.useState(false);
  const [contentOpen, setContentOpen] = React.useState(true);
  const [loadingDealIndex, setLoadingDealIndex] = useState(null);
  const [searchId, setSearchId] = useState("");
  const [isLoding, setIsLoding] = useState(false);
  const today = new Date();
  const oneWeekFromToday = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000); // Adding milliseconds for one week
  const oneYearFromToday = new Date();
  oneYearFromToday.setFullYear(today.getFullYear() + 1); // Add one year to the current date
  const [selectedStartDate, setSelectedStartDate] = useState(oneWeekFromToday);
  const formatDateForDisplay = (date) => {
    const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
    const month = new Intl.DateTimeFormat("en", { month: "short" }).format(
      date
    );
    const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
      date
    );
    const weekday = new Intl.DateTimeFormat("en", { weekday: "long" }).format(
      date
    );

    return { day, month, year, weekday };
  };
  const { day, month, year, weekday } = formatDateForDisplay(selectedStartDate);

  const [formValues, setFormValues] = useState({
    searchID: "", // Default value or dynamic value
    client: 2,
    segment: [
      {
        originAirport: "",
        destinationAirport: "",
        travelDate: "",
        originCountry: "US",
        destinationCountry: "ALL",
      },
      {
        originAirport: "",
        destinationAirport: "",
        travelDate: "",
      },
    ],
    searchDirectFlight: false,
    flexibleSearch: false,
    tripType: "1", // Default to round trip
    adults: 1,
    child: 0,
    infants: 0,
    infantsWs: 0,
    cabinType: "1",
    airline: "All",
    currencyCode: "USD",
    siteId: 16,
    source: "Online",
    media: "",
    sID: "",
    rID: "",
    locale: "en",
    isNearBy: false,
    limit: 200,
    userIP: "",
    serverIP: "",
    device: "",
    browser: "", // Define browserName if necessary
  });
  const navigate = useNavigate();
  const handleDealClick = async (deal, index) => {
    const deviceName = isMobile
      ? "Mobile Device"
      : isDesktop
      ? "Desktop"
      : isAndroid
      ? "Android"
      : isIOS;
      setIsLoding(true);
      setLoadingDealIndex(index);
    try {
      // Fetch airport data from the API
      const searchValue = `${deal.originCode}`;
      const searchValue2 = `${deal.destinationCode}`;
      const airportResponse = await axios.get(
        `https://www.tgsapi.com/flights/CrossAirportList?data=${searchValue}&authcode=farehuts`
      );
      // console.log(
      //   "FGBG",
      //   `https://www.tgsapi.com/flights/CrossAirportList?data=${searchValue}&authcode=farehuts`
      // );
      const airport2Response = await axios.get(
        `https://www.tgsapi.com/flights/CrossAirportList?data=${searchValue2}&authcode=farehuts`
      );

      if (airportResponse?.data) {
        const airports = airportResponse.data; // Assuming the API returns an array
        const selectedOrigin = airports.find(
          (item) => item.airportCode === deal.originCode
        );

        if (selectedOrigin) {
          localStorage.setItem(
            "selectedOrigin",
            JSON.stringify(selectedOrigin)
          );
        }
      }
      if (airport2Response?.data) {
        const airports = airport2Response.data; // Assuming the API returns an array
        const selectedDestination = airports.find(
          (item) => item.airportCode === deal.destinationCode
        );

        if (selectedDestination) {
          localStorage.setItem(
            "selectedDestination",
            JSON.stringify(selectedDestination)
          );
        }
      }

      // Fetch user IP
      const response = await axios.get("https://api.ipify.org?format=json");
      const userIP = response.data.ip;

      const newSearchID = uuidv4().slice(0, 16);
      setSearchId(newSearchID);

      // Update form state based on the selected deal
      const updatedFormValues = {
        ...formValues,
        segment: [
          {
            originAirport: deal.originCode,
            destinationAirport: deal.destinationCode,
            travelDate: formatDate(deal.date),
            originCountry: "US",
            destinationCountry: "ALL",
          },
        ],
        searchID: newSearchID,
        userIP: userIP,
        device: deviceName,
      };

      setFormValues(updatedFormValues);
      setIsLoding(false);
      
      navigate("/listing", { state: { updatedFormValues } });
      setLoadingDealIndex(null);

      // console.log("Selected deal:", updatedFormValues);
    } catch (error) {
      setIsLoding(false)
      setLoadingDealIndex(null);
      console.error("API fetch error:", error);
    }
  };

  const DomesticDeals = [
    {
      origin: `ATL`,
      originCode: `ATL`,
      img: orl,
      originName: "Atlanta",
      destination: "Orlando",
      destinationCode: "MCO",
      date: "2025-06-16",
      returnDate: "2025-02-27",
      price: "USD 49*",
      tripType: "One Way Trip",
      formTrip: "1",
    },
    {
      origin: `ATL`,
      originCode: `ATL`,
      img: nyork,
      originName: "Atlanta",
      destination: "New York",
      destinationCode: "NYC",
      date: "2025-06-16",
      returnDate: "2025-02-27",
      price: "USD 37*",
      tripType: "One Way Trip",
      formTrip: "1",
    },
    {
      origin: `BOS`,
      originCode: `BOS`,
      img: newport,
      originName: "Boston",
      destination: "Orlando",
      destinationCode: "MCO",
      date: "2025-06-16",
      returnDate: "2025-02-27",
      price: "USD 63*",
      tripType: "One Way Trip",
      formTrip: "1",
    },
    {
      origin: `DEN`,
      originCode: `DEN`,
      img: lasvegas,
      originName: "Denver",
      destination: "Los Angeles",
      destinationCode: "LAX",
      date: "2025-06-16",
      returnDate: "2025-02-27",
      price: "USD 90*",
      tripType: "One Way Trip",
      formTrip: "1",
    },
  ];
  const IntlDeals = [
    {
      origin: `ATL`,
      originCode: `ATL`,
      img: mex,
      originName: "Atlanta",
      destination: "Mexico City",
      destinationCode: "MEX",
      date: "2025-06-16",
      returnDate: "2025-02-27",
      price: "USD 223*",
      tripType: "One Way Trip",
      formTrip: "1",
    },
    {
      origin: `ORD`,
      originCode: `ORD`,
      img: toro,
      originName: "Chicago",
      destination: "Toronto",
      destinationCode: "YYZ",
      date: "2025-06-16",
      returnDate: "2025-02-27",
      price: "USD 111*",
      tripType: "One Way Trip",
      formTrip: "1",
    },
    {
      origin: `DFW`,
      originCode: `DFW`,
      img: mont,
      originName: "Dallas",
      destination: "Monterrey",
      destinationCode: "MTY",
      date: "2025-06-16",
      returnDate: "2025-02-27",
      price: "USD 120*",
      tripType: "One Way Trip",
      formTrip: "1",
    },
    {
      origin: `DFW`,
      originCode: `DFW`,
      img: cun,
      originName: "Dallas",
      destination: "Cuncun",
      destinationCode: "CUN",
      date: "2025-06-16",
      returnDate: "2025-02-27",
      price: "USD 163*",
      tripType: "One Way Trip",
      formTrip: "1",
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          Rawfares - Cheap Airlines Flight Tickets, Search and Compare Airfare
          Deals
        </title>
        <meta
          name="description"
          content="RawFares offers unbeatable travel deals. Connect with our team to get affordable travel options."
        />
        {/* Other meta tags */}
        <link rel="canonical" href={`${constant.baseUrl}/`} />
        {/* Additional meta tags for homepage */}
      </Helmet>
      <Header />
      <SearchEngine
        headText={
          <>
            Search and book cheap flights on over{" "}
            500 airlines!
          </>
        }
        imgSpecial={true} img='https://imgfolders.com/rawfaresnew/banner/Home.webp'
      />

      <section className="dtjrrdt wqerq45 mob-hide">
        <div className="container">
          <Link to={`tel:${displayNumber}`}>
            <div className="row sfs34f align-items-center">
              <div className="col-lg-3">
                <p className="ghjge3">
                  Call us 24/7 at <span>{displayNumber}</span>
                </p>
                <p className="wri34d mb-0">
                  Need help booking? Our agents are ready! Choose from over 500
                  airlines.
                </p>
              </div>
              <div className="col-lg-9 ">
                <div className="row w-100 m-auto dftgdfty">
                  <div className="col-lg-4">
                    <div className="d-flex align-items-start">
                      <img src={bking} alt="" />
                      <div className="fdtd3">
                        <p className="mb-0 e5t66">EASY BOOKING</p>
                        <p className="mb-0 dtgr6r4">
                          We offer easy and convenient flight booking with
                          attractive offers.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 rftw4">
                    <div className="d-flex align-items-start">
                      <img src={transfr} alt="" />
                      <div className="fdtd3 ">
                        <p className="mb-0 e5t66">LOWEST PRICE</p>
                        <p className="mb-0 dtgr6r4">
                          We ensure low rates on airlines reservation, holiday
                          packages and on flight tickets.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="d-flex align-items-start">
                      <img src={Sport} alt="" />
                      <div className="fdtd3">
                        <p className="mb-0 e5t66">24/7 SUPPORT</p>
                        <p className="mb-0 dtgr6r4">
                          Get assistance 24/7 on any kind of travel related
                          query. We are happy to assist you.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </section>

      <section className="dtjrrdt wqerq45">
        <div className="container">
          <div className="row">
            <h3 className="ueyru4">Popular Destination</h3>
            <div className="col-6 col-lg-3 resty">
              <Link to={`/flights/cheap-flights-to-san-francisco-sfo`}>
                <div className="srhrw4">
                  <img className="ikshri3 wrs6rr33 " src={sec1} alt="" />
                  <div className="sjr4">
                    <div className="row">
                      <div className="col-lg-6 order-2 order-lg-1">
                        <p className="siryh4">San Francisco</p>
                        <p className="shru24 ier9u">Starting from</p>
                        <p className="hjgrjw4 ier9u">$215*</p>
                      </div>
                      <div className="col-lg-6 d-flex justify-content-end align-items-end order-1 order-lg-2">
                        <div className="swrs ier9u ">
                          <img src={rown} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              <Link to={`/flights/cheap-flights-to-chicago-ord`}>
                <div className="srhrw4">
                  <img className="ikshri3 wrs6rr33 " src={sec2} alt="" />
                  <div className="sjr4">
                    <div className="row">
                      <div className="col-lg-6 order-2 order-lg-1">
                        <p className="siryh4">Chicago</p>
                        <p className="shru24 ier9u">Starting from</p>
                        <p className="hjgrjw4 ier9u">$199*</p>
                      </div>
                      <div className="col-lg-6 d-flex justify-content-end align-items-end order-1 order-lg-2">
                        <div className="swrs ier9u">
                          <img src={rown} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 mob-hide">
              <Link to={`/flights/cheap-flights-to-miami-mia`}>
                <div className="srhrw4 rt6er6">
                  <img className="ikshri3 h-100" src={sec3} alt="" />
                  <div className="sjr4">
                    <div className="row">
                      <div className="col-lg-6">
                        <p className="siryh4">Miami</p>
                        <p className="shru24 ier9u">Starting from</p>
                        <p className="hjgrjw4 ier9u">$89*</p>
                      </div>
                      <div className="col-lg-6 d-flex justify-content-end align-items-end">
                        <div className="swrs ier9u">
                          <img src={rown} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-6 col-lg-5 resty">
              <Link to={`/flights/cheap-flights-to-atlanta-atl`}>
                <div className="srhrw4">
                  <img className="ikshri3 wrs6rr33 " src={sec4} alt="" />
                  <div className="sjr4">
                    <div className="row">
                      <div className="col-lg-6 order-2 order-lg-1">
                        <p className="siryh4">Atlanta</p>
                        <p className="shru24 ier9u">Starting from</p>
                        <p className="hjgrjw4 ier9u">$99*</p>
                      </div>
                      <div className="col-lg-6 d-flex justify-content-end align-items-end order-1 order-lg-2">
                        <div className="swrs ier9u">
                          <img src={rown} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              <div className="row w-100 m-auto">
                <div className="col-lg-5 ps-0 mob-hide">
                  <Link to={`/flights/cheap-flights-to-austin-aus`}>
                    <div className="srhrw4">
                      <img className="ikshri3 wrs6rr33 " src={sec5} alt="" />
                      <div className="sjr4">
                        <div className="row">
                          <div className="col-lg-6">
                            <p className="siryh4">Austin</p>
                            <p className="shru24 ier9u">Starting from</p>
                            <p className="hjgrjw4 ier9u">$110*</p>
                          </div>
                          <div className="col-lg-6 d-flex justify-content-end align-items-end">
                            <div className="swrs ier9u">
                              <img src={rown} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-7 pe-0 ry634">
                  <Link to={`/flights/cheap-flights-to-boston-bos`}>
                    <div className="srhrw4">
                      <img className="ikshri3 wrs6rr33 " src={sec6} alt="" />
                      <div className="sjr4">
                        <div className="row">
                          <div className="col-lg-6 order-2 order-lg-1">
                            <p className="siryh4">Boston</p>
                            <p className="shru24 ier9u">Starting from</p>
                            <p className="hjgrjw4 ier9u">$105*</p>
                          </div>
                          <div className="col-lg-6 d-flex justify-content-end align-items-end order-1 order-lg-2">
                            <div className="swrs ier9u">
                              <img src={rown} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <p className="srtr5e3">
                <span>Note:</span> All fares are quoted in USD. Last updated on
                {getYesterdayDate()} at 05:00 AM, the fares mentioned above are
                for Round Trip flight tickets and inclusive of fuel surcharges,
                service fee and taxes . Based on historical data, these fares
                are subject to change without prior notice and cannot be
                guaranteed at the time of booking. Kindly go through our terms
                and conditions before booking
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="dtjrrdt wqerq45 pt-0 mob-hide">
        <div className="container">
          <div className="row jkhdt">
            <div className="col-lg-8 d-flex align-items-center">
              <button
                className={`edrt4 ${domesticOpen ? "dt4ff" : ""}`}
                onClick={() => {
                  setDomesticOpen(true);
                  setIntlOpen(false);
                }}
              >
                Top Domestic Flight Deals{" "}
              </button>
              <button
                className={`edrt4 ${intlOpen ? "dt4ff" : ""}`}
                onClick={() => {
                  setIntlOpen(true);
                  setDomesticOpen(false);
                }}
              >
                Top Internationl Flight Deals{" "}
              </button>
            </div>
            <div className="col-lg-4 d-flex align-items-center justify-content-end">
              <Link to="/sitemap">
                <p className="ietr3">
                  View All <img src={sarr3} alt="" />{" "}
                </p>
              </Link>
            </div>
            {domesticOpen ? (
              <div className="col-lg-12">
                {DomesticDeals.map((deal, index) => (
                  <div
                    className="row align-items-center text-center ew63ew"
                    key={index}
                    onClick={() => handleDealClick(deal, index)}
                  >
                    <div className="col-lg-1">
                      <img src={deal.img} alt="" />
                    </div>
                    <div className="col-lg-4">
                      <p className="mb-0 ewtay3 text-start">
                        {deal.originName} to {deal.destination}
                      </p>
                    </div>
                    <div className="col-lg-2">
                      <p className="mb-0 ewtay3">{deal.price}</p>
                    </div>
                    <div className="col-lg-2">
                      <p className="mb-0 ewtay3">{deal.date}</p>
                    </div>
                    <div className="col-lg-3">
                      {/* <Link> */}
                        <p className="mb-0 sar3d ewtay3" style={{cursor: 'pointer'}}>
                        {loadingDealIndex === index ? (
                          <div class="spinner14">
                              <span></span>
                              <span></span>
                              <span></span>
                            </div>
                            ) : 'Book Now'}
                        </p>
                      {/* </Link> */}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="col-lg-12">
                {IntlDeals.map((deal, index) => (
                  <div
                    className="row align-items-center text-center ew63ew"
                    key={index}
                    onClick={() => handleDealClick(deal, index)}
                  >
                    <div className="col-lg-1">
                      <img src={deal.img} alt="" />
                    </div>
                    <div className="col-lg-4">
                      <p className="mb-0 ewtay3 text-start">
                        {deal.originName} to {deal.destination}
                      </p>
                    </div>
                    <div className="col-lg-2">
                      <p className="mb-0 ewtay3">{deal.price}</p>
                    </div>
                    <div className="col-lg-2">
                      <p className="mb-0 ewtay3">{deal.date}</p>
                    </div>
                    <div className="col-lg-3">
                      {/* <Link> */}
                        <p className="mb-0 sar3d ewtay3" style={{cursor: 'pointer'}}>{loadingDealIndex === index ? (
                          <div class="spinner14">
                              <span></span>
                              <span></span>
                              <span></span>
                            </div>
                            ) : 'Book Now'}</p>
                      {/* </Link> */}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="col-lg-12">
            <p className="srtr5e3">
              <span>Note:</span> All fares are quoted in USD. Last updated on
              {getYesterdayDate()} at 05:00 AM, the fares mentioned above are for
              One Way Trip flight tickets and inclusive of fuel surcharges,
              service fee and taxes . Based on historical data, these fares are
              subject to change without prior notice and cannot be guaranteed at
              the time of booking. Kindly go through our terms and conditions
              before booking
            </p>
          </div>
        </div>
      </section>

      <section className="dtjrrdt wqerq45 pt-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="ueyru4">
                Book Cheap Flight Tickets with RawFares
              </h3>
              <p className="jie5i4">
                Flight Travel does not have to force you to always stretch your
                budget. At RawFares, affordability meets convenience, making it
                simple for you to plan your journey with zero stress! We
                specialize in finding the best rates on flights to your favorite
                destinations. With easy-to-use features and a vow to provide
                WOW-deals, RawFares makes booking flights effortless and
                budget-friendly. Start saving on your travel plans today!
              </p>
              {contentOpen && (
                <>
                  <h4>Why Choose RawFares?</h4>
                  <p className="jie5i4">
                    <ul>
                      <li>
                        <b>Competitive Prices:</b> Find unbeatable flight deals
                        tailored to your budget.
                      </li>
                      <li>
                        <b>User-Friendly Platform:</b> Search and book cheap
                        flights effortlessly with a clear, intuitive layout.
                      </li>
                      <li>
                        <b>Reliable Customer Support:</b> Count on our team at
                        <Link to={`tel:${displayNumber}`}>
                          {displayNumber}
                        </Link>{" "}
                        for assistance whenever you need it.
                      </li>
                      <li>
                        <b>Exclusive Offers:</b> Access guaranteed deals you
                        won’t find elsewhere.
                      </li>
                    </ul>
                  </p>
                  <h4>Find the Best Flight Deals Today</h4>
                  <p className="jie5i4">
                    Your next adventure is just a few clicks away. RawFares
                    brings you affordable travel options with deals that cater
                    to every budget. Don’t miss out on discounted airfares for
                    top destinations. With secure booking and tailored search
                    filters, planning your trip has never been easier. Take
                    action now and find your perfect flight at a price that
                    feels just right.
                  </p>
                </>
              )}
              <p
                className="irt56"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setContentOpen(!contentOpen);
                }}
              >
                {contentOpen ? "Less..." : "Read More..."}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="dtjrrdt wqerq45 pt-0 mob-hide">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="ueyru4">FAQ About Air Ticket Reservation</h3>
            </div>
            <div className="col-lg-12">
              <div
                className={`sjrg ${firstQuestion ? "dtgedt" : ""}`}
                onClick={() => {
                  setFirstQuestion(!firstQuestion);
                  setSecondQuestion(false);
                  setThirdQuestion(false);
                  setFourthQuestion(false);
                  setFifthQuestion(false);
                  setSixthQuestion(false);
                  setSeventhQuestion(false);
                }}
              >
                <div className="faur">
                  <div className="d-flex align-items-center">
                    <img className="me-3" src={faew} alt="" />
                    <p className="asruhe">
                      What makes RawFares the best choice for booking air
                      tickets?
                    </p>
                  </div>
                  <img src={faew1} alt="" />
                </div>
                {firstQuestion && (
                  <div className="dsjte">
                    <hr />
                    <p className="mb-0 sewr32">
                      RawFares combines competitive pricing on cheap flights,
                      secure payment options, and exclusive discounts to provide
                      exceptional value. With user-friendly search tools and
                      reliable customer support, we ensure a hassle-free booking
                      experience. Whether you&#39;re planning a family vacation
                      or a solo adventure, RawFares makes it easy to find
                      affordable options while prioritizing safety and
                      convenience, making it a trusted partner for your travel
                      needs.
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-12">
              <div
                className={`sjrg ${secondQuestion ? "dtgedt" : ""} `}
                onClick={() => {
                  setSecondQuestion(!secondQuestion);
                  setFirstQuestion(false);
                }}
              >
                <div className="faur">
                  <div className="d-flex align-items-center">
                    <img className="me-3" src={faew} alt="" />
                    <p className="asruhe">
                      How can you find the lowest airfares on RawFares?
                    </p>
                  </div>
                  <img src={faew1} alt="" />
                </div>
                {secondQuestion && (
                  <div className="dsjte">
                    <hr />
                    <p className="mb-0 sewr32">
                      Securing the best airfares with us is straightforward.
                      Book early, explore the deals section for seasonal offers,
                      and use the website’s filters to compare budget-friendly
                      travel options. By subscribing to the newsletter, you’ll
                      receive regular updates on exclusive discounts and insider
                      tips, ensuring you always have access to the most
                      affordable flight deals tailored to your travel
                      preferences.
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div className="col-lg-12">
              <div
                className={`sjrg ${thirdQuestion ? "dtgedt" : ""} `}
                onClick={() => {
                  setThirdQuestion(!thirdQuestion);
                  setFirstQuestion(false);
                  setSecondQuestion(false);
                  setFourthQuestion(false);
                  setFifthQuestion(false);
                  setSixthQuestion(false);
                  setSeventhQuestion(false);
                }}
              >
                <div className="faur">
                  <div className="d-flex align-items-center">
                    <img className="me-3" src={faew} alt="" />
                    <p className="asruhe">
                      Where can you find the latest flight deals on RawFares?
                    </p>
                  </div>
                  <img src={faew1} alt="" />
                </div>
                {thirdQuestion && (
                  <div className="dsjte">
                    <hr />
                    <p className="mb-0 sewr32">
                      Finding great flight deals is easy on RawFares. Use the
                      search bar to browse affordable options, visit the
                      dedicated offers page for updated discounts, and follow us
                      on social media for regular announcements. These platforms
                      are designed to keep travelers informed about the best
                      deals, ensuring you never miss an opportunity to save on
                      your next adventure.
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-12">
              <div
                className={`sjrg ${fourthQuestion ? "dtgedt" : ""} `}
                onClick={() => {
                  setFourthQuestion(!fourthQuestion);
                  setFirstQuestion(false);
                  setSecondQuestion(false);
                  setThirdQuestion(false);
                  setFifthQuestion(false);
                  setSixthQuestion(false);
                  setSeventhQuestion(false);
                }}
              >
                <div className="faur">
                  <div className="d-flex align-items-center">
                    <img className="me-3" src={faew} alt="" />
                    <p className="asruhe">
                      How do you confirm that your flight booking with RawFares
                      is successful?
                    </p>
                  </div>
                  <img src={faew1} alt="" />
                </div>
                {fourthQuestion && (
                  <div className="dsjte">
                    <hr />
                    <p className="mb-0 sewr32">
                      Once your booking is finalized, RawFares sends an email
                      confirmation containing all your travel details. This
                      email serves as proof of your reservation and includes
                      everything you need for your trip. If you encounter any
                      issues or have questions, our 24/7 customer support team
                      is always available at{" "}
                      <Link to={`tel:${displayNumber}`}>{displayNumber}</Link>{" "}
                      to assist and provide peace of mind.
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-12">
              <div
                className={`sjrg ${fifthQuestion ? "dtgedt" : ""} `}
                onClick={() => {
                  setFifthQuestion(!fifthQuestion);
                  setFirstQuestion(false);
                  setSecondQuestion(false);
                  setThirdQuestion(false);
                  setFourthQuestion(false);
                  setSixthQuestion(false);
                  setSeventhQuestion(false);
                }}
              >
                <div className="faur">
                  <div className="d-flex align-items-center">
                    <img className="me-3" src={faew} alt="" />
                    <p className="asruhe">
                      Can you modify your booking after making a reservation on
                      RawFares?
                    </p>
                  </div>
                  <img src={faew1} alt="" />
                </div>
                {fifthQuestion && (
                  <div className="dsjte">
                    <hr />
                    <p className="mb-0 sewr32">
                      Yes, RawFares allows you to make changes to your booking.
                      You can access the “Manage Booking” section on the website
                      or contact the customer support team at{" "}
                      <Link to={`tel:${displayNumber}`}>{displayNumber}</Link>
                      for assistance. Keep in mind that changes may incur
                      additional fees, depending on the airline’s policy. The
                      process is designed to ensure flexibility and accommodate
                      unexpected changes in travel plans.
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-12">
              <div
                className={`sjrg ${sixthQuestion ? "dtgedt" : ""} `}
                onClick={() => {
                  setSixthQuestion(!sixthQuestion);
                  setFirstQuestion(false);
                  setSecondQuestion(false);
                  setThirdQuestion(false);
                  setFourthQuestion(false);
                  setFifthQuestion(false);
                  setSeventhQuestion(false);
                }}
              >
                <div className="faur">
                  <div className="d-flex align-items-center">
                    <img className="me-3" src={faew} alt="" />
                    <p className="asruhe">
                      What payment options does RawFares offer?
                    </p>
                  </div>
                  <img src={faew1} alt="" />
                </div>
                {sixthQuestion && (
                  <div className="dsjte">
                    <hr />
                    <p className="mb-0 sewr32">
                      We provide a variety of payment methods to suit every
                      traveler’s needs. Options include credit cards, debit
                      cards, and secure digital wallets, ensuring both
                      convenience and security. These trusted payment systems
                      are designed to provide a smooth and reliable booking
                      experience, giving you the flexibility to choose the
                      method that works best for you while protecting your
                      financial information.
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-12">
              <div
                className={`sjrg ${seventhQuestion ? "dtgedt" : ""} `}
                onClick={() => {
                  setSeventhQuestion(!seventhQuestion);
                  setFirstQuestion(false);
                  setSecondQuestion(false);
                  setThirdQuestion(false);
                  setFourthQuestion(false);
                  setFifthQuestion(false);
                  setSixthQuestion(false);
                }}
              >
                <div className="faur">
                  <div className="d-flex align-items-center">
                    <img className="me-3" src={faew} alt="" />
                    <p className="asruhe">
                      How do you stay updated on exclusive deals from RawFares?
                    </p>
                  </div>
                  <img src={faew1} alt="" />
                </div>
                {seventhQuestion && (
                  <div className="dsjte">
                    <hr />
                    <p className="mb-0 sewr32">
                      Staying in the loop with us is simple. Sign up for the
                      newsletter to receive the latest deals directly in your
                      inbox, and follow RawFares on social media for updates on
                      cheap flights and travel tips. These channels make sure
                      you are always informed about the most affordable options
                      for your next trip, helping you plan your travels wisely.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
