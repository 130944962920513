/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Footer from "../Component/Footer/Footer";
import Header from "../Component/Header/header";
import axios from "axios";
import { constant } from "../constant";
import MetaTitle from '../Component/MetaTitle/metaTitle';
export default function Sitemap() {
    const [domesticAirports, setDomesticAirports] = useState([]);
  const [internationalAirports, setInternationalAirports] = useState([]);
  const [domesticAirportsRoute, setDomesticAirportsRoute] = useState([]);
  const [internationalAirportsRoute, setInternationalAirportsRoute] = useState([]);
  const [dealsData, setDealsData] = useState([]);
  const [domesticOpenFlights, setDomesticOpenFlights] = useState(true);
  const [internationalOpenFlights, setInternationalOpenFlights] = useState(true);
  const [domesticOpenRoutes, setDomesticOpenRoutes] = useState(true);
  const [internationalOpenRoutes, setInternationalOpenRoutes] = useState(true);
  const [dealsOpen, setDealsOpen] = useState(true);
  const [airlinesOpen, setAirlinesOpen] = useState(true);
  const [quickLinksOpen, setQuickLinksOpen] = useState(true);
  const [airlinesData, setAirlinesData] = useState([]);
  const [airlines, setAirlines] = useState({
    pageType: "Airline",
    pageValue: "",
    siteId: "16",
    url: ""
  });

  const [deals, setDeals] = useState({
    froCity: "",
    toCity: "",
    airline: airlines.pageValue,
    cabinClass: "Y",
    tripType: "2",
    page: "",
    currency: "USD",
    siteId: "16",
    departDate: "",
    countryName: "US",
    countryCode: "US",
    cityName: ""
  });
  const [responseData, setResponseData] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    // Use a useEffect to make the API call whenever airlines state changes
    if (airlines.pageValue && airlines.url) {
      handleAirlineClicked();
    }
  }, [airlines]);

  const handleAirlineClicked = async () => {

    const navigationPath =
      airlines.pageValue === "flight"
        ? `/deals/${airlines.url}-${airlines.pageValue}`
        : airlines.pageValue.length === 2
          ? `/airline/${airlines.url}-${airlines.pageValue}-flights`
          : `/flights-to/${airlines.url}-${airlines.pageValue}`;


    const newTab = window.open(navigationPath, '_blank');
    if (newTab) {
      //console.log('Received data in new tab:');
      newTab.onload = function () {
        newTab.postMessage(window.location.origin);
      };
    } else {
      //console.error('Failed to open new tab. Please check your browser settings or extensions.');
    }

    // Navigate to the new page with data passed via state
    //navigate(navigationPath);
  };



  const handleCardClick = (pageType, pageValue, url) => {
    // Set the new values for airlines based on the card clicked
    setAirlines({
      ...airlines,
      pageType,
      pageValue,
      url,
    });
  };

  const openLink = (url) => {
    window.open(url, '_blank', 'width=650,height=550,left=300,top=50');
  };

  const sitemapRef = useRef(null);

  // Handle the scroll when the "View More" button is clicked
  const handleScrollToSitemap = () => {
    if (sitemapRef.current) {
      sitemapRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const handleAllData = async () => {
    const payloadFirst = {
      pageType: "City_PPC",
      siteId: "16",
    };
  
    try {
      const apiResponse = await axios.post(`${constant.baseUrl}/api/ppc/sitemap`, payloadFirst);
  
      if (apiResponse.status === 200) {
        // Initialize lists for domestic and international airports
        const domesticAirports = [];
        const internationalAirports = [];
        const seenCities = new Set(); // Track unique city names
        // console.log("CityPPC", apiResponse.data.response)
        // Process the API response data directly
        apiResponse.data.response.forEach((item) => {
          const fromCountry = item.fromCountry ? item.fromCountry.trim().toUpperCase() : null;
          const category = fromCountry === "US" ? "Domestic" : "International";
  
          if (!seenCities.has(item.url.trim())) {
            seenCities.add(item.url.trim());
            
            const airportWithUrl = {
              ...item,
              category,
              url: item.url, // Include the URL
            };
  
            // Categorize the airport
            if (category === "Domestic") {
              domesticAirports.push(airportWithUrl);
            } else {
              internationalAirports.push(airportWithUrl);
            }
          }
        });
  
        // Log and update state
        // console.log("Domestic Airports:", domesticAirports);
        // console.log("International Airports:", internationalAirports);
        setDomesticAirports(domesticAirports);
        setInternationalAirports(internationalAirports);
  
        // Return or use the lists as needed
        return { domesticAirports, internationalAirports };
      } else {
        // console.log("Error: API response status is not 200");
      }
    } catch (error) {
      console.error("Error fetching sitemap data:", error.message);
    }
  };
  

  const handleAllDataRoute = async () => {
    const payloadFirst = {
      pageType: "Routes_PPC",
      siteId: "16",
    };
  
    try {
      const apiResponse = await axios.post(`${constant.baseUrl}/api/ppc/sitemap`, payloadFirst);
  
      if (apiResponse.status === 200) {
        // Initialize lists for domestic and international airports
        const domesticAirportsRoute = [];
        const internationalAirportsRoute = [];
        const seenCitiesRoute = new Set(); // Track unique city names
        // console.log("apiResponse", apiResponse)
        // Process the API response data directly
        apiResponse.data.response.forEach((item) => {
          const toCountry = item.toCountry.trim().toUpperCase();
          const category = toCountry === "US" ? "Domestic" : "International";

          // console.log(`Item: ${item.pageName}, toCountry: "${toCountry}", Category: ${category}`);
  
          if (!seenCitiesRoute.has(item.url.trim())) {
            seenCitiesRoute.add(item.url.trim());
  
            const airportWithUrl = {
              ...item,
              category,
              url: item.url, // Include the URL
            };
  
            // Categorize the airport
            if (category === "Domestic") {
              domesticAirportsRoute.push(airportWithUrl);
            } else {
              internationalAirportsRoute.push(airportWithUrl);
            }
          }
        });
  
        // Log and update state
        // console.log("Domestic Airports:", domesticAirportsRoute);
        // console.log("International Airports:", internationalAirportsRoute);
        setDomesticAirportsRoute(domesticAirportsRoute);
        setInternationalAirportsRoute(internationalAirportsRoute);
  
        // Return or use the lists as needed
        return { domesticAirportsRoute, internationalAirportsRoute };
      } else {
        // console.log("Error: API response status is not 200");
      }
    } catch (error) {
      console.error("Error fetching sitemap data:", error.message);
    }
  };

  const handleAllDataAirlines = async () => {
    const payloadFirst = {
      pageType: "Airline",
      siteId: "16",
    };
  
    try {
      const apiResponse = await axios.post(`${constant.baseUrl}/api/ppc/sitemap`, payloadFirst);
      // console.log(apiResponse)
      if (apiResponse.status === 200) {
        setAirlinesData(apiResponse.data.response)
      } else {} } catch {}
  }

  const handleDeals = async () => {
    const payloadFirst = {
      pageType: "Deals",
      siteId: "16",
    };
  
    try {
      const apiResponse = await axios.post(`${constant.baseUrl}/api/ppc/sitemap`, payloadFirst);
      // console.log(apiResponse)
      if (apiResponse.status === 200) {
        setDealsData(apiResponse.data.response)
      } else {} } catch {}
  }


  useEffect(() => {
    handleAllData();
    handleAllDataRoute();
    handleAllDataAirlines();
    handleDeals();
  }, []);

  // console.log("srhtshhsfg", domesticAirportsRoute)
  return (
    <>
      <MetaTitle pageTitle="Explore Sitemap of rawfares.com" pageDescription="Check the sitemap to get the details of flights and other information at rawfares.com" url={`${constant.baseUrl}/sitemap`} />
     <Header />
      <section className="py-4">
        <div className="container">
        <div class="row cvkfujg p-3 shadow rounded mb-3">
            <h4 className="my-3 edryte">Quick Links</h4>
            <div className="col-lg-12">
              <ul className={`ie4yt4 ${quickLinksOpen ? '' : 'sdft4'}`}>
                <li>
                  <Link to='/about'>About Us </Link>
                </li>
                <li>
                  <Link to='/privacy-policy'>Privacy Policy </Link>
                </li>
                <li>
                  <Link to='/terms-and-condition'>Terms & Condition </Link>
                </li>
                <li>
                  <Link to='/disclaimer'>Disclaimer </Link>
                </li>
                <li>
                  <Link to='/contact-us'>Contact Us </Link>
                </li>
                <li>
                  <Link to='/our-services-fee'>Our Service Fee </Link>
                </li>
                <li>
                  <Link to='/post-ticketing-fee'>Post Ticketing </Link>
                </li>
                <li>
                  <Link to='/taxes-and-fees'>Taxes & Fees </Link>
                </li>
                <li>
                  <Link to='/airline-baggage-fee'>Airline Baggage Fee </Link>
                </li>
                <li>
                  <Link to='/online-check-in'>Online CheckIn </Link>
                </li>
                <li>
                  <Link to='/cruise'>Cruise </Link>
                </li>
                <li>
                  <Link to='/flights'>Flights </Link>
                </li>
              </ul>
              {/* <p className='ujyt8' onClick={() => {setQuickLinksOpen(!quickLinksOpen)}}>view more</p> */}
            </div>
          </div>
          <div className="row cvkfujg p-3 shadow rounded mb-3">
            <h4 className="my-3 edryte">Popular Domestic Flights</h4>
            <div className="col-lg-12">
              <ul className={`ie4yt4 ${domesticOpenFlights ? '' : 'sdft4'}`}>
              {domesticAirports.map((item, index) => (
                <li key={index}>
                  <Link to={`/flights/${item.url}`} target='_blank'>Flights to {item.pageName} </Link>
                </li>
                ))}
              </ul>
              <p className='ujyt8' onClick={() => {setDomesticOpenFlights(!domesticOpenFlights)}}>{domesticOpenFlights ? 'view less' : 'view more'}</p>
            </div>
            
          </div>
          <div className="row cvkfujg p-3 shadow rounded mb-3">
            <h4 className="my-3 edryte">Popular Domestic Routes</h4>
            <div className="col-lg-12">
              <ul className={`ie4yt4 ${domesticOpenRoutes ? '' : 'sdft4'}`}>
              {domesticAirportsRoute.map((item, index) => (
                <li key={index}>
                  <Link to={`/flights/${item.url}`} target='_blank'>Flights from {item.pageName} to {item.toCity} </Link>
                </li>
                 ))}
              </ul>
              <p className='ujyt8' onClick={() => {setDomesticOpenRoutes(!domesticOpenRoutes)}}>{domesticOpenRoutes ? 'view less' : 'view more'}</p>
            </div>
          </div>
          <div className="row cvkfujg p-3 shadow rounded mb-3">
            <h4 className="my-3 edryte">Popular International Flights</h4>
            <div className="col-lg-12">
              <ul className={`ie4yt4 ${internationalOpenFlights ? '' : 'sdft4'}`}>
              {internationalAirports.map((item, index) => (
                <li key={index}>
                  <Link to={`/flights/${item.url}`} target='_blank'>Flights to {item.pageName} </Link>
                </li>
                ))}
              </ul>
              {/* <p className='ujyt8' onClick={() => {setInternationalOpenFlights(!internationalOpenFlights)}}>{internationalOpenFlights ? 'view less' : 'view more'}</p> */}
            </div>
          </div>
          <div className="row cvkfujg p-3 shadow rounded mb-3">
            <h4 className="my-3 edryte">Popular International Routes</h4>
            <div className="col-lg-12">
              <ul className={`ie4yt4 ${internationalOpenRoutes ? '' : 'sdft4'}`}>
              {internationalAirportsRoute.map((item, index) => (
                <li key={index}>
                  <Link to={`/flights/${item.url}`} target='_blank'>Flights from {item.pageName} to {item.toCity} </Link>
                </li>
                ))}
              </ul>
              {/* <p className='ujyt8' onClick={() => {setInternationalOpenRoutes(!internationalOpenRoutes)}}>view more</p> */}
            </div>
          </div>
          <div className="row cvkfujg p-3 shadow rounded mb-3">
            <h4 className="my-3 edryte">Popular Deals</h4>
            <div className="col-lg-12">
              <ul className={`ie4yt4 ${dealsOpen ? '' : 'sdft4'}`}>
              {dealsData.map((item, index) => (
                <li key={index}>
                  <Link to={`/deals/${item.url}`}>{item.pageName}</Link>
                </li>
                ))}
              </ul>
              <p className='ujyt8' onClick={() => {setDealsOpen(!dealsOpen)}}>{dealsOpen ? 'view less' : 'view more'}</p>
            </div>
          </div>
          <div className="row cvkfujg p-3 shadow rounded mb-3">
            <h4 className="my-3 edryte">Top Airlines</h4>
            <div className="col-lg-12">
              <ul className={`ie4yt4 ${airlinesOpen ? '' : 'sdft4'}`}>
              {airlinesData.map((item, index) => (
                <li key={index}>
                  <Link to={`/airline/${item.url}`}>{item.pageName} </Link>
                </li>
              ))}
              </ul>
              {/* <p className='ujyt8' onClick={() => {setAirlinesOpen(!airlinesOpen)}}>view more</p> */}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
