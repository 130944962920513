const USState = [ 
             { 'ID': 'AA'  ,'Name': 'AA-Armed Forces (the) Americas'  },
                { 'ID': 'AE'  ,'Name': 'AE-Armed Forces Elsewhere'  },
                { 'ID': 'AP'  ,'Name': 'AP-Armed Forces Pacific'  },
                { 'ID': 'AL'  ,'Name': 'AL-Alabama'  },
                { 'ID': 'AK'  ,'Name': 'AK-Alaska'  },
                { 'ID': 'AZ'  ,'Name': 'AZ-Arizona'  },
                { 'ID': 'AR'  ,'Name': 'AR-Arkansas'  },
                { 'ID': 'CA'  ,'Name': 'CA-California'  },
                { 'ID': 'CO'  ,'Name': 'CO-Colorado'  },
                { 'ID': 'CT'  ,'Name': 'CT-Connecticut'  },
                { 'ID': 'DE'  ,'Name': 'DE-Delaware'  },
                { 'ID': 'DC'  ,'Name': 'DC-District of Columbia'  },
                { 'ID': 'FL'  ,'Name': 'FL-Florida'  },
                { 'ID': 'GA'  ,'Name': 'GA-Georgia'  },
                { 'ID': 'HI'  ,'Name': 'HI-Hawaii'  },
                { 'ID': 'ID'  ,'Name': 'ID-Idaho'  },
                { 'ID': 'IL'  ,'Name': 'IL-Illinois'  },
                { 'ID': 'IN'  ,'Name': 'IN-Indiania'  },
                { 'ID': 'IA'  ,'Name': 'IA-Iowa'  },
                { 'ID': 'KS'  ,'Name': 'KS-Kansas'  },
                { 'ID': 'KY'  ,'Name': 'KY-Kentucky'  },
                { 'ID': 'LA'  ,'Name': 'LA-Louisiana'  },
                { 'ID': 'ME'  ,'Name': 'ME-Maine'  },
                { 'ID': 'MD'  ,'Name': 'MD-Maryland'  },
                { 'ID': 'MA'  ,'Name': 'MA-Massachusetts'  },
                { 'ID': 'MI'  ,'Name': 'MI-Michigan'  },
                { 'ID': 'MN'  ,'Name': 'MN-Minnesota'  },
                { 'ID': 'MS'  ,'Name': 'MS-Mississippi'  },
                { 'ID': 'MO'  ,'Name': 'MO-Missouri'  },
                { 'ID': 'MT'  ,'Name': 'MT-Montana'  },
                { 'ID': 'NE'  ,'Name': 'NE-Nebraska'  },
                { 'ID': 'NV'  ,'Name': 'NV-Nevada'  },
                { 'ID': 'NH'  ,'Name': 'NH-New Hampshire'  },
                { 'ID': 'NJ'  ,'Name': 'NJ-New Jersey'  },
                { 'ID': 'NM'  ,'Name': 'NM-New Mexico'  },
                { 'ID': 'NY'  ,'Name': 'NY-New York'  },
                { 'ID': 'NC'  ,'Name': 'NC-North Carolina'  },
                { 'ID': 'ND'  ,'Name': 'ND-North Dakota'  },
                { 'ID': 'OH'  ,'Name': 'OH-Ohio'  },
                { 'ID': 'OK'  ,'Name': 'OK-Oklahoma'  },
                { 'ID': 'OR'  ,'Name': 'OR-Oregon'  },
                { 'ID': 'PA'  ,'Name': 'PA-Pennsylvania'  },
                { 'ID': 'RI'  ,'Name': 'RI-Rhode Island'  },
                { 'ID': 'SC'  ,'Name': 'SC-South Carolina'  },
                { 'ID': 'SD'  ,'Name': 'SD-South Dakota'  },
                { 'ID': 'TN'  ,'Name': 'TN-Tennessee'  },
                { 'ID': 'TX'  ,'Name': 'TX-Texas'  },
                { 'ID': 'UT' , 'Name': 'UT-Utah'  },
                { 'ID': 'VT'  ,'Name': 'VT-Vermont'  },
                { 'ID': 'VA'  ,'Name': 'VA-Virginia'  },
                { 'ID': 'WA' , 'Name': 'WA-Washington'  },
                { 'ID': 'WV' , 'Name': 'WV-West Virginia'  },
                { 'ID': 'WI' , 'Name': 'WI-Wisconsin'  },
                { 'ID': 'WY' , 'Name': 'WY-Wyoming'  },
         ];
         export default USState;