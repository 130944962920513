/* eslint-disable no-unused-vars */
import React from "react";
import Footer from '../Component/Footer/Footer';
import Header from "../Component/Header/header";
import { Link } from "react-router-dom";
import { constant } from "../constant";
import MetaTitle from "../Component/MetaTitle/metaTitle";

export default function TermsCondition() {
    return (
        <>
        <MetaTitle pageTitle={`Terms and Condition - www.rawfares.com`} pageDescription='Rawfares only have the authority to change the Terms and Condition as needed.' url={`${constant.baseUrl}/terms-and-condition`} />
        <Header />
        <section className='uyut7'>
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h1>Terms & Conditions</h1>
                </div>
            </div>
        </div>
    </section>
        <section className='py-4'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        
                        <p>We always look forward with an opportunity to provide the best services to our customers. Your use of this website completely affirms the terms and conditions hereunder. Please ensure you must read the Terms and Conditions carefully before trusting our services offered by us. When you use or make a flight booking on our site, then you are supposed to accept the Terms and Conditions. If in case you do not agree with any of the Terms and Condition, you should not make use of this website, Rawfares only have the authority to change the Terms and Condition as needed</p>
                        <h2>Limited Use of Terms and Conditions</h2>
                        <p>To cancel the flight tickets prior to the departure date, its liability of money will be refunded to the Traveler, towards the canceled ticket. To alter, amend, differ or withdraw by any tour, the facility has been advertised or published by the organization. The organization is not liable for any reimbursement, additional expense, or any loss that is suffered by a group of people or by the traveler.</p>
                        <p>Apart from the organization, no other person is entitled to modify, add, vary, or waive off any representation. Description, Terms, and conditions set forth here, or in the organization brochure, or other Terms and Condition in regards a tour been booked by the Traveler which is authorized by the organization.</p>
                        
                        <h2>To proceed with the Holiday as amended or modified</h2>
                        <p>To agree with any holiday or tour that organization might offer. The organization is not responsible for any of the afore cases of the Traveler/Group for damage, additional expense, and huge loss which is being suffered by the Traveler/Group of people.</p>
                        <p>In no circumstance, the organization is not liable to the Traveler/Group of people for Any death, accident, sickness, personal injury, high expense, delay, loss, or consequential damage by any misadventure or by other similar reason Any default of any carrier, travel element supplier, or by any agent/servant employed by the travel supplier who may be liable for the provision of meals, accommodations, carriage facility, or the Traveler/ Group service, howsoever it is caused. In regards to this, depicts the negligence on the role of any involved person.</p>
                        <p>No responsibility for the organization arise, thorough any means out of the contract with regards to holiday, excursion, tour facility shall initiate the total paid amount or agreed to the paid-for booking the tour, holidays, excursion, in no cases shall include any damage or additional expense whatsoever</p>
                        <p>The price quoted in the organization brochure(s)/quotation is in the USD or its equivalent foreign currency. The Organization is the sole creation to amend the cost if there is a case of currency fluctuation or in a change in exchange rated or arising flue cost, and to surcharged accordingly. All Such prices to be paid whenever there is an amendment in the rates. The Flight Ticket does not cover Insurance premium costs. The Traveler/Group has to make payment for premium cost</p>
                        <p>All tour programs are bound by-laws, rules, and regulations, during the entire tour and traveling. The Organization is not responsible in regards to any condition which is brought about by such laws, or due to the act of creation.</p>
                        <p>Any complaint raised by the traveler (including complaining on behalf of another Traveler or of the group who submitted the same through the Traveler) is associated with or because of the tour should be conveyed to the organization prior to 21 days in written with the detail of the tour end date. Under any circumstances, no form of claim, or complaint shall be entertained with regards to aforesaid dates. No separate complaint of the traveler traveling with the group of people shall be considered, so the same campaign must be made only by the Traveler who is the leader of the entire group.</p>
                        <p>The organization is not responsible for any traveler for refund, compensation, or claim in regards to reducing the number of tour days or postpone, cancellation, re-routing for any scheduled transport service due to come reason like a natural calamity. The rule in regards to the flight cancellation, the refund is applicable.</p>
                        <h2>In Case of Disputes</h2>
                        <p>Rawfares is dedicated to providing customer satisfaction at each time, if in case you have any problem or dispute related to our products or service, we will surely look in to matter at the earliest. Along with this, if we fail to do so, you can claim for the same at your convenience. It has also been explained in the below flowing sections:</p>
                        <p>While dealing with us, you give us an opportunity to resolve disputes, claim regards the website, dealing with our agent, any services, or ay product provided by us, any kind of representation made by us, or privacy policy, by contacting Rawfares customer support division. In case we fail to resolve your claim, you may seek relief through meditation or in court, which has been set forth in the section</p>
                        <p>Any claim can be resolved by mediation rather than court. excluding when you assert a claim on an individual basis in court for a claim if they clear for the same. This includes any claim you state against us, our subsidiaries, or any claim that offers products or services with us.</p>
                        <p>No need for a Judge or jury in the meditation, the review on meditation is prohibited. However, a mediator can compensate the damages and relief on an individual basis as the court itself, and also follow as well as initiate these Terms and Conditions as a court would. American Arbitration Association (AAA) conducts Mediation under its own set of rules, which include the AAA consumer rules. Payment of all administration, mediator fees, is governed by the AAA rules, excluding as provided in the section. You can choose mediation can be conducted over the phone, based on written submission, or in person within the state where you stay or some mutually agreed location</p>
                        <p>The claim will be resolved which is made on an individual basis, not as a class. The Federal Act and Federal Meditation law are applied to the agreement. Meditation decision is confirmed by the court which holds competent jurisdiction.</p>
                        <h2>Things to keep in mind:</h2>
                        <p>At RawFares, the prices displayed are live rates and cover All applicable taxes and fees. Each fare that you see is presented in USD ($). To take advantage of any current promotions or discounts on flight tickets, please call us directly at our contact number (TFN).</p>
                        <p>Kindly note, any promotional offers or cashbacks are available exclusively through Rawfares, not through airlines or other travel agencies. We at RawFares, provide these discounts as a gesture of appreciation to our customers, reflecting a portion of the profit we earn from airlines.</p>
                        <p>You might not find the same deals, discounts, or rates on airline websites. All discounts are applicable only for bookings made via our phone service.</p>
                        <p>Keep in mind that fares are subject to change until the booking is finalized. There are no hidden fees—the prices listed on our website are fully inclusive of all taxes and charges. For further details, please refer to our Payment Policies or reach out to us at any time of the day.</p>
                        <h2>Disclaimer:</h2>
                        <p>The rates, offers, and promotions featured on our website are subject to change and may not always be accurate. There could be occasional errors related to destinations, fares, or deals listed. RawFares is not responsible for any inaccuracies or mistakes concerning travel products and services presented on our site, which include flights, hotels, cruises, vacation packages, and car rentals.</p>
                        <p>We reserve the right to modify, continue, or withdraw any travel promotion, adjust pricing errors, or correct any reservation affected by incorrect pricing or expired offers. If a pricing correction occurs, and subject to availability, you can either maintain your reservation at the updated rate or have it canceled without penalty. Please note that any actions taken regarding flight changes or cancellations are your responsibility.</p>
                        <h2>Trademarks, Brand Names, and Logos:</h2>
                        <p>The trademarks, brand names, and logos shown on our website are used solely to provide accurate and up-to-date information about those specific brands. Their presence here does not imply any affiliation or endorsement between RawFares and the brands in question. All trademarks, names, and logos remain the property of their respective owners.</p>
                    </div>
                </div>
                
            </div>
        </section>
        
        <Footer />
        </>
      )
};