const CAState = [
    { 'ID':'AB', 'Name':'AB-Alberta'  },
    { 'ID':'BC', 'Name':'BC-British Columbia'  },
    { 'ID':'MB', 'Name':'MB-Manitoba'  },
    { 'ID':'NB', 'Name':'NB-New Brunswick'  },
    { 'ID':'NF', 'Name':'NF-Newfoundland and Labrador'  },
    { 'ID':'NT', 'Name':'NT-Northwest Territories'  },
    { 'ID':'NS', 'Name':'NS-Nova Scotia'  },
    { 'ID':'NU', 'Name':'NU-Nunavut'  },
    { 'ID':'ON', 'Name':'ON-Ontario'  },
    { 'ID':'PE', 'Name':'PE-Prince Edward Island'  },
    { 'ID':'QC', 'Name':'QC-Quebec'  },
    { 'ID':'SK', 'Name':'SK-Saskatchewan'  },
    { 'ID':'YT', 'Name':'YT-Yukon'  }];
    export default CAState;