/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, forwardRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { DateRangePicker } from "react-date-range";
import { v4 as uuidv4 } from "uuid";
import {
  browserName,
  isAndroid,
  isDesktop,
  isIOS,
  isMobile,
} from "react-device-detect";
import "react-date-range/dist/styles.css"; // Main style file
import "react-date-range/dist/theme/default.css"; // Theme css file
import exchange from "../../assest/images/icon/exchange.png";
import loweplan from "../../assest/images/icon/aplave.png";
import crofyt from "../../assest/images/icon/crossy.png";

const formatDate = (date) => {
  return date ? format(date, "MM/dd/yyyy") : "";
};
export default function Modifyengine({
  formValues,
  airportsData,
  onSearchClick,
  // setNewForm,
}) {
  const [originOpen, setOriginOpen] = useState(false);
  const [destinationOpen, setDestinationOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [initialOrigin, setInitialOrigin] = useState(localStorage.getItem("selectedOrigin")
  ? JSON.parse(localStorage.getItem("selectedOrigin"))
  : null);
  const [initialdestination, setInitialdestination] = useState(localStorage.getItem("selectedDestination")
  ? JSON.parse(localStorage.getItem("selectedDestination"))
  : null
  );
  const [selectedOrigin, setSelectedOrigin] = useState(null);
  const [travelorVisible, setTravelorVisible] = useState(false);
  const originInputRefClick = useRef(null); // Ref for the input field
  const destinationInputRefClick = useRef(null);
  const today = new Date();
  const oneWeekFromToday = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000); // Adding milliseconds for one week
  const oneYearFromToday = new Date();
  oneYearFromToday.setFullYear(today.getFullYear() + 1); // Add one year to the current date
  // const [selectedStartDate, setSelectedStartDate] = useState(
  //   formValues?.segment[0]?.travelDate
  //     ? new Date(formValues?.segment[0]?.travelDate)
  //     : today
  // );
  const [selectedStartDate, setSelectedStartDate] = useState(
    formValues?.segment[0]?.travelDate ? new Date(formValues.segment[0].travelDate) : today
  );
  // const [selectedEndDate, setSelectedEndDate] = useState(
  //   formValues?.segment[1]?.travelDate
  //     ? new Date(formValues?.segment[1]?.travelDate)
  //     : new Date(selectedStartDate).setDate(selectedStartDate.getDate() + 7)
  // );
  const [selectedEndDate, setSelectedEndDate] = useState(
    formValues?.segment[1]?.travelDate
      ? new Date(formValues.segment[1].travelDate) 
      : new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000) // Ensure correct date calculation
  );
  
  const [selectedOneDate, setSelectedOneDate] = useState(selectedStartDate);
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [tripType, setTripType] = useState(formValues?.tripType || "2");
  const [modifyOpen, setModifyOpen] = useState(false);
  const [totalTraveller, setTotalTraveller] = useState(`Travellor(s)`);
  useEffect(() => {
    const total =
      (formValues?.adults || 0) +
      (formValues?.child || 0) +
      (formValues?.infants || 0) +
      (formValues?.infantsWs || 0);
    setTotalTraveller(`${total}`);
  }, [formValues]);
  // console.log("TotalTraveler", totalTraveller)
  const [adult, setAdult] = useState(formValues?.adults || 1);
  const [child, setChild] = useState(formValues?.child || 0);
  const [infant, setInfant] = useState(formValues?.infants || 0);
  const [infantWs, setInfantWs] = useState(formValues?.infantsWs || 0);

  const [originInputRef, setOriginInputRef] = useState(null);
  const [destinationInputRef, setDestinationInputRef] = useState(null);
  const calendarRef = useRef(null);
  const [selectedDestination, setSelectedDestination] = useState(null);
  const [filteredOriginResults, setFilteredOriginResults] = useState([]);
  const [filteredDestinationResults, setFilteredDestinationResults] = useState(
    []
  );
  const [activeField, setActiveField] = useState(null);
  const [rotation, setRotation] = useState(0);
  const formRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (formRef.current && !formRef.current.contains(event.target)) {
        setActiveField(null); // Close all fields
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const [searchId, setSearchId] = useState("");
  const [modifyFormValues, setModifyFormValues] = useState({
    searchID: "", // Default value or dynamic value
    client: 2,
    segment: [
      {
        originAirport: formValues?.segment[0]?.originAirport,
        destinationAirport: formValues?.segment[0]?.destinationAirport,
        travelDate: formatDate(selectedStartDate),
        originCountry: "US",
        destinationCountry: "ALL",
      },
      {
        originAirport: formValues?.segment[1]?.originAirport,
        destinationAirport: formValues?.segment[1]?.destinationAirport,
        travelDate: formatDate(selectedEndDate),
      },
    ],
    searchDirectFlight: false,
    flexibleSearch: false,
    tripType: formValues?.tripType || "2", // Default to round trip
    adults: formValues?.adults || 1,
    child: formValues?.child || 0,
    infants: formValues?.infants || 0,
    infantsWs: formValues?.infantsWs || 0,
    cabinType: formValues?.cabinType || "1",
    airline: "All",
    currencyCode: "USD",
    siteId: 16,
    source: "Online",
    media: "",
    sID: "",
    rID: "",
    locale: "en",
    isNearBy: false,
    limit: 200,
    userIP: "",
    serverIP: "",
    device: "",
    browser: "", // Define browserName if necessary
  });

  // useEffect(() => {
  //   if (modifyFormValues?.tripType === "2") {
  //     setSelectedEndDate(new Date(selectedStartDate).setDate(selectedStartDate.getDate() + 7));
  //   }
  // }, [modifyFormValues?.tripType]);
  useEffect(() => {
    if (modifyFormValues?.tripType === "2" && !formValues?.segment[1]?.travelDate) {
      setSelectedEndDate(new Date(selectedStartDate.getTime() + 7 * 24 * 60 * 60 * 1000));
    }
  }, [selectedStartDate, modifyFormValues?.tripType]);
  // console.log("modifysearch", modifyFormValues);

  const handleOriginClick = (inputname) => {
    // console.log("called");
    if (inputname === "Origin") {
      setOriginOpen(!originOpen);
      setTimeout(() => {
        originInputRefClick.current?.focus(); // Focus the input box
      }, 0);
      setDestinationOpen(false);
    } else {
      setDestinationOpen(!destinationOpen);
      setTimeout(() => {
        destinationInputRefClick.current?.focus(); // Focus the input box
      }, 0);
      setOriginOpen(false);
    }
  };

  const handleSelect = (item, isOrigin) => {
    if (isOrigin) {
      handleFieldChange("segment.0.originAirport", item.airportCode);
      setFilteredOriginResults([]);
    } else {
      // console.log("item", item);
      handleFieldChange("segment.0.destinationAirport", item.airportCode);
      setFilteredDestinationResults([]);
    }
  };

  const handleFieldChange = (fieldPath, value) => {
    const keys = fieldPath.split(".");
    setModifyFormValues((prevValues) => {
      let updatedValues = JSON.parse(JSON.stringify(prevValues)); // Deep copy to avoid direct mutation
      let currentLevel = updatedValues;
  
      for (let i = 0; i < keys.length - 1; i++) {
        if (!currentLevel[keys[i]]) currentLevel[keys[i]] = {}; // Ensure the path exists
        currentLevel = currentLevel[keys[i]];
      }
  
      currentLevel[keys[keys.length - 1]] = value;
      // console.log("Moddddd", prevValues.tripType)
      // Ensure tripType is 2 before modifying segment[1]
      if (prevValues.tripType === "2" && updatedValues.segment?.[1]) {
        if (fieldPath === "segment.0.originAirport") {
          updatedValues.segment[1].destinationAirport = value;
        } else if (fieldPath === "segment.0.destinationAirport") {
          updatedValues.segment[1].originAirport = value;
        }
      }
  
      return updatedValues;
    });
  };
  
  

  const handleCabinClick = () => {
    setIsActive(!isActive);
  };

  const fetchData = async (searchValue) => {
    // Try to get cached data specific to the search value
    const cachedData = localStorage.getItem(`cachedData_${searchValue}`);

    if (cachedData) {
      const parsedData = JSON.parse(cachedData);
      return parsedData;
    } else {
      try {
        const response = await axios.get(
          `https://www.tgsapi.com/flights/CrossAirportList?data=${searchValue}&authcode=farehuts`
        );
        const fetchedData = response.data;

        // Cache the data specific to the search value
        localStorage.setItem(
          `cachedData_${searchValue}`,
          JSON.stringify(fetchedData)
        );
        return fetchedData;
      } catch (error) {
        console.error("API fetch error:", error);
        return [];
      }
    }
  };

  const handleSearch = async (searchValue, isOrigin) => {
    if (searchValue.length > 2) {
      // Fetch data synchronously
      const data = await fetchData(searchValue);

      const matchingAirportCode = data?.find(
        (item) => item.airportCode.toLowerCase() === searchValue.toLowerCase()
      );

      const filteredByName = data
        .filter(
          (item) =>
            item.airportCode.toLowerCase() !== searchValue.toLowerCase() &&
            (item.airportName
              .toLowerCase()
              .includes(searchValue.toLowerCase()) ||
              item.cityName.toLowerCase().includes(searchValue.toLowerCase()))
        )
        .slice(0, 9);

      const filteredResults = matchingAirportCode
        ? [matchingAirportCode, ...filteredByName]
        : filteredByName;
      // console.log("filteredResults", filteredResults);
      if (isOrigin) {
        setFilteredOriginResults(filteredResults);
      } else {
        setFilteredDestinationResults(filteredResults);
      }
    } else {
      if (isOrigin) {
        setFilteredOriginResults([]);
      } else {
        setFilteredDestinationResults([]);
      }
    }
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        // On smaller screens, display calendars vertically
        setDirection("vertical");
        setMonths(1);
      } else {
        // On larger screens, display calendars horizontally
        setDirection("horizontal");
        setMonths(2);
      }
    }

    // Initial setup
    handleResize();

    // Add event listener to update the layout when the screen size changes
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [direction, setDirection] = useState("horizontal");
  const [months, setMonths] = useState(2);
  const isDayDisabled = (date) => {
    return date < today;
  };

  const handleTripTypeChange = (type) => {
    setTripType(type);
    // console.log(tripType);
    if (type === "1") {
      // Clear return segment if One Way
      setModifyFormValues((prevValues) => ({
        ...prevValues,
        tripType: "1",
        segment: [
          { ...prevValues.segment[0] },
          {
            originAirport: "",
            destinationAirport: "",
            travelDate: "",
          },
        ],
      }));
    } else {
      setModifyFormValues((prevValues) => ({
        ...prevValues,
        tripType: "2",
        segment: [
          { ...prevValues.segment[0] },
          {
            originAirport: prevValues.segment[0].destinationAirport,
            destinationAirport: prevValues.segment[0].originAirport,
            travelDate: dateRange[1] ? formatDate(dateRange[1]) : "",
          },
        ],
      }));
    }
  };
  const handleTravelorClick = () => {
    setTravelorVisible(!travelorVisible);
  };

  const handleMinusClick = (pax, event) => {
    event.stopPropagation();
    
    if (pax === "adult") {
      setAdult((prevAdult) => {
        const newAdult = Math.max(prevAdult - 1, 1); // Ensure at least 1 adult
        setInfant((prevInfant) => Math.min(prevInfant, newAdult)); // Adjust infants
        setInfantWs((prevInfantWs) => Math.min(prevInfantWs, newAdult)); // Adjust infantsWs
        return newAdult;
      });
    } else if (pax === "child") {
      setChild((prevChild) => Math.max(prevChild - 1, 0)); // Minimum 0 children
    } else if (pax === "infant") {
      setInfant((prevInfant) => Math.max(prevInfant - 1, 0)); // Minimum 0 infants
    } else if (pax === "infantWs") {
      setInfantWs((prevInfantWs) => Math.max(prevInfantWs - 1, 0)); // Minimum 0 infantWs
    }
  };
  
  const handlePlusClick = (pax, event) => {
    event.stopPropagation();
    const totalCount = adult + child + infant + infantWs;
  
    if (totalCount < 9) { // Ensure total travelers <= 9
      if (pax === "adult") {
        setAdult((prevAdult) => prevAdult + 1);
      } else if (pax === "child") {
        setChild((prevChild) => prevChild + 1);
      } else if (pax === "infant") {
        if (infant < adult) {
          setInfant((prevInfant) => prevInfant + 1);
        }
      } else if (pax === "infantWs") {
        if (infantWs < adult) {
          setInfantWs((prevInfantWs) => prevInfantWs + 1);
        }
      }
    } else {
      // Handle the error condition if the total traveler count exceeds 9
    }
  };
  
  useEffect(() => {
    setModifyFormValues((prevState) => ({
      ...prevState,
      adults: adult,
      child: child,
      infants: infant,
      infantsWs: infantWs,
    }));
  }, [adult, child, infant, infantWs]);

  const calculateTraveller = () => {
    const totalCount = adult + child + infant + infantWs;
    setTotalTraveller(totalCount || "Travellor(s)");
  };

  useEffect(() => {
    calculateTraveller();
  }, [adult, child, infant, infantWs]);

  const getCabinTypeLabel = (cabinTypeValue) => {
    switch (cabinTypeValue) {
      case "1":
        return "Economy";
      case "2":
        return "Premium Economy";
      case "3":
        return "Business";
      case "4":
        return "First";
      default:
        return "Economy";
    }
  };

  const formatDateForDisplay = (date) => {
    const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
    const month = new Intl.DateTimeFormat("en", { month: "short" }).format(
      date
    );
    const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
      date
    );
    const weekday = new Intl.DateTimeFormat("en", { weekday: "long" }).format(
      date
    );

    return { day, month, year, weekday };
  };

  const formatDateForDisplayReturn = (date) => {
    const dayReturn = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
      date
    );
    const monthReturn = new Intl.DateTimeFormat("en", {
      month: "short",
    }).format(date);
    const yearReturn = new Intl.DateTimeFormat("en", {
      year: "numeric",
    }).format(date);
    const weekdayReturn = new Intl.DateTimeFormat("en", {
      weekday: "long",
    }).format(date);

    return { dayReturn, monthReturn, yearReturn, weekdayReturn };
  };

  // Example usage inside the component
  const { day, month, year, weekday } = formatDateForDisplay(selectedStartDate);
  const { dayReturn, monthReturn, yearReturn, weekdayReturn } =
    formatDateForDisplayReturn(selectedEndDate);

  const navigate = useNavigate();
const [engineError, setEngineError] = useState(false)
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    
    setModifyOpen(false)
    const deviceName = isMobile
      ? "Mobile Device"
      : isDesktop
      ? "Desktop"
      : isAndroid
      ? "Android"
      : isIOS;
    const response = await axios.get("https://api.ipify.org?format=json");
    const userIP = response.data.ip;
    const newSearchID = uuidv4().slice(0, 16);
    setSearchId(newSearchID);
    // console.log("formValues", formValues);
    let updatedSegment = [];
  if (modifyFormValues?.tripType === "2") {
    // Round trip: Use both segments
    updatedSegment = [...modifyFormValues?.segment];
    if (!updatedSegment[1]) {
      updatedSegment[1] = {
        originAirport: updatedSegment[0]?.destinationAirport || "",
        destinationAirport: updatedSegment[0]?.originAirport || "",
        travelDate: formatDate(selectedEndDate), // Ensure travelDate is set
      };
    } else if (!updatedSegment[1].travelDate) {
      updatedSegment[1].travelDate = formatDate(selectedEndDate);
    }
  } else if (modifyFormValues?.tripType === "1") {
    // One-way trip: Use only the first segment
    updatedSegment = [modifyFormValues?.segment[0]];
  }
    const updatedModifyFormValues = {
      ...modifyFormValues,
      segment: updatedSegment,
      searchID: newSearchID,
      userIP: userIP,
      device: deviceName,
    };
    const updatedFormValues = {
      ...modifyFormValues,
      segment: updatedSegment,
      searchID: newSearchID,
      userIP: userIP,
      device: deviceName,
    };
    const fillMissingFields = (base, updated) => {
      if (!base || !updated) return base || updated || {};
      return Object.keys(base).reduce((acc, key) => {
        acc[key] = updated[key] ?? base[key];
        return acc;
      }, {});
    };
    const areObjectsEqualExcludingSearchID = (obj1, obj2) => {
      const keysToIgnore = ["searchID"];
    
      const normalize = (value) =>
        typeof value === "object" && value !== null ? cleanObj(value) : value;
    
      const cleanObj = (obj) => {
        return Object.keys(obj || {})
          .filter((key) => !keysToIgnore.includes(key))
          .sort()
          .reduce((acc, key) => {
            acc[key] = normalize(obj[key]);
            return acc;
          }, {});
      };
    
      return JSON.stringify(cleanObj(obj1)) === JSON.stringify(cleanObj(obj2));
    };
    
    // Fill missing fields and compare
    const normalizedUpdatedFormValues = fillMissingFields(formValues, updatedModifyFormValues);
    
    if (areObjectsEqualExcludingSearchID(normalizedUpdatedFormValues, formValues)) {
      // console.log("Forms are equal; skipping API call.");
      setEngineError(true);
      return;
    }
    onSearchClick();
    setEngineError(false);
    // setTimeout(() => {
    navigate("/listing", { state: { updatedFormValues } });
  // }, 1000);
    setModifyFormValues(updatedModifyFormValues);
    // setNewForm(updatedModifyFormValues);
  };

  const handleExchange = () => {
    setRotation(rotation + 180);
  
    if (selectedOrigin !== null && selectedDestination !== null) {
      setSelectedOrigin(selectedDestination);
      setSelectedDestination(selectedOrigin);
      localStorage.setItem("selectedOrigin", JSON.stringify(selectedDestination));
      localStorage.setItem("selectedDestination", JSON.stringify(selectedOrigin));
      // Both origin and destination are selected, swap them
      handleFieldChange("segment.0.originAirport", selectedDestination.airportCode);
      handleFieldChange("segment.0.destinationAirport", selectedOrigin.airportCode);
    } else if (selectedOrigin !== null && selectedDestination === null) {
      // Only origin is selected, swap with initial destination
      setSelectedOrigin(initialdestination);
      setSelectedDestination(selectedOrigin);
      localStorage.setItem("selectedOrigin", JSON.stringify(initialdestination));
      localStorage.setItem("selectedDestination", JSON.stringify(selectedOrigin));
      handleFieldChange("segment.0.originAirport", initialdestination.airportCode);
      handleFieldChange("segment.0.destinationAirport", selectedOrigin.airportCode);
    } else if (selectedOrigin === null && selectedDestination !== null) {
      // Only destination is selected, swap with initial origin
      setSelectedOrigin(selectedDestination);
      setSelectedDestination(initialOrigin);
      localStorage.setItem("selectedOrigin", JSON.stringify(selectedDestination));
      localStorage.setItem("selectedDestination", JSON.stringify(initialOrigin));
      handleFieldChange("segment.0.originAirport", selectedDestination.airportCode);
      handleFieldChange("segment.0.destinationAirport", initialOrigin.airportCode);
    } else {
      setInitialOrigin(initialdestination);
    setInitialdestination(initialOrigin);
    localStorage.setItem("selectedOrigin", JSON.stringify(initialdestination));
    localStorage.setItem("selectedDestination", JSON.stringify(initialOrigin));
      // Both are null, swap initial values
      handleFieldChange("segment.0.originAirport", initialdestination.airportCode);
      handleFieldChange("segment.0.destinationAirport", initialOrigin.airportCode);
    }
  };
  
  const handleOriginSelect = (item) => {
    // setSelectedOrigin(item);
    localStorage.setItem("selectedOrigin", JSON.stringify(item));
  };

  const handleDestinationSelect = (item) => {
    // setSelectedOrigin(item);
    localStorage.setItem("selectedDestination", JSON.stringify(item));
  };

  return (
   <>
    <section className={`fir-maib-r re64e4 zdujr-pop2 ${modifyOpen === true ? 'khetr' : ''}`}>
    <div className="srh1">
    <button className="kdti4"></button>
    <img className="urhue45" onClick={() => setModifyOpen(!modifyOpen)} src={crofyt} alt="" />

      <div className="container">
        <div className="row align-items-end h-100">
          <div className="col-lg-12" ref={formRef}>
            <div className="engiht12">
              <div className="d-flex align-items-center pb-3 text-white fyt5r" onClick={() => {setActiveField(null)}}>
              <div class={`form-check cursor-point sdrt31 ${tripType === "2" ? 'srr33' : ''}`}>
                  <input
                    class="form-check-input mob-hide cursor-point"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    defaultChecked
                    value="2"
                    checked={tripType === "2"}
                    onChange={() => handleTripTypeChange("2")}
                  />
                  <label
                    class="form-check-label font-500 cursor-point"
                    for="flexRadioDefault2"
                  >
                    {" "}
                    Round Trip{" "}
                  </label>
                </div>
                <div class={`form-check ms-4 cursor-point sdrt31 ${tripType === "1" ? 'srr33' : ''}`}>
                  <input
                    class="form-check-input mob-hide cursor-point"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    value="1"
                    checked={tripType === "1"}
                    onChange={() => handleTripTypeChange("1")}
                  />
                  <label
                    class="form-check-label font-500 cursor-point"
                    for="flexRadioDefault1"
                  >
                    {" "}
                    One Way{" "}
                  </label>
                </div>

                
              </div>

              <div className="row">
                <div className="col-lg-5 rela p-0 sdetr">
                  <div className="row rela">
                    <div className="col-lg-6 sfsdt rela sdetr">
                      <div
                        className="enginer-se sdetr2"
                        onClick={() => {
                          handleOriginClick("Origin");
                          setActiveField(
                            activeField === "origin" ? null : "origin"
                          );
                        }}
                      >
                        {originOpen === false ? (
                          <p className="weghrt w42re">From</p>
                        ) : null}
                        {selectedOrigin ? (
                          <>
                            <p className="edtrdt ds5t43">
                              {selectedOrigin?.cityName}
                            </p>
                            <p className="seroj ryr65">
                              {selectedOrigin?.airportCode},{" "}
                              {selectedOrigin?.airportName}
                            </p>
                          </>
                        ) : (
                          <>
                            <p className="edtrdt ds5t43">{initialOrigin?.cityName}</p>
                            <p className="seroj ryr65">
                              {initialOrigin?.airportCode},{" "} 
                              {initialOrigin?.airportName}
                            </p>
                          </>
                        )}
                      </div>
                      {activeField === "origin" ? (
                        <div className="zdujr-pop khetr">
                          <div className="srh">
                          <button className="kdti4"></button>
                          <img className="urhue4" src={crofyt} onClick={() => {setActiveField(null)}} alt="" />
                          <div class="form-floating r634y">
                            <input
                              type="text"
                              class="form-control sdtst"
                              id="floatingPassword"
                              placeholder="Password"
                              autoComplete="off"
                              onChange={(e) => {
                                handleFieldChange(
                                  "segment.0.originAirport",
                                  e.target.value
                                );
                                handleSearch(e.target.value, true);
                              }}
                              onFocus={() => setFilteredOriginResults([])}
                              inputRef={(ref) => setOriginInputRef(ref)}
                              ref={originInputRefClick}
                            />
                            <label className="asru23" for="floatingPassword">
                              From
                            </label>
                          </div>
                          {filteredOriginResults.length > 0 ? (
                            <p className="srihe mb-0">Filtered Cities</p>
                          ) : null}
                          <div className="uge">
                            {filteredOriginResults.map((item, index) => (
                              <div
                                className="kjdr d-flex align-items-center"
                                key={index}
                              >
                                <img className="urer" src={loweplan} alt="" />
                                <div
                                  className="dhit"
                                  onClick={() => {
                                    handleSelect(item, true);
                                    handleOriginSelect(item);
                                    setSelectedOrigin(item);
                                    setOriginOpen(!originOpen);
                                    setActiveField(null);
                                  }}
                                >
                                  <p className="mb-0 wdtew">
                                    {item.airportName}
                                  </p>
                                  <p className="mb-0 sdtewt">
                                    {item.airportCode}, {item.countryCode}
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                          </div>
                          
                        </div>
                      ) : null}
                    </div>
                    <div className="jkdgt" onClick={handleExchange}>
                      <img
                        src={exchange}
                        alt=""
                        style={{ cursor: "pointer", transform: `rotate(${rotation}deg)`, transition: "transform 0.5s" }}
                        onClick={() => {setActiveField(null)}}
                      />
                    </div>
                    <div className="col-lg-6 sfsdt rela sdetr">
                      <div
                        className="enginer-se sdetr2"
                        onClick={() => {
                          handleOriginClick("Destination");
                          setActiveField(
                            activeField === "destination" ? null : "destination"
                          );
                        }}
                      >
                        {destinationOpen === false ? (
                          <p className="weghrt w42re">To</p>
                        ) : null}
                        {selectedDestination ? (
                          <>
                            <p className="edtrdt ds5t43">
                              {selectedDestination?.cityName}
                            </p>
                            <p className="seroj ryr65">
                              {selectedDestination?.airportCode},{" "}
                              {selectedDestination?.airportName} Airport
                            </p>
                          </>
                        ) : (
                          <>
                            <p className="edtrdt ds5t43">
                              {initialdestination?.cityName}
                            </p>
                            <p className="seroj ryr65">
                              {initialdestination?.airportCode},{" "}
                              {initialdestination?.airportName}
                            </p>
                          </>
                        )}
                      </div>
                      {activeField === "destination" ? (
                        <div className="zdujr-pop khetr">
                          <div className="srh">
                          <button className="kdti4"></button>
                          <img className="urhue4" src={crofyt} onClick={() => {setActiveField(null)}} alt="" />
                          <div class="form-floating r634y">
                            <input
                              type="text"
                              class="form-control sdtst"
                              id="floatingPassword"
                              placeholder="Password"
                              autoComplete="off"
                              onChange={(e) => {
                                handleFieldChange(
                                  "segment.0.destinationAirport",
                                  e.target.value
                                );
                                handleSearch(e.target.value, false);
                              }}
                              onFocus={() => setFilteredDestinationResults([])}
                              inputRef={(ref) => setDestinationInputRef(ref)}
                              ref={destinationInputRefClick}
                            />
                            <label className="asru23" for="floatingPassword">
                              To
                            </label>
                          </div>
                          {filteredDestinationResults.length > 0 ? (
                            <p className="srihe mb-0">Filtered Cities</p>
                          ) : null}
                          <div className="uge">
                            {filteredDestinationResults.map((item, index) => (
                              <div
                                className="kjdr d-flex align-items-center"
                                key={index}
                              >
                                <img className="urer" src={loweplan} alt="" />
                                <div
                                  className="dhit"
                                  onClick={() => {
                                    handleSelect(item, false);
                                    handleDestinationSelect(item);
                                    setSelectedDestination(item);
                                    setDestinationOpen(!destinationOpen);
                                    setActiveField(null);
                                  }}
                                >
                                  <p className="mb-0 wdtew">
                                    {item.airportName}
                                  </p>
                                  <p className="mb-0 sdtewt">
                                    {item.airportCode}, {item.countryCode}
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                          </div>
                          
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {engineError === true ? (
                  <div><p className="orgin-eror"> Please change your search criteria before clicking the "Modify" </p></div>
                  ) : null}
                </div>
                <div className="col-lg-7 p-0">
                  <div className="row">
                    <div className="col-6 col-lg-3 sfsdt">
                      <div
                        className="enginer-se sdetr2"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setCalendarVisible(true);
                          setActiveField(
                            activeField === "calendar" ? null : "calendar"
                          );
                        }}
                      >
                        <p className="weghrt w42re">Departure</p>
                        <p className="edtrdt ds5t43">
                          {day}{" "}
                          <span>
                            {month} {year}
                          </span>
                        </p>
                        <p className="seroj ryr65">{weekday}</p>
                      </div>
                    </div>
                    {activeField === "calendar" ? (
                      <DateRangePicker
                        // ref={calendarRef}
                        onChange={(selectedDates) => {
                          // handleSelect(selectedDates);

                          const newStartDate =
                            selectedDates.selection.startDate;
                          const newEndDate = selectedDates.selection.endDate;

                          // Update selectedStartDate and selectedEndDate directly
                          setSelectedStartDate(newStartDate);
                          setSelectedEndDate(newEndDate);

                          setModifyFormValues((prevState) => ({
                            ...prevState,
                            segment: [
                              {
                                ...prevState.segment[0],
                                travelDate: formatDate(newStartDate),
                              },
                              {
                                ...prevState.segment[1],
                                travelDate: formatDate(newEndDate),
                              },
                            ],
                          }));

                          // console.log(
                          //   "selectedDates.selection.endDate",
                          //   newEndDate
                          // );

                          if (tripType === "2") {
                            // Use a ref to track the last click time
                            const now = Date.now();
                      
                            if (
                              newEndDate &&
                              newEndDate.getTime() === newStartDate.getTime()
                            ) {
                              if (window.lastClickTime && now - window.lastClickTime < 300) {
                                // Double-click detected: close the calendar
                                // console.log("Round trip for the same day selected (double-click)");
                                setCalendarVisible(false);
                                setActiveField(null);
                              } else {
                                // Set the last click time for single click
                                window.lastClickTime = now;
                              }
                            } else if (
                              newEndDate &&
                              newEndDate.getTime() !== newStartDate.getTime()
                            ) {
                              // If the dates are different, close the calendar
                              setCalendarVisible(false);
                              setActiveField(null);
                            }
                          } else {
                            // For other trip types, close the calendar after selecting the start date
                            setCalendarVisible(false);
                            setActiveField(null);
                          }
                        }}
                        ranges={[
                          {
                            startDate: selectedStartDate,
                            endDate: selectedEndDate,
                            key: "selection",
                          },
                        ]}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={months}
                        showMonthAndYearPickers={true} // Show month and year picker buttons
                        showDateDisplay={true} // Show the date display on the top
                        direction={direction}
                        minDate={today}
                        maxDate={oneYearFromToday}
                        isDayBlocked={isDayDisabled} // Disable past dates
                        style={{
                          width: "300px",
                          height: "250px",
                          borderColor: "gray",
                          borderRadius: "3px",
                          zIndex: "5",
                        }}
                      />
                    ) : null}
                    <div className="col-6 col-lg-3 sfsdt">
                      <div className="enginer-se sdetr2">
                        <p className="weghrt w42re">Return</p>
                        {tripType === "2" ? (
                          <div onClick={() => {setCalendarVisible(true); setActiveField(activeField === "calendar" ? null : "calendar")}}>
                            <p
                              className="edtrdt ds5t43"
                              onClick={() => {
                                setCalendarVisible(true);
                                setActiveField(
                                  activeField === "calendar" ? null : "calendar"
                                );
                              }}
                            >
                              {dayReturn}{" "}
                              <span>
                                {monthReturn} {yearReturn}
                              </span>
                            </p>
                            <p className="seroj ryr65">{weekdayReturn}</p>
                          </div>
                        ) : (
                          <>
                            <p
                              className="seroj ryr65"
                              onClick={() => handleTripTypeChange("2")}
                            >
                              Select Round trip
                              <br /> for Return Date
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-3 sfsdt rela">
                      <div
                        className="enginer-se sdetr2"
                        onClick={() => {
                          handleTravelorClick();
                          setActiveField(
                            activeField === "traveler" ? null : "traveler"
                          );
                        }}
                      >
                        <p className="weghrt w42re">Traveler(s) & Class</p>
                        <p className="edtrdt ds5t43">
                          {totalTraveller} <span>Traveler</span>
                        </p>
                        <p className="seroj ryr65">
                          {getCabinTypeLabel(modifyFormValues?.cabinType)}
                        </p>
                      </div>
                      {activeField === "traveler" ? (
                        <div className="zdujr-pop1 khetr">
                          <div className="srh">
                          <button className="kdti4"></button>
                          <img className="urhue4" onClick={() => {setActiveField(null)}} src={crofyt} alt="" />
                          <div className="row align-items-center px-2 py-lg-2 swtr3s">
                            <div className="col-lg-6 se6tet">
                              <p className="dtstset">Adult</p>
                              <div className="d-flex align-items-center">
                                <button
                                  className="teiyr"
                                  onClick={(e) => handleMinusClick("adult", e)}
                                >
                                  -
                                </button>
                                <p className="mb-0 fdtyr">
                                  {adult}
                                </p>
                                <button
                                  className="teiyr"
                                  onClick={(e) => handlePlusClick("adult", e)}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                            <div className="col-lg-6 se6tet">
                              <p className="dtstset">
                                Child{" "}
                                <span className="mb-0 srwsrr1">
                                  (0-12 Years)
                                </span>
                              </p>
                              <div className="d-flex align-items-center">
                                <button
                                  className="teiyr"
                                  onClick={(e) => handleMinusClick("child", e)}
                                >
                                  -
                                </button>
                                <p className="mb-0 fdtyr">
                                  {child}
                                </p>
                                <button
                                  className="teiyr"
                                  onClick={(e) => handlePlusClick("child", e)}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="row align-items-center pt-lg-2 px-2">
                            <div className="col-lg-6 se6tet">
                            <div className="fdhy5">
                              <p className="dtstset mb-0">
                                Infant{" "}
                                <span className="mb-0 srwsrr1">lap</span>
                              </p>
                              <span className="mb-0 srwsrr1">(0-2 Years)</span>
                              </div>
                              <div className="d-flex align-items-center">
                                <button
                                  className="teiyr"
                                  onClick={(e) => handleMinusClick("infant", e)}
                                >
                                  -
                                </button>
                                <p className="mb-0 fdtyr">
                                  {infant}
                                </p>
                                <button
                                  className="teiyr"
                                  onClick={(e) => handlePlusClick("infant", e)}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                            <div className="col-lg-6 se6tet">
                            <div className="fdhy5">
                              <p className="dtstset mb-0">
                                Infant{" "}
                                <span className="mb-0 srwsrr1">seat</span>
                              </p>
                              <span className="mb-0 srwsrr1">(0-2 Years)</span>
                              </div>
                              <div className="d-flex align-items-center">
                                <button
                                  className="teiyr"
                                  onClick={(e) =>
                                    handleMinusClick("infantWs", e)
                                  }
                                >
                                  -
                                </button>
                                <p className="mb-0 fdtyr">
                                  {infantWs}
                                </p>
                                <button
                                  className="teiyr"
                                  onClick={(e) =>
                                    handlePlusClick("infantWs", e)
                                  }
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>
                          <hr className="d-none d-md-block"/>
                          <div className="row px-2">
                            <div className="col-6">
                              <button
                                className={`sert4 ${
                                  modifyFormValues?.cabinType === "1"
                                    ? "activrt"
                                    : ""
                                }`}
                                onClick={() =>
                                  setModifyFormValues((prevState) => ({
                                    ...prevState,
                                    cabinType: "1",
                                  }))
                                }
                              >
                                Economy
                              </button>
                            </div>
                            <div className="col-6">
                              <button
                                className={`sert4 ${
                                  modifyFormValues?.cabinType === "2"
                                    ? "activrt"
                                    : ""
                                }`}
                                onClick={() =>
                                  setModifyFormValues((prevState) => ({
                                    ...prevState,
                                    cabinType: "2",
                                  }))
                                }
                              >
                                Premium Economy
                              </button>
                            </div>
                            <div className="col-6">
                              <button
                                className={`sert4 mb-0 ${
                                  modifyFormValues?.cabinType === "3"
                                    ? "activrt"
                                    : ""
                                }`}
                                onClick={() =>
                                  setModifyFormValues((prevState) => ({
                                    ...prevState,
                                    cabinType: "3",
                                  }))
                                }
                              >
                                Business
                              </button>
                            </div>
                            <div className="col-6">
                              <button
                                className={`sert4 mb-0 ${
                                  modifyFormValues?.cabinType === "4"
                                    ? "activrt"
                                    : ""
                                }`}
                                onClick={() =>
                                  setModifyFormValues((prevState) => ({
                                    ...prevState,
                                    cabinType: "4",
                                  }))
                                }
                              >
                                First
                              </button>
                            </div>
                          </div>
                          <hr />
                          <div
                            className="yhsdt4 px-2 pb-3"
                            onClick={() => {
                              setTravelorVisible(false);
                              setActiveField(null);
                            }}
                          >
                            <button className="sttde5">Apply</button>
                          </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-lg-3 sfsdt" onClick={(e) => {handleFormSubmit(e); setActiveField(null)}}>
                      <button className="asrije" onClick={(e) => {handleFormSubmit(e); setActiveField(null)}}>
                        Modify
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
    <section className='jai456' onClick={() => setModifyOpen(!modifyOpen)}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-10">
            <div className="kfy">
                      <div className="d-flex">
                      
                      <div className='srgd2 ejt6t5'>{selectedOrigin ? (<span>{selectedOrigin?.airportCode}</span>) : (<span>{initialOrigin?.airportCode}</span>)} <i class="bi bi-arrow-left-right arrow"></i> {selectedDestination ? (<span>{selectedDestination?.airportCode}</span>) : ( <span>{initialdestination?.airportCode}</span> )}</div>
                    
                      <div className='srgd2 dftye3 mb-0'><span>{month} {day}, {year }</span> {tripType === "2" ? (<><span>-</span> <span>{monthReturn} {dayReturn}, {yearReturn}</span></>) : null}</div>
                      </div>
                      <p className='dfedt mb-0'>{totalTraveller} traveler, {getCabinTypeLabel(formValues?.cabinType)}</p>
            </div>
          </div>
          <div className="col-2">
            <button className='osru3'>edit</button>
          </div>
        </div>
      </div>
    </section>
   </>
  );
}
