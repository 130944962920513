import { useLocation } from "react-router-dom";

const usePageData = () => {
    const location = useLocation();
    const url = location.pathname;

    let pageData = { isSpecialPage: false };

    if (url.includes("/flights/cheap-flights-to")) {
        // City Page: Extract airport code
        const match = url.match(/-([a-zA-Z]{3})$/);
        // console.log("match", match);
        if (match) {
            pageData = { isSpecialPage: true, type: "city", origin: match[1].toUpperCase() };
        }
    } 
    
    if (url.includes("/flights/cheap-flights-from")) {
        // Route Page: Extract origin and destination codes
        const match = url.match(/-([a-zA-Z]{3})-([a-zA-Z]{3})$/);
        if (match) {
            pageData = {
                isSpecialPage: true,
                type: "route",
                origin: match[1].toUpperCase(),
                destination: match[2].toUpperCase(),
            };
        }
    }

    return pageData;
};

export default usePageData;
