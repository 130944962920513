/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { constant } from "../constant";
import Header from "../Component/Header/header";
import Footer from "../Component/Footer/Footer";
import img1 from '../assest/images/cruise-img/cl-1.png';
import img2 from '../assest/images/cruise-img/cl-2.png';
import img3 from '../assest/images/cruise-img/cl-3.png';
import img4 from '../assest/images/cruise-img/cl-4.png';
import img5 from '../assest/images/cruise-img/cl-5.png';
import img6 from '../assest/images/cruise-img/cl-6.png';
import img7 from '../assest/images/cruise-img/cl-7.png';
import img8 from '../assest/images/cruise-img/cl-8.png';
import img9 from '../assest/images/cruise-img/cl-9.png';
import img10 from '../assest/images/cruise-img/cl-10.png';
import img21 from '../assest/images/cruise-img/cl-21.png';
import img12 from '../assest/images/cruise-img/cl-12.png';
import img13 from '../assest/images/cruise-img/cl-13.png';
import img22 from '../assest/images/cruise-img/cl-22.png';
import img15 from '../assest/images/cruise-img/cl-15.png';
import img16 from '../assest/images/cruise-img/cl-16.png';
import img17 from '../assest/images/cruise-img/cl-17.png';
import img18 from '../assest/images/cruise-img/cl-18.png';
import img19 from '../assest/images/cruise-img/cl-19.png';
import img20 from '../assest/images/cruise-img/cl-20.png';
import MetaTitle from '../Component/MetaTitle/metaTitle';

export default function Cruise() {
    const [utmParams, setUtmParams] = useState({});
     const location = useLocation();
    const [cruiseForm, setCruiseForm] = useState({
        destination: "",
        month: "",
        nights: "",
        cruiseName: "",
        fullName: "",
        email: "",
        phoneNumber: "",
        remarks: "",
    });

     useEffect(() => {
        const storeUtmData = () => {
          const queryParams = new URLSearchParams(window.location.search);
          // console.log("queryParams", queryParams)
          const utmData = {};
      
          // Iterate through query parameters and extract UTM parameters
          for (let [key, value] of queryParams.entries()) {
            if (key.startsWith("utm_")) {
              utmData[key] = value;
            }
          }
      
          if (Object.keys(utmData).length > 0) {
            const expirationTime = new Date().getTime() + 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
            const dataWithExpiration = {
              utmData,
              expiresAt: expirationTime,
            };
      
            // Store UTM data in localStorage
            localStorage.setItem("utm_data", JSON.stringify(dataWithExpiration));
            setUtmParams(utmData); // Update state
          }
        };
      
        // Execute on query parameter change
        storeUtmData();
      }, [location.search]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCruiseForm((prev) => ({ ...prev, [name]: value }));
    };

    const validateForm = (form) => {
        const errors = {};
    
        // Check each field for validation
        if (!form.destination.trim() || /\d/.test(form.destination)) 
            errors.destination = "Destination cannot contain numbers and is required.";
        
        if (!form.month.trim()) 
            errors.month = "Month is required.";
        
        if (!form.nights.trim()) 
            errors.nights = "Please enter a valid number of nights.";
        
        if (!form.cruiseName.trim()) 
            errors.cruiseName = "Cruise name is required.";
        
        if (!form.fullName.trim()) 
            errors.fullName = "Full name is required.";
        
        if (!form.email.trim() || !/\S+@\S+\.\S+/.test(form.email)) 
            errors.email = "Please enter a valid email.";
        
        if (!form.phoneNumber.trim() || !/^\d{10}$/.test(form.phoneNumber)) 
            errors.phoneNumber = "Phone number must be a valid 10-digit number.";
        
        if (!form.remarks.trim()) 
            errors.remarks = "Remarks are required.";
    
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const errors = validateForm(cruiseForm);
    if (Object.keys(errors).length > 0) {
        alert("Please fix the errors:\n" + Object.values(errors).join("\n"));
        return;
    }

        // Send data to the backend API
        try {
            const response = await fetch(`${constant.baseUrl}/api/ppc/cruise-form`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(cruiseForm),
            });

            if (response.ok) {
                alert("Enquiry sent successfully!");
                setCruiseForm({
                    destination: "",
                    month: "",
                    nights: "",
                    cruiseName: "",
                    fullName: "",
                    email: "",
                    phoneNumber: "",
                    remarks: "",
                });
            } else {
                alert("Failed to send enquiry. Please try again later.");
            }
        } catch (error) {
            console.error("Error sending enquiry:", error);
            alert("An error occurred. Please try again.");
        }
    };
  return (
    <>
    <MetaTitle pageTitle='Find Cheap Cruise Packages | Last-Minute Deals on Cruise Vacation Sales' pageDescription='With Rawfares, you can find exciting cruise vacations and last-minute cruise deals. Booking cheap cruise packages is all about timing and flexibility.' url={`${constant.baseUrl}/cruise`} />
    <Header />
      <section className="uyut7">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>Find Last Minute Cruise Deals</h1>
            </div>
          </div>
        </div>
      </section>
      <section>
                <div className="container pt-4">
                <div class="row">
          <div class="search_form srchbg">
            <div class="search_list">
                <div class="form_items">
                    <form id="CruiseSearch" onSubmit={handleSubmit}>
                    
                        <div class="row">
                            <div class="col-lg-3 ">
                              <div class="form-floating mb-3">
                                  <input type="text" class="form-control" id="floatingInput" placeholder="Destination" name="destination" value={cruiseForm.destination} onChange={handleChange} />
                                  <label for="floatingInput">Destination</label>
                              </div>
                            </div>
                            <div class="col-lg-3">
                                 <select class="form-select awrzr6 mb-3" aria-label="Default select example" name="month" value={cruiseForm.month} onChange={handleChange}>
                                        <option value="0">Sail Months</option>
                                         <option value="Jan-2025">Jan-2025</option>
                                         <option value="Feb-2025">Feb-2025</option>
                                         <option value="Mar-2025">Mar-2025</option>
                                         <option value="Apr-2025">Apr-2025</option>
                                         <option value="May-2025">May-2025</option>
                                         <option value="Jun-2025">Jun-2025</option>
                                         <option value="Jul-2025">Jul-2025</option>
                                         <option value="Aug-2025">Aug-2025</option>
                                    </select>
                            
                            </div>
                            <div class="col-lg-3">
                                    <select class="form-select awrzr6 mb-3" aria-label="Default select example" name="nights" value={cruiseForm.nights} onChange={handleChange}>
                                        <option value="0">Select Nights</option>
                                        <option value="1-3">1 To 3 night(s)</option>
                                        <option value="4-6">4 To 6 night(s)</option>
                                        <option value="7-9">7 To 9 night(s)</option>
                                        <option value="10-13">10 To 13 night(s)</option>
                                        <option value="more than 13">More than 13 night(s)</option>
                                    </select>

                            </div>
                            <div class="col-lg-3">
                                    <select class="form-select awrzr6 mb-3" aria-label="Default select example" name="cruiseName" value={cruiseForm.cruiseName} onChange={handleChange}>
                                    <option value="0">Select Cruise Line</option>
                                     <option value="Angriya Cruises">Angriya Cruises</option>
                                     <option value="Carnival Cruise Line">Carnival Cruise Line</option>
                                     <option value="Celebrity Cruises">Celebrity Cruises</option>
                                     <option value="Cordelia Cruises">Cordelia Cruises</option>
                                     <option value="Costa Cruises">Costa Cruises</option>
                                     <option value="Cunard Line">Cunard Line</option>
                                     <option value="Disney Cruise Line">Disney Cruise Line</option>
                                     <option value="Dream Cruises">Dream Cruises</option>
                                     <option value="Holland America Line">Holland America Line</option>
                                     <option value="MSC Cruises">MSC Cruises</option>
                                     <option value="Norwegian Cruise Line">Norwegian Cruise Line</option>
                                     <option value="Princess Cruises">Princess Cruises</option>
                                     <option value="Royal Caribbean International">Royal Caribbean International</option>
                                    </select>
                            </div>

                           
                        </div>

                        <div class="row name">
                            <div class="col-lg-3">
                            <div class="form-floating mb-3">
                                  <input type="text" class="form-control" id="floatingInput" placeholder="Full Name" name="fullName" value={cruiseForm.fullName} onChange={handleChange} />
                                  <label for="floatingInput">Full Name</label>
                              </div>
                            </div>
                            <div class="col-lg-3">
                            <div class="form-floating mb-3">
                                  <input type="email" class="form-control" id="floatingInput" placeholder="Enter Your Email" name="email" value={cruiseForm.email} onChange={handleChange} />
                                  <label for="floatingInput">Email Address</label>
                              </div>
                            </div>
                            <div class="col-lg-3">
                            <div class="form-floating mb-3">
                                  <input type="text" class="form-control" id="floatingInput" placeholder="Phone Number" name="phoneNumber" value={cruiseForm.phoneNumber} onChange={handleChange} maxlength="10" />
                                  <label for="floatingInput">Phone Number</label>
                              </div>
                            </div>
                            <div class="col-lg-3">
                            <div class="form-floating mb-3">
                                  <input type="text" class="form-control" id="floatingInput" placeholder="Your Remark" name="remarks" value={cruiseForm.remarks} onChange={handleChange} />
                                  <label for="floatingInput">Your Remark</label>
                              </div>  
                            </div>
                        </div>

                        <div className='ier4i'>
                        <button type="submit" className='uet54'>Serach</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        </div>
                </div>
            </section>
      <section>
        <div class="container pt-5">
          <div class="col-md-12 col-xs-12">
            <div class="wpb_wrapper">
              <h2 >
                Cruise Packages by RawFares for your Dream Vacation
              </h2>
              <p class="text-justify"> A cruise offers the ultimate escape, blending relaxation and adventure all in one journey. From breathtaking ocean views to
                exciting on-board activities, every moment promises something
                special. With a variety of destinations and cruise lines
                available, finding the perfect package has never been easier. Be
                you seeking peaceful shores or vibrant cityscapes, these cruises
                cater to every type of traveler as elaborated on this page by
                RawFares.
              </p>
              <h4>What are the category of Cruises available here?</h4>
              <p class="text-justify">
                When booking a cruise, travelers can choose from various
                categories based on their preferences and vacation goals on our
                RawFares site. From luxurious ocean liners to adventure-filled
                expedition cruises, there’s something for every type of
                wanderer. <b>Luxury cruises</b> offer top-notch amenities,
                exclusive services, and stunning destinations.{" "}
                <b>Family-friendly cruises</b> combine entertainment,
                relaxation, and child-focused activities for an unforgettable
                experience. For those seeking an immersive journey, river
                cruises offer scenic views and a chance to explore picturesque
                towns and cities along famous waterways.{" "}
              </p>
              <p class="text-justify">
                Among the <b>most popular cruise lines</b>, there are some
                standout names that offer exceptional experiences. Lines like
                Royal Caribbean are known for their innovative ships and a vast
                array of onboard activities. Norwegian Cruise Line impresses
                with flexible itineraries and a laid-back atmosphere, ideal for
                those who prefer more freedom while traveling. Celebrity Cruises
                is perfect for those looking for modern luxury with an upscale
                feel. For adventure-seekers, Viking Ocean Cruises provides
                culturally enriching voyages to some of the world’s most exotic
                destinations.
              </p>
              <h4>What are some popular cruise destinations?</h4>
              <p class="text-justify">
                There are countless stunning destinations to explore on a
                cruise, each offering its own unique charm and allure.
              </p>
              <p class="text-justify">
                The Caribbean remains a favorite, with its crystal-clear waters,
                white sandy beaches, and vibrant islands like the Bahamas,
                Jamaica, and the Cayman Islands. Travelers can spend their days
                lounging on picturesque shores or exploring lush jungles and
                rich cultural histories.
              </p>
              <p class="text-justify">
                For those seeking something a bit cooler, Alaska’s breathtaking
                fjords and towering glaciers are a must- see. Cruises to this
                remote region provide a front-row seat to nature’s raw beauty,
                with opportunities to witness wildlife like whales and bears in
                their natural habitats.
              </p>
              <p class="text-justify">
                Mediterranean <b>cruise packages</b> captivate those with a love
                for history, art, and stunning coastlines. Stops in places like
                Italy, Greece, and Spain allow travelers to enjoy world-class
                architecture, ancient ruins, and mouthwatering cuisine.
              </p>
              <p class="text-justify">
                Closer to home, river cruises through Europe offer a more
                intimate experience, allowing travelers to float through
                charming towns and cities, where they can get a closer look at
                local cultures and picturesque landscapes.
              </p>
              <p class="text-justify">
                Regardless of the destination, each cruise is an opportunity to
                experience something unforgettable.
              </p>
              <h4>How to get cheap Cruise packages?</h4>
              <p class="text-justify">
                Finding <b>cheap cruise packages</b> is all about timing and
                flexibility. One of the best ways to score a deal is by booking
                early. Many cruise lines offer discounted prices for early
                bookings, giving travelers the chance to secure their spot at a
                lower rate. If you can plan ahead, this is an excellent option
                to get the most value for your cruise.
              </p>
              <p class="text-justify">
                Also, <b>last-minute deals on Cruise packages</b> can also be a
                great way to save money. As cruise departure dates approach,
                cruise lines may offer discounted rates to fill remaining
                cabins. If you’re flexible with your travel dates and don’t mind
                spontaneous planning, last-minute offers can provide significant
                savings.
              </p>
              <p class="text-justify">
                Another way to save is by considering off-peak seasons. Cruises
                during non-holiday periods, such as late fall or early spring,
                are typically less expensive. Prices rise during peak vacation
                times, so traveling outside of these months can lead to lower
                fares and fewer crowds.
              </p>
              <p class="text-justify">
                Then, signing up for email alerts and newsletters from cruise
                lines can help you stay informed about exclusive promotions,
                special offers, and limited-time discounts, making it easier to
                grab the <b>best deals on cruise packages</b>.
              </p>
              <h4>Best cruise deals in 2025/2026</h4>
              <ol>
                <li>
                  <b>Early Booking Rewards:</b>Take advantage of early bird
                  offers that slash prices on Cruise deals while adding perks
                  like complimentary upgrades and on-board credits.
                </li>
                <li>
                  <b>Seasonal Discounts:</b>Certain times of the year feature
                  unbeatable rates, perfect for planning budget-friendly
                  getaways.
                </li>
                <li>
                  <b>All-Inclusive Cruise Packages:</b>Bundle options that cover
                  meals, drinks, and entertainment ensure great value without
                  compromising quality.
                </li>
                <li>
                  <b>Last-Minute Cruise Deals Opportunities:</b>For the
                  spontaneous traveler, discounted cabins can be found close to
                  sailing dates, offering incredible savings.
                </li>
                <li>
                  <b>Family and Group Deals:</b>Special fares designed for
                  larger groups make cruises an affordable option for reunions
                  or celebrations.
                </li>
              </ol>
              <p class="text-justify">
                With these <b>cruise package deals</b>, enjoying luxury at sea
                has never been so attainable.
              </p>
              <p class="text-justify">
                <b>Cruise packages</b> from us can help provide an unforgettable
                experience that goes beyond just a getaway. The right package
                ensures guests have everything they need for a worry-less
                journey, from comfortable accommodations to thrilling
                excursions. With flexible options available, it’s possible to
                tailor a cruise to any budget or schedule. Be if you're planning
                a quick weekend retreat or an extended adventure, booking the
                right cruise opens the door to unforgettable memories at sea.
                You can reach out to us at RawFares for more details on{" "}
                <b>discounts for Cruise packages</b>.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
          <div class="container pt-4 pb-4">
                <h3 class="text-center">Top Cruise Lines</h3>
                <div class="clients-container">
                    <div class="clients">
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="100">
                            <img class="img01" src={img1}  alt=""/>
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="200">
                            <img src={img2} alt="" class="img01" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="300">
                            <img src={img3} alt="" class="img01" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="400">
                            <img src={img4} alt="" class="img01" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="500">
                            <img src={img5} alt="" class="img01" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="600">
                            <img src={img6} alt="" class="img01" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="700">
                            <img src={img7} alt="" class="img01" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="800">
                            <img src={img8} alt="" class="img01" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="100">
                            <img src={img9} alt="" class="img01" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="200">
                            <img src={img10} alt="" class="img01" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="300">
                            <img src={img21} alt="" class="img01" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="400">
                            <img src={img12} alt="" class="img01" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="500">
                            <img src={img13} alt="" class="img01" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="600">
                            <img src={img22} class="img01" alt="" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="700">
                            <img src={img15} alt="" class="img01" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="800">
                            <img src={img16} alt="" class="img01" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="100">
                            <img src={img17} alt="" class="img01" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="200">
                            <img src={img18} alt="" class="img01" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="300">
                            <img src={img19} alt="" class="img01" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="400">
                            <img src={img20} alt="" class="img01" />
                        </div>
                      
    
                    </div>
                </div>
    
            </div>
            </section>
      <Footer />
    </>
  );
}
