import React, { useEffect } from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import Home from '../Pages/Home';
import Listing from '../Pages/Listing';
import Payment from '../Pages/payment';
import Confirmation from '../Pages/confirmation';
import Landing from '../Component/LandingPage/Landing';
import Flights from '../Pages/Flights/Flights';
import Sitemap from '../Pages/sitemap';
import Privacy from '../Pages/privacypolicy';
import TermsCondition from '../Pages/termscondition';
import Disclaimer from '../Pages/disclaimer';
import Aboutus from '../Pages/Aboutus';
import Contact from '../Pages/ContactUs';
import Ourservicesfees from '../Pages/our-services-fees';
import Postticketing from '../Pages/post-ticketing';
import Taxesfees from '../Pages/taxes-and-fees';
import Airbaggagesfees from '../Pages/baggage-fees';
import Onlinecheckin from '../Pages/online-check-in';
import Deals from '../Pages/Deals';
import Cruise from '../Pages/cruise';
import Airline from '../Pages/Airline/airline';
import Thankyou from '../Pages/thankyou';
import Flight from '../Pages/flight';

export default function Routers() {
  const ScrollToTop = () => {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
  };
  const location = useLocation();

 useEffect(() => {
  const loadGtag = (id, callback) => {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
    
    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() { window.dataLayer.push(arguments); }
      window.gtag = gtag;

      gtag('js', new Date());
      gtag('config', id);

      if (callback) callback();
    };

    document.head.appendChild(script);
  };

  // Load all necessary gtag scripts
  loadGtag('G-4T4E0J0CXE');
  loadGtag('AW-10877497550', () => {
    window.gtag('config', 'AW-10877497550/0ShWCMaM1asZEM7h5cIo', {
      'phone_conversion_number': '(888) 415-1536'
    });
  });
  loadGtag('G-K0QR4DHC3F');

  // Conversion tracking function
  const gtag_report_conversion = (url) => {
    const callback = () => {
      if (typeof url !== 'undefined') {
        window.location = url;
      }
    };
    if (window.gtag) {
      window.gtag('event', 'conversion', {
        'send_to': 'AW-10877497550/r_CRCJeH1asZEM7h5cIo',
        'event_callback': callback
      });
    }
    return false;
  };

  // Add this function to global scope if you want to call it from other components
  window.gtag_report_conversion = gtag_report_conversion;

}, [location]);
  return (
    <>
    <ScrollToTop />
    <Routes>
         <Route path='/home' element={<Navigate to='/'/>} />
         <Route exact path='/' element={<Home />} />
         <Route path='/listing' element={<Listing />} />
         <Route path='/payment' element={<Payment />} />
         <Route path='/confirmation' element={<Confirmation />} />
         <Route path='/landing' element={<Landing />} />
         <Route path='/flights/:airlineName' element={<Flights />} />
         <Route path='/deals/:airlineName' element={<Deals />} />
         <Route path='/sitemap' element={<Sitemap />} />
         <Route path='/privacy-policy' element={<Privacy />} />
         <Route path='/terms-and-condition' element={<TermsCondition />} />
         <Route path='/disclaimer' element={<Disclaimer />} />
         <Route path='/about' element={<Aboutus />} />
         <Route path='/contact-us' element={<Contact />} />
         <Route path='/our-services-fee' element={<Ourservicesfees />} />
         <Route path='/post-ticketing-fee' element={<Postticketing />} />
         <Route path='/taxes-and-fees' element={<Taxesfees />} />
         <Route path='/airline-baggage-fee' element={<Airbaggagesfees />} />
         <Route path='/online-check-in' element={<Onlinecheckin />} />
         <Route path='/cruise' element={<Cruise />} />
         <Route path='/airline/:airlineName' element={<Airline />} />
         <Route path='/thankyou' element={<Thankyou />} />
         <Route path='/flights' element={<Flight />} />
         {/* <Route path='/airline' element={<Airline />} /> */}
    </Routes>
    </>
  )
}
