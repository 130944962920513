import { BrowserRouter } from 'react-router-dom'
import Layout from './Layout/Layout';
// import Listing from './Pages/Listing';

function App() {
  return (
    <>
    <BrowserRouter>
    <Layout/>
     {/* <Listing /> */}
    </BrowserRouter>
    </>
  );
}

export default App;
